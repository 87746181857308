import React from 'react';
import {Link} from 'react-router-dom';
import LocalizedMessage from '../../../components/LocalizedMessage';
import classes from '../Header.module.scss';
import {isOnPointsGroup} from '../../../config';
import {UILogo} from '../../UILogo';

const Logo = () => (
  <Link
    to='/'
    className={classes.Logo}
  >
    {isOnPointsGroup && <UILogo className={classes.LogoImage} />}
    {!isOnPointsGroup && (
      <LocalizedMessage
        id='site.shortName'
      >
        {localizedMessage => (
          <img
            src='/img/logo.png'
            alt={localizedMessage}
            className={classes.LogoImage}
          />
        )}
      </LocalizedMessage>
    )}
  </Link>
);

export default Logo;
