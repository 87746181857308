import React from 'react';
import cx from 'classnames';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import classes from '../Table.module.scss';

const AddBtn = () => {
  return (
    <div
      className={cx(
        classes.AddDataBtn,
        'icon-plus-circle'
      )}
    >
      <LocalizedMessage
        id='persona.table.cells.addMeasurement'
      />
    </div>
  );
};

export default AddBtn;
