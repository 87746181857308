import {arrayToObject, isJSON} from '../../helpers/utils';
import {fetchResponse} from './helpers/fakeFetch';
import settingsOlap from '../../mocks/settings-olap.json';

const settingsOlapIdMap = arrayToObject(settingsOlap, 'id');
const settingsOlapKeyMap = arrayToObject(settingsOlap, 'url');

const getMemberListById = (id, memberLists) => {
  for (let catalogKey in memberLists) {
    if (memberLists.hasOwnProperty(catalogKey)) {
      for (let i = 0; i < memberLists[catalogKey].length; i++) {
        if (memberLists[catalogKey][i].id === id) {
          return {
            ...memberLists[catalogKey][i],
            catalogId: settingsOlapKeyMap[catalogKey].id
          };
        }
      }
    }
  }

  return null;
};

const memberListsAPI = {
  getById (id) {
    console.log('api.memberLists.getById(', id, ')');

    const memberLists = localStorage.getItem('memberLists');

    const currentMemberLists =
      !memberLists || !isJSON(memberLists)
        ? {}
        : JSON.parse(memberLists) || {};

    return fetchResponse(1200, getMemberListById(id, currentMemberLists));
  },

  downloadById (catalog, id) {
    console.log('api.memberLists.downloadById(', catalog, id, ')');

    return fetchResponse(1000, 'test');
  },

  insert (catalog, type, title, members) {
    console.log('api.memberLists.insert(', catalog, type, title, members, ')');

    const memberLists = localStorage.getItem('memberLists');
    const currentMemberLists =
      !memberLists || !isJSON(memberLists)
        ? {}
        : JSON.parse(memberLists) || {};

    const record = {
      id: Math.round(Math.random() * 100000000),
      type,
      title,
      members
    };

    const catalogKey = settingsOlapIdMap[catalog].url;

    localStorage.setItem('memberLists', JSON.stringify({
      ...currentMemberLists,
      [catalogKey]: [
        ...currentMemberLists[catalogKey] || [],
        record
      ]
    }));

    return fetchResponse(1000, record);
  },

  update (catalog, id, type, title, members) {
    console.log('api.memberLists.update(', catalog, id, type, title, members, ')');

    const memberLists = localStorage.getItem('memberLists');
    const currentMemberLists =
      !memberLists || !isJSON(memberLists)
        ? {}
        : JSON.parse(memberLists) || {};

    const record = {
      id,
      type,
      title,
      members
    };

    const catalogKey = settingsOlapIdMap[catalog].url;

    localStorage.setItem('memberLists', JSON.stringify({
      ...currentMemberLists,
      [catalogKey]: (currentMemberLists[catalogKey] || []).map(cMemberList => (
        cMemberList.id === id ? record : cMemberList
      ))
    }));

    return fetchResponse(1000, record);
  },

  delete (id) {
    console.log('api.memberLists.delete(', id, ')');

    const memberLists = localStorage.getItem('memberLists');
    const currentMemberLists =
      !memberLists || !isJSON(memberLists)
        ? {}
        : JSON.parse(memberLists) || {};

    for (let catalogKey in currentMemberLists) {
      if (currentMemberLists.hasOwnProperty(catalogKey)) {
        currentMemberLists[catalogKey] = currentMemberLists[catalogKey].filter(cMemberList => cMemberList.id !== id);
      }
    }

    localStorage.setItem('memberLists', JSON.stringify(currentMemberLists));

    return fetchResponse(1000, {
      status: true
    });
  }
};

export default memberListsAPI;
