import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Logo from './components/Logo';
import Account from './components/Account';
import Menu from './components/Menu';
import {ReactComponent as SkLogo} from '../../assets/sk-logo.svg';
import classes from './Header.module.scss';

class Header extends Component {
  static propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired
    }),
    logOut: PropTypes.func.isRequired
  };

  render () {
    const {isAuthorized, profile, logOut} = this.props;

    if (!isAuthorized) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <div className={classes.Content}>
          <Logo />
          <Menu />
          <Account
            profile={profile}
            logOut={logOut}
          />
          <a className={classes.SkLink} href='https://sk.ru/' target='_blank' rel='noopener noreferrer'>
            <SkLogo className={classes.SkLogo} />
          </a>
        </div>
      </div>
    );
  }
}

export default Header;
