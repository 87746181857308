import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ContextMenuTrigger as ContextMenuBaseTrigger, ContextMenu, MenuItem, connectMenu} from 'react-contextmenu';
import classes from './ContextMenu.module.scss';

export const ContextMenuTrigger = (props) => {
  const {children, renderTag, holdToDisplay, items, ...otherProps} = props;

  return (
    <ContextMenuBaseTrigger
      id='context-menu'
      renderTag={renderTag}
      attributes={otherProps}
      items={items}
      collect={({items}) => ({items})}
      holdToDisplay={holdToDisplay}
    >
      {children}
    </ContextMenuBaseTrigger>
  );
};

ContextMenuTrigger.defaultProps = {
  holdToDisplay: 1000
};

ContextMenuTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  renderTag: PropTypes.string,
  holdToDisplay: PropTypes.number,
  items: PropTypes.array
};

class ContextMenuView extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    trigger: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          onClick: PropTypes.func.isRequired,
          data: PropTypes.any
        })
      ).isRequired
    })
  };

  render () {
    const {id, trigger} = this.props;

    return (
      <ContextMenu
        id={id}
        className={classes.ContextMenu}
      >
        {
          trigger && trigger.items && trigger.items.length ?
            trigger.items.map((item, index) => (
              <MenuItem
                key={index}
                onClick={item.onClick}
                data={item.data}
                attributes={{
                  className: classes.ContextMenuItem
                }}
              >
                {item.label}
              </MenuItem>
            ))
            :
            <MenuItem />
        }
      </ContextMenu>
    );
  }
}

export default connectMenu('context-menu')(ContextMenuView);
