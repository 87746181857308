import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage from '../../../../../../components/LocalizedMessage';
import AdditionalDndItem from './AdditionalDndItem';
import classes from './AdditionalDndPanel.module.scss';

class AdditionalDndPanel extends Component {
  static propTypes = {
    openMemberListModal: PropTypes.func.isRequired
  };

  render () {
    const {openMemberListModal} = this.props;

    return (
      <ul className={classes.List}>
        <AdditionalDndItem
          type='tv_index'
          openMemberListModal={openMemberListModal}
        >
          <LocalizedMessage
            id='persona.table.filter.memberList.dndPanel.memberIdListTv'
          />
        </AdditionalDndItem>
      </ul>
    );
  }
}

export default AdditionalDndPanel;
