import {isJSON} from '../../helpers/utils';
import {fetchResponse} from './helpers/fakeFetch';
import settingsOlap from '../../mocks/settings-olap.json';
import categories from '../../mocks/categories.json';
import filter from '../../mocks/filter.json';
import measures from '../../mocks/measures.json';
import groups from '../../mocks/groups.json';

const catalogsAPI = {
  getCatalogs () {
    console.log('api.catalogs.getCatalogs()');

    return fetchResponse(500, settingsOlap);
  },

  getFilters (catalog) {
    console.log('api.catalogs.getFilters(', catalog, ')');

    const sourceFormulaList = localStorage.getItem('formulas');

    const formulas =
      sourceFormulaList && isJSON(sourceFormulaList)
        ? JSON.parse(sourceFormulaList)[catalog] || []
        : [];
    const expression = formulas.map(formula => ({
      id: formula.id,
      title: formula.title,
      condition: JSON.stringify(formula.formula)
    }));

    return fetchResponse(500, {
      categories: categories.categories,
      expression
    });
  },

  getMeasures (catalog) {
    console.log('api.catalogs.getMeasures(', catalog, ')');

    return fetchResponse(500, measures);
  },

  getGlobalFilter (catalog) {
    console.log('api.catalogs.getGlobalFilter(', catalog, ')');

    return fetchResponse(500, filter);
  },

  getAdvertisers () {
    console.log('api.catalogs.getAdvertisers()');

    return fetchResponse(500, [
      {
        id: 23,
        name: 'Unilever Asia',
        key: 'unilever_asias2'
      },
      {
        name: 'test2',
        key: 'test2'
      },
      {
        name: 'test3',
        key: 'test3'
      },
      {
        name: 'test4',
        key: 'test4'
      },
      {
        name: 'test5',
        key: 'test5'
      },
      {
        name: 'test6',
        key: 'test6'
      },
      {
        name: 'test7',
        key: 'test7'
      },
      {
        name: 'test8',
        key: 'test8'
      },
      {
        name: 'test9',
        key: 'test9'
      },
      {
        name: 'test10',
        key: 'test10'
      },
      {
        name: 'test11',
        key: 'test11'
      },
      {
        name: 'test12',
        key: 'test12'
      }
    ]);
  },

  getUsers () {
    console.log('api.catalogs.getUsers()');

    return fetchResponse(500, []);
  },

  addGroup (catalog, title) {
    console.log('api.catalogs.addFolder(', catalog, title, ')');

    return fetchResponse(500, {
      id: Math.round(Math.random() * 1000),
      title: title
    });
  },

  getGroups (catalog) {
    console.log('api.catalogs.getFolders(', catalog, ')');

    return fetchResponse(500, groups);
  }
};

export default catalogsAPI;
