import Config from '../../config';
import {serialize, fetchResponse} from '../../helpers/fetch';
import {capitalizeFirstLetter} from '../../helpers/utils';

const getHeaders = (withToken, isJson) => {
  const headers = {
    ...Config.api.headers
  };

  if (isJson) {
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  const token =
    typeof withToken === 'object' &&
    typeof withToken.type === 'string' && withToken.type.length &&
    typeof withToken.token === 'string' && withToken.token.length
      ? `${capitalizeFirstLetter(withToken.type)} ${withToken.token}`
      : localStorage['access_token'] ? `Bearer ${localStorage['access_token']}` : null;

  if (withToken && token) {
    headers['Authorization'] = token;
  }

  return headers;
};

const getBody = (data, isJson) => {
  if (typeof data === 'function') {
    data = data();
  }

  return isJson ? JSON.stringify(data) : serialize(data);
};

export default {
  get (uri, data = {}, withToken = true, isJson = true, showError, initController) {
    const query = Object.keys(data).length ? `?${serialize(data)}` : '';

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri + query, {
      headers: () => getHeaders(withToken, isJson),
      initController
    }, showError);
  },

  post (uri, data = {}, withToken = true, isJson = true, showError, initController) {
    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'POST',
      headers: () => getHeaders(withToken, isJson),
      body: () => getBody(data, isJson),
      initController
    }, showError);
  },

  put (uri, data = {}, withToken = true, isJson = true, showError, initController) {
    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'PUT',
      headers: () => getHeaders(withToken, isJson),
      body: () => getBody(data, isJson),
      initController
    }, showError);
  },

  delete (uri, data = {}, withToken = true, isJson = true, showError, initController) {
    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'DELETE',
      headers: () => getHeaders(withToken, isJson),
      body: () => getBody(data, isJson),
      initController
    }, showError);
  }
};
