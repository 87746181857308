import {connect} from 'react-redux';
import {logOut} from '../../modules/auth';
import Header from './Header';

const mapDispatchToProps = {
  logOut
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
  profile: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
