import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ym, {YMInitializer} from 'react-yandex-metrika';
import Config from '../../config';

class YandexMetrika extends Component {
  static propTypes = {
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired
    }).isRequired,

    profile: PropTypes.shape({
      id: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired
    })
  };

  accounts =
    Config.services.yandexMetrikaAccount &&
    Config.services.yandexMetrikaAccount.length
      ? [Number(Config.services.yandexMetrikaAccount)]
      : [];

  prevLocationPathname = null;
  prevProfileId = null;

  componentDidMount () {
    this.props.history.listen(location => {
      this.hit({location});
    });

    this.hit();
  }

  shouldComponentUpdate ({profile, location}) {
    if (this.props.profile !== profile) {
      this.hit({profile});
    }

    return true;
  }

  hit = ({location = this.props.location, profile = this.props.profile} = this.props) => {
    if (
      !this.accounts.length ||
      (location.pathname === '/' && !profile) ||
      (
        this.prevLocationPathname === location.pathname &&
        this.prevProfileId === ((profile || {}).id || null)
      )
    ) {
      return;
    }

    this.prevLocationPathname = location.pathname;
    this.prevProfileId = (profile || {}).id || null;

    ym('hit', location.pathname,
      {
        params:
          profile
            ? {
              userId: profile.id,
              userName: profile.userName
            }
            : {
              unauthorized: true
            }
      }
    );
  };

  render () {
    if (!this.accounts.length) {
      return null;
    }

    return (
      <YMInitializer
        accounts={this.accounts}
        version='2'
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true
        }}
      />
    );
  }
}

export default YandexMetrika;
