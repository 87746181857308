import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from '../Table.module.scss';

class StaticCell extends Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    data: PropTypes.shape({
      type: PropTypes.string.isRequired,
      path: PropTypes.array,
      direction: PropTypes.string,
      colSpan: PropTypes.number,
      rowSpan: PropTypes.number,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    }).isRequired,
    openFilter: PropTypes.func.isRequired
  };

  render () {
    const {content, data, openFilter} = this.props;
    const {type, direction, colSpan = null, rowSpan = null} = data || {};

    return (
      <td
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={cx(
          classes.Cell,
          {
            [classes.CellHead]: ['head', 'statistic'].indexOf(type) > -1,
            [cx(classes.CellAddDataBtn, classes.CellPointer)]: type === 'add-btn',
            [cx(classes.CellFilter, {[classes.CellPointer]: data.active})]: type === 'filter'
          }
        )}
        onClick={
          type === 'add-btn'
            ? () => openFilter(direction)
            : (type === 'filter' && data.active ? () => openFilter('base') : null)
        }
      >
        {content}
      </td>
    );
  }
}

export default StaticCell;
