import Client from './HTTPClient';

const memberListsAPI = {
  async getById (id) {
    const loadedMemberList = await Client.get(`/member-id-list/${id}`);

    return {
      id: loadedMemberList.id,
      type: loadedMemberList.memberIdListType.toLowerCase(),
      title: loadedMemberList.title,
      members: loadedMemberList.listId,
      catalogId: loadedMemberList.settingsOlap
    };
  },

  downloadById (catalog, id) {
    return Client.get(`/olap/${catalog.url}/${id}/member-id-list/download`);
  },

  async insert (catalog, type, title, memberList) {
    const addedMemberList = await Client.post('/member-id-list/create', {
      title: title,
      memberIdListType: type.toUpperCase(),
      listId: memberList,
      settingsOlap: catalog
    });

    return {
      id: addedMemberList.id,
      title: addedMemberList.title,
      members: addedMemberList.listId,
      catalogId: addedMemberList.settingsOlap
    };
  },

  async update (catalog, id, type, title, memberList) {
    const updatedMemberList = await Client.put(`/member-id-list/${id}`, {
      title: title,
      memberIdListType: type.toUpperCase(),
      listId: memberList,
      settingsOlap: catalog
    });

    return {
      id: updatedMemberList.id,
      title: updatedMemberList.title,
      members: updatedMemberList.listId,
      catalogId: updatedMemberList.settingsOlap
    };
  },

  delete (id) {
    return Client.delete(`/member-id-list/${id}`);
  }
};

export default memberListsAPI;
