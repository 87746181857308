import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import shortid from 'shortid';
import LocalizedMessage, {localizeMessage} from '../../../../../components/LocalizedMessage';
import confirm from '../../../../../components/Confirm';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import alert from '../../../../../helpers/alert';
import API from '../../../../../api';
import classes from './MemberListModal.module.scss';

class MemberListModal extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      location: PropTypes.shape({
        path: PropTypes.arrayOf(
          PropTypes.number
        ),
        direction: PropTypes.oneOf(['left', 'right']),
        isConnector: PropTypes.bool
      })
    }).isRequired,
    catalog: PropTypes.object.isRequired,
    changeFormula: PropTypes.func.isRequired,
    changeFormulaItemTitle: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };

  state = {
    form: {
      title: '',
      members: ''
    },
    type: null,
    loading: false,
    saving: false
  };

  _form = null;

  componentDidMount () {
    this.loadData();
  }

  setFormRef = ref => {
    this._form = ref;
  };

  validateMemberList = (memberList, type = 'tv_index') => {
    if (type === 'romir') {
      return /^ssp[0-9]+(, ?ssp[0-9]+)*$/.test(memberList);
    }

    return /^[0-9]+(, ?[0-9]+)*$/.test(memberList);
  };

  loadData = async () => {
    const {data, close} = this.props;
    const {form} = this.state;

    if (!data.id || form.members) {
      this._form.title.focus();

      return;
    }

    try {
      this.setState({
        loading: true
      });

      const loadedMemberList = await API.memberLists.getById(data.id);

      this.setState({
        loading: false,
        form: {
          title: loadedMemberList.title,
          members: loadedMemberList.members
        },
        type: loadedMemberList.type
      }, () => {
        this._form.title.focus();
      });
    } catch (error) {
      confirm(
        localizeMessage({
          id: 'persona.table.filter.memberList.confirm.retryLoading'
        }),
        {
          confirmBtnText: localizeMessage({
            id: 'base.yes'
          }),
          cancelBtnText: localizeMessage({
            id: 'base.no'
          }),
          cancelCallback: close
        }
      )
        .then(() => {
          this.loadData();
        });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {data} = this.props;
    const {form} = this.state;

    const save = data.id
      ? this.updateMemberList
      : this.insertMemberList;

    save(
      form.title,
      form.members
    );
  };

  insertMemberList = async (titleValue, membersValue) => {
    const {catalog, data, close, changeFormula} = this.props;

    titleValue =
      typeof titleValue === 'string'
        ? titleValue.trim()
        : '';
    membersValue =
      typeof membersValue === 'string'
        ? membersValue.trim().replace(/,( )*/g, ', ')
        : '';

    const titleIsFull = !!titleValue.length;
    const membersIsFull = !!membersValue.length;
    const membersIsValid = membersIsFull && this.validateMemberList(membersValue, data.type);

    if (
      !titleIsFull ||
      !membersIsFull ||
      !membersIsValid
    ) {
      if (!titleIsFull) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListNameIsEmpty'
        }));
      }

      if (!membersIsFull) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListDataIsEmpty'
        }));
      }

      if (membersIsFull && !membersIsValid) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListDataIsInvalid'
        }));
      }

      return;
    }

    this.setState({
      saving: true
    });

    try {
      const savedMemberList = await API.memberLists.insert(
        catalog.id,
        data.type,
        titleValue,
        membersValue
      );

      alert.success(localizeMessage({
        id: 'persona.table.filter.alerts.successfulMemberListSaving'
      }, {
        memberListName: savedMemberList.title
      }));

      close();

      changeFormula({
        action: 'add',
        data: {
          uid: shortid.generate(),
          id: savedMemberList.id,
          title: savedMemberList.title,
          condition: {
            type: 'member_id_list'
          },
          connector: 'and',
          hasNot: false
        }
      }, data.location);
    } catch (error) {
      console.error(error);

      alert.error(localizeMessage({
        id: 'persona.table.filter.alerts.errorMemberListWasNotSaved'
      }));

      this.setState({
        saving: false
      });
    }
  };

  updateMemberList = async (titleValue, membersValue) => {
    const {catalog, data, changeFormulaItemTitle, close} = this.props;
    const {type} = this.state;

    titleValue =
      typeof titleValue === 'string'
        ? titleValue.trim()
        : '';
    membersValue =
      typeof membersValue === 'string'
        ? membersValue.trim().replace(/,( )*/g, ', ')
        : '';

    const titleIsFull = !!titleValue.length;
    const membersIsFull = !!membersValue.length;
    const membersIsValid = membersIsFull && this.validateMemberList(membersValue, type);

    if (
      !titleIsFull ||
      !membersIsFull ||
      !membersIsValid
    ) {
      if (!titleIsFull) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListNameIsEmpty'
        }));
      }

      if (!membersIsFull) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListDataIsEmpty'
        }));
      }

      if (membersIsFull && !membersIsValid) {
        alert.warning(localizeMessage({
          id: 'persona.table.filter.alerts.errorMemberListDataIsInvalid'
        }));
      }

      return;
    }

    this.setState({
      saving: true
    });

    try {
      const editedMemberList = await API.memberLists.update(
        catalog.id,
        data.id,
        type,
        titleValue,
        membersValue
      );

      alert.success(localizeMessage({
        id: 'persona.table.filter.alerts.successfulMemberListEditing'
      }, {
        memberListName: editedMemberList.title
      }));

      close();

      changeFormulaItemTitle(data.location.path, titleValue);
    } catch (error) {
      alert.error(localizeMessage({
        id: 'persona.table.filter.alerts.errorMemberListWasNotEdited'
      }));

      this.setState({
        saving: false
      });
    }
  };

  onFieldChange = (name, value) => {
    const {form} = this.state;

    this.setState({
      form: {
        ...form,
        [name]: value
      }
    });
  };

  render () {
    const {data, close} = this.props;
    const {form, type, loading, saving} = this.state;

    return (
      <Modal
        title={
          <LocalizedMessage
            id={data.id
              ? `persona.table.filter.memberList.edit.${type}`
              : `persona.table.filter.memberList.add.${data.type}`
            }
          />
        }
        footerComponent={
          <div className={classes.Buttons}>
            <Button
              theme='transparent'
              onClick={close}
              disabled={saving}
            >
              <LocalizedMessage
                id='base.cancel'
              />
            </Button>
            <Button
              onClick={this.onSubmit}
              disabled={saving}
            >
              <LocalizedMessage
                id='base.save'
              />
            </Button>
          </div>
        }
        className={classes.Container}
        contentClassName={classes.Content}
        loading={loading || saving}
        onClose={close}
      >
        <form
          ref={this.setFormRef}
          className={classes.Form}
          onSubmit={this.onSubmit}
        >
          <div className={classes.FormRow}>
            <label
              htmlFor='member-list-title'
              className={classes.FormLabel}
            >
              <LocalizedMessage
                id='persona.table.filter.memberList.form.title'
              />
            </label>
            <LocalizedMessage
              id='persona.table.filter.memberList.form.title.placeholder'
            >
              {localizedPlaceholder => (
                <input
                  id='member-list-title'
                  name='title'
                  type='text'
                  value={form.title}
                  placeholder={!loading ? localizedPlaceholder : null}
                  className={classes.FormField}
                  disabled={saving}
                  onChange={e => this.onFieldChange('title', e.target.value)}
                />
              )}
            </LocalizedMessage>
          </div>
          <div
            className={cx(
              classes.FormRow,
              classes.FormRowContent
            )}
          >
            <label
              htmlFor='member-list-members'
              className={classes.FormLabel}
            >
              <LocalizedMessage
                id='persona.table.filter.memberList.form.members'
              />
            </label>
            <LocalizedMessage
              id='persona.table.filter.memberList.form.members.placeholder'
            >
              {localizedPlaceholder => (
                <textarea
                  id='member-list-members'
                  name='members'
                  value={form.members || ''}
                  placeholder={!loading ? localizedPlaceholder : null}
                  className={cx(
                    classes.FormField,
                    classes.FormFieldTextArea
                  )}
                  disabled={saving}
                  onChange={e => this.onFieldChange('members', e.target.value)}
                />
              )}
            </LocalizedMessage>
          </div>
        </form>
      </Modal>
    );
  }
}

export default MemberListModal;
