import {findDOMNode} from 'react-dom';
import {createSelectable} from '../../../../../../lib/react-selectable-fast';
import {DragSource, DropTarget} from 'react-dnd';
import Item from './Item';

const ItemWithSelectable = createSelectable(Item);

const ItemWithDragSource = DragSource('FORMULA_ITEM', {
  canDrag: (props) => {
    return !props.isSelecting;
  },
  beginDrag (props) {
    return {
      action: 'move',
      data: props.data,
      prevPath: props.path
    };
  },
  endDrag (props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    props.changeFormula(item, dropResult);
  }
}, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  canDrag: monitor.canDrag(),
  isDragging: monitor.isDragging()
}))(ItemWithSelectable);

const ItemWithDropTarget = DropTarget('FORMULA_ITEM', {
  canDrop (props, monitor) {
    const draggingItem = monitor.getItem();

    return !draggingItem.prevPath || props.path.join('-') !== draggingItem.prevPath.join('-');
  },
  hover (props, monitor, component) {
    const _component = findDOMNode(component);
    const _borderLeft = _component.querySelector('[data-type="border-left"]');
    const _borderRight = _component.querySelector('[data-type="border-right"]');
    const borderLeftPosition = _borderLeft.getBoundingClientRect();
    const borderRightPosition = _borderRight.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();

    if (borderLeftPosition.top === borderRightPosition.top) {
      const hoverMiddleX = (borderRightPosition.right - borderLeftPosition.left) / 2;
      const hoverClientX = clientOffset.x - borderLeftPosition.left;

      props.setCursor(hoverClientX < hoverMiddleX ? 'left' : 'right');
    } else {
      const hoverBoundingRect = _component.getBoundingClientRect();
      const oneLineHeight =
        borderLeftPosition.height +
        (borderLeftPosition.top - hoverBoundingRect.top) +
        (hoverBoundingRect.bottom - borderRightPosition.bottom);
      const firstLineWidth = hoverBoundingRect.right - borderLeftPosition.left;
      const lastLineWidth = borderRightPosition.right - hoverBoundingRect.left;
      const maxLinesNumber = Math.round(hoverBoundingRect.height / oneLineHeight);
      const mainWidth =
        firstLineWidth +
        lastLineWidth +
        ((maxLinesNumber - 2) * hoverBoundingRect.width);

      const cursorLineNumber = Math.floor(Math.max(clientOffset.y - hoverBoundingRect.top, 0) / oneLineHeight);
      let leftOffset = 0;

      if (cursorLineNumber === 0) {
        leftOffset = clientOffset.x - borderLeftPosition.left;
      } else {
        leftOffset =
          firstLineWidth +
          ((cursorLineNumber - 1) * hoverBoundingRect.width) +
          (clientOffset.x - hoverBoundingRect.left);
      }

      const percent = Math.round((leftOffset / mainWidth) * 100);

      props.setCursor(percent < 50 ? 'left' : 'right');
    }
  },
  drop (props, monitor) {
    if (monitor.didDrop()) {
      return;
    }

    return {
      path: props.path,
      direction: props.cursorDirection
    };
  }
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))(ItemWithDragSource);

export default ItemWithDropTarget;
