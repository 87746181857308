import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Line, Bar, Doughnut} from 'react-chartjs-2';
import LocalizedMessage from '../../../../components/LocalizedMessage';
import Legends from './Legends';
import './helpers/chart.zoom';
import classes from './Graph.module.scss';

const MAX_LABEL_LINE_LENGTH = 42;

class Chart extends Component {
  static propTypes = {
    title: PropTypes.string,
    datasets: PropTypes.arrayOf(
      PropTypes.object
    ),
    labels: PropTypes.array,
    selectedStatisticTypes: PropTypes.array.isRequired,
    originalType: PropTypes.string.isRequired,
    hasSecondChart: PropTypes.bool,
    isGroupingData: PropTypes.bool,
    currentPos: PropTypes.number.isRequired,
    viewPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onChangePos: PropTypes.func.isRequired,
    exportChart: PropTypes.func.isRequired
  };

  static defaultProps = {
    title: null,
    labels: [],
    hasSecondChart: false,
    isGroupingData: false
  };

  _chart = null;

  setChartRef = ref => {
    this._chart = ref;
  };

  getChart = () => {
    return this._chart.chartInstance;
  };

  onChangePos = (minIndex, maxIndex) => {
    const {viewPos, onChangePos} = this.props;

    const updates = {
      currentPos: minIndex
    };

    if (viewPos !== maxIndex - minIndex + 1) {
      updates.viewPos = maxIndex - minIndex + 1;
    }

    onChangePos(updates);
  };

  splitLabel = (label) => {
    const labelArray = [];
    const splitLabel = label.split(' ');

    splitLabel.forEach(labelPart => {
      const lastLabel = labelArray[labelArray.length - 1];

      if (!labelArray.length || `${lastLabel} ${labelPart}`.length > MAX_LABEL_LINE_LENGTH) {
        labelArray.push(labelPart);
      } else {
        labelArray[labelArray.length - 1] = `${lastLabel} ${labelPart}`;
      }
    });

    return labelArray;
  };

  getOptionsByType = () => {
    const {
      labels,
      datasets,
      currentPos,
      viewPos,
      selectedStatisticTypes,
      hasSecondChart
    } = this.props;

    const type = datasets[0].type;
    const isReverse = hasSecondChart && type === 'bar';

    switch (type) {
      case 'line':
      case 'bar':
        const yAxes = [{
          id: 'A',
          type: 'linear',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: selectedStatisticTypes[0].title
          },
          offset: true
        }];
        if (hasSecondChart) {
          yAxes.push({
            id: 'B',
            type: 'linear',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: selectedStatisticTypes[1].title
            },
            offset: true
          });
        }

        if (isReverse) {
          yAxes.reverse();
        }

        return {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: true,
                maxRotation: 90,
                minRotation: 90,
                min: labels[currentPos],
                max: labels[Math.min(labels.length, viewPos + currentPos) - 1],
                lineHeight: 1,
                maxLinesLength: 2,
                callback: label => this.splitLabel(label)
              },
              offset: true
            }],
            yAxes: yAxes
          },
          pan: {
            enabled: true,
            mode: 'x',
            onChange: this.onChangePos
          }
        };
      case 'doughnut':
        return {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        };
      default:
        return {};
    }
  };

  getChartComponent = (type) => {
    switch (type) {
      case 'bar':
        return Bar;
      case 'doughnut':
        return Doughnut;
      default:
        return Line;
    }
  };

  getConfig = () => {
    const {labels, datasets, hasSecondChart} = this.props;

    return {
      type: hasSecondChart ? 'bar' : datasets[0].type,
      data: {
        labels,
        datasets
      },
      options: this.getOptionsByType()
    };
  };

  exportChart = () => {
    const {exportChart} = this.props;

    if (
      !this._chart ||
      !this._chart.chartInstance ||
      !this._chart.chartInstance.canvas
    ) {
      return;
    }

    exportChart(this._chart.chartInstance.canvas);
  };

  render () {
    const {
      title,
      datasets,
      labels,
      originalType,
      hasSecondChart,
      isGroupingData,
      viewPos,
      maxPos
    } = this.props;

    if (!datasets.length) {
      return null;
    }

    const {type, data, options} = this.getConfig();
    const ChartComponent = this.getChartComponent(type);

    return (
      <div className={classes.ChartContainer}>
        <div
          className={cx(
            classes.ChartContent,
            {
              [classes.ChartContentWithPan]: (
                (
                  type === 'line' ||
                  type === 'bar'
                ) &&
                maxPos > viewPos
              )
            }
          )}
        >
          {
            !isGroupingData ?
              <h4 className={classes.ChartTitle}>
                {title}
              </h4>
              : null
          }
          <Legends
            type={type}
            originalType={originalType}
            datasets={datasets}
            labels={labels}
            hasSecondChart={hasSecondChart}
            isGroupingData={isGroupingData}
            getChart={this.getChart}
          />
          <div
            ref={this.setWrapperRef}
            className={classes.ChartWrapper}
          >
            <div className={classes.ChartControl}>
              <div
                className={cx(
                  classes.ChartControlButton,
                  'icon-download'
                )}
                onClick={this.exportChart}
              >
                <LocalizedMessage
                  id='persona.table.graph.control.export'
                />
              </div>
            </div>
            <ChartComponent
              ref={this.setChartRef}
              data={data}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;
