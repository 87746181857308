import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import DatePickerInput from './DatePickerInput';

class DatePickerView extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
    placeholder: PropTypes.string,
    selected: moment.isMoment,
    startDate: moment.isMoment,
    endDate: moment.isMoment,
    minDate: moment.isMoment,
    maxDate: moment.isMoment,
    onChange: PropTypes.func
  };

  _datepicker = null;

  setDatepickerRef = ref => {
    this._datepicker = ref;
  };

  onChange = value => {
    const {onChange} = this.props;

    if (typeof onChange === 'function' && value) {
      onChange(value);
    }
  };

  onBlur = event => {
    this._datepicker.handleSelect(this.props.selected, event);
  };

  render () {
    const {
      locale,
      disabled,
      placeholder,
      selected,
      startDate,
      endDate,
      minDate,
      maxDate,
      selectsStart,
      selectsEnd
    } = this.props;

    return (
      <DatePicker
        disabled={disabled}
        ref={this.setDatepickerRef}
        customInput={
          <DatePickerInput
            container={this}
          />
        }
        selected={selected}
        popperPlacement='right'
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={this.onChange}
        onBlur={this.onBlur}
        showMonthDropdown
        showYearDropdown
        locale={locale}
        dateFormat='DD.MM.YYYY'
        placeholderText={placeholder}
        disabledKeyboardNavigation
      />
    );
  }
}

export default DatePickerView;
