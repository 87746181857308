import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Scrollbar from '../Scrollbar';
import Loader from '../Loader';
import OneLineLoader from '../OneLineLoader';
import {getArrayItemByParam} from '../../helpers/utils';
import classes from './FieldWithHintsDropdown.module.scss';

const HINTS_SCROLLING_OFFSET_TO_LOAD = 20;    // px

class FieldWithHintsDropdown extends Component {
  static propTypes = {
    hints: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    hintsLimitInRequest: PropTypes.number,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    className: PropTypes.string,
    loadHints: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    hints: [],
    hintsLimitInRequest: 30,
    defaultValue: null,
    placeholder: '',
    loading: false,
    className: null
  };

  state = {
    selectedHint: this.props.defaultValue
      ? getArrayItemByParam(this.props.hints, 'value', this.props.defaultValue) || null
      : null,
    value: null,
    hintsIsOpened: false,
    hintListPosition: {
      top: 0,
      left: 0,
      width: 0
    },
    focusedAutocompleteIndex: 0
  };

  _field = null;
  _hints = {};
  _hintScrollbar = null;

  static getDerivedStateFromProps (props, state) {
    if (props.defaultValue && (!state.selectedHint || state.selectedHint.value !== props.defaultValue)) {
      return {
        selectedHint: props.defaultValue
          ? getArrayItemByParam(props.hints, 'value', props.defaultValue) || null
          : null
      };
    }

    return null;
  }

  componentDidMount () {
    this.updateHintsPosition();

    window.addEventListener('resize', this.updateHintsPosition);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateHintsPosition);
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  setHintScrollbarRef = ref => {
    this._hintScrollbar = ref;
  };

  updateHintsPosition = () => {
    if (!this._field) {
      return;
    }

    const fieldPosition = this._field.getBoundingClientRect();

    this.setState({
      hintListPosition: {
        top: fieldPosition.top + fieldPosition.height,
        left: fieldPosition.left,
        width: fieldPosition.width - 2
      }
    });
  };

  focus = () => {
    if (this._field) {
      this._field.focus();
    }
  };

  blur = () => {
    if (this._field) {
      this._field.blur();
    }
  };

  onFieldFocus = e => {
    const {loadHints, hintsLimitInRequest} = this.props;
    const {selectedHint} = this.state;

    this.setState({
      hintsIsOpened: true,
      value: selectedHint ? selectedHint.label : ''
    });

    e.target.select();

    loadHints(selectedHint ? selectedHint.label : '', 0, hintsLimitInRequest);
  };

  onFieldBlur = () => {
    const {hints} = this.props;
    const {value} = this.state;

    this.setState({
      hintsIsOpened: false
    });

    const foundSelectedHint = getArrayItemByParam(hints, 'value', value);

    if (foundSelectedHint) {
      this.setItem(foundSelectedHint);
    } else {
      this.setState({
        value: null
      });
    }
  };

  onFieldChange = e => {
    const {hintsLimitInRequest, loadHints} = this.props;

    const value = e.target.value.trim();

    this.setState({
      value,
      focusedAutocompleteIndex: 0
    });

    loadHints(value, 0, hintsLimitInRequest);
  };

  onFieldKeyDown = (e, hints) => {
    const {focusedAutocompleteIndex} = this.state;

    switch (e.keyCode) {
      case 13:            // Enter
        if (hints[focusedAutocompleteIndex]) {
          this.setItem(hints[focusedAutocompleteIndex], true);
        } else {
          this._field.blur();
        }

        break;
      case 38:            // Arrow Up
        e.preventDefault();

        if (focusedAutocompleteIndex > 0) {
          this.focusHint(focusedAutocompleteIndex - 1);
        }

        break;
      case 40:            // Arrow Down
        e.preventDefault();

        if (focusedAutocompleteIndex < hints.length - 1) {
          this.focusHint(focusedAutocompleteIndex + 1);
        }

        break;
      default:
        break;
    }
  };

  onHintsScroll = e => {
    const {hints, loadHints, hintsLimitInRequest} = this.props;
    const {value} = this.state;

    const _target = e.target;

    if (_target.scrollTop + _target.offsetHeight > _target.scrollHeight - HINTS_SCROLLING_OFFSET_TO_LOAD) {
      loadHints(value, hints.length, hintsLimitInRequest);
    }
  };

  focusHint = (hintIndex, withScroll = true) => {
    const {hints} = this.props;
    const {value, hintsIsOpened} = this.state;

    this.setState({
      focusedAutocompleteIndex: hintIndex
    }, () => {
      if (withScroll && hintsIsOpened && hints.length) {
        const _focusedHint = this._hints[`${value}-${hintIndex}`];
        const hintHeight = _focusedHint.offsetHeight;
        const hintOffsetTop = _focusedHint.offsetTop;

        this._hintScrollbar.scrollTop(hintOffsetTop - hintHeight);
      }
    });
  };

  setItem = (selectedHint) => {
    const {onChange} = this.props;

    this.setState({
      selectedHint,
      value: null
    }, () => {
      this.blur();
    });

    if (typeof onChange === 'function') {
      onChange(selectedHint.value);
    }
  };

  render () {
    const {hints, className, placeholder, loading} = this.props;
    const {value, selectedHint, hintListPosition, focusedAutocompleteIndex, hintsIsOpened} = this.state;

    return (
      <div className={classes.Container}>
        <input
          ref={this.setFieldRef}
          type='text'
          placeholder={placeholder}
          className={className}
          value={value || (!hintsIsOpened && selectedHint ? selectedHint.label : '')}
          onFocus={this.onFieldFocus}
          onBlur={this.onFieldBlur}
          onChange={this.onFieldChange}
          onKeyDown={e => this.onFieldKeyDown(e, hints)}
        />
        {
          loading && !hints.length ?
            <div className={classes.LoaderContainer}>
              <Loader
                isSmall
              />
            </div>
            : null
        }
        {
          hintsIsOpened && hints.length && value && value.length ?
            createPortal(
              <div
                className={classes.ListContainer}
                style={{
                  top: hintListPosition.top,
                  left: hintListPosition.left,
                  width: hintListPosition.width
                }}
              >
                <Scrollbar
                  ref={this.setHintScrollbarRef}
                  autoHeightMax={180}
                  onScroll={this.onHintsScroll}
                >
                  <ul className={classes.List}>
                    {
                      hints.map((hint, index) => (
                        <li
                          key={hint.value}
                          ref={ref => {
                            this._hints[`${value}-${index}`] = ref;
                          }}
                          onMouseEnter={() => this.focusHint(index, false)}
                          onMouseDown={() => this.setItem(hint)}
                          className={cx(
                            classes.ListItem,
                            {
                              [classes.ListItemFocused]: focusedAutocompleteIndex === index
                            }
                          )}
                        >
                          {hint.label}
                        </li>
                      ))
                    }
                  </ul>
                  {
                    loading && hints.length ?
                      <OneLineLoader />
                      : null
                  }
                </Scrollbar>
              </div>,
              document.body
            )
            : null
        }
      </div>
    );
  }
}

export default FieldWithHintsDropdown;
