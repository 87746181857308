import {connect} from 'react-redux';
import PrivateRoute from './PrivateRoute';
import {checkAuth} from '../../modules/auth';

const mapDispatchToProps = {
  checkAuth
};

const mapStateToProps = (state) => ({
  isAuthorizing: state.auth.isAuthorizing,
  isAuthorized: state.auth.isAuthorized
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
