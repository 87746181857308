import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Switch.module.scss';

class Switch extends Component {
  static propTypes = {
    defaultStatus: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    defaultStatus: false,
    disabled: false
  };

  state = {
    status: this.props.defaultStatus
  };

  getStatus = () => {
    const {status} = this.state;

    return status;
  };

  toggle = (automatically = true) => {
    const {disabled, onChange} = this.props;
    const {status} = this.state;

    if (automatically && disabled) {
      return;
    }

    this.setState({
      status: !status
    });

    if (automatically && typeof onChange === 'function') {
      onChange(!status);
    }
  };

  render () {
    const {disabled, className} = this.props;
    const {status} = this.state;

    return (
      <span
        className={cx(
          classes.Switch,
          className,
          {
            [classes.SwitchOn]: status,
            [classes.SwitchDisabled]: disabled
          }
        )}
        onClick={this.toggle}
      />
    );
  }
}

export default Switch;
