import React from 'react';
import {Switch, Route} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import List from './List';
import Auth from './Auth';
import Edit from './Edit';
import Profile from './Profile';
import NotFound from './NotFound';

export const Routes = () => (
  <Switch>
    <PrivateRoute exact path='/' component={List} />
    <PrivateRoute path='/profile' component={Profile} />
    <PrivateRoute path='/cube/:catalog/:id?' component={Edit} />
    <Route path='/auth' component={Auth} />
    <PrivateRoute component={NotFound} />
  </Switch>
);

export default Routes;
