import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Scrollbar from '../Scrollbar';
import classes from './Dropdown.module.scss';

class Dropdown extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node
        ]).isRequired,
        value: PropTypes.any
      })
    ).isRequired,
    defaultValue: PropTypes.any,
    withArrow: PropTypes.bool,
    optionsAutoWidth: PropTypes.bool,
    autoHeightMax: PropTypes.number,
    className: PropTypes.string,
    openedClassName: PropTypes.string,
    currentValueClassName: PropTypes.string,
    currentValueArrowClassName: PropTypes.string,
    optionsClassName: PropTypes.string,
    optionClassName: PropTypes.string,
    diffFn: PropTypes.func,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: null,
    openedClassName: null,
    currentValueClassName: null,
    currentValueArrowClassName: null,
    optionsClassName: null,
    optionClassName: null,
    withArrow: true,
    optionsAutoWidth: false,
    autoHeightMax: 100,
    diffFn: (v1, v2) => v1 === v2
  };

  state = {
    value: this.props.defaultValue || (this.props.options.length ? this.props.options[0].value : null),
    isOpen: false
  };

  componentDidMount () {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick = event => {
    const {isOpen} = this.state;
    const dropdownElement = findDOMNode(this);

    if (
      isOpen &&
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target)
    ) {
      this.toggle(false);
    }
  };

  diffMeasure = (o1, o2) => {
    return Object.keys(o2).reduce((diff, key) => {
      if (o1[key] === o2[key]) {
        return diff;
      }

      return {
        ...diff,
        [key]: o2[key]
      };
    }, {});
  };

  getFullValue = () => {
    const {options, diffFn} = this.props;
    const {value} = this.state;

    for (let i = 0; i < options.length; i++) {
      if (diffFn(options[i].value, value)) {
        return options[i];
      }
    }

    return {
      label: '',
      value: null
    };
  };

  toggle = (status) => {
    const {isOpen} = this.state;

    if (typeof status === 'undefined') {
      status = !isOpen;
    } else if (isOpen === status) {
      return;
    }

    this.setState({
      isOpen: status
    });
  };

  onChange = (value, sendAction = true, e) => {
    if (e) {
      e.stopPropagation();
    }

    const {onChange} = this.props;

    if (sendAction) {
      onChange(value);
    }

    this.setState({
      value,
      isOpen: false
    });
  };

  setValue = (value) => {
    this.onChange(value, false);
  };

  render () {
    const {
      className,
      openedClassName,
      currentValueClassName,
      currentValueArrowClassName,
      optionsClassName,
      optionClassName,
      withArrow,
      optionsAutoWidth,
      autoHeightMax,
      options
    } = this.props;
    const {isOpen} = this.state;
    const currentValue = this.getFullValue();

    return (
      <div className={cx(
        classes.Container,
        {
          [cx(classes.ContainerOpened, openedClassName)]: isOpen
        },
        className
      )}>
        <p
          className={cx(
            classes.CurrentValue,
            {
              [classes.CurrentValueWithArrow]: withArrow
            },
            currentValueClassName
          )}
          onClick={() => this.toggle()}
        >
          {currentValue.label}
          <span
            className={cx(
              classes.CurrentValueArrow,
              isOpen ? 'icon-arrow-drop-up' : 'icon-arrow-drop-down',
              currentValueArrowClassName
            )}
          />
        </p>
        {
          isOpen ?
            <div
              className={cx(
                classes.Options,
                optionsClassName
              )}
            >
              <Scrollbar
                autoHeightMax={autoHeightMax}
                autoWidth={optionsAutoWidth}
              >
                <ul className={classes.OptionsList}>
                  {
                    options.map((option, index) => (
                      <li
                        key={index}
                        title={typeof option.label === 'string' ? option.label : null}
                        className={cx(
                          classes.OptionsItem,
                          optionClassName,
                          {
                            [classes.OptionsItemCurrent]: option.value === currentValue.value
                          }
                        )}
                        onClick={e => this.onChange(option.value, true, e)}
                      >
                        {option.label}
                      </li>
                    ))
                  }
                </ul>
              </Scrollbar>
            </div>
            : null
        }
      </div>
    );
  }
}

export default Dropdown;
