import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TagsInput from 'react-tagsinput';
import AutosizeInput from '../../../../../../../../lib/react-input-autosize';
import classes from '../Templates.module.scss';

class IntegerField extends Component {
  static propTypes = {
    multi: PropTypes.bool,
    autoFocus: PropTypes.bool,
    value: PropTypes.shape({
      value: PropTypes.number.isRequired
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired
      })
    ),
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    isSelecting: PropTypes.bool.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    selectItem: PropTypes.func,
    setFieldRef: PropTypes.func,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    multi: false,
    autoFocus: true,
    minValue: 0,
    maxValue: 100
  };

  state = {
    inputValue:
      this.props.value
        ? this.props.value.value.toString()
        : !this.props.multi ? '0' : ''
  };

  _field = null;

  componentDidMount () {
    const {multi, autoFocus, isSelecting, setFieldRef, onChange} = this.props;
    const {inputValue} = this.state;

    if (!multi) {
      onChange({
        value: parseInt(inputValue, 10)
      });
    }

    if (autoFocus && this._field && !isSelecting) {
      this._field.select();
    }

    if (typeof setFieldRef === 'function') {
      setFieldRef(this._field);
    }
  }

  UNSAFE_componentWillReceiveProps ({setFieldRef}) {
    if (typeof setFieldRef === 'function') {
      setFieldRef(this._field);
    }
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  onChangeInput = (value) => {
    const {multi, minValue, maxValue, onChange} = this.props;

    let inputValue = value.replace(/[^0-9]+/, '');

    if (inputValue.length) {
      inputValue = parseInt(inputValue, 10);
    } else if (!multi) {
      inputValue = 0;
    }

    if (typeof inputValue === 'number') {
      if (typeof minValue === 'number' && inputValue < minValue) {
        inputValue = minValue;
      } else if (typeof maxValue === 'number' && inputValue > maxValue) {
        inputValue = maxValue;
      }
    }

    this.setState({
      inputValue:
        typeof inputValue === 'number'
          ? inputValue.toString()
          : ''
    });

    if (!multi) {
      onChange({
        value: inputValue
      });
    }
  };

  onChangeList = (values) => {
    const {onChange} = this.props;

    const resultValues = values.slice();
    const lastValue = resultValues[resultValues.length - 1];

    if (typeof lastValue === 'string') {
      const lastNumberValue = parseInt(resultValues.pop(), 10);

      if (resultValues.indexOf(lastNumberValue) === -1) {
        resultValues.push(lastNumberValue);
      }
    }

    onChange(resultValues.map(value => ({value})));
  };

  renderLayoutComponent = (tagComponents, inputComponent) => {
    return (
      <div className={classes.TagsLayout}>
        {tagComponents}
        {inputComponent}
      </div>
    );
  };

  renderInputComponent = (props) => {
    const {isSelecting, toggleChanging} = this.props;
    const {addTag, placeholder, onFocus, onBlur, ...otherInputProps} = props;

    return (
      <AutosizeInput
        {...otherInputProps}
        inputRef={this.setFieldRef}
        className={cx(
          classes.FieldContainer,
          {
            [classes.FieldContainerInSelectionMode]: isSelecting
          }
        )}
        inputClassName={classes.Field}
        minWidth={1}
        onFocus={(...args) => {
          if (isSelecting) {
            args[0].preventDefault();

            this._field.blur();

            return;
          }

          toggleChanging(true);

          if (typeof onFocus === 'function') {
            onFocus(...args);
          }
        }}
        onBlur={(...args) => {
          toggleChanging(false);

          if (typeof onBlur === 'function') {
            onBlur(...args);
          }
        }}
      />
    );
  };

  renderTagComponent = (props) => {
    const {selectItem} = this.props;
    const {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...tagProps} = props;

    return (
      <div
        key={key}
        {...tagProps}
        className={classes.TagsItem}
      >
        <div className={classes.TagsItemContent}>
          {
            getTagDisplayValue(tag)
          }
          {
            !disabled ?
              <span
                className={classes.TagsItemRemoveBtn}
                onClick={e => {
                  onRemove(key);
                  selectItem(e);
                }}
              />
              : null
          }
        </div>
      </div>
    );
  };

  render () {
    const {multi, values} = this.props;
    const {inputValue} = this.state;

    if (!multi) {
      return this.renderInputComponent({
        value: inputValue,
        onChange: e => this.onChangeInput(e.target.value)
      });
    }

    return (
      <TagsInput
        className={classes.Tags}
        value={values.map(value => value.value)}
        onChange={this.onChangeList}
        onChangeInput={this.onChangeInput}
        inputValue={inputValue}
        renderLayout={this.renderLayoutComponent}
        renderInput={this.renderInputComponent}
        renderTag={this.renderTagComponent}
      />
    );
  }
}

export default IntegerField;
