import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import YandexMetrika from './YandexMetrika';

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  profile: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YandexMetrika));
