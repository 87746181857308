import auth from './auth';
import catalogs from './catalogs';
import cubes from './cubes';
import eventHints from './eventHints';
import formula from './formula';
import memberLists from './memberLists';
import mIndex from './mIndex';

export default {
  auth,
  catalogs,
  cubes,
  eventHints,
  formula,
  memberLists,
  mIndex
};
