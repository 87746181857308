import {findDOMNode} from 'react-dom';
import {DragSource, DropTarget} from 'react-dnd';
import Connector from './Connector';

const ConnectorWithDragSource = DragSource('FORMULA_ITEM', {
  beginDrag () {
    return {};
  }
}, (connect, monitor) => ({
  connectDragSource: connect.dragSource()
}))(Connector);

const ConnectorWithDropTarget = DropTarget('FORMULA_ITEM', {
  canDrop: (props) => {
    return !props.isSelecting;
  },
  hover (props, monitor, component) {
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    props.setCursor(hoverClientX < hoverMiddleX ? 'left' : 'right');
  },
  drop (props, monitor) {
    if (monitor.didDrop()) {
      return;
    }

    return {
      path: props.path,
      direction: props.cursorDirection,
      isConnector: true
    };
  }
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))(ConnectorWithDragSource);

export default ConnectorWithDropTarget;
