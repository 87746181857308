import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Scrollbar from '../../../../../components/Scrollbar';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import classes from './AffinityField.module.scss';

class AffinityField extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired
  };

  _field = null;

  setFieldRef = ref => {
    this._field = ref;
  };

  focus = () => {
    this._field.focus();
  };

  maskValue = (value) => {
    const filteredValue = value.replace(/[^0-9-+]/g, '');

    if (!filteredValue) {
      return '';
    }

    const matchedValue = filteredValue.match(/[1-9]([0-9]{1,3})?-[1-9]?([0-9]{1,3})?|[1-9]([0-9]{1,3})?\+?/);

    if (!matchedValue) {
      return '';
    }

    return matchedValue[0];
  };

  onFieldChange = e => {
    this._field.value = this.maskValue(e.target.value);
  };

  onFieldKeyDown = e => {
    switch (e.keyCode) {
      case 8:
        this.deleteLastItem(e);

        break;
      case 13:
      case 32:
      case 188:
        e.preventDefault();

        this.addValue(e.target.value);

        break;
      default:
        break;
    }
  };

  deleteLastItem = (e) => {
    const {data, onChange} = this.props;

    const value = e.target.value;

    if (value.length || !data.values.length) {
      return;
    }

    e.preventDefault();

    onChange(data.values.slice(0, -1));
  };

  clearField = () => {
    this._field.value = '';
  };

  addValue = (value) => {
    const {data, onChange} = this.props;

    if (
      !value.length ||
      value.match(/[1-9]([0-9]{1,3})?-[1-9]([0-9]{1,3})?|[1-9]([0-9]{1,3})?\+?/)[0] !== value ||
      (
        value.match(/([1-9]([0-9]{1,3})?)-([1-9]([0-9]{1,3})?)/) &&
        value.match(/([1-9]([0-9]{1,3})?)-([1-9]([0-9]{1,3})?)/)[0] === value &&
        (
          parseInt(value.match(/([1-9]([0-9]{1,3})?)-([1-9]([0-9]{1,3})?)/)[1]) >
          parseInt(value.match(/([1-9]([0-9]{1,3})?)-([1-9]([0-9]{1,3})?)/)[3])
        )
      ) ||
      data.values.indexOf(value) > -1
    ) {
      return;
    }

    this.clearField();

    const updatedValues = [
      ...data.values,
      value
    ];

    onChange(updatedValues);
  };

  removeValue = (e, value) => {
    e.stopPropagation();

    const {data, onChange} = this.props;

    const updatedValues = data.values.slice();
    const index = updatedValues.indexOf(value);

    if (index > -1) {
      updatedValues.splice(index, 1);
    }

    onChange(updatedValues);
  };

  render () {
    const {data} = this.props;

    return (
      <div className={classes.Container}>
        <div className={classes.Wrapper}>
          <Scrollbar>
            <div className={classes.Content}>
              {
                data.values.length ?
                  <ul className={classes.Values}>
                    {
                      data.values.map(value => (
                        <li
                          key={value}
                          className={classes.ValuesItem}
                        >
                          {value}
                          <span
                            className={cx(
                              classes.ValuesItemClose,
                              'icon-close'
                            )}
                            onClick={e => this.removeValue(e, value)}
                          />
                        </li>
                      ))
                    }
                  </ul>
                  : null
              }
              <div className={classes.FieldContainer}>
                <LocalizedMessage
                  id='persona.table.headerRow.kpis.withFrequency.placeholder'
                >
                  {
                    withFrequencyPlaceholder => (
                      <input
                        key={`affinity-frequency-field-${data.id}`}
                        ref={this.setFieldRef}
                        type='text'
                        placeholder={!data.values.length ? withFrequencyPlaceholder : null}
                        className={classes.Field}
                        onChange={this.onFieldChange}
                        onKeyDown={this.onFieldKeyDown}
                      />
                    )
                  }
                </LocalizedMessage>
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

export default AffinityField;
