import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage, {localizeMessage} from '../../../../components/LocalizedMessage';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import RadioList from '../../../../components/RadioList';
import FolderList from './FolderList';
import AdvertiserGroup from './AdvertiserGroup';
import Users from './Users';
import {getArrayItemByParam} from '../../../../helpers/utils';
import alert from '../../../../helpers/alert';
import api from '../../../../api';
import classes from './AccessRights.module.scss';

class AccessRights extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['formula', 'template']).isRequired,
    catalog: PropTypes.object.isRequired,
    data: PropTypes.shape({
      accessType: PropTypes.oneOf([
        'all',
        'advertisers',
        'user',
        'users'
      ]),
      advertisers: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired
        }).isRequired
      ),
      group: PropTypes.number
    }),
    isExternalUser: PropTypes.bool.isRequired,
    addGroup: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };

  state = {
    advertisers: [],
    folders: [],
    users: [],
    selectedAdvertisers: [],
    selectedUsers: [],
    selectedFolder: null,
    mode: this.props.data && this.props.data.accessType ? this.props.data.accessType.toLowerCase() : 'user',
    loading: false,
    loaded: false
  };

  componentDidMount () {
    this.loadData();
  }

  loadData = async () => {
    const {type, catalog, data} = this.props;

    this.setState({
      loading: true
    });

    try {
      const promises = [
        api.catalogs.getAdvertisers(),
        type === 'formula'
          ? api.catalogs.getGroups(catalog.url)
          : Promise.resolve([]),
        type === 'template'
          ? api.catalogs.getUsers()
          : Promise.resolve([])
      ];

      const [advertisers, folders, users] = await Promise.all(promises);

      const selectedAdvertisers =
        data &&
        data.advertisers &&
        data.advertisers.length
          ? data.advertisers.map(cAdvertise => ({
            label: cAdvertise.name,
            isActive: cAdvertise.isActive,
            id: cAdvertise.id,
            value: cAdvertise.key
          }))
          : [];
      const selectedUsers =
        data &&
        data.users &&
        data.users.length
          ? data.users.map(username => ({
            label: username,
            value: username
          }))
          : [];
      const selectedFolder =
        data &&
        data.group
          ? getArrayItemByParam(folders, 'value', data.group)
          : null;

      this.setState({
        loading: false,
        loaded: true,
        advertisers: advertisers && advertisers.length
          ? advertisers.map(advertiser => ({
            label: advertiser.name,
            isActive: advertiser.isActive,
            id: advertiser.id,
            value: advertiser.key
          }))
          : [],
        folders: folders && folders.length
          ? folders.map(folder => ({
            label: folder.title,
            value: folder.id
          }))
          : [],
        users: users && users.length
          ? users.map(user => ({
            label: user.username,
            value: user.username
          }))
          : [],
        selectedAdvertisers,
        selectedUsers,
        selectedFolder
      });
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.accessRights.errors.errorLoadingData'
        })
      );

      this.setState({
        loading: false,
        loaded: false
      });
    }
  };

  onRoleChange = mode => {
    this.setState({
      mode
    });
  };

  onAdvertiserGroupChange = selectedAdvertisers => {
    this.setState({
      selectedAdvertisers
    });
  };

  onUsersChange = selectedUsers => {
    this.setState({
      selectedUsers
    });
  };

  addNewFolder = async folderName => {
    const {addGroup} = this.props;
    const {folders} = this.state;

    try {
      const newFolder = await addGroup(folderName);

      this.setState({
        folders: [
          {
            value: newFolder.id,
            label: newFolder.title
          },
          ...folders
        ]
      });
    } catch (error) {
      console.error(error);
    }
  };

  onFolderChange = selectedFolder => {
    this.setState({
      selectedFolder
    });
  };

  onSubmit = () => {
    const {type, save} = this.props;
    const {mode, selectedAdvertisers, selectedFolder, selectedUsers} = this.state;

    if (mode === 'advertisers' && !selectedAdvertisers.length) {
      alert.warn(
        localizeMessage({
          id: 'persona.accessRights.errors.advertisersAreEmpty'
        })
      );

      return;
    }

    if (type === 'formula' && mode === 'all' && !selectedFolder) {
      alert.warn(
        localizeMessage({
          id: 'persona.accessRights.errors.groupIsEmpty'
        })
      );

      return;
    }

    const savingData = {
      accessType: mode.toUpperCase(),
      advertisers: selectedAdvertisers.length
        ? selectedAdvertisers.map(advertiser => ({
          id: advertiser.id,
          key: advertiser.value,
          name: advertiser.label,
          isActive: advertiser.isActive,
        }))
        : null
    };

    if (type === 'template') {
      savingData.users = selectedUsers.length
        ? selectedUsers.map(user => user.value)
        : null;
    }

    if (type === 'formula') {
      savingData.group = selectedFolder ? selectedFolder.value : null;
    }

    save(savingData);
  };

  render () {
    const {type, close, isExternalUser} = this.props;
    const {
      advertisers,
      folders,
      users,
      selectedAdvertisers,
      selectedFolder,
      selectedUsers,
      mode,
      loading,
      loaded
    } = this.state;

    const options = [
      {
        label: <LocalizedMessage
          id='persona.accessRights.checkboxes.user'
        />,
        value: 'user'
      },
    ];

    if (!isExternalUser) {
      options.push(
        {
          label: <LocalizedMessage
            id='persona.accessRights.checkboxes.all'
          />,
          value: 'all'
        },
        {
          label: <LocalizedMessage
            id='persona.accessRights.checkboxes.advertisers'
          />,
          value: 'advertisers'
        }
      );
    }

    if (type === 'template') {
      options.push({
        label: <LocalizedMessage
          id='persona.accessRights.checkboxes.users'
        />,
        value: 'users'
      });
    }

    return (
      <Modal
        title={
          <LocalizedMessage
            id='persona.accessRights.title'
          />
        }
        footerComponent={
          <div className={classes.Buttons}>
            <Button
              theme='transparent'
              onClick={close}
            >
              <LocalizedMessage
                id='base.cancel'
              />
            </Button>
            <Button
              disabled={!loaded}
              onClick={this.onSubmit}
            >
              <LocalizedMessage
                id='base.save'
              />
            </Button>
          </div>
        }
        className={classes.Container}
        contentClassName={classes.Content}
        onClose={close}
      >
        <p className={classes.Description}>
          <LocalizedMessage
            id={`persona.accessRights.description.${type}`}
          />
        </p>
        {
          !loaded || loading
            ? <Loader />
            : <div className={classes.Form}>
              <p className={classes.FormTitle}>
                <LocalizedMessage
                  id={`persona.accessRights.checkboxes.${type}`}
                />
              </p>
              <RadioList
                name='access-role'
                itemActiveClassName={classes.FormRadioButtonsItemActive}
                labelClassName={classes.FormRadioButtonsLabel}
                options={options}
                defaultValue={mode}
                onChange={this.onRoleChange}
              />
              {
                type === 'formula' && mode === 'all' ?
                  <FolderList
                    folders={folders}
                    value={selectedFolder}
                    addNewFolder={this.addNewFolder}
                    onChange={this.onFolderChange}
                  />
                  : null
              }
              {
                mode === 'advertisers' ?
                  <AdvertiserGroup
                    options={advertisers}
                    values={selectedAdvertisers}
                    onChange={this.onAdvertiserGroupChange}
                  />
                  : null
              }
              {
                mode === 'users' ?
                  <Users
                    options={users}
                    values={selectedUsers}
                    onChange={this.onUsersChange}
                  />
                  : null
              }
            </div>
        }
      </Modal>
    );
  }
}

export default AccessRights;
