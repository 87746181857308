import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage, {localizeMessage} from '../../../../../components/LocalizedMessage';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import FieldWithHintsDropdown from '../../../../../components/FieldWithHintsDropdown';
import alert from '../../../../../helpers/alert';
import api from '../../../../../api';
import classes from './MmiModal.module.scss';

const PRE_LOADING_DELAY = 1000;               // ms

class MmiModal extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired
  };

  state = {
    searchValue: '',
    value: '',
    hintsLoading: false,
    hintsFinished: false,
    hints: [],
    sending: false
  };

  _field = null;
  _loadingTimer = 0;

  componentDidMount () {
    this.focusField();
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  loadHints = async (value = '', offset, limit) => {
    const {searchValue, hints, hintsLoading, hintsFinished} = this.state;

    clearTimeout(this._loadingTimer);

    if (!value.length || (searchValue === value && hintsLoading) || (searchValue === value && hintsFinished)) {
      return;
    }

    this.setState({
      searchValue: value,
      hints: offset ? hints : [],
      hintsLoading: true
    });

    this._loadingTimer = setTimeout(async () => {
      try {
        const loadedData = await api.mIndex.getHints(value, offset, limit);
        const resultHints = offset
          ? [
            ...hints,
            ...loadedData.hints
          ]
          : loadedData.hints;

        this.setState({
          hintsLoading: false,
          hints: resultHints,
          hintsFinished: loadedData.length <= resultHints.length
        });
      } catch (error) {
        console.error(error);

        alert.warn(
          localizeMessage({
            id: 'persona.table.filter.mmiModal.error.hintsLoading'
          })
        );

        this.setState({
          hintsLoading: false
        });
      }
    }, offset ? 0 : PRE_LOADING_DELAY);
  };

  focusField = () => {
    if (this._field) {
      this._field.focus();
    }
  };

  onFieldChange = (value) => {
    this.setState({
      value
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.sendData();
  };

  sendData = async () => {
    const {close} = this.props;
    const {value, sending} = this.state;

    if (sending) {
      return;
    }

    if (!value.length) {
      alert.warn(
        localizeMessage({
          id: 'persona.table.filter.mmiModal.error.empty'
        })
      );

      this.focusField();

      return;
    }

    this.setState({
      sending: true
    });

    try {
      await api.mIndex.insert(value);

      alert.success(
        localizeMessage({
          id: 'persona.table.filter.mmiModal.success.sentSuccessful'
        })
      );

      close();
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.filter.mmiModal.error.notSent'
        })
      );

      this.setState({
        sending: false
      });
    }
  };

  getHintList = () => {
    const {hints} = this.state;

    return hints.map(hint => ({
      value: hint,
      label: hint
    }));
  };

  render () {
    const {close} = this.props;
    const {value, sending, hintsLoading} = this.state;

    return (
      <Modal
        title={
          <LocalizedMessage
            id='persona.table.filter.mmiModal.title'
          />
        }
        footerComponent={
          <div className={classes.Buttons}>
            <Button
              theme='transparent'
              onClick={close}
            >
              <LocalizedMessage
                id='base.cancel'
              />
            </Button>
            <Button
              disabled={sending}
              onClick={this.sendData}
            >
              <LocalizedMessage
                id='base.request'
              />
            </Button>
          </div>
        }
        loading={sending}
        className={classes.Container}
        contentClassName={classes.Content}
        onClose={close}
      >
        <form
          className={classes.Form}
          onSubmit={this.onSubmit}
        >
          <p className={classes.Label}>
            <LocalizedMessage
              id='persona.table.filter.mmiModal.fields.name'
            />
          </p>
          <div className={classes.FieldContainer}>
            <LocalizedMessage
              id='persona.table.filter.mmiModal.fields.name.placeholder'
            >
              {placeholder => (
                <FieldWithHintsDropdown
                  ref={this.setFieldRef}
                  hints={this.getHintList()}
                  placeholder={placeholder}
                  className={classes.Field}
                  defaultValue={value}
                  loading={hintsLoading}
                  loadHints={this.loadHints}
                  onChange={this.onFieldChange}
                />
              )}
            </LocalizedMessage>
          </div>
        </form>
      </Modal>
    );
  }
}

export default MmiModal;
