import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {addUserSelectStylesToBody, removeUserSelectStylesFromBody} from '../../../../../helpers/dom';
import classes from './Mover.module.scss';

class Mover extends Component {
  static propTypes = {
    onMove: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired
  };

  startY = null;

  componentDidMount () {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseStop);
  }

  componentWillUnmount () {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseStop);
  }

  onMouseStart = (e) => {
    this.startY = e.pageY;

    addUserSelectStylesToBody();
  };

  onMouseMove = (e) => {
    if (this.startY === null) {
      return;
    }

    const {onMove} = this.props;

    onMove(e.pageY - this.startY);
  };

  onMouseStop = () => {
    const {onStop} = this.props;

    this.startY = null;

    removeUserSelectStylesFromBody();

    onStop();
  };

  render () {
    return (
      <div
        className={classes.Container}
        onMouseDown={this.onMouseStart}
      />
    );
  }
}

export default Mover;
