import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Tabs.module.scss';

class Tabs extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node
        ]).isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool
      }).isRequired
    ).isRequired,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    optionClassName: PropTypes.string,
    optionSelectedClassName: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    className: null,
    optionClassName: null,
    optionSelectedClassName: null
  };

  state = {
    value: this.props.defaultValue || (this.props.options.length ? this.props.options[0].value : null)
  };

  reset = () => {
    const {options, defaultValue} = this.props;

    this.setState({
      value: defaultValue || (options.length ? options[0].value : null)
    });
  };

  getSelectedOption = (value) => {
    const {options} = this.props;

    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i];
      }
    }

    return null;
  };

  changeValue = (newValue, withCallback = true) => {
    const {onChange} = this.props;
    const {value} = this.state;

    if (value === newValue) {
      return false;
    }

    const selectedOption = this.getSelectedOption(newValue);

    if (selectedOption.disabled) {
      return false;
    }

    this.setState({
      value: newValue
    });

    if (withCallback && typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  render () {
    const {options, className, optionClassName, optionSelectedClassName} = this.props;
    const {value} = this.state;

    return (
      <ul className={cx(
        classes.List,
        className
      )}>
        {
          options.map(option => (
            <li
              key={option.value}
              className={cx(
                classes.Item,
                optionClassName,
                {
                  [classes.ItemDisabled]: option.disabled,
                  [cx(classes.ItemSelected, optionSelectedClassName)]: option.value === value
                }
              )}
              onClick={() => this.changeValue(option.value)}
            >
              {option.label}
            </li>
          ))
        }
      </ul>
    );
  }
}

export default Tabs;
