import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AutosizeInput from '../../../../../../../../lib/react-input-autosize';
import LocalizedMessage from '../../../../../../../../components/LocalizedMessage';
import Scrollbar from '../../../../../../../../components/Scrollbar';
import Loader from '../../../../../../../../components/Loader';
import OneLineLoader from '../../../../../../../../components/OneLineLoader';
import Config from '../../../../../../../../config';
import classes from '../Templates.module.scss';

const PRE_LOADING_DELAY = 1000;               // ms
const HINTS_SCROLLING_OFFSET_TO_LOAD = 50;    // px

class EventField extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    multi: PropTypes.bool,
    data: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.shape({
          ru: PropTypes.string.isRequired,
          en: PropTypes.string.isRequired
        }).isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      })
    ),
    isSelecting: PropTypes.bool.isRequired,
    loadEventHints: PropTypes.func.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    addOnScrollCallback: PropTypes.func.isRequired,
    removeOnScrollCallback: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputWidthChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    multi: false
  };

  state = {
    fieldValue: '',
    menuIsOpen: false,
    focusedAutocompleteIndex: 0,
    loading: false
  };

  _hintScrollbar = null;
  _field = null;
  _menu = null;
  _value = null;
  _hints = {};
  preLoadingTimer = 0;

  componentDidMount () {
    const {addOnScrollCallback, setFieldRef} = this.props;

    addOnScrollCallback(this.updateMenuPosition);

    setFieldRef(this._field);
  }

  componentWillUnmount () {
    const {removeOnScrollCallback} = this.props;

    removeOnScrollCallback(this.updateMenuPosition);
  }

  UNSAFE_componentWillReceiveProps ({setFieldRef}) {
    setFieldRef(this._field);
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  setValueRef = ref => {
    this._value = ref;
  };

  setMenuRef = ref => {
    this._menu = ref;
  };

  setHintScrollbarRef = ref => {
    this._hintScrollbar = ref;
  };

  updateMenuPosition = (containerPositions = null) => {
    if (!this._field) {
      return;
    }

    const inputComponentPosition = this._field.getBoundingClientRect();

    if (this._menu) {
      const fieldIsNotVisible =
        containerPositions &&
        (
          (containerPositions.left > inputComponentPosition.left) ||
          (containerPositions.right < inputComponentPosition.right) ||
          (containerPositions.top > inputComponentPosition.top) ||
          (containerPositions.bottom < inputComponentPosition.bottom)
        );

      if (fieldIsNotVisible) {
        this._menu.style.visibility = 'hidden';
      } else {
        this._menu.style.visibility = 'visible';
        this._menu.style.top = `${inputComponentPosition.bottom}px`;
        this._menu.style.left = `${inputComponentPosition.left}px`;
      }
    }
  };

  onFieldFocus = (e) => {
    const {data, isSelecting, toggleChanging} = this.props;

    if (isSelecting) {
      e.preventDefault();

      this._field.blur();

      return;
    }

    toggleChanging(true);

    this.setState({
      menuIsOpen: true,
      focusedAutocompleteIndex: 0
    }, () => {
      this.updateMenuPosition();
    });

    if (['ta_list', 'ta_list_advertisers'].includes(data.condition.type)) {
      return;
    }

    this.loadHints();
  };

  onFieldBlur = () => {
    const {toggleChanging} = this.props;

    toggleChanging(false);

    this.setState({
      menuIsOpen: false
    });
  };

  onFieldChange = (fieldValue) => {
    const {data, catalog} = this.props;

    this.setState({
      fieldValue,
      focusedAutocompleteIndex: 0
    });

    if (['ta_list', 'ta_list_advertisers'].includes(data.condition.type)) {
      setTimeout(() => {
        this.updateMenuPosition();
      });

      return;
    }

    const hasCurrentOptions =
      catalog.filters.hints[data.id] &&
      catalog.filters.hints[data.id][fieldValue] &&
      typeof catalog.filters.hints[data.id][fieldValue].loading === 'boolean' &&
      !catalog.filters.hints[data.id][fieldValue].loading;

    clearTimeout(this.preLoadingTimer);

    if (hasCurrentOptions) {
      setTimeout(() => {
        this.updateMenuPosition();
      });

      return;
    }

    this.setState({
      loading: true
    });

    this.preLoadingTimer = setTimeout(() => {
      this.preLoadingTimer = 0;

      setTimeout(() => {
        this.setState({
          loading: false
        }, () => {
          this.updateMenuPosition();
        });
      });

      this.loadHints();
    }, PRE_LOADING_DELAY);
  };

  onFieldKeyDown = (e, options) => {
    const {focusedAutocompleteIndex} = this.state;

    switch (e.keyCode) {
      case 13:            // Enter
        if (options[focusedAutocompleteIndex]) {
          this.selectOption(options[focusedAutocompleteIndex], true);
        }

        break;
      case 8:             // Backspace
        this.removeLastItem(e);

        break;
      case 38:            // Arrow Up
        if (focusedAutocompleteIndex > 0) {
          this.focusHint(focusedAutocompleteIndex - 1);
        }

        break;
      case 40:            // Arrow Down
        if (focusedAutocompleteIndex < options.length - 1) {
          this.focusHint(focusedAutocompleteIndex + 1);
        }

        break;
      default:
        break;
    }
  };

  focusInput = () => {
    if (this._field) {
      this._field.focus();
    }
  };

  blurInput = () => {
    if (this._field) {
      this._field.blur();
    }
  };

  loadHints = async () => {
    const {catalog, data, loadEventHints} = this.props;
    const {fieldValue} = this.state;

    const currentOptionsIsLoaded =
      catalog.filters.hints[data.id] &&
      catalog.filters.hints[data.id][fieldValue] &&
      typeof catalog.filters.hints[data.id][fieldValue].loading === 'boolean' &&
      !catalog.filters.hints[data.id][fieldValue].loading &&
      catalog.filters.hints[data.id][fieldValue].items.length;

    if (!currentOptionsIsLoaded) {
      await loadEventHints(data.id, fieldValue);

      this.updateMenuPosition();
    }
  };

  loadHintsMore = async () => {
    const {catalog, data, loadEventHints} = this.props;
    const {fieldValue} = this.state;

    const currentOptionsData =
      catalog.filters.hints[data.id] &&
      catalog.filters.hints[data.id][fieldValue]
        ? catalog.filters.hints[data.id][fieldValue]
        : null;

    if (currentOptionsData && !currentOptionsData.loading && !currentOptionsData.finished) {
      await loadEventHints(data.id, fieldValue, currentOptionsData.items.length);
    }
  };

  checkOptionsCount = () => {
    const {data} = this.props;

    const {options, loading, subLoading} = this.getOptions();

    if (
      !['ta_list', 'ta_list_advertisers'].includes(data.condition.type) &&
      !loading &&
      !subLoading &&
      options.length < Config.appOptions.eventHintsLoadingItemsLimit
    ) {
      this.loadHintsMore();
    }
  };

  onHintsScroll = (e) => {
    const _target = e.target;

    if (_target.scrollTop + _target.offsetHeight > _target.scrollHeight - HINTS_SCROLLING_OFFSET_TO_LOAD) {
      this.loadHintsMore();
    }
  };

  focusHint = (hintIndex, withScroll = true) => {
    const {fieldValue} = this.state;

    this.setState({
      focusedAutocompleteIndex: hintIndex
    }, () => {
      if (withScroll) {
        const _focusedHint = this._hints[`${fieldValue}-${hintIndex}`];
        const hintHeight = _focusedHint.offsetHeight;
        const hintOffsetTop = _focusedHint.offsetTop;

        this._hintScrollbar.scrollTop(hintOffsetTop - hintHeight);
      }
    });
  };

  removeLastItem = (e) => {
    const {multi, values, onChange} = this.props;

    if (!multi || !values.length || e.target.value.length) {
      return;
    }

    const nextValues = values.slice(0);
    nextValues.pop();

    onChange(nextValues);

    setTimeout(() => {
      this.updateMenuPosition();
    });
  };

  selectOption = (selectedOption, withBlur = false) => {
    const {multi, values, onChange} = this.props;

    if (multi) {
      const nextValues = values.slice(0);
      nextValues.push(selectedOption);

      onChange(nextValues);

      setTimeout(() => {
        this.focusInput();
        this.updateMenuPosition();
        this.checkOptionsCount();
      });
    } else {
      onChange([selectedOption]);
    }

    if (withBlur) {
      this.blurInput();
    }

    this.setState({
      fieldValue: ''
    });
  };

  removeValue = (removingOption) => {
    const {values, onChange} = this.props;

    setTimeout(() => {
      const nextValues = values.slice(0).filter(option => option.value !== removingOption.value);

      onChange(nextValues);
    });
  };

  filterUsedValues = (options) => {
    const {multi, values} = this.props;

    if (!options || !options.length) {
      return [];
    }

    if (!values || !values.length) {
      return options;
    }

    if (multi) {
      return options.filter(option => (
        !values.some(valueOption => valueOption.value === option.value)
      ));
    }

    return options.filter(option => (
      option.value !== values[0].value
    ));
  };

  getOptions = () => {
    const {locale, data, catalog} = this.props;
    const {fieldValue, loading} = this.state;

    if (['ta_list', 'ta_list_advertisers'].includes(data.condition.type)) {
      const fieldValueLowerCase = fieldValue.toLowerCase();

      const taListKey = (data.condition.type === 'ta_list_advertisers' ? 'advertiser_' : '') + data.id;
      const unfilteredOptions = catalog.filters.taLists[taListKey] || [];

      return {
        options: this.filterUsedValues(unfilteredOptions).filter(option => (
          option.label[locale].toLowerCase().indexOf(fieldValueLowerCase) > -1
        )),
        unfilteredOptions
      };
    }

    const currentOptionsData =
      catalog.filters.hints[data.id] &&
      catalog.filters.hints[data.id][fieldValue]
        ? catalog.filters.hints[data.id][fieldValue]
        : null;
    const unfilteredOptions =
      currentOptionsData &&
      currentOptionsData.items
        ? catalog.filters.hints[data.id][fieldValue].items
        : null;
    const options = this.filterUsedValues(unfilteredOptions);
    const currentOptionsLoading =
      (currentOptionsData && currentOptionsData.loading && !currentOptionsData.items.length) ||
      (!currentOptionsData && loading);
    const currentOptionsSubLoading =
      !loading &&
      currentOptionsData &&
      currentOptionsData.loading &&
      currentOptionsData.items.length;

    return {
      options,
      unfilteredOptions,
      loading: currentOptionsLoading,
      subLoading: currentOptionsSubLoading
    };
  };

  render () {
    const {locale, multi, data, values, isSelecting, onInputWidthChange} = this.props;
    const {fieldValue, menuIsOpen, focusedAutocompleteIndex} = this.state;

    const {options, unfilteredOptions, loading = false, subLoading = false} = this.getOptions();

    return (
      <div
        className={cx(
          classes.Select,
          {
            [classes.SelectMulti]: multi,
            [classes.SelectInSelectionMode]: isSelecting
          }
        )}
      >
        {
          multi &&
          values &&
          values.length ?
            <ul className={classes.SelectValues}>
              {
                values.map(option => (
                  <li
                    key={option.value}
                    className={classes.SelectValuesItem}
                  >
                    <div className={classes.SelectValuesItemContent}>
                      <span className={classes.SelectValuesLabel}>
                        {option.label[locale] || ''}
                      </span>
                      <span
                        className={classes.SelectValuesRemoveBtn}
                        onClick={() => this.removeValue(option)}
                      >
                        &times;
                      </span>
                    </div>
                  </li>
                ))
              }
            </ul>
            : null
        }
        {
          !['ta_list', 'ta_list_advertisers'].includes(data.condition.type) ||
          unfilteredOptions.length ?
            <div className={classes.SelectFieldContailer}>
              <LocalizedMessage
                id='persona.table.filter.formulaEditor.hints.enterSomething'
              >
                {localizedMessage => (
                  <AutosizeInput
                    inputRef={this.setFieldRef}
                    className={classes.FieldContainer}
                    inputClassName={cx(
                      classes.Field,
                      classes.SelectField,
                      {
                        [classes.SelectFieldWithValue]: (
                          !multi &&
                          !fieldValue.length &&
                          values &&
                          values.length === 1
                        )
                      }
                    )}
                    minWidth={1}
                    onFocus={this.onFieldFocus}
                    onBlur={this.onFieldBlur}
                    onKeyDown={e => this.onFieldKeyDown(e, options)}
                    onChange={e => this.onFieldChange(e.target.value)}
                    onAutosize={onInputWidthChange}
                    value={fieldValue}
                    placeholder={
                      !values ||
                      !values.length
                        ? localizedMessage
                        : null
                    }
                  />
                )}
              </LocalizedMessage>
              {
                !multi &&
                !fieldValue.length &&
                values &&
                values.length === 1 ?
                  <div
                    ref={this.setValueRef}
                    className={classes.SelectValue}
                    onClick={this.focusInput}
                  >
                    {values[0].label[locale]}
                  </div>
                  : null
              }
              {
                menuIsOpen && options && options.length ?
                  <div
                    ref={this.setMenuRef}
                    className={classes.SelectOptions}
                  >
                    <Scrollbar
                      ref={this.setHintScrollbarRef}
                      autoWidth
                      autoHeightMax={180}
                      onScroll={
                        !['ta_list', 'ta_list_advertisers'].includes(data.condition.type) ?
                          this.onHintsScroll
                          : null
                      }
                    >
                      <ul className={classes.SelectOptionsList}>
                        {
                          options.map((option, index) => (
                            <li
                              key={`${fieldValue}_${index}`}
                              ref={ref => {
                                this._hints[`${fieldValue}-${index}`] = ref;
                              }}
                              className={cx(
                                classes.SelectOptionsItem,
                                {
                                  [classes.SelectOptionsItemFocused]: focusedAutocompleteIndex === index
                                }
                              )}
                              onMouseEnter={() => this.focusHint(index, false)}
                              onMouseDown={() => this.selectOption(option)}
                            >
                              {option.label[locale] || ''}
                            </li>
                          ))
                        }
                      </ul>
                      {
                        subLoading ?
                          <OneLineLoader />
                          : null
                      }
                    </Scrollbar>
                  </div>
                  : null
              }
            </div>
            : null
        }
        {
          loading ?
            <div className={classes.SelectLoader}>
              <Loader
                isSmall
              />
            </div>
            : null
        }
      </div>
    );
  }
}

export default EventField;
