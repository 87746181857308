import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import shortid from 'shortid';
import {SelectableGroup} from '../../../../../lib/react-selectable-fast';
import Scrollbar from '../../../../../components/Scrollbar';
import Loader from '../../../../../components/Loader';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import Buttons from './Buttons';
import AdditionalDndPanel from './AdditionalDndPanel';
import StartingField from './StartingField';
import AdditionalItem from './AdditionalItem';
import Item from './Item';
import Connector from './Connector';
import {cloneObject, removeArrayItemsByIndexes} from '../../../../../helpers/utils';
import {conditionValueConverter, getItemLinkByPath, getMainParentPath} from '../../../helpers/formula';
import classes from './FormulaEditor.module.scss';

const FORMULA_AUTO_SCROLLING_VERTICAL_PADDING = 5;    // px

class FormulaEditor extends Component {
  static propTypes = {
    formula: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    withDateRange: PropTypes.bool,
    selectedItem: PropTypes.object,
    selectedList: PropTypes.object,
    errorPaths: PropTypes.array,
    formulaTaStatisticsNumber: PropTypes.shape({
      number: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
      loading: PropTypes.bool.isRequired,
      loaded: PropTypes.bool.isRequired,
      error: PropTypes.object
    }),
    memberListModalIsOpen: PropTypes.bool.isRequired,
    withSupportOfMemberLists: PropTypes.bool.isRequired,
    updateNumberOfTaStatistics: PropTypes.func.isRequired,
    getFieldByPath: PropTypes.func.isRequired,
    changeFormulaSelectedItems: PropTypes.func.isRequired,
    removeErrorPath: PropTypes.func.isRequired,
    updateFormula: PropTypes.func.isRequired,
    changeFormula: PropTypes.func.isRequired,
    openMemberListModal: PropTypes.func.isRequired,
    loadEventHints: PropTypes.func.isRequired
  };

  static defaultProps = {
    withDateRange: false
  };

  state = {
    isSelectingMode: false,
    cursorDirection: 'left'
  };

  _buttons = null;
  _scrollbar = null;
  _scrollbarContainer = null;
  _formulaContent = null;
  _wrapper = null;
  isSelectingWithDelay = false;
  onScrollCallbacks = [];
  fieldRefs = {};

  componentDidMount () {
    window.addEventListener('mousedown', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount () {
    window.removeEventListener('mousedown', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
    window.removeEventListener('keydown', this.onKeyDown);
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.scrollToSelectedField(newProps.selectedItem);
  }

  setButtonsRef = ref => {
    this._buttons = ref;
  };

  setScrollbarRef = ref => {
    this._scrollbar = ref;
  };

  setScrollbarContainerRef = ref => {
    this._scrollbarContainer = ref;
  };

  setFormulaContentRef = ref => {
    this._formulaContent = ref;
  };

  setWrapperRef = ref => {
    this._wrapper = ref;
  };

  onWindowClick = (event) => {
    const {memberListModalIsOpen, changeFormulaSelectedItems} = this.props;

    if (memberListModalIsOpen || this.isSelectingWithDelay) {
      return;
    }

    if (
      event.target !== this._formulaContent &&
      !this._buttons.getContainer().contains(event.target) &&
      !this._formulaContent.contains(event.target)
    ) {
      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: null
      });
    }
  };

  onKeyDown = (e) => {
    const {memberListModalIsOpen, selectedItem} = this.props;

    if (memberListModalIsOpen) {
      return;
    }

    if (
      (
        e.keyCode === 8 ||     // Backspace
        e.keyCode === 46       // Delete
      ) &&
      e.target.tagName.toLowerCase() !== 'input'
    ) {
      e.preventDefault();

      if (selectedItem) {
        this.removeItem();
      }
    }
  };

  addOnScrollCallback = (callback) => {
    this.onScrollCallbacks.push(callback);
  };

  removeOnScrollCallback = (callback) => {
    const callbackIndex = this.onScrollCallbacks.indexOf(callback);

    if (callbackIndex > -1) {
      this.onScrollCallbacks.splice(callbackIndex, 1);
    }
  };

  onScroll = () => {
    const scrollBarContainerPositions = this._scrollbarContainer.getBoundingClientRect();

    this.onScrollCallbacks.forEach(callback => {
      callback(scrollBarContainerPositions);
    });
  };

  selectItem = (item, ctrlKey, shiftKey, focusField) => {
    const {selectedItem, selectedList, changeFormulaSelectedItems} = this.props;

    if (
      selectedItem &&
      selectedItem.path.join('-') === item.path.join('-') &&
      selectedItem.type === item.type
    ) {
      return;
    }

    if (
      (!ctrlKey && !shiftKey) ||
      (
        (!selectedItem || selectedItem.type !== 'item') &&
        !selectedList
      )
    ) {
      changeFormulaSelectedItems({
        selectedItem: item,
        selectedList: null
      }, focusField);

      return;
    }

    if (ctrlKey) {
      this.selectItemWithCtrl(item);

      return;
    }

    if (shiftKey) {
      this.selectItemWithShift(item);
    }
  };

  selectItemWithCtrl = (item) => {
    const {formula, selectedItem, selectedList, changeFormulaSelectedItems} = this.props;

    const itemParentPath = item.path.slice(0, -1);

    if (selectedItem) {
      const parentPath = getMainParentPath(selectedItem.path, itemParentPath);
      const indexes = [
        selectedItem.path.slice(parentPath.length)[0],
        item.path.slice(parentPath.length)[0]
      ];

      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: {
          parentPath,
          indexes
        }
      });

      return;
    }

    if (selectedList) {
      const mainParentPath = getMainParentPath(selectedList.parentPath, itemParentPath);
      const selectedListParentPathIsMainParentPath = selectedList.parentPath.join('-') === mainParentPath.join('-');
      const itemIndex = item.path.slice(mainParentPath.length)[0];

      if (
        itemParentPath.length >= selectedList.parentPath.length &&
        selectedListParentPathIsMainParentPath &&
        selectedList.indexes.indexOf(itemIndex) > -1
      ) {
        const newIndexes = selectedList.indexes.slice(0);
        newIndexes.splice(newIndexes.indexOf(itemIndex), 1);

        if (newIndexes.length === 1) {
          const lastItemPath = selectedList.parentPath.concat([newIndexes[0]]);
          const lastItem = getItemLinkByPath(formula, lastItemPath);

          if (lastItem.children && lastItem.children.length) {
            changeFormulaSelectedItems({
              selectedItem: null,
              selectedList: {
                parentPath: lastItemPath,
                indexes: lastItem.children.map((child, index) => index)
              }
            });
          } else {
            changeFormulaSelectedItems({
              selectedItem: {
                type: 'item',
                path: lastItemPath
              },
              selectedList: null
            });
          }
        } else {
          changeFormulaSelectedItems({
            selectedItem: null,
            selectedList: {
              parentPath: selectedList.parentPath,
              indexes: newIndexes
            }
          });
        }

        return;
      }

      let newIndexes = [];
      newIndexes.push(itemIndex);

      if (selectedListParentPathIsMainParentPath) {
        newIndexes = newIndexes.concat(selectedList.indexes);
      } else {
        newIndexes.push(selectedList.parentPath.slice(mainParentPath.length)[0]);
      }

      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: {
          parentPath: mainParentPath,
          indexes: newIndexes
        }
      });
    }
  };

  selectItemWithShift = (item) => {
    const {selectedItem, selectedList, changeFormulaSelectedItems} = this.props;

    const itemParentPath = item.path.slice(0, -1);

    if (selectedItem) {
      const parentPath = getMainParentPath(selectedItem.path, itemParentPath);
      const selectedItemIndex = selectedItem.path.slice(parentPath.length)[0];
      const addingItemIndex = item.path.slice(parentPath.length)[0];
      const startIndex = Math.min(selectedItemIndex, addingItemIndex);
      const endIndex = Math.max(selectedItemIndex, addingItemIndex);

      const indexes = [];
      for (let index = startIndex; index <= endIndex; index++) {
        indexes.push(index);
      }

      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: {
          parentPath,
          indexes
        }
      });

      return;
    }

    if (selectedList) {
      const mainParentPath = getMainParentPath(selectedList.parentPath, itemParentPath);
      const selectedListParentPathIsMainParentPath = selectedList.parentPath.join('-') === mainParentPath.join('-');
      const itemIndex = item.path.slice(mainParentPath.length)[0];

      if (
        itemParentPath.length >= selectedList.parentPath.length &&
        selectedListParentPathIsMainParentPath &&
        selectedList.indexes.indexOf(itemIndex) > -1
      ) {
        return;
      }

      const startIndex =
        selectedList.indexes[0] > itemIndex
          ? itemIndex
          : selectedList.indexes[0];
      const endIndex =
        selectedList.indexes[0] > itemIndex
          ? selectedList.indexes[selectedList.indexes.length - 1]
          : itemIndex;

      const indexes = [];
      for (let index = startIndex; index <= endIndex; index++) {
        indexes.push(index);
      }

      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: {
          parentPath: mainParentPath,
          indexes
        }
      });
    }
  };

  changeConnector = (value) => {
    const {formula, selectedItem, changeFormulaSelectedItems, updateFormula} = this.props;

    if (!selectedItem || selectedItem.type !== 'connector') {
      return false;
    }

    const copyFormula = cloneObject(formula);

    const itemLink = getItemLinkByPath(copyFormula, selectedItem.path);

    itemLink.connector = value;

    updateFormula(copyFormula);

    changeFormulaSelectedItems({
      selectedItem: {
        type: 'connector',
        path: selectedItem.path
      },
      selectedList: null
    });
  };

  switchConnector = (path) => {
    const {formula, changeFormulaSelectedItems, updateFormula} = this.props;

    const copyFormula = cloneObject(formula);

    const itemLink = getItemLinkByPath(copyFormula, path);

    itemLink.connector = itemLink.connector === 'and' ? 'or' : 'and';

    updateFormula(copyFormula);

    changeFormulaSelectedItems({
      selectedItem: {
        type: 'connector',
        path: path
      },
      selectedList: null
    });
  };

  removeItem = () => {
    const {formula, selectedItem, changeFormulaSelectedItems, updateFormula} = this.props;

    if (!selectedItem || selectedItem.type !== 'item') {
      return false;
    }

    const path = selectedItem.path.slice(0);
    const index = path.pop();

    const copyFormula = cloneObject(formula);
    const itemLink = getItemLinkByPath(copyFormula, path);

    itemLink.children.splice(index, 1);

    if (
      itemLink.children.length === 1 &&
      (
        path.length ||
        (
          itemLink.children[0].children &&
          itemLink.children[0].children.length
        )
      )
    ) {
      itemLink.id = itemLink.children[0].id;
      itemLink.title = itemLink.children[0].title;

      if (itemLink.children[0].condition) {
        itemLink.condition = itemLink.children[0].condition;
      }

      itemLink.hasNot = itemLink.children[0].hasNot;
      itemLink.children = itemLink.children[0].children;
    }

    updateFormula(copyFormula);

    changeFormulaSelectedItems({
      selectedItem: null,
      selectedList: null
    });
  };

  groupItems = () => {
    const {formula, selectedList, changeFormulaSelectedItems, updateFormula} = this.props;

    if (!selectedList) {
      return;
    }

    const copyFormula = cloneObject(formula);

    const parentIndex = selectedList.parentPath.slice(-1)[0];

    const parent = getItemLinkByPath(copyFormula, selectedList.parentPath);
    const childrenLink = parent.children;

    if (childrenLink.length === selectedList.indexes.length) {
      if (!selectedList.parentPath.length) {
        return;
      }

      const parentContainerLink = getItemLinkByPath(copyFormula, selectedList.parentPath.slice(0, -1)).children;

      childrenLink[0].connector = parentContainerLink[parentIndex].connector;
      parentContainerLink.splice(parentIndex, 1, ...childrenLink);

      updateFormula(copyFormula);

      changeFormulaSelectedItems({
        selectedItem: null,
        selectedList: {
          parentPath: selectedList.parentPath.slice(0, -1),
          indexes: selectedList.indexes.map((cIndex, index) => parentIndex + index)
        }
      });
    } else {
      this.groupSiblings(copyFormula, parent);
    }
  };

  groupSiblings = (copyFormula, parent, hasNot = false) => {
    const {selectedList, changeFormulaSelectedItems, updateFormula} = this.props;

    const groupingItems = selectedList.indexes.map(index => parent.children[index]);
    const firstItemIndex = selectedList.indexes[0];
    const firstItemConnector = parent.children[firstItemIndex].connector;

    parent.children = removeArrayItemsByIndexes(parent.children, selectedList.indexes);
    parent.children.splice(
      firstItemIndex,
      0,
      {
        uid: shortid.generate(),
        connector: firstItemConnector,
        children: groupingItems,
        hasNot
      }
    );

    updateFormula(copyFormula);

    changeFormulaSelectedItems({
      selectedItem: null,
      selectedList: {
        parentPath: selectedList.parentPath.concat([firstItemIndex]),
        indexes: selectedList.indexes.map((cIndex, index) => index)
      }
    });
  };

  toggleNot = () => {
    const {formula, selectedItem, selectedList, updateFormula} = this.props;

    if ((!selectedItem || selectedItem.type !== 'item') && !selectedList) {
      return;
    }

    const copyFormula = cloneObject(formula);

    if (selectedItem) {
      const selectedItemLink = getItemLinkByPath(copyFormula, selectedItem.path);

      selectedItemLink.hasNot = !selectedItemLink.hasNot;

      updateFormula(copyFormula);
    } else {
      const parentIndex = selectedList.parentPath.length ? selectedList.parentPath.slice(-1)[0] : 0;

      const childrenLink = selectedList.parentPath.length
        ? getItemLinkByPath(copyFormula, selectedList.parentPath.slice(0, -1)).children
        : [copyFormula];

      const parent = childrenLink[parentIndex];

      if (parent.children.length === selectedList.indexes.length) {
        parent.hasNot = !parent.hasNot;

        updateFormula(copyFormula);
      } else {
        this.groupSiblings(copyFormula, parent, true);
      }
    }
  };

  changeConditionOperator = (operator) => {
    const {formula, selectedItem, updateFormula} = this.props;

    if (!selectedItem || selectedItem.type !== 'item') {
      return false;
    }

    const copyFormula = cloneObject(formula);

    const itemLink = getItemLinkByPath(copyFormula, selectedItem.path);

    itemLink.condition.values = conditionValueConverter(
      itemLink.condition.operator,
      operator,
      itemLink.condition.values
    );
    itemLink.condition.operator = operator;

    updateFormula(copyFormula, true);
  };

  changeConditionValue = (path, values) => {
    const {formula, updateFormula, errorPaths, removeErrorPath} = this.props;

    const copyFormula = cloneObject(formula);

    const itemLink = getItemLinkByPath(copyFormula, path);

    itemLink.condition.values = values;

    updateFormula(copyFormula);

    if (errorPaths.indexOf(path.join('-')) > -1) {
      setTimeout(() => {
        removeErrorPath(path);
      });
    }
  };

  onInputWidthChange = () => {
    this.forceUpdate();
  };

  setDateRange = (path, {start, end}) => {
    const {formula, updateFormula} = this.props;

    const copyFormula = cloneObject(formula);

    const itemLink = getItemLinkByPath(copyFormula, path);

    itemLink.dateRange = {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD')
    };

    updateFormula(copyFormula);
  };

  toggleSelection = () => {
    const {isSelectingMode} = this.state;

    this.setState({
      isSelectingMode: !isSelectingMode
    });
  };

  setCursorDirection = (direction) => {
    const {cursorDirection} = this.state;

    if (cursorDirection === direction) {
      return;
    }

    this.setState({
      cursorDirection: direction
    });
  };

  onEndSelection = () => {
    setTimeout(() => {
      this.isSelectingWithDelay = false;
    });
  };

  scrollToSelectedField = (selectedItem) => {
    if (!selectedItem || selectedItem.type !== 'item') {
      return;
    }

    this.scrollToField(selectedItem.path);
  };

  scrollToField = (path) => {
    const {getFieldByPath} = this.props;

    const _field = getFieldByPath(path);

    if (!_field) {
      return;
    }

    const fieldPosition = _field.getBoundingClientRect();

    const scrollbarScrollTop = this._scrollbar.getScrollTop();
    const scrollbarContainerPosition = this._scrollbarContainer.getBoundingClientRect();

    if (scrollbarContainerPosition.bottom < fieldPosition.bottom + FORMULA_AUTO_SCROLLING_VERTICAL_PADDING) {
      this._scrollbar.scrollTop(
        scrollbarScrollTop +
        (
          (
            fieldPosition.bottom +
            FORMULA_AUTO_SCROLLING_VERTICAL_PADDING
          ) -
          scrollbarContainerPosition.bottom
        )
      );
    } else if (scrollbarContainerPosition.top > fieldPosition.top - FORMULA_AUTO_SCROLLING_VERTICAL_PADDING) {
      this._scrollbar.scrollTop(
        scrollbarScrollTop -
        (
          scrollbarContainerPosition.top -
          (
            fieldPosition.top -
            FORMULA_AUTO_SCROLLING_VERTICAL_PADDING
          )
        )
      );
    }
  };

  handleSelection = (selectedItems) => {
    this.isSelectingWithDelay = true;

    const selectedPaths = selectedItems.map(item => item.props.path);

    const {changeFormulaSelectedItems} = this.props;

    if (!selectedPaths.length) {
      return;
    }

    if (selectedPaths.length === 1) {
      changeFormulaSelectedItems({
        selectedItem: {
          type: 'item',
          path: selectedPaths[0]
        },
        selectedList: null
      }, false);

      return;
    }

    let parentPath = selectedPaths[0].slice(0, -1);

    for (let k = 1; k < selectedPaths.length; k++) {
      parentPath = getMainParentPath(parentPath, selectedPaths[k].slice(0, -1));
    }

    const indexes = [];
    const parentPathLength = parentPath.length;

    for (let l = 0; l < selectedPaths.length; l++) {
      const currentIndex = selectedPaths[l][parentPathLength];

      if (indexes.indexOf(currentIndex) === -1) {
        indexes.push(currentIndex);
      }
    }

    changeFormulaSelectedItems({
      selectedItem: null,
      selectedList: {
        parentPath,
        indexes: indexes.sort((a, b) => a - b)
      }
    }, false);
  };

  getGroupClassNames = (path, index) => {
    const {formula, selectedList} = this.props;

    const classNames = [classes.FormulaGroup];

    if (selectedList) {
      const parentPathString = selectedList.parentPath.join('-');

      if (
        parentPathString === path.join('-') &&
        selectedList.indexes.indexOf(index) > -1
      ) {
        classNames.push(classes.FormulaGroupSelected);

        if (selectedList.indexes.indexOf(index) === 0) {
          classNames.push(classes.FormulaGroupSelectedFirst);
        }
      } else if (path.concat([index]).join('-') === parentPathString) {
        const parent = getItemLinkByPath(formula, path.concat([index]));

        if (parent.children.length === selectedList.indexes.length) {
          classNames.push(classes.FormulaGroupSelected);
          classNames.push(classes.FormulaGroupSelectedFirst);
        }
      }
    }

    return cx(classNames);
  };

  getParentClassNames = (currentPath, hasNot = false) => {
    const {formula, selectedItem, selectedList} = this.props;

    const classNames = [classes.FormulaParent];

    const currentPathString = currentPath.join('-');

    if (hasNot) {
      classNames.push(classes.FormulaParentHasNot);
    }

    if (selectedItem) {
      if (
        selectedItem.path.length &&
        currentPathString === selectedItem.path.slice(0, -1).join('-')
      ) {
        classNames.push(classes.FormulaParentSelected);
      }
    } else if (selectedList) {
      const parent = getItemLinkByPath(formula, selectedList.parentPath);

      if (
        (
          selectedList.parentPath.length &&
          selectedList.indexes.length === parent.children.length &&
          currentPathString === selectedList.parentPath.slice(0, -1).join('-')
        ) ||
        (
          selectedList.indexes.length < parent.children.length &&
          currentPathString === selectedList.parentPath.join('-')
        )
      ) {
        classNames.push(classes.FormulaParentSelected);
      }
    }

    return cx(classNames);
  };

  formulaGenerator = (formula, path = []) => {
    const {
      locale,
      catalog,
      withDateRange,
      selectedItem,
      selectedList,
      errorPaths,
      changeFormula,
      openMemberListModal,
      loadEventHints
    } = this.props;
    const {isSelectingMode, cursorDirection} = this.state;

    if (!formula.children || !formula.children.length) {
      return (
        <StartingField />
      );
    }

    return formula.children.map((item, index) => {
      const currentPath = path.slice(0);
      currentPath.push(index);
      const currentPathString = currentPath.join('-');

      return (
        <div
          key={item.uid || item.id}
          className={this.getGroupClassNames(path, index)}
        >
          {
            item.children && item.children.length && index ?
              <Connector
                selectedItem={selectedItem}
                path={currentPath}
                isSelecting={isSelectingMode}
                setCursor={this.setCursorDirection}
                cursorDirection={cursorDirection}
                switchConnector={this.switchConnector}
              >
                <LocalizedMessage
                  id={`persona.table.filter.formulaEditor.buttons.${item.connector}`}
                />
              </Connector>
              : null
          }
          {
            item.children && item.children.length ?
              <div
                className={this.getParentClassNames(currentPath, item.hasNot)}
              >
                <div className={classes.FormulaNotOperator}>
                  <LocalizedMessage
                    id='persona.table.filter.formulaEditor.buttons.not'
                  />
                </div>
                {
                  this.formulaGenerator(item, currentPath)
                }
              </div> :
              <div className={classes.FormulaItemContainer}>
                {
                  index ?
                    <Connector
                      selectedItem={selectedItem}
                      path={currentPath}
                      isSelectedFirst={selectedList && selectedList.indexes.indexOf(index) === 0}
                      isSelecting={isSelectingMode}
                      setCursor={this.setCursorDirection}
                      cursorDirection={cursorDirection}
                      switchConnector={this.switchConnector}
                    >
                      <LocalizedMessage
                        id={`persona.table.filter.formulaEditor.buttons.${item.connector}`}
                      />
                    </Connector>
                    : null
                }
                <Item
                  setFieldRef={ref => {
                    this.fieldRefs[currentPathString] = ref;
                  }}
                  locale={locale}
                  data={item}
                  catalog={catalog}
                  selectableKey={currentPath}
                  selectedItem={selectedItem}
                  path={currentPath}
                  scrollToField={() => setTimeout(() => {
                    this.scrollToField(currentPath);
                  })}
                  selectItem={this.selectItem}
                  isSelecting={isSelectingMode}
                  isInvalid={errorPaths.indexOf(currentPathString) > -1}
                  withDateRange={withDateRange}
                  setCursor={this.setCursorDirection}
                  cursorDirection={cursorDirection}
                  changeFormula={changeFormula}
                  setDateRange={dateRange => this.setDateRange(currentPath, dateRange)}
                  onInputWidthChange={this.onInputWidthChange}
                  openMemberListModal={openMemberListModal}
                  addOnScrollCallback={this.addOnScrollCallback}
                  removeOnScrollCallback={this.removeOnScrollCallback}
                  changeConditionValue={values => this.changeConditionValue(currentPath, values)}
                  loadEventHints={loadEventHints}
                />
              </div>
          }
        </div>
      );
    });
  };

  render () {
    const {
      formula,
      selectedItem,
      selectedList,
      formulaTaStatisticsNumber,
      withSupportOfMemberLists,
      openMemberListModal,
      updateNumberOfTaStatistics
    } = this.props;
    const {isSelectingMode} = this.state;

    const formulaIsEmpty = !formula.children || !formula.children.length;

    return (
      <div className={classes.Container}>
        <div className={classes.Control}>
          {
            formulaTaStatisticsNumber &&
            (
              formulaTaStatisticsNumber.loaded ||
              formulaTaStatisticsNumber.loading
            ) ?
              <div className={classes.AudienceSize}>
                <span className={classes.AudienceSizeLabel}>
                  <LocalizedMessage
                    id='persona.table.filter.formulaEditor.audienceSize'
                  />
                </span>
                <span className={classes.AudienceSizeValue}>
                  {
                    !formulaTaStatisticsNumber.loading
                      ? !formulaTaStatisticsNumber.error ? formulaTaStatisticsNumber.number : '-'
                      : <Loader
                        isSmall
                      />
                  }
                </span>
                <span
                  className={cx(
                    classes.AudienceSizeUpdateBtn,
                    'icon-refresh'
                  )}
                  onClick={updateNumberOfTaStatistics}
                />
              </div>
              : null
          }
          <Buttons
            ref={this.setButtonsRef}
            formula={formula}
            selectedItem={selectedItem}
            selectedList={selectedList}
            isSelecting={isSelectingMode}
            changeConnector={this.changeConnector}
            removeItem={this.removeItem}
            groupItems={this.groupItems}
            toggleNot={this.toggleNot}
            toggleSelection={this.toggleSelection}
            changeConditionOperator={this.changeConditionOperator}
          />
        </div>
        <div
          ref={this.setScrollbarContainerRef}
          className={classes.FormulaScrollbarContainer}
        >
          <div className={classes.FormulaScrollbarView}>
            <Scrollbar
              ref={this.setScrollbarRef}
              hideHorizontalScrollbar
              className={classes.FormulaScrollbar}
              viewProps={{
                'data-type': 'formula-editor-scrollbar'
              }}
              onScroll={this.onScroll}
            >
              <SelectableGroup
                disabled={!isSelectingMode}
                verticalItemOffset={3}
                duringSelection={this.handleSelection}
                onSelectionFinish={this.onEndSelection}
                resetOnStart
                allowClickWithoutSelected={false}
                scrollContainer='[data-type=formula-editor-scrollbar]'
                className={classes.Formula}
                selectboxClassName={classes.FormulaSelection}
              >
                <div className={classes.FormulaView}>
                  {
                    !formulaIsEmpty ?
                      <AdditionalItem
                        formula={formula}
                        path={[0]}
                        direction='left'
                      />
                      : null
                  }
                  {
                    !formulaIsEmpty ?
                      <AdditionalItem
                        formula={formula}
                        path={[formula.children.length - 1]}
                        direction='right'
                      />
                      : null
                  }
                  <div
                    ref={this.setWrapperRef}
                    className={classes.FormulaWrapper}
                  >
                    <div
                      ref={this.setFormulaContentRef}
                      className={cx(
                        classes.FormulaContent,
                        {
                          [classes.FormulaContentHasNot]: !formulaIsEmpty && formula.hasNot,
                          [classes.FormulaContentSelected]: (
                            !formulaIsEmpty &&
                            selectedList &&
                            !selectedList.parentPath.length &&
                            formula.children.length === selectedList.indexes.length
                          )
                        }
                      )}
                    >
                      <div className={classes.FormulaNotOperator}>
                        <LocalizedMessage
                          id='persona.table.filter.formulaEditor.buttons.not'
                        />
                      </div>
                      {
                        formulaIsEmpty ?
                          <StartingField /> :
                          this.formulaGenerator(formula)
                      }
                    </div>
                  </div>
                </div>
              </SelectableGroup>
            </Scrollbar>
          </div>
        </div>
        {
          withSupportOfMemberLists ?
            <AdditionalDndPanel
              openMemberListModal={openMemberListModal}
            />
            : null
        }
      </div>
    );
  }
}

export default FormulaEditor;
