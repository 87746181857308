import React from 'react';
import {localizeMessage} from '../../../../components/LocalizedMessage';
import classes from './AuthInfoPopup.module.scss';

function AuthInfoPopup () {
  return (
    <div className={classes.popup}>
      <p>{localizeMessage({id: 'persona.alerts.needToUseDomainLoginAndPassword'})}</p>
      <p>
        {localizeMessage({id: 'persona.alerts.getAccessToSystem'})}
        {' '}
        <a className={classes.link} href='mailto:support@aizek.io'>support@aizek.io</a>
      </p>
    </div>
  );
}

export {AuthInfoPopup};
