import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FileSaver from 'file-saver';
import Scrollbar from '../../../../components/Scrollbar';
import Control from './Control';
import InfiniteScroll from './InfiniteScroll';
import Chart from './Chart';
import {transposeMatrix} from '../../../../helpers/utils';
import {generateReverseDateString} from '../../../../helpers/date';
import {generateColorList, convertHexToRGB} from '../../../../helpers/colours';
import {addCustomMeasures} from '../../helpers/table';
import {getNPlusValue, parseTableData} from '../../logic/parser';
import classes from './Graph.module.scss';

class Graph extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    catalog: PropTypes.object.isRequired,
    data: PropTypes.object,
    type: PropTypes.string,
    direction: PropTypes.oneOf(['x', 'y']).isRequired,
    selectedStatisticTypes: PropTypes.array.isRequired,
    isGraphGroupingData: PropTypes.bool.isRequired,
    currentPos: PropTypes.number.isRequired,
    viewPos: PropTypes.number.isRequired,
    onChangePos: PropTypes.func.isRequired
  };

  static defaultProps = {
    chars: []
  };

  state = {
    currentScrollPage: 0
  };

  UNSAFE_componentWillReceiveProps (newProps) {
    if (!newProps.isGraphGroupingData && this.props.isGraphGroupingData) {
      this.resetCurrentScrollPage();
    }
  }

  resetCurrentScrollPage = () => {
    this.setState({
      currentScrollPage: 0
    });
  };

  recursiveRender = (matrix, item, row, depth = 0) => {
    const {locale} = this.props;

    // If matrix row is not exist, we create new matrix row
    if (typeof matrix[row] === 'undefined') {
      matrix[row] = [];

      // If depth is greater than 0, we add empty cells with link to cell that uses this place
      if (depth > 0) {
        for (let i = 0; i < depth; i++) {
          matrix[row].push(matrix[row - 1][i]);
        }
      }
    }

    // Add current cell to matrix
    matrix[row].push({
      content: item.title[locale] || ''
    });

    // Add children to matrix
    if (item.children && item.children.length) {
      item.children.forEach((child, index) => {
        const nextRow = matrix.length + (!index ? -1 : 0);

        this.recursiveRender(matrix, child, nextRow, depth + 1);
      });
    }
  };

  generateMatrix = (dataTree) => {
    const matrix = [];

    dataTree.forEach(item => {
      this.recursiveRender(matrix, item, matrix.length, 0);
    });

    return matrix;
  };

  generateLabelList = (dataTree, statisticLength) => {
    const resultLabelList = [];

    const hasOneParent =
      dataTree &&
      dataTree.length &&
      dataTree[0].length &&
      dataTree.every(r => r[0] === dataTree[0][0]);

    for (let k = 0; k < dataTree.length / statisticLength; k++) {
      const row = dataTree[k * statisticLength];
      const labelArray = [];

      // `1` is number of statistic columns
      for (let l = hasOneParent ? 1 : 0; l < row.length - 1; l++) {
        labelArray.push(row[l].content);
      }

      resultLabelList.push(labelArray.join(' - '));
    }

    return resultLabelList;
  };

  generateTitleList = (dataTree) => {
    const resultLabelList = [];

    for (let k = 0; k < dataTree.length; k++) {
      const row = dataTree[k];
      const labelArray = [];

      for (let l = 0; l < row.length; l++) {
        labelArray.push(row[l].content);
      }

      resultLabelList.push(labelArray.join(' - '));
    }

    return resultLabelList;
  };

  generateStatKey = (stat) => {
    return stat.id + (stat.affinity ? 'a' : '') + (stat.n ? '_' + getNPlusValue(stat) : '');
  };

  parseDataMatrixToStatistic = (dataMatrix, statistic) => {
    const {direction} = this.props;

    const currentDataMatrix = direction === 'x' ? transposeMatrix(dataMatrix) : dataMatrix;

    const resultStatistics = {};
    const statisticKeys = [];
    statistic.items.forEach(stat => {
      if (stat.nplus) {
        stat.values.forEach(value => {
          const key = this.generateStatKey(stat) + '_' + value;

          resultStatistics[key] = [];
          statisticKeys.push(key);
        });
      } else {
        const key = this.generateStatKey(stat);

        resultStatistics[key] = [];
        statisticKeys.push(key);
      }
    });
    const statisticKeysLength = statisticKeys.length;

    for (let k = 0; k < currentDataMatrix.length; k++) {
      statisticKeys.forEach(statKey => {
        resultStatistics[statKey].push([]);
      });

      for (let l = 0; l < currentDataMatrix[k].length; l++) {
        const currentStatisticKey = statisticKeys[l % statisticKeysLength];

        resultStatistics[currentStatisticKey][k].push(currentDataMatrix[k][l]);
      }
    }

    return resultStatistics;
  };

  generateData = () => {
    const {data, direction} = this.props;
    const parsedData = parseTableData(data, direction);

    addCustomMeasures(parsedData);

    const {verticalDataTree, horizontalDataTree, matrix, statistic} = parsedData;

    const matrixVertical = this.generateMatrix(verticalDataTree);
    const matrixHorizontal = this.generateMatrix(horizontalDataTree);
    const rows = this.parseDataMatrixToStatistic(matrix, statistic);
    const labels = this.generateLabelList(
      direction === 'y' ? matrixHorizontal : matrixVertical,
      Object.keys(rows).length
    );
    const titles = this.generateTitleList(direction === 'x' ? matrixHorizontal : matrixVertical);

    return {
      rows,
      labels,
      titles
    };
  };

  getSecondGraphData = (rows, index) => {
    const {type, selectedStatisticTypes} = this.props;

    if (
      (
        type !== 'line' &&
        type !== 'bar'
      ) ||
      !selectedStatisticTypes[1]
    ) {
      return null;
    }

    return {
      type: type === 'line' ? 'bar' : 'line',
      label: selectedStatisticTypes[1].title,
      data: rows[this.generateStatKey(selectedStatisticTypes[1])][index]
    };
  };

  sortDatasets = (datas, labels) => {
    const sortedDatas = datas[0].map((col, i) => ({
      label: labels[i],
      data: datas.map(row => parseFloat(row[i]) || 0)
    })).slice().sort((row1, row2) => (row2.data[0] - row1.data[0]));

    const resultLabels = sortedDatas.map(data => data.label);
    const resultDatasets = sortedDatas[0].data.map((col, i) => {
      return sortedDatas.map(row => row.data[i]);
    });

    return {
      labels: resultLabels,
      datasets: resultDatasets
    };
  };

  generateDatasets = (rows, sourceLabels, data, index) => {
    const {type, selectedStatisticTypes} = this.props;

    const colorList = generateColorList(data.length);

    const secondGraph = this.getSecondGraphData(rows, index);

    const datas = [data];
    if (secondGraph) {
      datas.push(secondGraph.data);
    }

    const {datasets, labels} = this.sortDatasets(datas, sourceLabels);

    const resultDatasets = [{
      type: type,
      label: selectedStatisticTypes[0].title,
      yAxisID: 'A',
      data: datasets[0],
      backgroundColor: type === 'line'
        ? 'transparent'
        : (type === 'bar' ? colorList.map(color => convertHexToRGB(color, 20)) : colorList),
      borderColor: type === 'line'
        ? '#ff6384'
        : (type === 'bar' ? colorList : '#fff'),
      borderWidth: type === 'line' ? 2 : 1
    }];

    if (secondGraph) {
      resultDatasets.push({
        type: secondGraph.type,
        label: secondGraph.label,
        yAxisID: 'B',
        data: datasets[1],
        backgroundColor: secondGraph.type === 'line' ? 'transparent' : '#9966ff',
        borderColor: secondGraph.type === 'line' ? '#9966ff' : '#fff',
        borderWidth: secondGraph.type === 'line' ? 2 : 1
      });
    }

    if (secondGraph && type === 'bar') {
      resultDatasets.reverse();
    }

    return {
      datasets: resultDatasets,
      labels: labels
    };
  };

  generateDatasetsForMultiChartList = (rows, sourceLabels, titles) => {
    const {type, selectedStatisticTypes} = this.props;

    const currentDatas = rows[this.generateStatKey(selectedStatisticTypes[0])];
    const {datasets, labels} = this.sortDatasets(currentDatas, sourceLabels);
    const colorList = generateColorList(datasets.length);

    return {
      datasets: datasets.map((data, index) => ({
        type: type,
        label: titles[index],
        data: data,
        borderColor: colorList[index],
        borderWidth: 2,
        backgroundColor: convertHexToRGB(colorList[index], 20)
      })),
      labels: labels
    };
  };

  exportChart = (canvas) => {
    const {catalog, data} = this.props;

    const dateString = generateReverseDateString(new Date(), ' ');
    const statisticString = data.structure.measures.statistics.map(statistic => (
      statistic.title +
      (
        statistic.affinity &&
        statistic.values &&
        statistic.values.length
          ? ` (${statistic.values.join(', ')})`
          : ''
      )
    )).join('_');

    canvas.toBlob(blob => {
      FileSaver.saveAs(blob, `${dateString}_Aizek_${catalog.title}_${statisticString}.png`);
    });
  };

  setPageNum = (pageNum) => {
    this.setState({
      currentScrollPage: pageNum
    });
  };

  render () {
    const {
      type,
      selectedStatisticTypes,
      isGraphGroupingData,
      currentPos,
      viewPos,
      onChangePos
    } = this.props;
    const {currentScrollPage} = this.state;

    const {rows, labels, titles} = this.generateData();

    const isGrouping = (type === 'line' || type === 'bar') && !selectedStatisticTypes[1] && isGraphGroupingData;

    const generatedDatasets =
      isGrouping
        ? this.generateDatasetsForMultiChartList(rows, labels, titles)
        : rows[this.generateStatKey(selectedStatisticTypes[0])]
          .slice(0, currentScrollPage + 1)
          .map((data, index) => this.generateDatasets(rows, labels, data, index));

    const maxPos =
      isGrouping
        ? generatedDatasets.datasets[0].data.length
        : generatedDatasets[0].datasets.length ? generatedDatasets[0].datasets[0].data.length : 0;

    const realViewPos = Math.min(viewPos, maxPos);

    const hasControlPanel = type === 'line' || type === 'bar';

    return (
      <div className={classes.Container}>
        {
          hasControlPanel ?
            <Control
              currentPos={currentPos}
              viewPos={realViewPos}
              maxPos={maxPos}
              onChangePos={onChangePos}
            />
            : null
        }
        <Scrollbar
          className={cx(
            classes.Scrollbar,
            {
              [classes.ScrollbarWithControlPanel]: hasControlPanel
            }
          )}
        >
          {
            isGrouping ?
              <Chart
                datasets={generatedDatasets.datasets}
                labels={generatedDatasets.labels}
                selectedStatisticTypes={selectedStatisticTypes}
                isGroupingData={isGraphGroupingData}
                originalType={type}
                currentPos={currentPos}
                viewPos={realViewPos}
                maxPos={maxPos}
                onChangePos={onChangePos}
                exportChart={this.exportChart}
              />
              :
              <InfiniteScroll
                element='ul'
                useWindow={false}
                className={classes.List}
                loadMore={this.setPageNum}
                hasMore={rows[this.generateStatKey(selectedStatisticTypes[0])].length > currentScrollPage}
              >
                {
                  generatedDatasets.map((datasets, index) => (
                    <li
                      key={index}
                      className={classes.Item}
                    >
                      <Chart
                        title={titles[index]}
                        datasets={datasets.datasets}
                        labels={datasets.labels}
                        selectedStatisticTypes={selectedStatisticTypes}
                        hasSecondChart={
                          !!(
                            (
                              type === 'line' ||
                              type === 'bar'
                            ) &&
                            selectedStatisticTypes[1]
                          )
                        }
                        originalType={type}
                        currentPos={currentPos}
                        viewPos={realViewPos}
                        maxPos={maxPos}
                        onChangePos={onChangePos}
                        exportChart={this.exportChart}
                      />
                    </li>
                  ))
                }
              </InfiniteScroll>
          }
        </Scrollbar>
      </div>
    );
  }
}

export default Graph;
