import {connect} from 'react-redux';
import Profile from '../components/Profile';

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  profile: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
