import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {getPageWidth} from '../../helpers/dom';
import classes from './DropPanel.module.scss';

class DropPanel extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    openedClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    withArrow: PropTypes.bool,
    contentPosition: PropTypes.oneOf(['left', 'right']),
    fixedContentPosition: PropTypes.bool,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    className: null,
    openedClassName: null,
    titleClassName: null,
    contentClassName: null,
    withArrow: true,
    contentPosition: 'left',
    fixedContentPosition: false
  };

  state = {
    isOpen: false,
    fixedPosition: {
      top: 0,
      left: 0
    }
  };

  componentDidMount () {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick = (event) => {
    const {isOpen} = this.state;
    const dropdownElement = findDOMNode(this);

    if (
      isOpen &&
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      event.target.className.indexOf('react-datepicker') === -1
    ) {
      this.toggle(false);
    }
  };

  toggle = isOpen => {
    const {onToggle} = this.props;

    this.setState({
      isOpen
    });

    if (typeof onToggle === 'function') {
      onToggle(isOpen);
    }
  };

  updateFixedPosition = (e) => {
    const {contentPosition} = this.props;

    const target = e.currentTarget;

    const targetPosition = target.getBoundingClientRect();

    const fixedPosition = {
      top: targetPosition.top + targetPosition.height
    };

    if (contentPosition === 'right') {
      fixedPosition.right = getPageWidth() - (targetPosition.left + targetPosition.width);
    } else {
      fixedPosition.left = targetPosition.left;
    }

    this.setState({
      fixedPosition
    });
  };

  handleClick = (e) => {
    const {fixedContentPosition} = this.props;
    const {isOpen} = this.state;

    this.toggle(!isOpen);

    if (!isOpen && fixedContentPosition) {
      this.updateFixedPosition(e);
    }
  };

  render () {
    const {
      title,
      children,
      className,
      openedClassName,
      titleClassName,
      contentClassName,
      withArrow,
      contentPosition,
      fixedContentPosition
    } = this.props;
    const {isOpen, fixedPosition} = this.state;

    return (
      <div className={cx(
        classes.Container,
        {
          [cx(classes.ContainerOpened, openedClassName)]: isOpen
        },
        className
      )}>
        <p
          className={cx(
            classes.Title,
            {
              [classes.TitleWithArrow]: withArrow
            },
            titleClassName
          )}
          onClick={this.handleClick}
        >
          {title}
          <span
            className={cx(
              classes.TitleArrow,
              isOpen ? 'icon-arrow-drop-up' : 'icon-arrow-drop-down'
            )}
          />
        </p>
        <div
          className={cx(
            classes.Content,
            {
              [classes.ContentRight]: contentPosition === 'right',
              [classes.ContentFixed]: fixedContentPosition
            },
            contentClassName
          )}
          style={fixedContentPosition ? fixedPosition : null}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default DropPanel;
