import {combineReducers} from 'redux';
import locale from './locale';
import auth from './auth';
import persona from './persona';

export default combineReducers({
  locale,
  auth,
  persona
});
