import Client from './HTTPClient';
import Config from '../../config';

const eventHintsAPI = {
  async find (catalog, id, pattern, offset = 0) {
    try {
      const hints = await Client.post(`/hint/${catalog}/autocomplete/dimension/${id}`, {
        limit: Config.appOptions.eventHintsLoadingItemsLimit,
        offset,
        pattern
      });

      return {
        hints,
        finished: (
          !hints ||
          !hints.length ||
          hints.length < Config.appOptions.eventHintsLoadingItemsLimit
        )
      };
    } catch (error) {
      throw error;
    }
  }
};

export default eventHintsAPI;
