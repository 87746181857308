import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import classes from './DatePicker.module.scss';

class DatePickerInput extends Component {
  static propTypes = {
    container: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.string,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    disabled: false
  };

  _dayInput = null;
  _monthInput = null;
  _yearInput = null;

  setDayInputRef = ref => {
    this._dayInput = ref;
  };

  setMonthInputRef = ref => {
    this._monthInput = ref;
  };

  setYearInputRef = ref => {
    this._yearInput = ref;
  };

  validatePartOfDateByType = (type, value) => {
    switch (type) {
      case 'day':
        return {
          index: 0,
          value: value.slice(0, 2)
        };
      case 'month':
        return {
          index: 1,
          value: value.slice(0, 2)
        };
      case 'year':
        return {
          index: 2,
          value: value.slice(0, 4)
        };
      default:
        break;
    }
  };

  onKeyDown = (type, e, splitValue) => {
    const {container, onKeyDown, onChange} = this.props;

    if (e.keyCode === 13) {
      e.preventDefault();

      const currentDate = container._datepicker.calcInitialState().preSelection.format('DD.MM.YYYY');

      onChange({
        ...e,
        target: {
          ...e.target,
          value: currentDate
        }
      });

      return;
    }

    const _target = e.target;

    const isNumber =
      (e.keyCode > 47 && e.keyCode < 58) ||             // number keys
      (e.keyCode >= 96 && e.keyCode <= 105);            // numpad number keys
    const maxLength = parseInt(_target.getAttribute('maxLength'), 10);

    onKeyDown(e);

    if (
      !e.metaKey &&
      !e.ctrlKey &&
      (
        e.shiftKey ||
        e.altKey ||
        (
          !isNumber &&
          (
            e.keyCode === 32 ||                              // space
            (e.keyCode > 64 && e.keyCode < 91) ||            // letter keys
            (e.keyCode > 95 && e.keyCode < 112) ||           // numpad keys
            (e.keyCode > 185 && e.keyCode < 193) ||          // ;=,-./` (in order)
            (e.keyCode > 218 && e.keyCode < 223)             // [\]' (in order)
          )
        )
      )
    ) {
      e.preventDefault();

      return;
    }

    if (
      e.keyCode === 37 &&                                // arrow left
      _target.selectionStart === 0 &&
      _target.selectionStart === _target.selectionEnd
    ) {
      e.preventDefault();

      if (type === 'year') {
        const prevInputValueLength = this._monthInput.value.length;

        this._monthInput.focus();
        this._monthInput.setSelectionRange(prevInputValueLength, prevInputValueLength);
      } else if (type === 'month') {
        const prevInputValueLength = this._dayInput.value.length;

        this._dayInput.focus();
        this._dayInput.setSelectionRange(prevInputValueLength, prevInputValueLength);
      }

      return;
    }

    if (
      (
        e.keyCode === 39 ||                               // arrow right
        isNumber
      ) &&
      _target.selectionStart === _target.selectionEnd &&
      _target.selectionStart === maxLength
    ) {
      e.preventDefault();

      if (type === 'month') {
        this._yearInput.focus();
        this._yearInput.setSelectionRange(0, isNumber ? this._yearInput.value.length : 0);
      } else if (type === 'day') {
        this._monthInput.focus();
        this._monthInput.setSelectionRange(0, isNumber ? this._monthInput.value.length : 0);
      }

      return;
    }

    if (
      e.keyCode === 8 &&                                   // backspace
      _target.selectionStart === 0 &&
      _target.selectionStart === _target.selectionEnd
    ) {
      if (type === 'year') {
        const newPrevInputValue = splitValue[1].slice(0, -1);
        const newPrevInputValueLength = newPrevInputValue.length;

        this._monthInput.value = newPrevInputValue;
        this._monthInput.focus();
        this._monthInput.setSelectionRange(newPrevInputValueLength, newPrevInputValueLength);
      } else if (type === 'month') {
        const newPrevInputValue = splitValue[0].slice(0, -1);
        const newPrevInputValueLength = newPrevInputValue.length;

        this._dayInput.value = newPrevInputValue;
        this._dayInput.focus();
        this._dayInput.setSelectionRange(newPrevInputValueLength, newPrevInputValueLength);
      }

      return;
    }

    if (
      isNumber &&
      _target.selectionStart === _target.selectionEnd &&
      _target.value.length >= maxLength
    ) {
      e.preventDefault();
    }

    if (
      isNumber &&
      (
        type === 'day' ||
        type === 'month'
      ) &&
      _target.selectionStart + 1 === 2
    ) {
      const _nextInput = type === 'day'
        ? this._monthInput
        : this._yearInput;
      const nextInputValueLength = _nextInput.value.length;

      setTimeout(() => {
        _nextInput.focus();

        if (nextInputValueLength) {
          _nextInput.setSelectionRange(0, nextInputValueLength);
        }
      }, 10);
    }
  };

  onFocus = (e) => {
    const {onFocus} = this.props;

    onFocus(e);

    e.target.setSelectionRange(0, e.target.value.length);
  };

  onChange = (type, e, splitValue) => {
    const {onChange} = this.props;

    const validatedPartOfDate = this.validatePartOfDateByType(type, e.target.value);

    const resultDate = splitValue.slice();
    resultDate[validatedPartOfDate.index] = validatedPartOfDate.value;

    onChange({
      ...e,
      target: {
        ...e.target,
        value: resultDate.join('.')
      }
    });
  };

  safeDateFormat = (date, {dateFormat, locale}) => {
    return (
      (date &&
        date
          .clone()
          .locale(locale || moment.locale())
          .format(Array.isArray(dateFormat) ? dateFormat[0] : dateFormat)) ||
      ''
    );
  };

  render () {
    const {
      container,
      className,
      disabled,
      value,
      onChange,
      onFocus,
      onKeyDown,
      ...otherProps
    } = this.props;

    const isOpen = container._datepicker ? container._datepicker.state.open : false;

    const inputValue = typeof value === 'string'
      ? value
      : this.safeDateFormat(container.props.selected, container._datepicker.props);

    const splitValue = inputValue.split('.');

    return (
      <div
        className={cx(
          classes.InputWrapper,
          {
            [classes.InputWrapperDisabled]: disabled,
            [classes.InputWrapperOpened]: isOpen
          }
        )}
      >
        <input
          ref={this.setDayInputRef}
          className={cx(
            classes.Input,
            classes.InputDay,
            className
          )}
          maxLength={2}
          disabled={disabled}
          value={splitValue[0] || ''}
          onKeyDown={e => this.onKeyDown('day', e, splitValue)}
          onFocus={this.onFocus}
          onChange={e => this.onChange('day', e, splitValue)}
          {...otherProps}
        />
        <input
          ref={this.setMonthInputRef}
          className={cx(
            classes.Input,
            classes.InputMonth,
            className
          )}
          maxLength={2}
          disabled={disabled}
          value={splitValue[1] || ''}
          onKeyDown={e => this.onKeyDown('month', e, splitValue)}
          onFocus={this.onFocus}
          onChange={e => this.onChange('month', e, splitValue)}
          {...otherProps}
        />
        <input
          ref={this.setYearInputRef}
          className={cx(
            classes.Input,
            classes.InputYear,
            className
          )}
          maxLength={4}
          disabled={disabled}
          value={splitValue[2] || ''}
          onKeyDown={e => this.onKeyDown('year', e, splitValue)}
          onFocus={this.onFocus}
          onChange={e => this.onChange('year', e, splitValue)}
          {...otherProps}
        />
      </div>
    );
  }
}

export default DatePickerInput;
