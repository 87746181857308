import {fetchResponse} from './helpers/fakeFetch';

const hints = [
  'Test 1',
  'Test 2',
  'Test 3',
  'Test 4',
  'Test 5',
  'Test 6',
  'Test 7',
  'Test 8',
  'Test 9',
  'Test 10',
  'Test 11',
  'Test 12',
  'Test 13',
  'Test 14',
  'Test 15',
  'Test 16',
  'Test 17',
  'Test 18',
  'Test 19',
  'Test 20',
  'Test 21',
  'Test 22',
  'Test 23',
  'Test 24',
  'Test 25',
  'Test 26',
  'Test 27',
  'Test 28',
  'Test 29',
  'Test 30',
  'Test 31',
  'Test 32',
  'Test 33',
  'Test 34',
  'Test 35',
  'Test 36',
  'Test 37',
  'Test 38',
  'Test 39',
  'Test 40',
  'Test 41',
  'Test 42',
  'Test 43',
  'Test 44',
  'Test 45',
  'Test 46',
  'Test 47',
  'Test 48',
  'Test 49',
  'Test 50',
  'Test 51'
];

const mIndexAPI = {
  insert (name) {
    console.log('api.mIndex.insert(', name, ')');

    return fetchResponse(1000, 'ok');
  },

  getHints (pattern = '', offset, limit) {
    console.log('api.mIndex.getHints(', pattern, offset, limit, ')');

    return fetchResponse(1000, {
      hints: hints.slice(offset, offset + limit).filter(hint => hint.toLowerCase().includes(pattern.toLowerCase())),
      length: hints.length
    });
  }
};

export default mIndexAPI;
