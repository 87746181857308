import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {confirmable} from 'react-confirm';
import Button from '../Button';
import Loader from '../Loader';
import classes from './Prompt.module.scss';

export class Prompt extends Component {
  static propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    dismiss: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      required: PropTypes.bool,
      minLength: PropTypes.number,
      defaultValue: PropTypes.string,
      placeholder: PropTypes.string,
      acceptBtnText: PropTypes.string.isRequired,
      cancelBtnText: PropTypes.string.isRequired,
      cancelCallback: PropTypes.func,
      checkFn: PropTypes.func
    }).isRequired
  };

  static defaultProps = {
    show: true,
    proceed: null,
    cancel: null,
    confirmation: null
  };

  state = {
    hasError: false,
    checking: false
  };

  _field = null;

  componentDidMount () {
    if (this._field) {
      this._field.focus();
    }
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  check = async (value) => {
    const {options} = this.props;
    const {checkFn} = options;

    if (typeof checkFn !== 'function') {
      return true;
    }

    const cbFn = checkFn(value);

    if (typeof cbFn.then !== 'function') {
      return cbFn;
    }

    this.setState({
      checking: true
    });

    try {
      const result = await cbFn;

      this.setState({
        checking: false
      });

      return result;
    } catch (error) {
      console.log(error);

      this.setState({
        checking: false
      });

      throw error;
    }
  }

  accept = async e => {
    e.preventDefault();

    const {proceed, options} = this.props;
    const {required = true, minLength = 1} = options;

    const value = this._field.value;

    if (
      (required && value.length < minLength) ||
      !await this.check(value)
    ) {
      this.setState({
        hasError: true
      });

      return;
    }

    proceed(value);
  };

  cancel = () => {
    const {dismiss, options} = this.props;

    dismiss();

    if (typeof options.cancelCallback === 'function') {
      options.cancelCallback();
    }
  };

  onFieldChange = () => {
    this.setState({
      hasError: false
    });
  };

  render () {
    const {show, confirmation, options} = this.props;
    const {hasError, checking} = this.state;

    const {defaultValue = '', placeholder = null, acceptBtnText, cancelBtnText} = options;

    if (!show) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <form
          className={classes.Content}
          onSubmit={this.accept}
        >
          {
            confirmation ?
              <p className={classes.Question}>
                {confirmation}
              </p>
              : null
          }
          <div className={classes.FieldContainer}>
            <input
              ref={this.setFieldRef}
              type='text'
              disabled={checking}
              defaultValue={defaultValue}
              placeholder={placeholder}
              className={cx(
                classes.Field,
                {
                  [classes.FieldError]: hasError
                }
              )}
              onChange={this.onFieldChange}
            />
            <Loader
              active={checking}
              isSmall
              className={classes.Loader}
            />
          </div>
          <div className={classes.Buttons}>
            <Button
              type='submit'
              disabled={checking}
              onClick={this.accept}
            >
              {acceptBtnText}
            </Button>
            <Button
              theme='transparent'
              disabled={checking}
              onClick={this.cancel}
            >
              {cancelBtnText}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default confirmable(Prompt);
