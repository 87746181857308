import {fetchResponse} from './helpers/fakeFetch';

const fakeAdminAccessToken = 'MTQ0NjJkZmQ5OTM2NDE1ZTZjNGZmZjI3';
const fakeAdminRefreshToken = 'IwOGYzYTlmM2YxOTQ5MGE3YmNmMDFkNTVk';
const fakeUserAccessToken = '0BMmGiJKG059sZlaY9MJLPTV2ZxwcEYW';
const fakeUserRefreshToken = 'deYa7S1cmFvrSU4gIkRSayagNOQzlttE';

const AuthAPI = {
  logIn (username, password) {
    console.log('api.auth.login(', username, password, ')');

    if (username === 'admin' && password === 'admin') {
      return fetchResponse(1000, {
        id: 5,
        access_token: fakeAdminAccessToken,
        refresh_token: fakeAdminRefreshToken,
        token_type: 'bearer',
        email: 'admin@test.com',
        expires_in: 29472,
        first_name: 'Admin',
        last_name: 'Admin',
        roles: ['ROLE_ADMIN']
      });
    } else if (username === 'user' && password === 'user') {
      return fetchResponse(1000, {
        id: 6,
        access_token: fakeUserAccessToken,
        refresh_token: fakeUserRefreshToken,
        token_type: 'bearer',
        email: 'danil@valov.me',
        expires_in: 29472,
        first_name: 'Danil',
        last_name: 'Valov',
        roles: ['ROLE_USER']
      });
    } else {
      const error = new Error('Request error');
      error.jsonResponse = {
        error: 'unauthorized',
        error_description: 'Invalid username or password'
      };

      return fetchResponse(1000, error);
    }
  },

  reLogIn () {
    console.log('api.auth.reLogIn()');

    if (localStorage['refresh_token'] === fakeAdminRefreshToken) {
      return fetchResponse(1000, {
        access_token: fakeAdminAccessToken,
        token_type: 'bearer',
        expires_in: 3600
      });
    } else if (localStorage['refresh_token'] === fakeUserRefreshToken) {
      return fetchResponse(1000, {
        access_token: fakeUserAccessToken,
        token_type: 'bearer',
        expires_in: 3600
      });
    } else {
      const error = new Error('Request error');
      error.jsonResponse = {
        error: 'invalid_request',
        error_description: 'Incorrect refresh token'
      };

      return fetchResponse(1000, error);
    }
  },

  validateToken () {
    console.log('api.auth.validateToken()');

    if (localStorage['access_token'] === fakeAdminAccessToken) {
      return fetchResponse(1000, {
        id: 5,
        email: 'admin@test.com',
        exp: 1527290321,
        first_name: 'Admin',
        last_name: 'Admin',
        full_name: 'Admin Admin',
        user_name: 'Admin',
        roles: ['ROLE_ADMIN']
      });
    } else if (localStorage['access_token'] === fakeUserAccessToken) {
      return fetchResponse(1000, {
        id: 6,
        email: 'danil@valov.me',
        exp: 1527290321,
        first_name: 'Danil',
        last_name: 'Valov',
        full_name: 'Danil Valov',
        roles: ['ROLE_USER']
      });
    } else {
      const error = new Error('Request error');
      error.jsonResponse = {
        error: 'invalid_token',
        error_description: 'Token was not recognised'
      };

      return fetchResponse(1000, error);
    }
  },

  logOut () {
    console.log('api.auth.logOut()');

    return fetchResponse(1000, {
      status: true
    });
  }
};

export default AuthAPI;
