import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import Tooltip from '../../../../../components/Tooltip';
import {generateFormulaString} from '../../../helpers/formula';
import classes from '../Table.module.scss';

class Filter extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    minWidth: PropTypes.number,
    useStaticTable: PropTypes.bool,
    globalFilter: PropTypes.shape({
      ta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      event: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-tv': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-web': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      })
    }).isRequired,
    catalog: PropTypes.object.isRequired,
    getContainer: PropTypes.func,
    openFilter: PropTypes.func
  };

  static defaultProps = {
    isActive: false,
    minWidth: 0,
    useStaticTable: false
  };

  getContent = (text) => {
    return (
      <div
        className={cx(
          classes.Filter,
          'icon-filter'
        )}
      >
        {text}
      </div>
    );
  };

  generateFilterArray = () => {
    const {catalog, globalFilter} = this.props;

    const showFilterText =
      globalFilter['ta'].children.length || (
        (!catalog.splitEvents && globalFilter['event'].children.length) ||
        (
          catalog.splitEvents &&
          (
            globalFilter['event-tv'].children.length ||
            globalFilter['event-web'].children.length
          )
        )
      );

    if (!showFilterText) {
      return [];
    }

    const resultArray = [];

    if (!catalog.splitEvents) {
      resultArray.push(
        globalFilter['event'].children.length
          ? (
            <LocalizedMessage
              id='persona.table.filter.formulaEditor.types.event'
            />
          )
          : globalFilter['event'].title || '-'
      );
    } else {
      resultArray.push(
        globalFilter['event-tv'].children.length
          ? (
            <LocalizedMessage
              id='persona.table.filter.formulaEditor.types.event.tv'
            />
          )
          : globalFilter['event-tv'].title || '-'
      );
      resultArray.push(
        globalFilter['event-web'].children.length
          ? (
            <LocalizedMessage
              id='persona.table.filter.formulaEditor.types.event.web'
            />
          )
          : globalFilter['event-web'].title || '-'
      );
    }

    resultArray.push(
      globalFilter['ta'].children.length
        ? (
          <LocalizedMessage
            id='persona.table.filter.formulaEditor.types.ta'
          />
        )
        : globalFilter['ta'].title || '-'
    );

    return resultArray;
  };

  renderContent = () => {
    const {locale, useStaticTable, catalog, globalFilter} = this.props;

    const globalFilterArray = this.generateFilterArray(catalog, globalFilter);

    if (!globalFilterArray.length) {
      return {
        content: null,
        overlay: null
      };
    }

    const labels = [];
    const formulas = [];

    if (!catalog.splitEvents) {
      if (globalFilter['event'].children.length) {
        labels.push('persona.table.filter.formulaEditor.types.event');
        formulas.push(generateFormulaString(locale, globalFilter['event']));
      }
    } else {
      if (globalFilter['event-tv'].children.length) {
        labels.push('persona.table.filter.formulaEditor.types.event.tv');
        formulas.push(generateFormulaString(locale, globalFilter['event-tv']));
      }
      if (globalFilter['event-web'].children.length) {
        labels.push('persona.table.filter.formulaEditor.types.event.web');
        formulas.push(generateFormulaString(locale, globalFilter['event-web']));
      }
    }

    if (globalFilter['ta'].children.length) {
      labels.push('persona.table.filter.formulaEditor.types.ta');
      formulas.push(generateFormulaString(locale, globalFilter['ta']));
    }

    const overlay = formulas.map((formula, index) => (
      <div
        key={labels[index]}
        className={classes.TooltipOverlayRow}
      >
        <p className={classes.TooltipOverlayLabel}>
          <LocalizedMessage
            id={labels[index]}
          />:
        </p>
        <p className={classes.TooltipOverlayText}>
          {formula && formula.length ? formula : '-'}
        </p>
      </div>
    ));

    if (useStaticTable) {
      return {
        context: globalFilterArray.map((filter, index) => (
          <span key={index}>
            {index ? ' / ' : ''}
            {filter}
          </span>
        )),
        overlay
      };
    }

    return {
      context: globalFilterArray.map((line, index) => (
        <span
          key={index}
          className={classes.FilterLine}
        >
          {line}
        </span>
      )),
      overlay
    };
  };

  render () {
    const {isActive, minWidth, useStaticTable, getContainer, openFilter} = this.props;

    const {context, overlay} = this.renderContent();
    const content = this.getContent(
      <LocalizedMessage
        id='persona.table.cells.globalFilter'
      >
        {localizedMessage => (
          context ||
          localizedMessage
        )}
      </LocalizedMessage>
    );

    if (useStaticTable) {
      return (
        <Tooltip
          overlay={overlay}
          container={getContainer}
          position='bottom'
        >
          {content}
        </Tooltip>
      );
    }

    return (
      <Tooltip
        overlay={overlay}
        container={getContainer}
        position='bottom'
      >
        <div
          className={cx(
            classes.Cell,
            classes.CellFilter,
            {
              [classes.CellPointer]: isActive
            }
          )}
          style={{
            minWidth: `${minWidth}px`
          }}
          onClick={isActive ? () => openFilter('base') : null}
        >
          {content}
        </div>
      </Tooltip>
    );
  }
}

export default Filter;
