export const getPageWidth = () => (
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )
);

export const addUserSelectStylesToBody = () => {
  if (document.body) {
    document.body.style.userSelect = 'none';
    document.body.style.webkitUserSelect = 'none';
  }
};

export const removeUserSelectStylesFromBody = () => {
  if (document.body) {
    document.body.style.userSelect = null;
    document.body.style.webkitUserSelect = null;
  }
};

const isMacintosh = () => navigator.platform.indexOf('Mac') > -1;

export const isMac = isMacintosh();
