import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FileSaver from 'file-saver';
import {Helmet} from 'react-helmet';
import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';
import Loader from '../../../components/Loader';
import BeforeUnload from '../../../components/BeforeUnload';
import Content from './Content';
import Filters from './Filters';
import AccessRights from './AccessRights';
import {cloneObject, getArrayItemByParam, wait} from '../../../helpers/utils';
import {
  clearCubeChildren,
  getFormulaHashWithoutTitles,
  prepareFormula,
  restructureCubeChildren
} from '../helpers/formula';
import {findStatisticItemIndex, parseCategories, parseSavedFormulas} from '../helpers/filter';
import {generateReverseDateString} from '../../../helpers/date';
import {convertStatistic} from '../logic/parser';
import {findAllRespondentsGroup, hasSpecificAllRespondents} from '../helpers/table';
import confirm from '../../../components/Confirm';
import prompt from '../../../components/Prompt';
import alert from '../../../helpers/alert';
import Config from '../../../config';
import API from '../../../api';
import classes from './Edit.module.scss';

const CUBE_QUEUE_REQUEST_DELAY = 5000; // ms
const {CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_INDEX_ID, BEELINE_CATALOG_ID} = Config.appOptions;

class Edit extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        catalog: PropTypes.string.isRequired
      })
    }),
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    }).isRequired,
    catalogs: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    isExternalUser: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    loadCatalogs: PropTypes.func.isRequired
  };

  state = {
    filterData: null,
    title: null,
    catalog: null,
    isCatalogDataLoading: true,
    verticalDataTree: [],
    horizontalDataTree: [],
    statisticAxis: 'rows',
    statisticItems: [],
    statisticRounding: 2,
    globalFilter: {
      ta: {
        title: '',
        children: []
      },
      event: {
        title: '',
        children: []
      },
      'event-tv': {
        title: '',
        children: []
      },
      'event-web': {
        title: '',
        children: []
      },
      connector: 'or'
    },
    filterSelectedItems: {
      category: null,
      group: null,
      dimensions: [],
      targetAudiences: []
    },
    eventBetweenStart: null,
    eventBetweenEnd: null,
    eventAverageDay: null,
    eventAverageDayWithAutoCalculation: true,
    mode: null,
    cube: {
      id: null,
      uuid: null,
      isAuthor: true,
      loading: false,
      loaded: false,
      saving: false,
      saved: false,
      renaming: false,
      exporting: false,
      new: false,
      data: null
    },
    accessRights: {
      accessType: 'user',
      advertisers: [],
      users: [],
      group: null
    },
    tempAccessRights: null
  };

  mounted = false;
  _content = null;
  filterContainerPercentHeight = null;

  componentDidMount () {
    this.mounted = true;

    this.loadCube(this.props);
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.match.params.id !== this.props.match.params.id) {
      this.loadCube(newProps);
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  setAsyncState = (newState) => {
    return new Promise(resolve => {
      this.setState(newState, () => {
        resolve();
      });
    });
  };

  setContentRef = ref => {
    this._content = ref;
  };

  calculateEventBetweenDatesByCatalog = (catalog) => {
    const eventBetweenEnd =
      catalog
        ? moment(catalog.endDate, 'YYYY-MM-DD')
        : moment();
    const eventBetweenStart =
      catalog
        ? moment(Math.max(moment(eventBetweenEnd).add(-1, 'M'), moment(catalog.startDate, 'YYYY-MM-DD')))
        : eventBetweenEnd.add(-1, 'M');

    return {
      eventBetweenEnd,
      eventBetweenStart
    };
  };

  addCustomMeasures = (measures) => {
    measures.push({
      affinity: false,
      digits: 2,
      id: CUSTOM_MEASURE_VERT_ID,
      key: 'VERT',
      measureType: 'VERT',
      nplus: false,
      status: 'ENABLED',
      title: 'Vert %',
      weight: 100
    });

    measures.push({
      affinity: false,
      digits: 2,
      id: CUSTOM_MEASURE_HORZ_ID,
      key: 'HORZ',
      measureType: 'HORZ',
      nplus: false,
      status: 'ENABLED',
      title: 'Horz %',
      weight: 100
    });

    measures.push({
      affinity: false,
      digits: 2,
      id: CUSTOM_MEASURE_INDEX_ID,
      key: 'INDEX',
      measureType: 'INDEX',
      nplus: false,
      status: 'ENABLED',
      title: 'Index',
      weight: 100
    });
  };

  loadCatalogsData = async (props, catalogKey) => {
    const {loadCatalogs, history} = this.props;
    const {catalogs} = props;

    const catalogList = !catalogs.loaded ? await loadCatalogs() : catalogs.items;

    const currentCatalog = catalogList[catalogKey];

    if (!currentCatalog) {
      history.replace('/');

      return false;
    }

    try {
      this.setState({isCatalogDataLoading: true});
      const [filters, measures, globalFilter] = await Promise.all([
        this.loadFilters(currentCatalog),
        API.catalogs.getMeasures(catalogKey),
        API.catalogs.getGlobalFilter(catalogKey)
      ]);

      this.setState({isCatalogDataLoading: false});
      this.addCustomMeasures(measures);

      return {
        catalog: {
          ...currentCatalog,
          filters: {
            ...parseCategories(filters.categories),
            savedFormulas: parseSavedFormulas(filters.expression),
            hints: {}
          },
          measures
        },
        ...this.calculateEventBetweenDatesByCatalog(currentCatalog),
        globalFilter: {
          connector: globalFilter.connector ? globalFilter.connector.toLowerCase() : 'or',
          'event-tv': globalFilter.eventsTvFusion
            ? {
              title: globalFilter.eventsTvFusion.title,
              children: restructureCubeChildren(globalFilter.eventsTvFusion.children, true)
            }
            : {
              title: '',
              children: []
            },
          'event-web': globalFilter.eventsWebFusion
            ? {
              title: globalFilter.eventsWebFusion.title,
              children: restructureCubeChildren(globalFilter.eventsWebFusion.children, true)
            }
            : {
              title: '',
              children: []
            },
          event: globalFilter.events
            ? {
              title: globalFilter.events.title,
              children: restructureCubeChildren(globalFilter.events.children, true)
            }
            : {
              title: '',
              children: []
            },
          ta: globalFilter.ta
            ? {
              title: globalFilter.ta.title,
              children: restructureCubeChildren(globalFilter.ta.children, true)
            }
            : {
              title: '',
              children: []
            }
        }
      };
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  // TODO: change to dynamic update without API
  reloadFiltersData = async () => {
    const {catalog} = this.state;

    const filters = await this.loadFilters(catalog);

    await this.setAsyncState({
      catalog: {
        ...catalog,
        filters: {
          ...catalog.filters,
          ...parseCategories(filters.categories),
          savedFormulas: parseSavedFormulas(filters.expression)
        }
      }
    });
  };

  loadFilters = async (catalog) => {
    const filters = await API.catalogs.getFilters(catalog.url);

    return {
      ...filters,
      categories: filters.categories?.filter(
        (category) => catalog.id === BEELINE_CATALOG_ID || category.titleEnglish !== 'Beeline Characteristics'
      ),
    };
  };

  parseCubeStructure = (structure, axis) => {
    const parsedStatistics = convertStatistic(structure.measures);

    const resultData = {
      title: structure.title || null,
      horizontalDataTree: restructureCubeChildren(structure.rowDimensionTree.children),
      verticalDataTree: restructureCubeChildren(structure.columnDimensionTree.children),
      statisticAxis: axis || structure.measures.axis || 'rows',
      statisticItems: parsedStatistics.items,
      statisticRounding: structure.measures.rounding || 2,
      globalFilter: {
        ta: {
          title: structure.globalFilter.ta.title,
          children: restructureCubeChildren(structure.globalFilter.ta.children, true)
        },
        event: structure.globalFilter.events
          ? {
            title: structure.globalFilter.events.title,
            children: restructureCubeChildren(structure.globalFilter.events.children, true)
          }
          : {
            title: '',
            children: []
          },
        'event-tv': structure.globalFilter.eventsTvFusion
          ? {
            title: structure.globalFilter.eventsTvFusion.title,
            children: restructureCubeChildren(structure.globalFilter.eventsTvFusion.children, true)
          }
          : {
            title: '',
            children: []
          },
        'event-web': structure.globalFilter.eventsWebFusion
          ? {
            title: structure.globalFilter.eventsWebFusion.title,
            children: restructureCubeChildren(structure.globalFilter.eventsWebFusion.children, true)
          }
          : {
            title: '',
            children: []
          },
        connector: structure.globalFilter.connector ? structure.globalFilter.connector.toLowerCase() : 'or'
      },
      eventBetweenStart: moment(structure.eventBetweenStart, 'YYYY-MM-DD'),
      eventBetweenEnd: moment(structure.eventBetweenEnd, 'YYYY-MM-DD'),
      eventAverageDay:
        structure.eventAverageDay
          ? moment(structure.eventAverageDay, 'YYYY-MM-DD')
          : null,
      eventAverageDayWithAutoCalculation: !!structure.eventAverageDayWithAutoCalculation,
    };

    if (structure.accessRights) {
      resultData.accessRights = {
        accessType: structure.accessRights.accessType.toLowerCase(),
        advertisers: structure.accessRights.advertisers,
        users: structure.accessRights.users,
        group: structure.accessRights.group
      };
    }

    return resultData;
  };

  loadCube = async props => {
    const {history} = this.props;

    this.setState({
      filterData: null,
      title: null,
      verticalDataTree: [],
      horizontalDataTree: [],
      statisticAxis: 'rows',
      statisticItems: [],
      statisticRounding: 2,
      globalFilter: {
        ta: {
          title: '',
          children: []
        },
        event: {
          title: '',
          children: []
        },
        'event-tv': {
          title: '',
          children: []
        },
        'event-web': {
          title: '',
          children: []
        },
        connector: 'or'
      },
      accessRights: {
        accessType: 'user',
        advertisers: [],
        users: [],
        group: null
      },
      mode: 'edit',
    });

    const cubeId = props.match.params.id;
    const catalogKey = props.match.params.catalog;

    if (!catalogKey) {
      history.replace('/');

      return;
    }

    if (!cubeId) {
      try {
        const {
          catalog,
          eventBetweenStart,
          eventBetweenEnd,
          globalFilter
        } = await this.loadCatalogsData(props, catalogKey || null);

        this.setState({
          catalog,
          globalFilter,
          eventBetweenStart,
          eventBetweenEnd,
          eventAverageDay: null,
          eventAverageDayWithAutoCalculation: true
        });
      } catch (error) {
        console.error(error);

        alert.error(
          localizeMessage({
            id: 'persona.table.alerts.errorGettingCatalog'
          })
        );
      }

      return;
    }

    let currentCube;

    try {
      currentCube = await API.cubes.getTemplateById(catalogKey, cubeId);
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.alerts.errorGettingCube'
        })
      );

      history.replace('/');

      return;
    }

    if (!currentCube) {
      history.replace('/');

      return;
    }

    const {catalog} = await this.loadCatalogsData(props, catalogKey);

    const skippedMeasureIds = [];

    currentCube.structure.measures.statistics = currentCube.structure.measures.statistics.filter(statistic => {
      if (!getArrayItemByParam(catalog.measures, 'id', statistic.id)) {
        skippedMeasureIds.push(statistic.id);

        return false;
      }

      return true;
    });

    if (skippedMeasureIds.length) {
      alert.warning(
        localizeMessage({
          id: 'persona.table.alerts.warnMeasureNotExist'
        }, {
          catalogName: catalog.title,
          measureIds: skippedMeasureIds.join(', ')
        })
      );
    }

    this.setState({
      ...this.parseCubeStructure(currentCube.structure),
      catalog: catalog,
      mode: currentCube.matrix ? 'view' : 'edit',
      cube: {
        id: cubeId,
        uuid: currentCube.uuid || null,
        isAuthor: currentCube.structure.isAuthor || false,
        loading: false,
        loaded: true,
        saving: false,
        saved: false,
        renaming: false,
        exporting: false,
        new: false,
        data: currentCube.matrix
          ? {
            ...currentCube,
            matrix: {
              ...currentCube.matrix,
              verticalAxis: restructureCubeChildren(currentCube.matrix.verticalAxis),
              horizontalAxis: restructureCubeChildren(currentCube.matrix.horizontalAxis)
            }
          }
          : null
      }
    });
  };

  loadEventHints = async (id, pattern = '', offset = 0) => {
    const {catalog} = this.state;

    this.setState({
      catalog: {
        ...catalog,
        filters: {
          ...catalog.filters,
          hints: {
            ...catalog.filters.hints,
            [id]: {
              ...catalog.filters.hints[id],
              [pattern]: {
                loading: true,
                finished: false,
                items:
                  catalog.filters.hints[id] &&
                  catalog.filters.hints[id][pattern]
                    ? catalog.filters.hints[id][pattern].items
                    : []
              }
            }
          }
        }
      }
    });

    try {
      const response = await API.eventHints.find(catalog.url, id, pattern, offset);

      this.setState({
        catalog: {
          ...catalog,
          filters: {
            ...catalog.filters,
            hints: {
              ...catalog.filters.hints,
              [id]: {
                ...catalog.filters.hints[id],
                [pattern]: {
                  loading: false,
                  finished: response.finished,
                  items: [
                    ...(
                      catalog.filters.hints[id] &&
                      catalog.filters.hints[id][pattern]
                        ? catalog.filters.hints[id][pattern].items
                        : []
                    ),
                    ...(
                      response.hints &&
                      response.hints.length
                        ? response.hints.map(option => ({
                          label: {
                            ru: option,
                            en: option,
                          },
                          value: option
                        }))
                        : []
                    )
                  ]
                }
              }
            }
          }
        }
      });
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.filter.alerts.errorCantLoadEvents'
        })
      );

      this.setState({
        catalog: {
          ...catalog,
          filters: {
            ...catalog.filters,
            hints: {
              ...catalog.filters.hints,
              [id]: {
                ...catalog.filters.hints[id],
                [pattern]: {
                  loading: false,
                  finished: true,
                  items:
                    catalog.filters.hints[id] &&
                    catalog.filters.hints[id][pattern]
                      ? catalog.filters.hints[id][pattern].items
                      : []
                }
              }
            }
          }
        }
      });
    }
  };

  editTable = () => {
    const {cube} = this.state;

    this.setState({
      mode: 'edit',
      cube: {
        id: cube.id,
        uuid: null,
        isAuthor: cube.isAuthor,
        loading: false,
        loaded: false,
        saving: false,
        saved: false,
        renaming: false,
        exporting: false,
        new: false,
        data: null
      }
    });

    this._content.reset();
  };

  addCell = (data, filterSelectedItems) => {
    const {filterData} = this.state;
    const {direction, path, index} = filterData;

    const axisDataTreeName = direction === 'rows' ? 'horizontalDataTree' : 'verticalDataTree';

    const newDataTree = {
      children: this.state[axisDataTreeName].slice(0)
    };

    let parentLink = newDataTree;

    if (path) {
      for (let i = 0; i < path.length; i++) {
        parentLink = parentLink.children[path[i]];
      }
    }

    if (!parentLink.children) {
      parentLink.children = [];
    }

    (Array.isArray(data) ? data : [data]).forEach(cellData => {
      if (!path || typeof index !== 'number' || index + 1 > parentLink.children.length - 1) {
        parentLink.children.push(cellData);
      } else {
        parentLink.children.splice(index + 1, 0, cellData);
      }
    });

    this.setState({
      [axisDataTreeName]: newDataTree.children
    });

    this.closeFilter(filterSelectedItems);
  };

  updateCell = (data, filterSelectedItems) => {
    const {filterData} = this.state;
    const {direction, path, index} = filterData;

    if (Array.isArray(data)) {
      data = data[0];
    }

    const axisDataTreeName = direction === 'rows' ? 'horizontalDataTree' : 'verticalDataTree';

    const newDataTree = {
      children: this.state[axisDataTreeName].slice(0)
    };

    let parent = newDataTree;

    for (let i = 0; i < path.length; i++) {
      parent = parent.children[path[i]];
    }

    parent.children.splice(index, 1, {
      ...data,
      children: parent.children[index].children || []
    });

    this.setState({
      [axisDataTreeName]: newDataTree.children
    });

    this.closeFilter(filterSelectedItems);
  };

  deleteCell = (direction, path) => {
    const axisDataTreeName = direction === 'rows' ? 'horizontalDataTree' : 'verticalDataTree';

    const newDataTree = {
      children: this.state[axisDataTreeName].slice(0)
    };

    const cellIndex = path.pop();

    let parent = newDataTree;
    for (let i = 0; i < path.length; i++) {
      parent = parent.children[path[i]];
    }

    parent.children.splice(cellIndex, 1, ...(parent.children[cellIndex].children || []));

    this.setState({
      [axisDataTreeName]: newDataTree.children
    });
  };

  updateStatisticAxis = async axis => {
    const {statisticAxis, cube} = this.state;

    if (statisticAxis === axis) {
      return;
    }

    this.setState({
      statisticAxis: axis
    });

    if (!cube.uuid) {
      return;
    }

    this._content.resetAfterAxisChanging();

    await this.loadCubeByUuid(cube.uuid, axis);
  };

  updateStatisticData = (updates) => {
    const {statisticItems} = this.state;

    const newStatisticData = statisticItems.slice(0);

    updates.forEach(({id, title, status, affinity, nplusValues}) => {
      const statisticItemIndex = findStatisticItemIndex(newStatisticData, {
        id,
        affinity,
        nplus: !!nplusValues
      });

      if (status) {
        const measureData = {
          id,
          title,
          affinity,
          nplus: !!nplusValues
        };

        if (nplusValues) {
          measureData.values = nplusValues;
        }

        if (statisticItemIndex === -1) {
          newStatisticData.push(measureData);
        } else {
          newStatisticData.splice(statisticItemIndex, 1, measureData);
        }
      } else if (!status && statisticItemIndex > -1) {
        newStatisticData.splice(statisticItemIndex, 1);
      }
    });

    this.setState({
      statisticItems: newStatisticData
    });
  };

  updateStatisticRounding = statisticRounding => {
    this.setState({
      statisticRounding
    });
  };

  changeGlobalFilterConnector = connector => {
    this.setState({
      globalFilter: {
        ...this.state.globalFilter,
        connector
      }
    });
  };

  openFilter = (direction, path, index = null, like = null, formula = null) => {
    const {mode} = this.state;

    if (mode === 'view') {
      return;
    }

    this.setState({
      filterData: {
        direction,
        path,
        index,
        like,
        formula
      }
    });
  };

  closeFilter = filterSelectedItems => {
    const {filterData} = this.state;

    const isCellEditMode = filterData.formula || filterData.like === 'cell';

    const newState = {
      filterData: null
    };

    if (
      filterSelectedItems &&
      !isCellEditMode
    ) {
      newState.filterSelectedItems = filterSelectedItems;
    }

    this.setState(newState);
  };

  applyGlobalFilter = (updatedGlobalFilter, filterSelectedItems) => {
    const {globalFilter} = this.state;

    this.setState({
      globalFilter: {
        ...globalFilter,
        ...updatedGlobalFilter
      }
    });

    this.closeFilter(filterSelectedItems);
  };

  loadCubeDataInQueue = async (catalogKey, id, axis) => {
    if (!this.mounted) {
      throw new Error('Component did unmount');
    }

    try {
      let cubeData = await API.cubes.getMatricesByQueueId(catalogKey, id, axis);

      if (cubeData.howRenderOlap && cubeData.rows && !cubeData.rows.length) {
        throw new Error('empty_cube');
      }

      if (!cubeData || !cubeData.matrix || !cubeData.structure) {
        await wait(CUBE_QUEUE_REQUEST_DELAY);

        cubeData = await this.loadCubeDataInQueue(catalogKey, id, axis);
      }

      return cubeData;
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  clearData = () => {
    const {catalog} = this.state;

    this.setState({
      filterData: null,
      verticalDataTree: [],
      horizontalDataTree: [],
      statisticAxis: 'rows',
      statisticItems: [],
      statisticRounding: 2,
      globalFilter: {
        ta: {
          title: '',
          children: []
        },
        event: {
          title: '',
          children: []
        },
        'event-tv': {
          title: '',
          children: []
        },
        'event-web': {
          title: '',
          children: []
        },
        connector: 'or'
      },
      accessRights: {
        accessType: 'user',
        advertisers: [],
        users: [],
        group: null
      },
      ...this.calculateEventBetweenDatesByCatalog(catalog),
      eventAverageDay: null,
      eventAverageDayWithAutoCalculation: true,
      mode: 'edit'
    });
  };

  generateSendingStatisticData = (statisticItems, filterCustomMeasures) => {
    const result = [];

    statisticItems
      .filter(stat => (
        !filterCustomMeasures ||
        ![
          CUSTOM_MEASURE_HORZ_ID,
          CUSTOM_MEASURE_VERT_ID,
          CUSTOM_MEASURE_INDEX_ID
        ].includes(stat.id)
      ))
      .forEach(stat => {
        if (stat.nplus && stat.values && stat.values.length) {
          stat.values.forEach(value => {
            const resultStat = {
              id: stat.id,
              affinity: stat.affinity,
              n: null,
              m: null
            };

            if (
              value.match(/[1-9]([0-9]{1,3})?/) &&
              value.match(/[1-9]([0-9]{1,3})?/)[0] === value
            ) {
              const valuesMatch = value.match(/([1-9]([0-9]{1,3})?)/);

              resultStat.n = valuesMatch[0];
              resultStat.m = valuesMatch[0];
            } else if (
              value.match(/[1-9]([0-9]{1,3})?\+/) &&
              value.match(/[1-9]([0-9]{1,3})?\+/)[0] === value
            ) {
              const valuesMatch = value.match(/([1-9]([0-9]{1,3})?)\+/);

              resultStat.n = valuesMatch[1];
            } else if (
              value.match(/[1-9]([0-9]{1,3})?-[1-9]([0-9]{1,3})?/) &&
              value.match(/[1-9]([0-9]{1,3})?-[1-9]([0-9]{1,3})?/)[0] === value
            ) {
              const valuesMatch = value.match(/([1-9]([0-9]{1,3})?)-([1-9]([0-9]{1,3})?)/);

              resultStat.n = valuesMatch[1];
              resultStat.m = valuesMatch[3];
            }

            result.push(resultStat);
          });
        } else {
          result.push({
            id: stat.id,
            affinity: stat.affinity
          });
        }
      });

    return result;
  };

  injectCustomMeasures = (statistics) => {
    const {statisticItems} = this.state;

    statisticItems.forEach((statistic, index) => {
      if ([CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_INDEX_ID].includes(statistic.id)) {
        statistics.splice(index, 0, {
          id: statistic.id,
          title: statistic.title,
          key: statistic.key,
          affinity: false,
          m: null,
          n: null,
          params: null,
        });
      }
    });
  };

  loadCubeByUuid = async (uuid, axis) => {
    const {cube, catalog} = this.state;

    this.setState({
      cube: {
        ...cube,
        loading: true
      }
    });

    try {
      const loadedCube = await this.loadCubeDataInQueue(catalog.url, uuid, axis);

      if (!this.mounted) {
        return;
      }

      this.injectCustomMeasures(loadedCube.structure.measures.statistics);

      await this.setAsyncState({
        ...this.parseCubeStructure(loadedCube.structure, axis),
        filterSelectedItems: {
          category: null,
          group: null,
          dimensions: [],
          targetAudiences: []
        },
        mode: 'view',
        cube: {
          ...cube,
          uuid: uuid,
          isAuthor: loadedCube.isAuthor || false,
          loading: false,
          loaded: true,
          new: true,
          data: loadedCube.matrix
            ? {
              ...loadedCube,
              matrix: {
                ...loadedCube.matrix,
                verticalAxis: restructureCubeChildren(loadedCube.matrix.verticalAxis),
                horizontalAxis: restructureCubeChildren(loadedCube.matrix.horizontalAxis)
              }
            }
            : null
        }
      });
    } catch (error) {
      console.error(error);

      if (typeof error.message === 'string' && error.message === 'empty_cube') {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.emptyGettingCube'
          })
        );
      } else if (error.jsonResponse && typeof error.jsonResponse.details === 'string') {
        alert.error(error.jsonResponse.details);
      } else {
        alert.error(
          localizeMessage({
            id: 'persona.table.alerts.errorGettingCube'
          })
        );
      }

      this.setState({
        cube: {
          ...cube,
          uuid: null,
          loading: false,
          loaded: false,
          new: false,
          data: null
        }
      });
    }
  };

  addAllRespondentsIfNotExists = (children, allRespondentsGroup) => {
    const {locale} = this.props;

    if (!children.some(item => hasSpecificAllRespondents(item, true))) {
      children.push({
        like: 'cell',
        title: allRespondentsGroup.title[locale],
        type: allRespondentsGroup.type,
        formula: allRespondentsGroup.condition,
        children: []
      });
    }
  }

  removeAllRespondents = (children, startIndex = 1) => {
    let num = 0;
    const allRespondentsIndexToRemove = [];

    children.forEach((item, index) => {
      if (hasSpecificAllRespondents(item, true)) {
        if (num >= startIndex) {
          allRespondentsIndexToRemove.push(index);
        }

        num += 1;
      }
    });

    if (allRespondentsIndexToRemove.length) {
      allRespondentsIndexToRemove.reverse();

      allRespondentsIndexToRemove.forEach(indexToRemove => {
        children.splice(indexToRemove, 1);
      });
    }
  };

  prepareDataToSend = (filterCustomMeasures) => {
    const {locale} = this.props;
    const {
      catalog,
      eventBetweenStart,
      eventBetweenEnd,
      eventAverageDayWithAutoCalculation,
      verticalDataTree,
      horizontalDataTree,
      statisticAxis,
      statisticItems,
      statisticRounding,
      globalFilter
    } = this.state;

    const hasCustomMeasures = statisticItems.some(stat => (
      [
        CUSTOM_MEASURE_HORZ_ID,
        CUSTOM_MEASURE_VERT_ID,
        CUSTOM_MEASURE_INDEX_ID
      ].includes(stat.id)
    ));

    const rowDimensionTreeChildren = clearCubeChildren(
      locale,
      catalog.filters.expressionHashMap,
      cloneObject(horizontalDataTree)
    );
    const columnDimensionTreeChildren = clearCubeChildren(
      locale,
      catalog.filters.expressionHashMap,
      cloneObject(verticalDataTree)
    );

    const allRespondentsGroup = findAllRespondentsGroup(catalog.filters.categories);

    this.removeAllRespondents(rowDimensionTreeChildren, allRespondentsGroup && hasCustomMeasures ? 0 : 1);
    this.removeAllRespondents(columnDimensionTreeChildren, allRespondentsGroup && hasCustomMeasures ? 0 : 1);

    if (allRespondentsGroup && hasCustomMeasures) {
      this.addAllRespondentsIfNotExists(rowDimensionTreeChildren, allRespondentsGroup);
      this.addAllRespondentsIfNotExists(columnDimensionTreeChildren, allRespondentsGroup);
    }

    const data = {
      rowDimensionTree: {
        title: 'root',
        children: rowDimensionTreeChildren
      },
      columnDimensionTree: {
        title: 'root',
        children: columnDimensionTreeChildren
      },
      measures: {
        statistics: this.generateSendingStatisticData(statisticItems, filterCustomMeasures),
        axis: statisticAxis,
        rounding: statisticRounding
      },
      globalFilter: {
        ta: {
          ...globalFilter['ta'],
          children: prepareFormula(locale, cloneObject(globalFilter['ta'])).children
        },
        connector: globalFilter.connector.toUpperCase()
      },
      eventBetweenStart: eventBetweenStart.format('YYYY-MM-DD'),
      eventBetweenEnd: eventBetweenEnd.format('YYYY-MM-DD'),
      eventAverageDay: this.getEventAverageDay().format('YYYY-MM-DD'),
      eventAverageDayWithAutoCalculation,
      eventCalculationType: 'default',
    };

    const globalTaExpressionId =
      catalog.filters.expressionHashMap[getFormulaHashWithoutTitles(globalFilter['ta'].children)];
    if (globalTaExpressionId) {
      data.globalFilter.ta.expressionId = globalTaExpressionId;
    }

    if (!catalog.splitEvents) {
      data.globalFilter['events'] = {
        ...globalFilter['event'],
        children: prepareFormula(locale, cloneObject(globalFilter['event'])).children
      };
    } else {
      data.globalFilter['eventsTvFusion'] = {
        ...globalFilter['event-tv'],
        children: prepareFormula(locale, cloneObject(globalFilter['event-tv'])).children
      };
      data.globalFilter['eventsWebFusion'] = {
        ...globalFilter['event-web'],
        children: prepareFormula(locale, cloneObject(globalFilter['event-web'])).children
      };
    }

    return data;
  };

  calculateCube = async () => {
    const {locale} = this.props;
    const {title, catalog, cube, statisticAxis} = this.state;

    const data = this.prepareDataToSend(true);

    if (process.env.NODE_ENV === 'development') {
      console.warn('CUBE STRUCTURE:');
      console.warn(JSON.stringify({
        title,
        olapKey: catalog.url,
        ...data
      }));
    }

    this.setState({
      cube: {
        ...cube,
        uuid: null,
        loading: true,
        loaded: false,
        new: false,
        data: null
      }
    });

    try {
      const loadingCube = await API.cubes.send(catalog.url, {
        locale: locale.toUpperCase(),
        title,
        ...data
      });

      await this.loadCubeByUuid(loadingCube.id, statisticAxis);
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.alerts.errorSendingCube'
        })
      );

      if (!this.mounted) {
        return;
      }

      this.setState({
        cube: {
          ...cube,
          uuid: null,
          loading: false,
          loaded: false,
          new: false,
          data: null
        }
      });
    }
  };

  checkCubeTitle = accessRights => {
    return async (newTitle) => {
      const {catalog, title} = this.state;

      if (title === newTitle.trim()) {
        return true;
      }

      try {
        const result = await API.cubes.checkTitle(catalog.url, newTitle, {
          accessType: accessRights.accessType.toUpperCase(),
          advertisers: accessRights.advertisers,
          group: accessRights.group,
          users: accessRights.users
        });

        if (result) {
          alert.warning(
            localizeMessage({
              id: 'persona.table.alerts.cubeTitleAlreadyExists'
            })
          );
        }

        return !result;
      } catch (error) {
        console.error(error);

        alert.error(
          localizeMessage({
            id: 'persona.table.alerts.errorCheckCubeTitle'
          })
        );

        throw error;
      }
    };
  };

  askCubeTitle = (title = '', accessRights) => {
    return new Promise(resolve => {
      prompt(
        localizeMessage({
          id: 'persona.table.prompt.saveCubeTemplate.title'
        }),
        {
          acceptBtnText: localizeMessage({
            id: 'base.save'
          }),
          cancelBtnText: localizeMessage({
            id: 'base.cancel'
          }),
          placeholder: localizeMessage({
            id: 'persona.table.prompt.saveCubeTemplate.placeholder'
          }),
          cancelCallback: () => {
            resolve(null);
          },
          defaultValue: title,
          checkFn: this.checkCubeTitle(accessRights)
        }
      )
        .then(name => {
          resolve(name.trim());
        });
    });
  };

  getAccessRights = (type, prevAccessRights) => {
    return new Promise(resolve => {
      this.setState({
        tempAccessRights: {
          type,
          data: prevAccessRights
            ? {
              accessType: prevAccessRights.accessType || null,
              advertisers: prevAccessRights.advertisers || null,
              group: prevAccessRights.groupId || null,
              users: prevAccessRights.users || null
            }
            : null,
          save: response => {
            this.setState({
              tempAccessRights: null
            });

            resolve(response);
          },
          close: () => {
            this.setState({
              tempAccessRights: null
            });

            resolve(null);
          }
        }
      });
    });
  };

  saveCube = async () => {
    const {isAdmin, history} = this.props;
    const {catalog, cube, title, accessRights} = this.state;

    let savingAccessRights = {
      accessType: accessRights.accessType.toUpperCase(),
      advertisers: accessRights.advertisers,
      group: accessRights.groupId,
      users: accessRights.users
    };

    if (!cube.id || cube.isAuthor || isAdmin) {
      savingAccessRights = await this.getAccessRights('template', accessRights);

      if (!savingAccessRights) {
        return;
      }
    }

    let savingTitle = title;

    if (!cube.id || !savingTitle || !savingTitle.length) {
      savingTitle = await this.askCubeTitle('', savingAccessRights);

      if (!savingTitle) {
        return;
      }
    }

    const data = this.prepareDataToSend(true);
    data.title = savingTitle;
    data.accessRights = savingAccessRights;

    if (process.env.NODE_ENV === 'development') {
      console.warn('CUBE STRUCTURE:');
      console.warn(JSON.stringify(data));
    }

    this.setState({
      cube: {
        ...cube,
        saving: true,
        saved: false,
        new: false,
        data: null
      }
    });

    try {
      const savingCube = await API.cubes.save(catalog.url, cube.id, data);

      alert.success(localizeMessage({
        id: 'persona.table.alerts.successfulCubeSaving'
      }, {
        cubeName: savingTitle
      }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        title: savingCube.title,
        cube: {
          ...cube,
          id: savingCube.id,
          isAuthor: savingCube.isAuthor || false,
          saving: false,
          saved: true,
          new: false,
          data: null
        },
        accessRights: {
          accessType: savingAccessRights.accessType.toLowerCase(),
          advertisers: savingAccessRights.advertisers || [],
          users: savingAccessRights.users || [],
          group: savingAccessRights.group || null
        }
      });

      if (!cube.id) {
        history.replace(`/cube/${catalog.url}/${savingCube.id}`);
      }
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.alerts.errorSavingCube'
        })
      );

      if (!this.mounted) {
        return;
      }

      this.setState({
        cube: {
          ...cube,
          saving: false,
          saved: false,
          new: false,
          data: null
        }
      });
    }
  };

  renameTemplate = async () => {
    const {catalog, cube, title, accessRights} = this.state;

    const newTitle = await this.askCubeTitle(title, accessRights);

    if (!newTitle || newTitle === this.state.title) {
      return;
    }

    this.setState({
      cube: {
        ...cube,
        renaming: true
      }
    });

    try {
      await API.cubes.rename(catalog.url, cube.id, newTitle);

      if (!this.mounted) {
        return;
      }

      this.setState({
        title: newTitle,
        cube: {
          ...cube,
          renaming: false
        }
      });
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.table.alerts.errorSavingTemplateCubeTitle'
        })
      );

      this.setState({
        cube: {
          ...cube,
          renaming: false
        }
      });
    }
  };

  copyTemplate = () => {
    const {history} = this.props;
    const {catalog, cube, title} = this.state;

    confirm(
      localizeMessage({
        id: 'persona.confirmations.copyCube'
      }, {
        cubeName: title
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(async () => {
        try {
          const copyingCube = await API.cubes.copy(catalog.url, cube.id);

          alert.success(
            localizeMessage({
              id: 'persona.alerts.successfulCubeCopying'
            }, {
              cubeName: title
            })
          );

          history.push(`/cube/${catalog.url}/${copyingCube.id}`);
        } catch (error) {
          console.error(error);

          alert.error(
            localizeMessage({
              id: 'persona.alerts.errorCubeCopying'
            }, {
              cubeName: title
            })
          );
        }
      });
  };

  deleteTemplate = () => {
    const {history} = this.props;
    const {catalog, cube, title} = this.state;

    confirm(
      localizeMessage({
        id: 'persona.confirmations.deleteCube'
      }, {
        cubeName: title
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(async () => {
        try {
          await API.cubes.delete(catalog.url, cube.id);

          alert.success(
            localizeMessage({
              id: 'persona.alerts.successfulCubeRemoving'
            }, {
              cubeName: title
            })
          );

          history.replace('/');
        } catch (error) {
          console.error(error);

          alert.error(
            localizeMessage({
              id: 'persona.alerts.errorCubeRemoving'
            }, {
              cubeName: title
            })
          );
        }
      });
  };

  insertFormula = async (title, formula, accessRights, memberLists) => {
    const {catalog} = this.state;

    const savedFormula = await API.formula.insert(catalog.id, title, formula, accessRights, memberLists);

    await this.reloadFiltersData();

    return savedFormula;
  };

  updateFormula = async (
    id,
    title,
    formula,
    isSaved,
    path,
    accessRights,
    memberLists
  ) => {
    const {catalog} = this.state;

    const updatedFormula = await API.formula.update(catalog.id, id, title, formula, accessRights, memberLists);

    await this.reloadFiltersData();

    return updatedFormula;
  };

  deleteFormula = async id => {
    const {catalog} = this.state;

    await API.formula.delete(catalog.id, id);

    await this.reloadFiltersData();
  };

  addGroup = async (title) => {
    const {catalog} = this.state;

    const addedGroup = await API.catalogs.addGroup(catalog.url, title);

    await this.reloadFiltersData();

    return addedGroup;
  };

  getActualData = () => {
    const {verticalDataTree, horizontalDataTree, statisticAxis, statisticItems, statisticRounding} = this.state;

    return cloneObject({
      verticalDataTree,
      horizontalDataTree,
      statisticAxis,
      statisticItems,
      statisticRounding
    });
  };

  updateFilterContainerPercentHeight = (filterContainerPercentHeight) => {
    this.filterContainerPercentHeight = filterContainerPercentHeight;
  };

  getEventAverageDay = (isAuto = false) => {
    const {eventBetweenStart, eventBetweenEnd, eventAverageDay, eventAverageDayWithAutoCalculation} = this.state;

    if (!isAuto && !eventAverageDayWithAutoCalculation && eventAverageDay) {
      return eventAverageDay;
    }

    return moment(
      (eventBetweenStart + eventBetweenEnd) / 2
    );
  };

  changeFilterDates = (nextState, cb) => {
    this.setState(nextState, cb);
  };

  exportCube = async () => {
    const {catalog, cube, statisticAxis} = this.state;

    if (!cube.uuid) {
      return;
    }

    this.setState({
      cube: {
        ...cube,
        exporting: true
      }
    });

    try {
      const cubeResponse = await API.cubes.downloadByUuid(catalog.url, cube.uuid, statisticAxis);

      if (cubeResponse.status === 204) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.filter.alerts.warningCubeCantBeExporting'
          })
        );

        this.setState({
          cube: {
            ...cube,
            exporting: false
          }
        });

        return;
      }

      const blob = await cubeResponse.blob();

      const dateString = generateReverseDateString(new Date(), ' ');
      const statisticString = cube.data.structure.measures.statistics.map(statistic => (
        statistic.title + (statistic.nplus && statistic.values.length ? ` (${statistic.values.join(', ')})` : '')
      )).join('_');

      FileSaver.saveAs(
        blob,
        `${dateString}_Aizek_${catalog.title}_${statisticString}.xlsx`
      );

      this.setState({
        cube: {
          ...cube,
          exporting: false
        }
      });
    } catch (error) {
      console.error(error);

      alert.warning(
        localizeMessage({
          id: 'persona.table.filter.alerts.warningCubeCantBeExporting'
        })
      );

      this.setState({
        cube: {
          ...cube,
          exporting: false
        }
      });
    }
  };

  handleBeforeUnload = () => {
    const {
      mode,
      verticalDataTree,
      horizontalDataTree,
      globalFilter,
      statisticItems,
      cube
    } = this.state;

    if (
      (
        mode === 'edit' &&
        (
          verticalDataTree.length ||
          horizontalDataTree.length ||
          statisticItems.length ||
          (globalFilter['ta'] && globalFilter['ta'].children.length) ||
          (globalFilter['event'] && globalFilter['event'].children.length) ||
          (globalFilter['event-tv'] && globalFilter['event-tv'].children.length) ||
          (globalFilter['event-web'] && globalFilter['event-web'].children.length)
        )
      ) ||
      (
        mode === 'view' &&
        cube.data &&
        cube.new
      )
    ) {
      return localizeMessage({
        id: 'persona.table.confirmations.reloadPage'
      });
    }

    return null;
  };

  render () {
    const {locale, isAdmin, isExternalUser} = this.props;
    const {
      filterData,
      title,
      catalog,
      isCatalogDataLoading,
      globalFilter,
      filterSelectedItems,
      eventBetweenStart,
      eventBetweenEnd,
      eventAverageDayWithAutoCalculation,
      mode,
      cube,
      tempAccessRights
    } = this.state;

    const tableStructure = this.getActualData();

    if (!catalog || !mode) {
      return (
        <div className={classes.Container}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={classes.Container}>
        <LocalizedMessage
          id={`site.title.edit.${title ? mode : 'new'}`}
          values={{
            title
          }}
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <BeforeUnload
          onBeforeUnload={this.handleBeforeUnload}
        />
        <Content
          ref={this.setContentRef}
          locale={locale}
          title={title}
          catalog={catalog}
          eventBetweenStart={eventBetweenStart}
          eventBetweenEnd={eventBetweenEnd}
          eventAverageDay={this.getEventAverageDay()}
          eventAverageDayWithAutoCalculation={eventAverageDayWithAutoCalculation}
          changeFilterDates={this.changeFilterDates}
          changingCell={
            filterData &&
            (filterData.formula || filterData.like === 'cell')
              ? {
                direction: filterData.direction,
                path: filterData.path.concat([filterData.index])
              }
              : null
          }
          isDisabled={!!filterData}
          mode={mode}
          data={cube.loaded ? cube.data : null}
          isAuthor={cube.isAuthor}
          isAdmin={isAdmin}
          openFilter={this.openFilter}
          deleteCell={this.deleteCell}
          clearData={this.clearData}
          calculateCube={this.calculateCube}
          saveCube={this.saveCube}
          tableStructure={tableStructure}
          globalFilter={globalFilter}
          loading={cube.loading || cube.saving || cube.renaming || cube.exporting || isCatalogDataLoading}
          renameTemplate={this.renameTemplate}
          copyTemplate={this.copyTemplate}
          deleteTemplate={this.deleteTemplate}
          updateStatisticAxis={this.updateStatisticAxis}
          updateStatisticData={this.updateStatisticData}
          updateStatisticRounding={this.updateStatisticRounding}
          changeGlobalFilterConnector={this.changeGlobalFilterConnector}
          editTable={this.editTable}
          exportCube={this.exportCube}
        />
        {
          filterData ?
            <Filters
              locale={locale}
              catalog={catalog}
              isAdmin={isAdmin}
              filterContainerPercentHeight={this.filterContainerPercentHeight}
              updateFilterContainerPercentHeight={this.updateFilterContainerPercentHeight}
              filterData={filterData}
              globalFilter={globalFilter}
              savedFormulas={catalog.filters.savedFormulas}
              filterSelectedItems={filterSelectedItems}
              eventBetweenStart={eventBetweenStart}
              eventBetweenEnd={eventBetweenEnd}
              eventAverageDay={this.getEventAverageDay()}
              getAccessRights={this.getAccessRights}
              addCell={this.addCell}
              updateCell={this.updateCell}
              closeFilter={this.closeFilter}
              applyGlobalFilter={this.applyGlobalFilter}
              insertFormula={this.insertFormula}
              updateFormula={this.updateFormula}
              deleteFormula={this.deleteFormula}
              loadEventHints={this.loadEventHints}
            />
            : null
        }
        {
          tempAccessRights ?
            <AccessRights
              type={tempAccessRights.type}
              catalog={catalog}
              data={tempAccessRights.data}
              addGroup={this.addGroup}
              save={tempAccessRights.save}
              close={tempAccessRights.close}
              isExternalUser={isExternalUser}
            />
            : null
        }
      </div>
    );
  }
}

export default Edit;
