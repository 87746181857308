import React from 'react';
import PropTypes from 'prop-types';
import StaticTable from './StaticTable';
import LazyTable from './LazyTable';

function Table (props) {
  const {
    mode,
    data,
    measures,
    tableStructure,
    direction,
    changingCell,
    globalFilter,
    catalog,
    sortedBy,
    openFilter,
    deleteCell,
    sortByCell,
    setCopyTableFn
  } = props;

  if (mode === 'edit') {
    return (
      <StaticTable
        catalog={catalog}
        globalFilter={globalFilter}
        data={data}
        measures={measures}
        tableStructure={tableStructure}
        direction={direction}
        changingCell={changingCell}
        openFilter={openFilter}
        deleteCell={deleteCell}
      />
    );
  }

  return (
    <LazyTable
      data={data}
      catalog={catalog}
      measures={measures}
      tableStructure={tableStructure}
      direction={direction}
      sortedBy={sortedBy}
      globalFilter={globalFilter}
      openFilter={openFilter}
      deleteCell={deleteCell}
      sortByCell={sortByCell}
      setCopyTableFn={setCopyTableFn}
    />
  );
}

Table.propTypes = {
  measures: PropTypes.array.isRequired,
  tableStructure: PropTypes.shape({
    verticalDataTree: PropTypes.array,
    horizontalDataTree: PropTypes.array,
    statisticAxis: PropTypes.oneOf(['rows', 'columns']).isRequired,
    statisticItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        affinity: PropTypes.bool.isRequired,
        nplus: PropTypes.bool.isRequired,
        values: PropTypes.arrayOf(
          PropTypes.string
        )
      })
    ).isRequired
  }),
  mode: PropTypes.oneOf([
    'view',
    'edit'
  ]),
  data: PropTypes.object,
  direction: PropTypes.oneOf(['x', 'y']).isRequired,
  catalog: PropTypes.object.isRequired,
  sortedBy: PropTypes.shape({
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    index: PropTypes.number.isRequired
  }),
  changingCell: PropTypes.shape({
    direction: PropTypes.string.isRequired,
    path: PropTypes.arrayOf(
      PropTypes.number
    ).isRequired
  }),
  globalFilter: PropTypes.shape({
    ta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired
    }),
    event: PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired
    }),
    'event-tv': PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired
    }),
    'event-web': PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired
    })
  }).isRequired,
  openFilter: PropTypes.func.isRequired,
  deleteCell: PropTypes.func.isRequired,
  sortByCell: PropTypes.func.isRequired,
  setCopyTableFn: PropTypes.func.isRequired
};

export default Table;
