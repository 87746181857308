import Client from './HTTPClient';

const catalogsAPI = {
  getCatalogs () {
    return Client.get('/olap/settings/all');
  },

  getFilters (catalog) {
    return Client.get(`/olap/${catalog}/navigation/all`);
  },

  getMeasures (catalog) {
    return Client.get(`/olap/${catalog}/navigation/measure`);
  },

  getGlobalFilter (catalog) {
    return Client.get(`/olap/${catalog}/filter`);
  },

  getAdvertisers () {
    return Client.get('/users/current/advertisers');
  },

  getUsers () {
    return Client.get('/users/emails');
  },

  addGroup (catalog, title) {
    return Client.post(`/olap/${catalog}/group/add`, {
      title: title
    });
  },

  getGroups (catalog) {
    return Client.get(`/olap/${catalog}/groups`);
  }
};

export default catalogsAPI;
