import API from '../api';

// ------------------------------------
// Constants
// ------------------------------------
export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILED = 'CHECK_AUTH_FAILED';
export const SEND_AUTH = 'SEND_AUTH';
export const SEND_AUTH_SUCCESS = 'SEND_AUTH_SUCCESS';
export const SEND_AUTH_FAILED = 'SEND_AUTH_FAILED';
export const LOGOUT = 'LOGOUT';

// ------------------------------------
// Actions
// ------------------------------------
export const checkAuth = () => async (dispatch) => {
  const token = localStorage['access_token'];

  if (typeof token !== 'string' || !token.length) {
    return;
  }

  dispatch({
    type: CHECK_AUTH
  });

  try {
    const checkAuthResponse = await API.auth.validateToken();

    dispatch({
      type: CHECK_AUTH_SUCCESS,
      profile: {
        id: checkAuthResponse.id,
        email: checkAuthResponse.email,
        firstName: checkAuthResponse.first_name,
        lastName: checkAuthResponse.last_name,
        fullName: checkAuthResponse.full_name,
        userName: checkAuthResponse.user_name,
        isExternalUser: checkAuthResponse.isExternalUser,
        isAdmin: (
          checkAuthResponse.roles &&
          checkAuthResponse.roles.length &&
          checkAuthResponse.roles.includes('ROLE_ADMIN')
        )
      }
    });
  } catch (error) {
    delete localStorage['access_token'];
    delete localStorage['refresh_token'];

    dispatch({
      type: CHECK_AUTH_FAILED
    });
  }
};

export const logIn = (username, password) => async (dispatch) => {
  dispatch({
    type: SEND_AUTH
  });

  try {
    const authResponse = await API.auth.logIn(username, password);

    localStorage['access_token'] = authResponse['access_token'];
    localStorage['refresh_token'] = authResponse['refresh_token'];

    const tokenContent = await API.auth.validateToken();

    dispatch({
      type: SEND_AUTH_SUCCESS,
      profile: {
        id: tokenContent.id,
        email: tokenContent.email,
        firstName: tokenContent.first_name,
        lastName: tokenContent.last_name,
        fullName: tokenContent.full_name,
        userName: username,
        isExternalUser: tokenContent.isExternalUser,
        isAdmin: tokenContent.roles.includes('ROLE_ADMIN')
      }
    });

    return authResponse;
  } catch (error) {
    dispatch({
      type: SEND_AUTH_FAILED
    });

    throw error;
  }
};

export const logOut = () => async (dispatch) => {
  API.auth.logOut();

  delete localStorage['access_token'];
  delete localStorage['refresh_token'];

  dispatch({
    type: LOGOUT
  });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthorizing: false,
  isAuthorized: false,
  profile: null
};

const ACTION_HANDLERS = {
  [CHECK_AUTH]: (state, action) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false
  }),
  [CHECK_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile
  }),
  [CHECK_AUTH_FAILED]: (state, action) => ({
    ...state,
    isAuthorizing: false
  }),
  [SEND_AUTH]: (state, action) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false
  }),
  [SEND_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile
  }),
  [SEND_AUTH_FAILED]: (state, action) => ({
    ...state,
    isAuthorizing: false
  }),
  [LOGOUT]: (state, action) => ({
    isAuthorizing: false,
    isAuthorized: false,
    profile: null
  })
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
