import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AutosizeInput from '../../../../../lib/react-input-autosize';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import Scrollbar from '../../../../../components/Scrollbar';
import classes from './Users.module.scss';

class Users extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    fieldValue: '',
    menuIsOpen: false,
    focusedAutocompleteIndex: 0
  };

  _container = null;
  _scrollbar = null;
  _hintScrollbar = null;
  _field = null;
  _menu = null;
  _hints = {};

  componentDidMount () {
    setTimeout(() => {
      this.focusInput();
    });
  }

  setContainerRef = ref => {
    this._container = ref;
  };

  setScrollbarRef = ref => {
    this._scrollbar = ref;
  };

  setHintScrollbarRef = ref => {
    this._hintScrollbar = ref;
  };

  setFieldRef = ref => {
    this._field = ref;
  };

  setMenuRef = ref => {
    this._menu = ref;
  };

  updateMenuPosition = () => {
    if (!this._field) {
      return;
    }

    const scrollbarContainerComponentPosition = this._scrollbar.getContainer().getBoundingClientRect();
    const containerComponentPosition = this._container.getBoundingClientRect();
    const inputComponentPosition = this._field.getBoundingClientRect();

    if (this._menu) {
      const fieldIsNotVisible =
        scrollbarContainerComponentPosition &&
        (
          (scrollbarContainerComponentPosition.left > inputComponentPosition.left) ||
          (scrollbarContainerComponentPosition.right < inputComponentPosition.right) ||
          (scrollbarContainerComponentPosition.top > inputComponentPosition.top) ||
          (scrollbarContainerComponentPosition.bottom < inputComponentPosition.bottom)
        );

      if (fieldIsNotVisible) {
        this._menu.style.visibility = 'hidden';
      } else {
        this._menu.style.visibility = 'visible';
        this._menu.style.top = `${inputComponentPosition.bottom - containerComponentPosition.top}px`;
        this._menu.style.left = `${inputComponentPosition.left - containerComponentPosition.left}px`;
      }
    }
  };

  onFieldFocus = () => {
    this.setState({
      menuIsOpen: true,
      focusedAutocompleteIndex: 0
    }, () => {
      this.updateMenuPosition();
    });
  };

  onFieldBlur = () => {
    this.setState({
      fieldValue: '',
      menuIsOpen: false
    });
  };

  onFieldChange = fieldValue => {
    this.setState({
      fieldValue,
      focusedAutocompleteIndex: 0
    }, () => {
      this.updateMenuPosition();
    });
  };

  onFieldKeyDown = (e, options) => {
    const {focusedAutocompleteIndex} = this.state;

    switch (e.keyCode) {
      case 13:            // Enter
        if (options[focusedAutocompleteIndex]) {
          this.selectOption(options[focusedAutocompleteIndex], true);
        }

        break;
      case 8:             // Backspace
        this.removeLastItem(e);

        break;
      case 38:            // Arrow Up
        if (focusedAutocompleteIndex > 0) {
          this.focusHint(focusedAutocompleteIndex - 1);
        }

        break;
      case 40:            // Arrow Down
        if (focusedAutocompleteIndex < options.length - 1) {
          this.focusHint(focusedAutocompleteIndex + 1);
        }

        break;
      default:
        break;
    }
  };

  focusInput = () => {
    if (this._field) {
      this._field.focus();
    }
  };

  blurInput = () => {
    if (this._field) {
      this._field.blur();
    }
  };

  focusHint = (hintIndex, withScroll = true) => {
    const {fieldValue} = this.state;

    this.setState({
      focusedAutocompleteIndex: hintIndex
    }, () => {
      if (withScroll) {
        const _focusedHint = this._hints[`${fieldValue}-${hintIndex}`];
        const hintHeight = _focusedHint.offsetHeight;
        const hintOffsetTop = _focusedHint.offsetTop;

        this._hintScrollbar.scrollTop(hintOffsetTop - hintHeight);
      }
    });
  };

  removeLastItem = (e) => {
    const {values, onChange} = this.props;
    if (!values.length || e.target.value.length) {
      return;
    }

    const nextValues = values.slice(0);
    nextValues.pop();

    onChange(nextValues);

    setTimeout(() => {
      this.updateMenuPosition();
    });
  };

  selectOption = (selectedOption, withBlur = false) => {
    const {values, onChange} = this.props;

    const nextValues = values.slice(0);
    nextValues.push(selectedOption);

    onChange(nextValues);

    if (withBlur) {
      this.blurInput();
    }

    this.setState({
      fieldValue: ''
    });

    setTimeout(() => {
      this.focusInput();
      this.updateMenuPosition();
    });
  };

  removeValue = (removingOption) => {
    const {values, onChange} = this.props;

    setTimeout(() => {
      const nextValues = values.slice(0).filter(option => option.value !== removingOption.value);

      onChange(nextValues);

      setTimeout(() => {
        this.focusInput();
        this.updateMenuPosition();
      });
    });
  };

  filterUsedValues = (options) => {
    const {values} = this.props;

    if (!options || !options.length) {
      return [];
    }

    if (!values || !values.length) {
      return options;
    }

    return options.filter(option => (
      !values.some(valueOption => valueOption.value === option.value)
    ));
  };

  getRemainingOptions = () => {
    const {options} = this.props;
    const {fieldValue} = this.state;

    const fieldValueLowerCase = fieldValue.toLowerCase();

    return this.filterUsedValues(options).filter(option => (
      option.label.toLowerCase().indexOf(fieldValueLowerCase) > -1
    ));
  };

  render () {
    const {options, values} = this.props;
    const {fieldValue, menuIsOpen, focusedAutocompleteIndex} = this.state;

    const remainingOptions = this.getRemainingOptions();

    return (
      <div
        ref={this.setContainerRef}
        className={classes.Container}
      >
        <Scrollbar
          ref={this.setScrollbarRef}
          autoHeightMax={180}
          className={classes.ScrollbarContent}
        >
          {
            values &&
            values.length
              ? <ul className={classes.SelectValues}>
                {
                  values.map(option => (
                    <li
                      key={option.value}
                      className={classes.SelectValuesItem}
                    >
                      <div className={classes.SelectValuesItemContent}>
                        <span className={classes.SelectValuesLabel}>
                          {option.label || ''}
                        </span>
                        <span
                          className={classes.SelectValuesRemoveBtn}
                          onClick={() => this.removeValue(option)}
                        >
                          &times;
                        </span>
                      </div>
                    </li>
                  ))
                }
              </ul>
              : null
          }
          <div className={classes.SelectFieldContailer}>
            <LocalizedMessage
              id='persona.table.filter.formulaEditor.hints.enterSomething'
            >
              {localizedMessage => (
                <AutosizeInput
                  inputRef={this.setFieldRef}
                  className={classes.FieldContainer}
                  inputClassName={cx(
                    classes.Field,
                    classes.SelectField,
                    {
                      [classes.SelectFieldStarting]: !values.length
                    }
                  )}
                  minWidth={1}
                  onFocus={this.onFieldFocus}
                  onBlur={this.onFieldBlur}
                  onKeyDown={e => this.onFieldKeyDown(e, remainingOptions)}
                  onChange={e => this.onFieldChange(e.target.value)}
                  value={fieldValue}
                  placeholder={
                    !values ||
                    !values.length
                      ? localizedMessage
                      : null
                  }
                />
              )}
            </LocalizedMessage>
          </div>
          {
            !options ||
            !options.length ?
              <p className={classes.EmptyList}>
                <LocalizedMessage
                  id='persona.accessRights.checkboxes.users.isEmpty'
                />
              </p>
              : null
          }
        </Scrollbar>
        {
          menuIsOpen &&
          remainingOptions &&
          remainingOptions.length ?
            <div
              ref={this.setMenuRef}
              className={classes.SelectOptions}
            >
              <Scrollbar
                ref={this.setHintScrollbarRef}
                autoWidth
                autoHeightMax={120}
              >
                <ul className={classes.SelectOptionsList}>
                  {
                    remainingOptions.map((option, index) => (
                      <li
                        key={`${fieldValue}_${index}`}
                        ref={ref => {
                          this._hints[`${fieldValue}-${index}`] = ref;
                        }}
                        className={cx(
                          classes.SelectOptionsItem,
                          {
                            [classes.SelectOptionsItemFocused]: focusedAutocompleteIndex === index
                          }
                        )}
                        onMouseEnter={() => this.focusHint(index, false)}
                        onMouseDown={() => this.selectOption(option)}
                      >
                        {option.label || ''}
                      </li>
                    ))
                  }
                </ul>
              </Scrollbar>
            </div>
            : null
        }
      </div>
    );
  }
}

export default Users;
