import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from '../Loader';
import Scrollbar from '../Scrollbar';
import classes from './Modal.module.scss';

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    footerComponent: PropTypes.node,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    className: null,
    contentClassName: null,
    title: null,
    footerComponent: null,
    isOpen: true,
    loading: false,
    onClose: null
  };

  _root = document.body;

  componentDidMount () {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount () {
    window.addEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    const {onClose} = this.props;

    if (
      typeof onClose === 'function' &&
      e.keyCode === 27
    ) {
      onClose();
    }
  };

  render () {
    const {children, className, contentClassName, title, footerComponent, isOpen, loading, onClose} = this.props;

    if (!isOpen) {
      return null;
    }

    return createPortal(
      <div className={classes.Container}>
        <div
          className={cx(
            classes.Parent,
            className
          )}
        >
          {
            title ?
              <h3 className={classes.Title}>
                {title}
              </h3>
              : null
          }
          {
            onClose ?
              <div
                className={cx(
                  classes.CloseBtn,
                  {
                    [classes.CloseBtnDisabled]: loading
                  },
                  'icon-close'
                )}
                onClick={!loading ? onClose : null}
              />
              : null
          }
          <div className={classes.Scrollbar}>
            <div className={classes.ScrollbarContent}>
              <Scrollbar
                className={cx(
                  classes.Content,
                  contentClassName
                )}
              >
                {children}
              </Scrollbar>
            </div>
          </div>
          {
            footerComponent ?
              <div className={classes.Footer}>
                {footerComponent}
              </div>
              : null
          }
        </div>
        <Loader
          active={loading}
        />
      </div>,
      this._root
    );
  }
}

export default Modal;
