import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import {getEmptyImage} from 'react-dnd-html5-backend';
import LocalizedMessage from '../../../../../../components/LocalizedMessage';
import {
  Simple,
  Equal,
  NotEqual,
  LessThan,
  LessThanOrEqual,
  GreaterThan,
  GreaterThanOrEqual,
  In,
  Between
} from './Templates';
import Calendar from './Calendar';
import {isMac} from '../../../../../../helpers/dom';
import {capitalizeFirstLetter} from '../../../../../../helpers/string';
import classes from '../FormulaEditor.module.scss';

class Item extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    selectedItem: PropTypes.shape({
      type: PropTypes.string.isRequired,
      path: PropTypes.array.isRequired
    }),
    path: PropTypes.array.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectableRef: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    isSelecting: PropTypes.bool.isRequired,
    isInvalid: PropTypes.bool.isRequired,
    withDateRange: PropTypes.bool.isRequired,
    cursorDirection: PropTypes.string,
    scrollToField: PropTypes.func.isRequired,
    changeConditionValue: PropTypes.func.isRequired,
    setDateRange: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onInputWidthChange: PropTypes.func.isRequired,
    openMemberListModal: PropTypes.func.isRequired,
    addOnScrollCallback: PropTypes.func.isRequired,
    removeOnScrollCallback: PropTypes.func.isRequired,
    loadEventHints: PropTypes.func.isRequired
  };

  state = {
    isChanging: false
  };

  eventMinDate =
    this.props.catalog
      ? moment(this.props.catalog.startDate)
      : null;

  eventMaxDate =
    this.props.catalog
      ? moment(this.props.catalog.endDate)
      : null;

  eventBetweenEnd =
    this.props.catalog
      ? moment(this.props.catalog.endDate, 'YYYY-MM-DD')
      : moment();

  eventBetweenStart =
    this.props.catalog
      ? moment(Math.max(moment(this.eventBetweenEnd).add(-1, 'M'), moment(this.props.catalog.startDate, 'YYYY-MM-DD')))
      : this.eventBetweenEnd.add(-1, 'M');

  _calendar = null;

  componentDidMount () {
    const {connectDragPreview} = this.props;

    connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    });
  }

  setCalendarRef = ref => {
    this._calendar = ref;
  };

  toggleChanging = (isChanging) => {
    this.setState({
      isChanging
    });
  };

  getTemplate = (data) => {
    const {
      locale,
      catalog,
      addOnScrollCallback,
      removeOnScrollCallback,
      changeConditionValue,
      scrollToField,
      setFieldRef,
      onInputWidthChange,
      isSelecting,
      loadEventHints
    } = this.props;

    if (
      data.condition.type === 'ta' ||
      data.condition.type === 'member_id_list'
    ) {
      return (
        <Simple
          setFieldRef={setFieldRef}
        >
          {data.title[locale]}
        </Simple>
      );
    }

    switch (data.condition.operator) {
      case '=':
        return (
          <Equal
            locale={locale}
            data={data}
            catalog={catalog}
            isSelecting={isSelecting}
            addOnScrollCallback={addOnScrollCallback}
            removeOnScrollCallback={removeOnScrollCallback}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
            onInputWidthChange={onInputWidthChange}
            loadEventHints={loadEventHints}
          />
        );
      case '\\=':
        return (
          <NotEqual
            locale={locale}
            data={data}
            catalog={catalog}
            isSelecting={isSelecting}
            addOnScrollCallback={addOnScrollCallback}
            removeOnScrollCallback={removeOnScrollCallback}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
            onInputWidthChange={onInputWidthChange}
            loadEventHints={loadEventHints}
          />
        );
      case '<':
        return (
          <LessThan
            locale={locale}
            data={data}
            isSelecting={isSelecting}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
          />
        );
      case '<=':
        return (
          <LessThanOrEqual
            locale={locale}
            data={data}
            isSelecting={isSelecting}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
          />
        );
      case '>':
        return (
          <GreaterThan
            locale={locale}
            data={data}
            isSelecting={isSelecting}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
          />
        );
      case '>=':
        return (
          <GreaterThanOrEqual
            locale={locale}
            data={data}
            isSelecting={isSelecting}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
          />
        );
      case 'in':
        return (
          <In
            locale={locale}
            data={data}
            catalog={catalog}
            isSelecting={isSelecting}
            addOnScrollCallback={addOnScrollCallback}
            removeOnScrollCallback={removeOnScrollCallback}
            scrollToField={scrollToField}
            toggleChanging={this.toggleChanging}
            selectItem={this.selectItem}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
            onInputWidthChange={onInputWidthChange}
            loadEventHints={loadEventHints}
          />
        );
      case 'between':
        return (
          <Between
            locale={locale}
            data={data}
            isSelecting={isSelecting}
            toggleChanging={this.toggleChanging}
            setFieldRef={setFieldRef}
            onChange={changeConditionValue}
          />
        );
      default:
        return (
          <Simple
            setFieldRef={setFieldRef}
          >
            {data.title[locale]}
          </Simple>
        );
    }
  };

  selectItem = (e) => {
    const {path, isSelecting, selectItem} = this.props;

    const isCalendar =
      this._calendar &&
      this._calendar._container &&
      this._calendar._container.contains(e.target);

    const ctrlKey = (
      (isMac && e.metaKey) ||
      (!isMac && e.ctrlKey)
    );
    const shiftKey = e.shiftKey;
    const focusField = !isSelecting && !isCalendar && e.target.tagName.toLowerCase() !== 'input';

    setTimeout(() => {
      selectItem(
        {
          type: 'item',
          path: path
        },
        ctrlKey,
        shiftKey,
        focusField
      );
    });
  };

  editItem = () => {
    const {data, path, openMemberListModal} = this.props;

    if (data.condition.type === 'member_id_list') {
      openMemberListModal({
        id: data.id
      }, {
        path
      });
    }
  };

  render () {
    const {
      data,
      selectedItem,
      path,
      selectableRef,
      connectDragSource,
      connectDropTarget,
      isOver,
      canDrop,
      isSelecting,
      isInvalid,
      withDateRange,
      cursorDirection,
      setDateRange
    } = this.props;
    const {isChanging} = this.state;

    const item = connectDropTarget(
      <div
        ref={selectableRef}
        className={cx(
          classes.FormulaItem,
          {
            [classes.FormulaItemSelected]: (
              selectedItem &&
              selectedItem.type === 'item' &&
              selectedItem.path.join('-') === path.join('-')
            ),
            [classes.FormulaItemHasNot]: data.hasNot,
            [classes[`FormulaItemCursor${capitalizeFirstLetter(cursorDirection)}`]]: (
              isOver &&
              canDrop
            ),
            [classes.FormulaItemInvalid]: isInvalid
          }
        )}
        onClick={this.selectItem}
      >
        <div className={classes.FormulaNotOperator}>
          <LocalizedMessage
            id='persona.table.filter.formulaEditor.buttons.not'
          />
        </div>
        <div
          data-type='border-left'
          className={classes.FormulaItemBorder}
        />
        {
          this.getTemplate(data)
        }
        {
          withDateRange &&
          data.condition.type !== 'member_id_list' ?
            <Calendar
              ref={this.setCalendarRef}
              hasDate={!!data.dateRange}
              dateRangeStart={
                data.dateRange && data.dateRange.start
                  ? moment(data.dateRange.start, 'YYYY-MM-DD')
                  : this.eventBetweenStart
              }
              dateRangeEnd={
                data.dateRange && data.dateRange.end
                  ? moment(data.dateRange.end, 'YYYY-MM-DD')
                  : this.eventBetweenEnd
              }
              minDate={this.eventMinDate}
              maxDate={this.eventMaxDate}
              setDateRange={setDateRange}
            />
            : null
        }
        {
          data.condition.type === 'member_id_list' ?
            <span
              className={cx(
                classes.FormulaItemEditBtn,
                'icon-edit'
              )}
              onClick={this.editItem}
            />
            : null
        }
        <div
          data-type='border-right'
          className={classes.FormulaItemBorder}
        />
      </div>
    );

    if (isSelecting || isChanging) {
      return item;
    }

    return connectDragSource(item);
  }
}

export default Item;
