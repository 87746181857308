import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {generateFormulaString} from '../../../helpers/formula';
import classes from './DragLayer.module.scss';

export class DragLayer extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    item: PropTypes.object,
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }),
    isDragging: PropTypes.bool.isRequired
  };

  static defaultProps = {
    currentOffset: null
  };

  getItemStyles = () => {
    const {currentOffset} = this.props;

    if (!currentOffset) {
      return {
        display: 'none'
      };
    }

    const {x, y} = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform: transform,
      WebkitTransform: transform
    };
  };

  render () {
    const {locale, item, isDragging} = this.props;

    if (!item || !item.data || !isDragging) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <div style={this.getItemStyles()}>
          ({generateFormulaString(locale, item.data)})
        </div>
      </div>
    );
  }
}

export default DragLayer;
