import shortid from 'shortid';
import hashObject from 'object-hash';
import {localizeMessage} from '../../../components/LocalizedMessage';
import {cloneObject} from '../../../helpers/utils';

export const getItemLinkByPath = (formula, path) => {
  let formulaChildrenLink = formula.children;

  for (let i = 0; i < path.length; i += 1) {
    if (path.length - 1 === i) {
      return formulaChildrenLink[path[i]];
    } else {
      formulaChildrenLink = formulaChildrenLink[path[i]].children;
    }
  }

  return formula;
};

export const getMainParentPath = (path1, path2) => {
  if (path1.join('-') === path2.join('-')) {
    return path1;
  }

  const mainPath = [];

  for (let i = 0; i < Math.min(path1.length, path2.length); i += 1) {
    if (path1[i] !== path2[i]) {
      break;
    }

    mainPath.push(path1[i]);
  }

  return mainPath;
};

export const conditionValueConverter = (prevCondition, nextCondition, values) => {
  switch (`${prevCondition} -> ${nextCondition}`) {
    case 'between -> =':
    case 'between -> \\=':
    case 'between -> <':
    case 'between -> <=':
    case 'between -> >':
    case 'between -> >=':
    case 'in -> =':
    case 'in -> \\=':
    case 'in -> <':
    case 'in -> <=':
    case 'in -> >':
    case 'in -> >=':
      return values && values.length ? values.slice(0, 1) : [];
    case '= -> between':
    case '\\= -> between':
    case '< -> between':
    case '<= -> between':
    case '> -> between':
    case '>= -> between':
      return [values && values.length ? values[0] : {value: 0}, {value: 0}];
    case '= -> in':
    case '\\= -> in':
    case '< -> in':
    case '<= -> in':
    case '> -> in':
    case '>= -> in':
      return values && values.length && (values.length > 1 || values[0].value) ? values : [];
    case 'between -> in':
      return values.filter(cValue => cValue && cValue.value);
    case 'in -> between':
      return values && values.length ? [values[0], values[1] || {value: 0}] : [{value: 0}, {value: 0}];
    default:
      return values;
  }
};

export const getFormulaStringByCondition = (locale, formula) => {
  const variable = formula.title[locale] ? `"${formula.title[locale]}"` : '';

  if (
    formula.condition.type === 'ta' ||
    formula.condition.type === 'expression' ||
    formula.condition.type === 'member_id_list' ||
    !formula.condition.values || !formula.condition.values.length
  ) {
    return variable;
  }

  switch (formula.condition.operator) {
    case '<':
    case '<=':
    case '>':
    case '>=':
      return `${variable} ${formula.condition.operator} ${formula.condition.values[0].value}`;
    case '=':
    case '\\=':
      if (['ta_list', 'ta_list_advertisers'].includes(formula.condition.type)) {
        return `${variable} ${formula.condition.operator} '${formula.condition.values[0].label[locale]}'`;
      }

      const operatorValue =
        formula.condition.type === 'ta_integer'
          ? formula.condition.values[0].value
          : `'${formula.condition.values[0].value}'`;

      return `${variable} ${formula.condition.operator} ${operatorValue}`;
    case 'in':
      if (['ta_list', 'ta_list_advertisers'].includes(formula.condition.type)) {
        return `${variable} ${localizeMessage({
          id: 'persona.table.filter.formulaEditor.buttons.in'
        })} (${formula.condition.values.map(option => `'${option.label[locale]}'`).join(', ')})`;
      }

      const inValues =
        formula.condition.type === 'ta_integer'
          ? formula.condition.values.map(option => option.value)
          : formula.condition.values.map(option => `'${option.label[locale]}'`);

      return `${variable} ${localizeMessage({
        id: 'persona.table.filter.formulaEditor.buttons.in'
      })} (${inValues.join(', ')})`;
    case 'between':
      const betweenValues = formula.condition.values;

      return `${variable} ${
        localizeMessage({
          id: 'persona.table.filter.formulaEditor.buttons.between'
        })} (${betweenValues[0].value}, ${betweenValues[1].value})`;
    default:
      return null;
  }
};

export const generateFormulaString = (locale, formula, depth = 0) => {
  if (!formula) {
    return '';
  }

  let resultString = '';

  if (formula.condition && (!formula.children || !formula.children.length)) {
    resultString += getFormulaStringByCondition(locale, formula);
  } else {
    let childrenString = '';

    formula.children.forEach((item, index) => {
      if (index) {
        childrenString += ` ${localizeMessage({id: `persona.table.filter.formulaEditor.buttons.${item.connector}`})} `;
      }

      childrenString += generateFormulaString(locale, item, depth + 1);
    });

    if (childrenString.length && depth) {
      childrenString = `(${childrenString})`;
    }

    resultString += childrenString;
  }

  if (!resultString.length) {
    return '';
  }

  if (formula.hasNot) {
    if (formula.children && formula.children.length && depth) {
      resultString = `${localizeMessage({id: 'persona.table.filter.formulaEditor.buttons.not'})} ${resultString}`;
    } else {
      resultString = `${localizeMessage({id: 'persona.table.filter.formulaEditor.buttons.not'})} (${resultString})`;
    }
  }

  return resultString;
};

export const validateFormula = (formula, path = []) => {
  if (!path.length && !formula.children.length) {
    return {
      status: false,
      errorPaths: []
    };
  }

  let errorPaths = [];

  if (!formula.children || !formula.children.length) {
    if (
      formula.condition.type === 'ta_integer' &&
      (
        (
          formula.condition.operator === 'between' &&
          formula.condition.values[0].value > formula.condition.values[1].value
        ) ||
        (
          formula.condition.operator === 'in' &&
          !formula.condition.values.length
        )
      )
    ) {
      errorPaths.push(path.join('-'));
    }

    if (
      ['event', 'ta_list', 'ta_list_advertisers'].includes(formula.condition.type) &&
      (
        !formula.condition.values ||
        !formula.condition.values.length ||
        !formula.condition.values[0].value.toString().length
      )
    ) {
      errorPaths.push(path.join('-'));
    }
  } else {
    formula.children.forEach((item, index) => {
      errorPaths = errorPaths.concat(validateFormula(item, path.concat([index])));
    });
  }

  if (path.length) {
    return errorPaths;
  }

  if (!errorPaths.length) {
    return {
      status: true
    };
  } else {
    return {
      status: false,
      errorPaths
    };
  }
};

export const prepareFormula = (locale, formula, memberLists, isFirst = true) => {
  if (
    memberLists &&
    Array.isArray(memberLists) &&
    formula.condition &&
    formula.condition.type === 'member_id_list'
  ) {
    memberLists.push(formula.id);
  }

  const resultObject = {
    ...formula,
    condition: formula.condition
      ? {
        ...formula.condition,
        values: formula.condition.values
          ? formula.condition.values.map(value => ({
            label: value.label && Object.keys(value.label).includes('ru')
              ? (value.label.ru || null)
              : (typeof value.label === 'string' ? value.label : null),
            labelEnglish: value.label && Object.keys(value.label).includes('en')
              ? (value.label.en || null)
              : (typeof value.label === 'string' ? value.label : null),
            value: value.value
          }))
          : formula.condition.values
      }
      : formula.condition,
    children:
      formula.children
        ? formula.children.map(child => prepareFormula(locale, child, memberLists, false))
        : []
  };

  if (typeof resultObject.title === 'string') {
    resultObject.title = {
      ru: resultObject.title,
      en: resultObject.titleEnglish || resultObject.title
    };

    delete resultObject.titleEnglish;
  }

  if (
    resultObject.title !== null &&
    (
      !resultObject.title ||
      typeof resultObject.title[locale] !== 'string'
    )
  ) {
    // TODO: Remove when adding formula to cube response
    resultObject.title = {
      ru: generateFormulaString('ru', formula),
      en: generateFormulaString('en', formula)
    };

    if (isFirst && !resultObject.title.length) {
      delete resultObject.title;
    }
  }

  if (resultObject.uid) {
    delete resultObject.uid;
  }

  resultObject.titleEnglish = resultObject.title ? resultObject.title.en : resultObject.title;
  resultObject.title = resultObject.title ? resultObject.title.ru : resultObject.title;

  return resultObject;
};

export const clearCubeChildren = (locale, expressionHashMap, children) => (
  children.map(child => {
    const resultChild = {...child};

    if (typeof resultChild.title === 'object') {
      resultChild.titleEnglish = resultChild.title.en || null;
      resultChild.title = resultChild.title.ru || null;
    }

    if (typeof resultChild.dimensionTitle === 'object') {
      resultChild.dimensionTitleEnglish = resultChild.dimensionTitle.en || null;
      resultChild.dimensionTitle = resultChild.dimensionTitle.ru || null;
    }

    if (child.formula) {
      resultChild.formula = prepareFormula(locale, child.formula);

      if (resultChild.formula.children && resultChild.formula.children.length) {
        const expressionId = expressionHashMap[getFormulaHashWithoutTitles(resultChild.formula.children)];

        if (expressionId) {
          resultChild.expressionId = expressionId;
        }
      }

      if (child.mode === 'event-tv') {
        resultChild.internalType = 'EVENT_FILTERED';
        resultChild.eventType = 'tv';
      }

      if (child.mode === 'event-web') {
        resultChild.internalType = 'EVENT_FILTERED';
        resultChild.eventType = 'web';
      }

      delete resultChild.mode;

      if (
        !resultChild.title ||
        !resultChild.title.length
      ) {
        if (
          typeof resultChild.formula.title === 'string' &&
          resultChild.formula.title.length
        ) {
          resultChild.title = resultChild.formula.title;
          resultChild.titleEnglish = resultChild.formula.titleEnglish || resultChild.formula.title;
        } else {
          resultChild.title = generateFormulaString('ru', child.formula);
          resultChild.titleEnglish = generateFormulaString('en', child.formula);
        }
      }
    }

    resultChild.children =
      child.children &&
      child.children.length
        ? clearCubeChildren(locale, expressionHashMap, child.children)
        : [];

    return resultChild;
  })
);

export const restructureCubeChildren = (children, isFormula = false, addUids = true) => (
  children.map(child => {
    let mode = 'ta';

    if (child.internalType === 'EVENT_FILTERED' && typeof child.eventType === 'string') {
      mode = `event-${child.eventType.toLowerCase()}`;
    }

    const resultChild = {
      ...child,
      title: {
        ru: child.title,
        en: child.titleEnglish || child.title
      },
      dimensionTitle: {
        ru: child.dimensionTitle,
        en: child.dimensionTitleEnglish || child.dimensionTitle
      },
      mode,
      children:
        child.children &&
        child.children.length
          ? restructureCubeChildren(child.children, isFormula)
          : []
    };

    delete resultChild.titleEnglish;
    delete resultChild.dimensionTitleEnglish;
    delete resultChild.internalType;
    delete resultChild.eventType;
    delete resultChild.alias;

    if (isFormula && addUids) {
      resultChild.uid = shortid.generate();

      if (resultChild.condition && resultChild.condition.values) {
        resultChild.condition = {
          ...resultChild.condition,
          values: resultChild.condition.values.map(value => ({
            value: value.value,
            label: {
              ru: value.label,
              en: value.labelEnglish || value.label
            }
          }))
        };
      }
    }

    if (!isFormula && child.formula) {
      resultChild.formula = {
        ...child.formula,
        condition: child.formula.condition
          ? {
            ...child.formula.condition,
            values: child.formula.condition.values
              ? child.formula.condition.values.map(value => ({
                value: value.value,
                label: {
                  ru: value.label,
                  en: value.labelEnglish || value.label
                }
              }))
              : child.formula.condition.values
          }
          : child.formula.condition,
        children:
          child.formula.children &&
          child.formula.children.length
            ? restructureCubeChildren(child.formula.children, true)
            : []
      };
    }

    return resultChild;
  })
);

export const getFormulaHashWithoutTitles = (object) => {
  const titlesVariants = [
    'title',
    'titleEnglish',
    'dimensionTitle',
    'dimensionTitleEnglish',
    'label',
    'labelEnglish',
    'uid'
  ];

  return hashObject(cloneObject(object, titlesVariants));
};
