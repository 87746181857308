import Client from './HTTPClient';
import Config from '../../config';
import decode from "jwt-decode"

let hasReLogInRequest = false;
let reLogInSendCallbacksTimer = 0;
let reLogInResult = null;
let additionalReLogInRequests = [];

const basicToken =
  typeof Config.api.authClientId === 'string' &&
  Config.api.authClientId.length &&
  typeof Config.api.authClientSecret === 'string' &&
  Config.api.authClientSecret.length
    ? {
      type: 'basic',
      token: window.btoa(unescape(encodeURIComponent(`${Config.api.authClientId}:${Config.api.authClientSecret}`)))
    }
    : false;

const reLogInSendCallbacks = () => {
  if (!reLogInResult) {
    return;
  }

  clearTimeout(reLogInSendCallbacksTimer);

  reLogInSendCallbacksTimer = setTimeout(() => {
    additionalReLogInRequests = additionalReLogInRequests.filter(request => {
      request[reLogInResult.status ? 'resolve' : 'reject'](reLogInResult.response);

      return false;
    });

    hasReLogInRequest = false;
    reLogInResult = null;
  }, 100);
};

const AuthAPI = {
  logIn (username, password) {
    return Client.post(`${Config.api.authHost}/keycloak/auth/realms/ADV/protocol/openid-connect/token`, {
      grant_type: 'password',
      username,
      password,
      client_id: 'persona',
    }, basicToken, false, false);
  },

  async reLogIn () {
    if (!hasReLogInRequest) {
      hasReLogInRequest = true;

      try {

        const response = await Client.post(`${Config.api.authHost}/keycloak/auth/realms/ADV/protocol/openid-connect/token`, {
          grant_type: 'refresh_token',
          client_id: 'persona',
          refresh_token: localStorage['refresh_token']
        }, basicToken, false, false);

        localStorage['access_token'] = response['access_token'];
        localStorage['refresh_token'] = response['refresh_token'];

        reLogInResult = {
          status: true,
          response: response
        };
        reLogInSendCallbacks();
      } catch (error) {
        reLogInResult = {
          status: false,
          response: error
        };
        reLogInSendCallbacks();
      }
    }

    return new Promise((resolve, reject) => {
      additionalReLogInRequests.push({
        resolve,
        reject
      });

      if (reLogInResult) {
        reLogInSendCallbacks();
      }
    });
  },

  validateToken () {

    try {
      const decoded = decode(localStorage['access_token'])

      const checkAuthResponse = {
        id: decoded.sub,
        email: decoded.email,
        first_name: decoded.given_name || '',
        last_name: decoded.family_name || '',
        full_name: decoded.name || '',
        user_name: decoded.preferred_username,
        isExternalUser: !!decoded.realm_access?.roles?.includes('external_user'),
        roles: decoded.resource_access.account.roles
      }

      if (!decoded.projects?.includes(Config.projectName)) {
        const error = new Error('No project access');
        error.jsonResponse = {
          error: 'invalid_grant',
          error_description: 'No project access'
        };
        return Promise.reject(error);
      }

      return Promise.resolve(checkAuthResponse)
    }catch (e){
      return Promise.resolve(undefined);
    }
  },

  logOut () {
    return Client.post(`${Config.api.authHost}/keycloak/auth/realms/ADV/protocol/openid-connect/logout`, () => ({
      client_id: 'persona',
      refresh_token: localStorage['refresh_token']
    }), basicToken, false, false);
  }
};

export default AuthAPI;
