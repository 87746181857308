import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Button.module.scss';

const Button = ({children, type, size, className, theme, icon, disabled, onMouseDown, onClick}) => (
  <button
    type={type}
    disabled={disabled}
    className={cx(
      classes.Button,
      {
        [classes.ButtonTransparent]: theme === 'transparent',
        [cx(classes.ButtonIcon, `icon-${icon}`)]: icon,
        [classes.ButtonSmall]: size === 'small',
        [classes.ButtonWithoutChildren]: !children
      },
      className
    )}
    onMouseDown={!disabled ? onMouseDown : null}
    onClick={!disabled ? onClick : null}
  >
    {children}
  </button>
);

Button.defaultProps = {
  children: null,
  type: 'button',
  size: 'default',
  className: null,
  theme: null,
  icon: null,
  onClick: null
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.any,
  onMouseDown: PropTypes.func,
  onClick: PropTypes.func
};

export default Button;
