import {connect} from 'react-redux';
import {loadCatalogs} from '../../../modules/persona';
import List from '../components/List';

const mapDispatchToProps = {
  loadCatalogs
};

const mapStateToProps = (state) => ({
  isExternalUser: state.auth.profile.isExternalUser,
  isAdmin: state.auth.profile.isAdmin,
  catalogs: state.persona.catalogs
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
