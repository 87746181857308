import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage from '../../../../components/LocalizedMessage';
import Dropdown from '../../../../components/Dropdown';
import classes from './Graph.module.scss';

const GRAPH_CONTROL_VIEW_POSITIONS = [5, 10, 20, 30];

class Control extends Component {
  static propTypes = {
    currentPos: PropTypes.number.isRequired,
    viewPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onChangePos: PropTypes.func.isRequired
  };

  _dropdown = null;

  UNSAFE_componentWillReceiveProps (newProps) {
    if (
      newProps.viewPos !== this.props.viewPos &&
      this._dropdown
    ) {
      this._dropdown.setValue(newProps.viewPos);
    }
  }

  setDropdownRef = ref => {
    this._dropdown = ref;
  };

  onChangePos = (value) => {
    const {currentPos, maxPos, onChangePos} = this.props;

    const positions = {
      viewPos: value
    };

    if (maxPos - currentPos < value) {
      positions.currentPos = maxPos - value;
    }

    onChangePos(positions);
  };

  render () {
    const {currentPos, viewPos, maxPos} = this.props;

    const viewPositionList = GRAPH_CONTROL_VIEW_POSITIONS.filter(position => position <= maxPos);

    if (
      viewPositionList[viewPositionList.length - 1] < maxPos &&
      GRAPH_CONTROL_VIEW_POSITIONS[GRAPH_CONTROL_VIEW_POSITIONS.length - 1] > maxPos
    ) {
      viewPositionList.push(maxPos);
    }

    return (
      <div className={classes.Control}>
        <div className={classes.ControlColumn}>
          <span className={classes.ControlLabel}>
            <LocalizedMessage
              id='persona.table.graph.control.currentPosition'
            />:
          </span>
          <span className={classes.ControlValue}>
            {currentPos + 1} - {viewPos + currentPos} / {maxPos}
          </span>
        </div>
        {
          GRAPH_CONTROL_VIEW_POSITIONS[0] <= maxPos ?
            <div className={classes.ControlColumn}>
              <span className={classes.ControlLabel}>
                <LocalizedMessage
                  id='persona.table.graph.control.showRecords'
                />:
              </span>
              <Dropdown
                ref={this.setDropdownRef}
                className={classes.ControlDropdown}
                openedClassName={classes.ControlDropdownOpened}
                currentValueClassName={classes.ControlDropdownValue}
                currentValueArrowClassName={classes.ControlDropdownArrow}
                optionClassName={classes.ControlDropdownOption}
                options={viewPositionList.map(value => ({
                  label: value,
                  value: value
                }))}
                defaultValue={viewPos}
                onChange={this.onChangePos}
              />
            </div>
            : null
        }
      </div>
    );
  }
}

export default Control;
