import React from 'react';
import {render} from 'react-dom';
import {Router} from 'react-router';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import store from './store';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

export const history = createBrowserHistory();

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
