import shortid from 'shortid';
import {isJSON, arrayToObject} from '../../helpers/utils';
import {fetchResponse} from './helpers/fakeFetch';
import settingsOlap from '../../mocks/settings-olap.json';

const settingsOlapIdMap = arrayToObject(settingsOlap, 'id');

const formulaAPI = {
  getNumberOfTaStatistics (formula, eventBetweenStart, eventBetweenEnd, eventAverageDay, initController) {
    console.log(
      'api.formula.getNumberOfTaStatistics(',
      formula,
      eventBetweenStart.format('DD.MM.YYYY'),
      eventBetweenEnd.format('DD.MM.YYYY'),
      eventAverageDay.format('DD.MM.YYYY'),
      ')'
    );

    return fetchResponse(5000, Math.round(Math.random() * 10000), initController);
  },

  insert (catalog, title, formula, accessRights, memberLists) {
    console.log('api.formula.insert(', catalog, title, formula, accessRights, memberLists, ')');

    const formulas = localStorage.getItem('formulas');
    const currentFormulas =
      !formulas || !isJSON(formulas)
        ? {}
        : JSON.parse(formulas) || {};

    const record = {
      id: shortid.generate(),
      title,
      formula,
      ...accessRights
    };

    const catalogKey = settingsOlapIdMap[catalog].url;

    localStorage.setItem('formulas', JSON.stringify({
      ...currentFormulas,
      [catalogKey]: [
        ...currentFormulas[catalogKey] || [],
        record
      ]
    }));

    return fetchResponse(1000, record);
  },

  update (catalog, id, title, formula, accessRights, memberLists) {
    console.log('api.formula.update(', catalog, id, title, formula, accessRights, memberLists, ')');

    const formulas = localStorage.getItem('formulas');
    const currentFormulas =
      !formulas || !isJSON(formulas)
        ? {}
        : JSON.parse(formulas) || {};

    const record = {
      id,
      title,
      formula
    };

    const catalogKey = settingsOlapIdMap[catalog].url;

    localStorage.setItem('formulas', JSON.stringify({
      ...currentFormulas,
      [catalogKey]: (currentFormulas[catalogKey] || []).map(cFormula => (
        cFormula.id === id ? record : cFormula
      ))
    }));

    return fetchResponse(1000, record);
  },

  delete (catalog, id) {
    console.log('api.formula.delete(', catalog, id, ')');

    const formulas = localStorage.getItem('formulas');
    const currentFormulas =
      !formulas || !isJSON(formulas)
        ? {}
        : JSON.parse(formulas) || {};

    const catalogKey = settingsOlapIdMap[catalog].url;

    const currentCatalogFormulas =
      currentFormulas[catalogKey]
        ? currentFormulas[catalogKey].filter(cFormula => cFormula.id !== id)
        : [];

    localStorage.setItem('formulas', JSON.stringify({
      ...currentFormulas,
      [catalogKey]: currentCatalogFormulas
    }));

    return fetchResponse(1000, {
      status: true
    });
  }
};

export default formulaAPI;
