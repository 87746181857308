import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import LocalizedMessage from '../../../../../../components/LocalizedMessage';
import DropPanel from '../../../../../../components/DropPanel';
import DatePicker from '../../../../../../components/DatePicker';
import {generateDateString} from '../../../../../../helpers/date';
import classes from '../FormulaEditor.module.scss';

class Calendar extends Component {
  static propTypes = {
    hasDate: PropTypes.bool.isRequired,
    dateRangeStart: moment.isMoment,
    dateRangeEnd: moment.isMoment,
    minDate: moment.isMoment,
    maxDate: moment.isMoment,
    setDateRange: PropTypes.func.isRequired
  };

  _container = null;

  setContainerRef = ref => {
    this._container = ref;
  };

  setDateRange = (type, date) => {
    const {
      dateRangeStart,
      dateRangeEnd,
      setDateRange
    } = this.props;

    const resultDateRange = {
      start: dateRangeStart,
      end: dateRangeEnd
    };

    resultDateRange[type] = date;

    if (type === 'start' && dateRangeEnd.isBefore(date)) {
      resultDateRange.end = date.clone();
    } else if (type === 'end' && date.isBefore(dateRangeStart)) {
      resultDateRange.start = date.clone();
    }

    setDateRange(resultDateRange);
  };

  render () {
    const {hasDate, dateRangeStart, dateRangeEnd, minDate, maxDate} = this.props;

    return (
      <div
        ref={this.setContainerRef}
        className={classes.FormulaItemCalendarContainer}
      >
        <DropPanel
          className={cx(
            classes.FormulaItemCalendar,
            {
              [classes.FormulaItemCalendarNotEmpty]: hasDate
            }
          )}
          openedClassName={classes.FormulaItemCalendarOpened}
          titleClassName={classes.FormulaItemCalendarTitle}
          contentClassName={classes.FormulaItemCalendarContent}
          title={
            <span
              className={cx(
                classes.FormulaItemCalendarIcon,
                'icon-calendar'
              )}
              title={
                hasDate ?
                  `${generateDateString(dateRangeStart)}-${generateDateString(dateRangeEnd)}`
                  : null
              }
            />
          }
          withArrow={false}
          contentPosition='right'
          fixedContentPosition
        >
          <div className={classes.FormulaItemCalendarRow}>
            <p className={classes.FormulaItemCalendarLabel}>
              <LocalizedMessage
                id='persona.table.headerRow.dates.startDate'
              />
            </p>
            <div
              className={classes.FormulaItemCalendarDatepicker}
            >
              <DatePicker
                selected={dateRangeStart}
                selectsStart
                startDate={dateRangeStart}
                endDate={dateRangeEnd}
                minDate={minDate}
                maxDate={maxDate}
                onChange={value => this.setDateRange('start', value)}
              />
            </div>
          </div>
          <div className={classes.FormulaItemCalendarRow}>
            <p className={classes.FormulaItemCalendarLabel}>
              <LocalizedMessage
                id='persona.table.headerRow.dates.endDate'
              />
            </p>
            <div
              className={classes.FormulaItemCalendarDatepicker}
            >
              <DatePicker
                selected={dateRangeEnd}
                selectsEnd
                startDate={dateRangeStart}
                endDate={dateRangeEnd}
                minDate={minDate}
                maxDate={maxDate}
                onChange={value => this.setDateRange('end', value)}
              />
            </div>
          </div>
        </DropPanel>
      </div>
    );
  }
}

export default Calendar;
