import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import LocalizedMessage from '../../../components/LocalizedMessage';
import LocaleSwitcher from '../../../components/LocaleSwitcher';
import classes from '../Header.module.scss';

const Account = ({profile, logOut}) => {
  const name = profile.firstName || profile.lastName ? `${profile.firstName} ${profile.lastName}` : profile.fullName;

  return (
    <div className={classes.Account}>
      <Link
        to='/profile'
        className={cx(
          classes.AccountLink,
          classes.AccountLinkProfile,
          'icon-account'
        )}
      >
        <span
          className={classes.AccountText}
          title={name}
        >
          {name}
        </span>
      </Link>
      -
      <span
        className={classes.AccountLink}
        onClick={logOut}
      >
        <span
          className={classes.AccountText}
        >
          <LocalizedMessage
            id='base.logout'
          />
        </span>
      </span>
      <LocaleSwitcher
        className={classes.AccountDropdown}
      />
    </div>
  );
};

Account.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired
  }),
  logOut: PropTypes.func.isRequired
};

export default Account;
