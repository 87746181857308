import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {loadCatalogs} from '../../../modules/persona';
import Edit from '../components/Edit';

const mapDispatchToProps = {
  loadCatalogs
};

const mapStateToProps = (state) => ({
  catalogs: state.persona.catalogs,
  isExternalUser: state.auth.profile.isExternalUser,
  isAdmin: state.auth.profile && state.auth.profile.isAdmin
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Edit));
