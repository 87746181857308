import Hammer from 'hammerjs';
import Chart from 'chart.js';

const helpers = Chart.helpers;

// Take the zoom namespace of Chart
const zoomNS = Chart.Zoom = Chart.Zoom || {};

// Where we modules functions to handle different scale types
const zoomFunctions = zoomNS.zoomFunctions = zoomNS.zoomFunctions || {};
const panFunctions = zoomNS.panFunctions = zoomNS.panFunctions || {};

// Default options if none are provided
const defaultOptions = zoomNS.defaults = {
  pan: {
    enabled: true,
    mode: 'xy',
    speed: 20,
    threshold: 10
  },
  zoom: {
    enabled: true,
    mode: 'xy',
    sensitivity: 3
  }
};

function directionEnabled (mode, dir) {
  if (mode === undefined) {
    return true;
  } else if (typeof mode === 'string') {
    return mode.indexOf(dir) !== -1;
  }

  return false;
}

function rangeMaxLimiter (zoomPanOptions, newMax) {
  if (zoomPanOptions.scaleAxes && zoomPanOptions.rangeMax &&
    !helpers.isNullOrUndef(zoomPanOptions.rangeMax[zoomPanOptions.scaleAxes])) {
    const rangeMax = zoomPanOptions.rangeMax[zoomPanOptions.scaleAxes];
    if (newMax > rangeMax) {
      newMax = rangeMax;
    }
  }

  return newMax;
}

function rangeMinLimiter (zoomPanOptions, newMin) {
  if (zoomPanOptions.scaleAxes && zoomPanOptions.rangeMin &&
    !helpers.isNullOrUndef(zoomPanOptions.rangeMin[zoomPanOptions.scaleAxes])) {
    const rangeMin = zoomPanOptions.rangeMin[zoomPanOptions.scaleAxes];
    if (newMin < rangeMin) {
      newMin = rangeMin;
    }
  }

  return newMin;
}

function zoomIndexScale (scale, zoom, center, zoomOptions) {
  const labels = scale.chart.data.labels;
  let minIndex = scale.minIndex;
  const lastLabelIndex = labels.length - 1;
  let maxIndex = scale.maxIndex;
  const sensitivity = zoomOptions.sensitivity;
  const chartCenter = scale.isHorizontal() ? scale.left + (scale.width / 2) : scale.top + (scale.height / 2);
  const centerPointer = scale.isHorizontal() ? center.x : center.y;

  zoomNS.zoomCumulativeDelta = zoom > 1 ? zoomNS.zoomCumulativeDelta + 1 : zoomNS.zoomCumulativeDelta - 1;

  if (Math.abs(zoomNS.zoomCumulativeDelta) > sensitivity) {
    if (zoomNS.zoomCumulativeDelta < 0) {
      if (centerPointer >= chartCenter) {
        if (minIndex <= 0) {
          maxIndex = Math.min(lastLabelIndex, maxIndex + 1);
        } else {
          minIndex = Math.max(0, minIndex - 1);
        }
      } else if (centerPointer < chartCenter) {
        if (maxIndex >= lastLabelIndex) {
          minIndex = Math.max(0, minIndex - 1);
        } else {
          maxIndex = Math.min(lastLabelIndex, maxIndex + 1);
        }
      }
      zoomNS.zoomCumulativeDelta = 0;
    } else if (zoomNS.zoomCumulativeDelta > 0) {
      if (centerPointer >= chartCenter) {
        minIndex = minIndex < maxIndex ? Math.min(maxIndex, minIndex + 1) : minIndex;
      } else if (centerPointer < chartCenter) {
        maxIndex = maxIndex > minIndex ? Math.max(minIndex, maxIndex - 1) : maxIndex;
      }
      zoomNS.zoomCumulativeDelta = 0;
    }

    const currentRangeIndex = maxIndex - minIndex;

    if (
      zoomOptions.scaleAxes &&
      zoomOptions.limitMin &&
      !helpers.isNullOrUndef(zoomOptions.limitMin[zoomOptions.scaleAxes]) &&
      currentRangeIndex < zoomOptions.limitMin[zoomOptions.scaleAxes] - 1
    ) {
      return;
    }

    if (
      zoomOptions.scaleAxes &&
      zoomOptions.limitMax &&
      !helpers.isNullOrUndef(zoomOptions.limitMax[zoomOptions.scaleAxes]) &&
      currentRangeIndex > zoomOptions.limitMax[zoomOptions.scaleAxes] - 1
    ) {
      return;
    }

    if (typeof zoomOptions.onChange === 'function') {
      zoomOptions.onChange(minIndex, maxIndex);
    }

    scale.options.ticks.min = rangeMinLimiter(zoomOptions, labels[minIndex]);
    scale.options.ticks.max = rangeMaxLimiter(zoomOptions, labels[maxIndex]);
  }
}

function zoomTimeScale (scale, zoom, center, zoomOptions) {
  const options = scale.options;

  let range;
  let minPercent;
  if (scale.isHorizontal()) {
    range = scale.right - scale.left;
    minPercent = (center.x - scale.left) / range;
  } else {
    range = scale.bottom - scale.top;
    minPercent = (center.y - scale.top) / range;
  }

  const maxPercent = 1 - minPercent;
  const newDiff = range * (zoom - 1);

  const minDelta = newDiff * minPercent;
  const maxDelta = newDiff * maxPercent;

  const newMin = scale.getValueForPixel(scale.getPixelForValue(scale.min) + minDelta);
  const newMax = scale.getValueForPixel(scale.getPixelForValue(scale.max) - maxDelta);

  const diffMinMax = newMax.diff(newMin);
  const minLimitExceeded = rangeMinLimiter(zoomOptions, diffMinMax) !== diffMinMax;
  const maxLimitExceeded = rangeMaxLimiter(zoomOptions, diffMinMax) !== diffMinMax;

  if (!minLimitExceeded && !maxLimitExceeded) {
    options.time.min = newMin;
    options.time.max = newMax;
  }
}

function zoomNumericalScale (scale, zoom, center, zoomOptions) {
  const range = scale.max - scale.min;
  const newDiff = range * (zoom - 1);

  const cursorPixel = scale.isHorizontal() ? center.x : center.y;
  const minPercent = (scale.getValueForPixel(cursorPixel) - scale.min) / range;
  const maxPercent = 1 - minPercent;

  const minDelta = newDiff * minPercent;
  const maxDelta = newDiff * maxPercent;

  scale.options.ticks.min = rangeMinLimiter(zoomOptions, scale.min + minDelta);
  scale.options.ticks.max = rangeMaxLimiter(zoomOptions, scale.max - maxDelta);
}

function zoomScale (scale, zoom, center, zoomOptions) {
  const fn = zoomFunctions[scale.options.type];
  if (fn) {
    fn(scale, zoom, center, zoomOptions);
  }
}

function doZoom (chartInstance, zoom, center, whichAxes) {
  const ca = chartInstance.chartArea;
  if (!center) {
    center = {
      x: (ca.left + ca.right) / 2,
      y: (ca.top + ca.bottom) / 2
    };
  }

  const zoomOptions = chartInstance.options.zoom;

  if (zoomOptions && helpers.getValueOrDefault(zoomOptions.enabled, defaultOptions.zoom.enabled)) {
    // Do the zoom here
    const zoomMode = helpers.getValueOrDefault(chartInstance.options.zoom.mode, defaultOptions.zoom.mode);
    zoomOptions.sensitivity =
      helpers.getValueOrDefault(chartInstance.options.zoom.sensitivity, defaultOptions.zoom.sensitivity);

    // Which axe should be modified when figers were used.
    let _whichAxes;
    if (zoomMode === 'xy' && whichAxes !== undefined) {
      // based on fingers positions
      _whichAxes = whichAxes;
    } else {
      // no effect
      _whichAxes = 'xy';
    }

    helpers.each(chartInstance.scales, function (scale, id) {
      if (scale.isHorizontal() && directionEnabled(zoomMode, 'x') && directionEnabled(_whichAxes, 'x')) {
        zoomOptions.scaleAxes = 'x';
        zoomScale(scale, zoom, center, zoomOptions);
      } else if (!scale.isHorizontal() && directionEnabled(zoomMode, 'y') && directionEnabled(_whichAxes, 'y')) {
        // Do Y zoom
        zoomOptions.scaleAxes = 'y';
        zoomScale(scale, zoom, center, zoomOptions);
      }
    });

    chartInstance.update(0);
  }
}

function panIndexScale (scale, delta, panOptions) {
  const labels = scale.chart.data.labels;
  const lastLabelIndex = labels.length - 1;
  const offsetAmt = Math.max(scale.ticks.length, 1);
  const panSpeed = panOptions.speed;
  let minIndex = scale.minIndex;
  const step = Math.round(scale.width / (offsetAmt * panSpeed));

  zoomNS.panCumulativeDelta += delta;

  minIndex = zoomNS.panCumulativeDelta > step
    ? Math.max(0, minIndex - 1)
    : zoomNS.panCumulativeDelta < -step
      ? Math.min(lastLabelIndex - offsetAmt + 1, minIndex + 1)
      : minIndex;
  zoomNS.panCumulativeDelta = minIndex !== scale.minIndex ? 0 : zoomNS.panCumulativeDelta;

  const maxIndex = Math.min(lastLabelIndex, minIndex + offsetAmt - 1);

  if (typeof panOptions.onChange === 'function') {
    panOptions.onChange(minIndex, maxIndex);
  }

  scale.options.ticks.min = rangeMinLimiter(panOptions, labels[minIndex]);
  scale.options.ticks.max = rangeMaxLimiter(panOptions, labels[maxIndex]);
}

function panTimeScale (scale, delta, panOptions) {
  const options = scale.options;
  const limitedMax = rangeMaxLimiter(panOptions, scale.getValueForPixel(scale.getPixelForValue(scale.max) - delta));
  const limitedMin = rangeMinLimiter(panOptions, scale.getValueForPixel(scale.getPixelForValue(scale.min) - delta));

  const limitedTimeDelta = delta < 0 ? limitedMax - scale.max : limitedMin - scale.min;

  options.time.max = scale.max + limitedTimeDelta;
  options.time.min = scale.min + limitedTimeDelta;
}

function panNumericalScale (scale, delta, panOptions) {
  const tickOpts = scale.options.ticks;
  const start = scale.start;
  const end = scale.end;

  if (tickOpts.reverse) {
    tickOpts.max = scale.getValueForPixel(scale.getPixelForValue(start) - delta);
    tickOpts.min = scale.getValueForPixel(scale.getPixelForValue(end) - delta);
  } else {
    tickOpts.min = scale.getValueForPixel(scale.getPixelForValue(start) - delta);
    tickOpts.max = scale.getValueForPixel(scale.getPixelForValue(end) - delta);
  }
  tickOpts.min = rangeMinLimiter(panOptions, tickOpts.min);
  tickOpts.max = rangeMaxLimiter(panOptions, tickOpts.max);
}

function panScale (scale, delta, panOptions) {
  const fn = panFunctions[scale.options.type];

  if (fn) {
    fn(scale, delta, panOptions);
  }
}

function doPan (chartInstance, deltaX, deltaY) {
  const panOptions = chartInstance.options.pan;
  if (panOptions && helpers.getValueOrDefault(panOptions.enabled, defaultOptions.pan.enabled)) {
    const panMode = helpers.getValueOrDefault(chartInstance.options.pan.mode, defaultOptions.pan.mode);
    panOptions.speed = helpers.getValueOrDefault(chartInstance.options.pan.speed, defaultOptions.pan.speed);

    helpers.each(chartInstance.scales, function (scale, id) {
      if (scale.isHorizontal() && directionEnabled(panMode, 'x') && deltaX !== 0) {
        panOptions.scaleAxes = 'x';
        panScale(scale, deltaX, panOptions);
      } else if (!scale.isHorizontal() && directionEnabled(panMode, 'y') && deltaY !== 0) {
        panOptions.scaleAxes = 'y';
        panScale(scale, deltaY, panOptions);
      }
    });

    chartInstance.update(0);
  }
}

function getYAxis (chartInstance) {
  const scales = chartInstance.scales;

  for (const scaleId in scales) {
    if (Object.prototype.hasOwnProperty.call(scales, scaleId)) {
      const scale = scales[scaleId];

      if (!scale.isHorizontal()) {
        return scale;
      }
    }
  }
}

// Store these for later
zoomNS.zoomFunctions.category = zoomIndexScale;
zoomNS.zoomFunctions.time = zoomTimeScale;
zoomNS.zoomFunctions.linear = zoomNumericalScale;
zoomNS.zoomFunctions.logarithmic = zoomNumericalScale;
zoomNS.panFunctions.category = panIndexScale;
zoomNS.panFunctions.time = panTimeScale;
zoomNS.panFunctions.linear = panNumericalScale;
zoomNS.panFunctions.logarithmic = panNumericalScale;
// Globals for catergory pan and zoom
zoomNS.panCumulativeDelta = 0;
zoomNS.zoomCumulativeDelta = 0;

// Chartjs Zoom Plugin
const zoomPlugin = {
  afterInit: function (chartInstance) {
    helpers.each(chartInstance.scales, function (scale) {
      scale.originalOptions = JSON.parse(JSON.stringify(scale.options));
    });

    chartInstance.resetZoom = function () {
      helpers.each(chartInstance.scales, function (scale, id) {
        const timeOptions = scale.options.time;
        const tickOptions = scale.options.ticks;

        if (timeOptions) {
          delete timeOptions.min;
          delete timeOptions.max;
        }

        if (tickOptions) {
          delete tickOptions.min;
          delete tickOptions.max;
        }

        scale.options = helpers.configMerge(scale.options, scale.originalOptions);
      });

      helpers.each(chartInstance.data.datasets, function (dataset, id) {
        dataset._meta = null;
      });

      chartInstance.update();
    };
  },

  beforeInit: function (chartInstance) {
    chartInstance.zoom = {};

    const node = chartInstance.zoom.node = chartInstance.chart.ctx.canvas;

    const options = chartInstance.options;
    const panThreshold =
      helpers.getValueOrDefault(options.pan ? options.pan.threshold : undefined, zoomNS.defaults.pan.threshold);

    if (options.zoom && options.zoom.enabled) {
      if (options.zoom && options.zoom.drag) {
        // Only want to zoom horizontal axis
        options.zoom.mode = 'x';

        chartInstance.zoom._mouseDownHandler = function (event) {
          chartInstance.zoom._dragZoomStart = event;
        };
        node.addEventListener('mousedown', chartInstance.zoom._mouseDownHandler);

        chartInstance.zoom._mouseMoveHandler = function (event) {
          if (chartInstance.zoom._dragZoomStart) {
            chartInstance.zoom._dragZoomEnd = event;
            chartInstance.update(0);
          }

          chartInstance.update(0);
        };
        node.addEventListener('mousemove', chartInstance.zoom._mouseMoveHandler);

        chartInstance.zoom._mouseUpHandler = function (event) {
          if (chartInstance.zoom._dragZoomStart) {
            const chartArea = chartInstance.chartArea;
            const yAxis = getYAxis(chartInstance);
            const beginPoint = chartInstance.zoom._dragZoomStart;
            const offsetX = beginPoint.target.getBoundingClientRect().left;
            const startX = Math.min(beginPoint.clientX, event.clientX) - offsetX;
            const endX = Math.max(beginPoint.clientX, event.clientX) - offsetX;
            const dragDistance = endX - startX;
            const chartDistance = chartArea.right - chartArea.left;
            const zoom = 1 + ((chartDistance - dragDistance) / chartDistance);

            if (dragDistance > 0) {
              doZoom(chartInstance, zoom, {
                x: (dragDistance / 2) + startX,
                y: (yAxis.bottom - yAxis.top) / 2
              });
            }

            chartInstance.zoom._dragZoomStart = null;
            chartInstance.zoom._dragZoomEnd = null;
          }
        };
        node.addEventListener('mouseup', chartInstance.zoom._mouseUpHandler);
      } else {
        chartInstance.zoom._wheelHandler = function (event) {
          const rect = event.target.getBoundingClientRect();
          const offsetX = event.clientX - rect.left;
          const offsetY = event.clientY - rect.top;

          const center = {
            x: offsetX,
            y: offsetY
          };

          if (event.deltaY < 0) {
            doZoom(chartInstance, 1.1, center);
          } else {
            doZoom(chartInstance, 0.909, center);
          }
          // Prevent the event from triggering the default behavior (eg. Content scrolling).
          event.preventDefault();
        };

        node.addEventListener('wheel', chartInstance.zoom._wheelHandler);
      }
    }

    if (Hammer) {
      const mc = new Hammer.Manager(node);
      mc.add(new Hammer.Pinch());
      mc.add(new Hammer.Pan({
        threshold: panThreshold
      }));

      // Hammer reports the total scaling. We need the incremental amount
      let currentPinchScaling;
      const handlePinch = function handlePinch (e) {
        const diff = 1 / (currentPinchScaling) * e.scale;
        const rect = e.target.getBoundingClientRect();
        const offsetX = e.center.x - rect.left;
        const offsetY = e.center.y - rect.top;
        const center = {
          x: offsetX,
          y: offsetY
        };

        // fingers position difference
        const x = Math.abs(e.pointers[0].clientX - e.pointers[1].clientX);
        const y = Math.abs(e.pointers[0].clientY - e.pointers[1].clientY);

        // diagonal fingers will change both (xy) axes
        const p = x / y;
        let xy;
        if (p > 0.3 && p < 1.7) {
          xy = 'xy';
        } else if (x > y) {  // x axis
          xy = 'x';
        } else {  // y axis
          xy = 'y';
        }

        doZoom(chartInstance, diff, center, xy);

        // Keep track of overall scale
        currentPinchScaling = e.scale;
      };

      mc.on('pinchstart', function (e) {
        currentPinchScaling = 1; // reset tracker
      });
      mc.on('pinch', handlePinch);
      mc.on('pinchend', function (e) {
        handlePinch(e);
        currentPinchScaling = null; // reset
        zoomNS.zoomCumulativeDelta = 0;
      });

      let currentDeltaX = null;
      let currentDeltaY = null;
      let panning = false;
      const handlePan = function handlePan (e) {
        if (currentDeltaX !== null && currentDeltaY !== null) {
          panning = true;
          const deltaX = e.deltaX - currentDeltaX;
          const deltaY = e.deltaY - currentDeltaY;
          currentDeltaX = e.deltaX;
          currentDeltaY = e.deltaY;
          doPan(chartInstance, deltaX, deltaY);
        }
      };

      mc.on('panstart', function (e) {
        currentDeltaX = 0;
        currentDeltaY = 0;
        handlePan(e);
      });
      mc.on('panmove', handlePan);
      mc.on('panend', function (e) {
        currentDeltaX = null;
        currentDeltaY = null;
        zoomNS.panCumulativeDelta = 0;
        setTimeout(function () {
          panning = false;
        }, 500);
      });

      chartInstance.zoom._ghostClickHandler = function (e) {
        if (panning) {
          e.stopImmediatePropagation();
          e.preventDefault();
        }
      };
      node.addEventListener('click', chartInstance.zoom._ghostClickHandler);

      chartInstance._mc = mc;
    }
  },

  beforeDatasetsDraw: function (chartInstance) {
    const ctx = chartInstance.chart.ctx;
    const chartArea = chartInstance.chartArea;
    ctx.save();
    ctx.beginPath();

    if (chartInstance.zoom._dragZoomEnd) {
      const yAxis = getYAxis(chartInstance);
      const beginPoint = chartInstance.zoom._dragZoomStart;
      const endPoint = chartInstance.zoom._dragZoomEnd;
      const offsetX = beginPoint.target.getBoundingClientRect().left;
      const startX = Math.min(beginPoint.clientX, endPoint.clientX) - offsetX;
      const endX = Math.max(beginPoint.clientX, endPoint.clientX) - offsetX;
      const rectWidth = endX - startX;

      ctx.fillStyle = 'rgba(225,225,225,0.3)';
      ctx.lineWidth = 5;
      ctx.fillRect(startX, yAxis.top, rectWidth, yAxis.bottom - yAxis.top);
    }

    ctx.rect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
    ctx.clip();
  },

  afterDatasetsDraw: function (chartInstance) {
    chartInstance.chart.ctx.restore();
  },

  destroy: function (chartInstance) {
    if (chartInstance.zoom) {
      const options = chartInstance.options;
      const node = chartInstance.zoom.node;

      if (options.zoom && options.zoom.drag) {
        node.removeEventListener('mousedown', chartInstance.zoom._mouseDownHandler);
        node.removeEventListener('mousemove', chartInstance.zoom._mouseMoveHandler);
        node.removeEventListener('mouseup', chartInstance.zoom._mouseUpHandler);
      } else {
        node.removeEventListener('wheel', chartInstance.zoom._wheelHandler);
      }

      if (Hammer) {
        node.removeEventListener('click', chartInstance.zoom._ghostClickHandler);
      }

      delete chartInstance.zoom;

      const mc = chartInstance._mc;
      if (mc) {
        mc.remove('pinchstart');
        mc.remove('pinch');
        mc.remove('pinchend');
        mc.remove('panstart');
        mc.remove('pan');
        mc.remove('panend');
      }
    }
  }
};

Chart.pluginService.register(zoomPlugin);

export default zoomPlugin;
