import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import LocalizedMessage from '../../../components/LocalizedMessage';
import Scrollbar from '../../../components/Scrollbar';
import classes from './NotFound.module.scss';

class NotFound extends Component {
  render () {
    return (
      <div>
        <LocalizedMessage
          id='site.title.notFound'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <Scrollbar className={classes.Container}>
          <div className={classes.Round}>
            <p className={classes.Description}>
              <LocalizedMessage
                id='persona.404.notYourPage'
              />
            </p>
            <h1 className={classes.Title}>
              404
            </h1>
            <h2 className={classes.SubTitle}>
              <LocalizedMessage
                id='persona.404.pageNotFound'
              />
            </h2>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default NotFound;
