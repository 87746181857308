import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import Loader from '../Loader';

class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    isAuthorizing: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    checkAuth: PropTypes.func.isRequired
  };

  componentDidMount () {
    const {isAuthorizing, isAuthorized} = this.props;

    this.checkAuth(isAuthorizing, isAuthorized);
  }

  UNSAFE_componentWillReceiveProps ({isAuthorizing, isAuthorized}) {
    this.checkAuth(isAuthorizing, isAuthorized);
  }

  checkAuth = (isAuthorizing, isAuthorized) => {
    const {checkAuth} = this.props;

    if (isAuthorizing || isAuthorized) {
      return;
    }

    checkAuth();
  };

  render () {
    const {component: Component, isAuthorizing, isAuthorized, ...rest} = this.props;

    if (
      isAuthorizing ||
      (
        !isAuthorized &&
        typeof localStorage['access_token'] === 'string' &&
        localStorage['access_token'].length
      )
    ) {
      return (
        <Loader />
      );
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthorized
            ? (
              <Component
                {...props}
              />
            )
            : (
              <Redirect
                to={{
                  pathname: '/auth',
                  search: props.location.search,
                  state: {
                    from: props.location
                  }
                }}
              />
            )
        }
      />
    );
  }
}

export default PrivateRoute;
