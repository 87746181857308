import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DragSource} from 'react-dnd';
import classes from './AdditionalDndPanel.module.scss';

class AdditionalDndItem extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]).isRequired,
    type: PropTypes.oneOf(['tv_index', 'romir']).isRequired,
    openMemberListModal: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired
  };

  addItem = () => {
    const {openMemberListModal, type} = this.props;

    openMemberListModal({type}, {});
  };

  render () {
    const {children, connectDragSource} = this.props;

    return connectDragSource(
      <li
        className={classes.Item}
        onClick={this.addItem}
      >
        {children}
      </li>
    );
  }
}

export default DragSource('FORMULA_ITEM', {
  isDragging (props, monitor) {
    return monitor.getItem().type === props.type;
  },

  beginDrag (props) {
    return {
      type: props.type
    };
  },

  endDrag (props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    props.openMemberListModal(item, dropResult);
  }
}, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(AdditionalDndItem);
