import Client from './HTTPClient';

const cubesAPI = {
  getMatricesByQueueId (catalog, id, axis) {
    return Client.get(`/olap/${catalog}/cube/matrices/${id}/${axis}`, {}, true, true, false);
  },

  downloadByUuid (catalog, uuid, axis) {
    return Client.get(`/olap/${catalog}/cube/excel/${uuid}/${axis}`);
  },

  send (catalog, payload) {
    return Client.post(`/olap/${catalog}/cube`, payload, true, true, false);
  },

  getList (catalog, accessType, advertiserId) {
    let url = `/olap/${catalog}/templates/`;

    if (accessType === 'advertiser') {
      url += `a/${advertiserId}`;
    }

    if (accessType === 'my') {
      url += 'user';
    }

    return Client.get(url, {}, true, true, false);
  },

  async getTemplateById (catalog, id) {
    const loadedTemplate =
      await Client.get(`/olap/${catalog}/templates/${id}/`, {}, true, true, false);

    return {
      structure: loadedTemplate,
      matrix: null
    };
  },

  save (catalog, cubeId, payload) {
    if (cubeId) {
      return Client.put(`/olap/${catalog}/templates/${cubeId}/`, payload, true, true, false);
    }

    return Client.post(`/olap/${catalog}/templates/`, payload, true, true, false);
  },

  checkTitle (catalog, title, accessRights) {
    return Client.post(`/olap/${catalog}/templates/checkTitle`, {
      title,
      accessRights
    }, true, true, false);
  },

  rename (catalog, cubeId, title) {
    return Client.post(`/olap/${catalog}/templates/${cubeId}/rename`, {title}, true, true, false);
  },

  copy (catalog, cubeId) {
    return Client.post(`/olap/${catalog}/templates/copy`, cubeId, true, true, false);
  },

  delete (catalog, cubeId) {
    return Client.delete(`/olap/${catalog}/templates/${cubeId}`, {}, true, true, false);
  }
};

export default cubesAPI;
