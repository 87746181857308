import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Config from '../../../../../config';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import Dropdown from '../../../../../components/Dropdown';
import DropPanel from '../../../../../components/DropPanel';
import Checkbox from '../../../../../components/Checkbox';
import Tabs from '../../../../../components/Tabs';
import AffinityField from './AffinityField';
import {findStatisticItemIndex} from '../../../helpers/filter';
import classes from '../Content.module.scss';

const {CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_INDEX_ID} = Config.appOptions;

class MeasureSelect extends Component {
  static propTypes = {
    measures: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ).isRequired,
    tableStructure: PropTypes.shape({
      verticalDataTree: PropTypes.array,
      horizontalDataTree: PropTypes.array,
      statisticAxis: PropTypes.oneOf(['rows', 'columns']).isRequired,
      statisticItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          affinity: PropTypes.bool.isRequired,
          nplus: PropTypes.bool.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.string
          )
        })
      ).isRequired,
      statisticRounding: PropTypes.number.isRequired
    }),
    updateStatisticAxis: PropTypes.func.isRequired,
    updateStatisticData: PropTypes.func.isRequired,
    updateStatisticRounding: PropTypes.func.isRequired,
    setStatisticDropPanelRef: PropTypes.func.isRequired
  };

  _axisTabs = null;
  _affinityFields = {};

  setAxisTabsRef = ref => {
    this._axisTabs = ref;
  };

  resetAxis = () => {
    this._axisTabs.reset();
  };

  onChange = ({id, title, key, affinity, nplus}, status) => {
    const {updateStatisticData} = this.props;

    const updatingData = [];

    if (status && [CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_INDEX_ID].includes(id)) {
      const universeMeasure = this.props.measures.find(measure => measure.key === 'UNIVERSE');
      updatingData.push({
        id: universeMeasure.id,
        key: universeMeasure.key,
        title: universeMeasure.title,
        status,
        affinity,
        nplusValues: nplus ? [] : null
      });

      if (id === CUSTOM_MEASURE_INDEX_ID) {
        updatingData.push({
          id: CUSTOM_MEASURE_VERT_ID,
          key: 'VERT',
          title: 'Vert %',
          status,
          affinity,
          nplusValues: nplus ? [] : null
        });
      }
    }

    updatingData.push({
      id,
      key,
      title,
      status,
      affinity,
      nplusValues: nplus ? [] : null
    });

    updateStatisticData(updatingData);

    if (status && nplus) {
      setTimeout(() => {
        this._affinityFields[id].focus();
      });
    }
  };

  onAffinityValuesChange = ({id, key, title, affinity}, nplusValues) => {
    const {updateStatisticData} = this.props;

    updateStatisticData([{
      id,
      key,
      title,
      status: true,
      affinity,
      nplusValues
    }]);
  };

  getMeasures = () => {
    const {measures} = this.props;

    const simpleMeasures = [];
    const rightColumnMeasures = [];

    measures.forEach(measure => {
      if (!measure.nplus) {
        simpleMeasures.push(measure);
      } else {
        rightColumnMeasures.push(measure);
      }
    });

    return {
      measures: simpleMeasures,
      rightColumnMeasures
    };
  };

  render () {
    const {
      tableStructure,
      updateStatisticAxis,
      updateStatisticRounding,
      setStatisticDropPanelRef
    } = this.props;

    const {
      measures,
      rightColumnMeasures
    } = this.getMeasures();

    const isUniverseForceChecked = tableStructure.statisticItems.some(item =>
      [CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_INDEX_ID].includes(item.id)
    );
    const isVertForceChecked = tableStructure.statisticItems.some(item => item.id === CUSTOM_MEASURE_INDEX_ID);

    return (
      <DropPanel
        ref={setStatisticDropPanelRef}
        title={
          <LocalizedMessage
            id='persona.table.headerRow.kpis'
          />
        }
        className={classes.ContentHeaderDropPanel}
        openedClassName={classes.ContentHeaderDropPanelOpened}
        contentClassName={cx(
          classes.ContentHeaderDropPanelWrapper,
          {
            [classes.ContentHeaderDropPanelWrapperMeasure]: rightColumnMeasures.length
          }
        )}
      >
        <div className={classes.ContentHeaderDropPanelContent}>
          <div className={classes.ContentHeaderDropPanelColumn}>
            <div className={classes.ContentHeaderDropPanelRow}>
              <p className={classes.ContentHeaderDropPanelLabel}>
                <LocalizedMessage
                  id='persona.table.headerRow.kpis.location'
                />:
              </p>
              <LocalizedMessage
                id={[
                  'persona.table.headerRow.kpis.location.rows',
                  'persona.table.headerRow.kpis.location.columns'
                ]}
              >
                {(localizedFirstMessage, localizedSecondMessage) => (
                  <Tabs
                    ref={this.setAxisTabsRef}
                    defaultValue={tableStructure.statisticAxis}
                    options={[
                      {
                        label: localizedFirstMessage,
                        value: 'rows'
                      },
                      {
                        label: localizedSecondMessage,
                        value: 'columns'
                      }
                    ]}
                    optionClassName={classes.ContentHeaderDropPanelTabsItem}
                    onChange={updateStatisticAxis}
                  />
                )}
              </LocalizedMessage>
            </div>
            <div className={classes.ContentHeaderDropPanelRow}>
              <ul className={classes.ContentHeaderStatistics}>
                {
                  measures.map(item => (
                    <li
                      key={`${item.key}_${item.settingsOlap}_${!item.affinity ? 'not' : ''}-affinity`}
                      className={classes.ContentHeaderStatisticsItem}
                    >
                      <Checkbox
                        name={`${item.key}_${item.settingsOlap}_${!item.affinity ? '-not' : ''}-affinity`}
                        checked={findStatisticItemIndex(tableStructure.statisticItems, {
                          id: item.id,
                          affinity: item.affinity
                        }) > -1}
                        disabled={
                          (isUniverseForceChecked && item.key === 'UNIVERSE') ||
                          (isVertForceChecked && item.key === 'VERT')
                        }
                        onChange={
                          (name, status) => this.onChange(item, status)
                        }
                      >
                        {item.title}
                      </Checkbox>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          {
            rightColumnMeasures.length ?
              <div
                className={cx(
                  classes.ContentHeaderDropPanelColumn,
                  classes.ContentHeaderDropPanelColumnAffinity
                )}
              >
                <div className={classes.ContentHeaderDropPanelRow}>
                  <ul className={classes.ContentHeaderStatistics}>
                    {
                      rightColumnMeasures.map(item => {
                        const currentStatisticItemIndex = findStatisticItemIndex(tableStructure.statisticItems, {
                          id: item.id,
                          affinity: item.affinity
                        });
                        const checked = currentStatisticItemIndex > -1;
                        const withNPlus = checked && item.nplus;

                        return (
                          <li
                            key={`${item.key}_${item.settingsOlap}_${!item.affinity ? 'not' : ''}-affinity`}
                            className={cx(
                              classes.ContentHeaderStatisticsItem,
                              classes.ContentHeaderStatisticsItemAffinity
                            )}
                          >
                            <Checkbox
                              name={`${item.key}_${item.settingsOlap}_${!item.affinity ? '-not' : ''}-affinity`}
                              checked={checked}
                              className={classes.ContentHeaderStatisticsAffinityCheckbox}
                              onChange={
                                (name, status) => this.onChange(item, status)
                              }
                            >
                              {item.title}
                              {
                                withNPlus
                                  ? <LocalizedMessage
                                    id='persona.table.headerRow.kpis.withFrequency'
                                  >
                                    {
                                      withFrequency => ` ${withFrequency}:`
                                    }
                                  </LocalizedMessage>
                                  : null
                              }
                            </Checkbox>
                            {
                              withNPlus ?
                                <AffinityField
                                  ref={ref => {
                                    this._affinityFields[item.id] = ref;
                                  }}
                                  data={tableStructure.statisticItems[currentStatisticItemIndex]}
                                  onChange={
                                    values => this.onAffinityValuesChange(item, values)
                                  }
                                />
                                : null
                            }
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
              : null
          }
        </div>
        <div className={classes.ContentHeaderDropPanelFooter}>
          <div className={classes.ContentHeaderDropPanelFooterRounding}>
            <LocalizedMessage
              id='persona.table.headerRow.kpis.rounding.start'
            />
            <Dropdown
              className={classes.ContentHeaderDropPanelFooterRoundingDropdown}
              currentValueClassName={classes.ContentHeaderDropPanelFooterRoundingDropdownValue}
              currentValueArrowClassName={classes.ContentHeaderDropPanelFooterRoundingDropdownArrow}
              defaultValue={tableStructure.statisticRounding}
              options={[
                {
                  label: '0',
                  value: 0
                },
                {
                  label: '1',
                  value: 1
                },
                {
                  label: '2',
                  value: 2
                },
                {
                  label: '3',
                  value: 3
                },
                {
                  label: '4',
                  value: 4
                }
              ]}
              onChange={updateStatisticRounding}
            />
            <LocalizedMessage
              id='persona.table.headerRow.kpis.rounding.end'
            />
          </div>
        </div>
      </DropPanel>
    );
  }
}

export default MeasureSelect;
