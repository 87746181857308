import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {confirmable} from 'react-confirm';
import Button from '../../../../../components/Button';
import classes from './RangePopup.module.scss';

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_MAX_VALUE = 100;

export class RangePopup extends Component {
  static propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    dismiss: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      validate: PropTypes.func.isRequired,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      defaultValues: PropTypes.arrayOf(
        PropTypes.number
      ),
      measure: PropTypes.string,
      acceptBtnText: PropTypes.string.isRequired,
      cancelBtnText: PropTypes.string.isRequired,
      cancelCallback: PropTypes.func
    }).isRequired
  };

  static defaultProps = {
    show: true,
    proceed: null,
    cancel: null,
    confirmation: null
  };

  firstValue =
    this.props.options.defaultValues &&
    this.props.options.defaultValues[0] &&
    this.props.options.defaultValues[0] >= (this.props.options.minValue || DEFAULT_MIN_VALUE) &&
    this.props.options.defaultValues[0] <= (this.props.options.maxValue || DEFAULT_MAX_VALUE)
      ? this.props.options.defaultValues[0]
      : this.props.options.minValue;

  secondValue =
    this.props.options.defaultValues &&
    this.props.options.defaultValues[1] &&
    this.props.options.defaultValues[1] >= (this.props.options.minValue || DEFAULT_MIN_VALUE) &&
    this.props.options.defaultValues[1] <= (this.props.options.maxValue || DEFAULT_MAX_VALUE) &&
    this.props.options.defaultValues[1] >= this.firstValue
      ? this.props.options.defaultValues[1]
      : this.firstValue;

  state = {
    values: [this.firstValue, this.secondValue],
    hasError: false
  };

  _field = null;

  componentDidMount () {
    if (this._field) {
      this._field.focus();
    }
  }

  setFieldRef = ref => {
    this._field = ref;
  };

  accept = (e) => {
    e.preventDefault();

    const {options, proceed} = this.props;
    const {values} = this.state;
    const {validate} = options;

    if (!validate(values)) {
      this.setState({
        hasError: true
      });

      return;
    }

    proceed(values);
  };

  onFieldChange = (fieldIndex, value) => {
    const {options} = this.props;
    const {values} = this.state;
    const {minValue = 0, maxValue = 100} = options;

    let newValue = value.replace(/[^0-9]+/, '');
    const newValues = values.slice();

    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }

    newValues[fieldIndex] = parseInt(newValue, 10) || 0;

    this.setState({
      values: newValues,
      hasError: false
    });
  };

  render () {
    const {show, dismiss, confirmation, options} = this.props;
    const {values, hasError} = this.state;

    const {acceptBtnText, cancelBtnText} = options;

    if (!show) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <form
          className={classes.Content}
          onSubmit={this.accept}
        >
          {
            confirmation ?
              <p className={classes.Question}>
                {confirmation}
              </p>
              : null
          }
          <div className={classes.Fields}>
            <div className={classes.FieldsItemContainer}>
              <input
                ref={this.setFieldRef}
                type='text'
                className={cx(
                  classes.FieldsItem,
                  {
                    [classes.FieldsItemError]: hasError
                  }
                )}
                value={values[0]}
                onChange={e => this.onFieldChange(0, e.target.value)}
              />
            </div>
            <div className={classes.FieldsSeparator}>
              -
            </div>
            <div className={classes.FieldsItemContainer}>
              <input
                type='text'
                className={cx(
                  classes.FieldsItem,
                  {
                    [classes.FieldsItemError]: hasError
                  }
                )}
                value={values[1]}
                onChange={e => this.onFieldChange(1, e.target.value)}
              />
            </div>
            {
              options.measure ?
                <div className={classes.FieldsMeasure}>
                  {options.measure}
                </div>
                : null
            }
          </div>
          <div className={classes.Buttons}>
            <Button
              type='submit'
              onClick={this.accept}
              className={classes.ButtonsItem}
            >
              {acceptBtnText}
            </Button>
            <Button
              theme='transparent'
              onClick={() => {
                dismiss();

                if (typeof options.cancelCallback === 'function') {
                  options.cancelCallback();
                }
              }}
              className={classes.ButtonsItem}
            >
              {cancelBtnText}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default confirmable(RangePopup);
