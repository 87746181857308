import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import LocalizedMessage from '../../../components/LocalizedMessage';
import Scrollbar from '../../../components/Scrollbar';
import classes from './Profile.module.scss';

class Profile extends Component {
  static propTypes = {
    profile: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired
    })
  };

  render () {
    const {profile} = this.props;

    return (
      <div>
        <LocalizedMessage
          id='site.title.profile'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <Scrollbar>
          <div className='content content-full-height'>
            <h1 className={classes.Title}>
              <LocalizedMessage
                id='profile.title'
              />
            </h1>
            <div className={classes.Table}>
              <p className={classes.Category}>
                <LocalizedMessage
                  id='profile.personalInfo'
                />
              </p>
              <div className={classes.Row}>
                <p className={classes.Label}>
                  <LocalizedMessage
                    id='profile.id'
                  />
                </p>
                <p className={classes.Value}>
                  {profile.id}
                </p>
              </div>
              <div className={classes.Row}>
                <p className={classes.Label}>
                  <LocalizedMessage
                    id='profile.username'
                  />
                </p>
                <p className={classes.Value}>
                  {profile.userName}
                </p>
              </div>
              <div className={classes.Row}>
                <p className={classes.Label}>
                  E-mail
                </p>
                <p className={classes.Value}>
                  {profile.email}
                </p>
              </div>
              {profile.firstName || profile.lastName ?
                (
                  <>
                    <div className={classes.Row}>
                      <p className={classes.Label}>
                        <LocalizedMessage
                          id='profile.firstName'
                        />
                      </p>
                      <p className={classes.Value}>
                        {profile.firstName || '-'}
                      </p>
                    </div>
                    <div className={classes.Row}>
                      <p className={classes.Label}>
                        <LocalizedMessage
                          id='profile.lastName'
                        />
                      </p>
                      <p className={classes.Value}>
                        {profile.lastName || '-'}
                      </p>
                    </div>
                  </>
                )
                : (
                  <div className={classes.Row}>
                    <p className={classes.Label}>
                      <LocalizedMessage
                        id='profile.fullName'
                      />
                    </p>
                    <p className={classes.Value}>
                      {profile.fullName || '-'}
                    </p>
                  </div>
                )}
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default Profile;
