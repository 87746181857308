import {isJSON} from '../../../helpers/utils';
import {getFormulaHashWithoutTitles} from './formula';

const parseCondition = condition => {
  if (condition && isJSON(condition)) {
    return JSON.parse(condition);
  }

  return null;
};

export const parseCategories = categories => {
  const resultTaLists = {};
  const resultExpressionHashMap = {};
  const resultCategories = categories.map(category => ({
    id: category.id,
    title: {
      ru: category.title,
      en: category.titleEnglish || category.title
    },
    list: category.groups && category.groups.length ?
      category.groups.map(group => ({
        id: group.id,
        title: {
          ru: group.title,
          en: group.titleEnglish || group.title
        },
        list: group.dimensions && group.dimensions.length ?
          group.dimensions.map(dimension => {
            const parsedDimensionCondition = parseCondition(dimension.condition);

            if (
              ['ta_list', 'ta_list_advertisers'].includes(dimension.type) &&
              dimension.filters &&
              dimension.filters.length
            ) {
              const taListKey = (dimension.type === 'ta_list_advertisers' ? 'advertiser_' : '') + dimension.id;

              resultTaLists[taListKey] = dimension.filters.map(filter => ({
                label: {
                  ru: filter.title,
                  en: filter.titleEnglish || filter.title
                },
                value: filter.id
              }));
            }

            if (
              dimension.type === 'expression' &&
              parsedDimensionCondition &&
              parsedDimensionCondition.children &&
              parsedDimensionCondition.children.length
            ) {
              resultExpressionHashMap[getFormulaHashWithoutTitles(parsedDimensionCondition.children)] = dimension.id;
            }

            return {
              id: dimension.id,
              title: {
                ru: dimension.title,
                en: dimension.titleEnglish || dimension.title
              },
              type: dimension.type,
              tabType: dimension.tabType || null,
              edit: dimension.edit || false,
              condition: parsedDimensionCondition,
              maxValue: dimension.maxValue || null,
              measure: dimension.measure || null,
              list: dimension.filters && dimension.filters.length ?
                dimension.filters.map(filter => {
                  const parsedFilterCondition = parseCondition(filter.condition);

                  if (
                    filter.type === 'expression' &&
                    parsedFilterCondition &&
                    parsedFilterCondition.children &&
                    parsedFilterCondition.children.length
                  ) {
                    resultExpressionHashMap[getFormulaHashWithoutTitles(parsedFilterCondition.children)] = filter.id;
                  }

                  return {
                    id: filter.id,
                    title: {
                      ru: filter.title,
                      en: filter.titleEnglish || filter.title
                    },
                    type: filter.type,
                    edit: filter.edit || false,
                    condition: parsedFilterCondition
                  };
                })
                : []
            };
          })
          : []
      }))
      : []
  }));

  return {
    taLists: resultTaLists,
    expressionHashMap: resultExpressionHashMap,
    categories: resultCategories
  };
};

export const parseSavedFormulas = expression => (
  expression
    .filter(formula => {
      const isJSONResult = isJSON(formula.condition);

      if (process.env.NODE_ENV === 'development' && !isJSONResult) {
        console.warn('Incorrect formula', formula);
      }

      return isJSONResult;
    })
    .map(formula => ({
      id: formula.id,
      title: formula.title,
      formula: JSON.parse(formula.condition),
      accessType: formula.accessType,
      advertisers: formula.advertisers || null,
      group: formula.group || null
    }))
);

export const findStatisticItemIndex = (statisticItems, wantedItem) => {
  if (
    !statisticItems ||
    !Array.isArray(statisticItems) ||
    !statisticItems.length ||
    !wantedItem ||
    typeof wantedItem.id !== 'number'
  ) {
    return -1;
  }

  for (let i = 0; i < statisticItems.length; i++) {
    if (
      statisticItems[i].id === wantedItem.id &&
      !!statisticItems[i].affinity === !!wantedItem.affinity
    ) {
      return i;
    }
  }

  return -1;
};
