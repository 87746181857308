import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Scrollbar from '../../../../components/Scrollbar';
import Cell from './Cell';
import {generateTable} from '../../helpers/table';
import classes from './Table.module.scss';

class StaticTable extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    measures: PropTypes.array.isRequired,
    tableStructure: PropTypes.shape({
      verticalDataTree: PropTypes.array,
      horizontalDataTree: PropTypes.array,
      statisticAxis: PropTypes.oneOf(['rows', 'columns']).isRequired,
      statisticItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          affinity: PropTypes.bool.isRequired,
          nplus: PropTypes.bool.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.string
          )
        })
      ).isRequired
    }),
    direction: PropTypes.oneOf(['x', 'y']).isRequired,
    data: PropTypes.object,
    catalog: PropTypes.object.isRequired,
    globalFilter: PropTypes.shape({
      ta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      event: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-tv': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-web': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      })
    }).isRequired,
    changingCell: PropTypes.shape({
      direction: PropTypes.string.isRequired,
      path: PropTypes.arrayOf(
        PropTypes.number
      ).isRequired
    }),
    openFilter: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired
  };

  _scrollbar = null;

  setScrollbarRef = ref => {
    this._scrollbar = ref;
  };

  getContainer = () => {
    return this._scrollbar.getContainer();
  };

  onContextMenu = (e) => {
    e.preventDefault();
  };

  render () {
    const {
      locale,
      catalog,
      globalFilter,
      data,
      measures,
      tableStructure,
      direction,
      changingCell,
      openFilter,
      deleteCell
    } = this.props;

    const table = generateTable(locale, data, measures, tableStructure, direction);

    return (
      <Scrollbar
        ref={this.setScrollbarRef}
        className={classes.StaticTableContainer}
        viewProps={{
          onContextMenu: this.onContextMenu
        }}
      >
        <table
          className={classes.StaticTable}
        >
          <tbody>{
            table.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={classes.StaticTableRow}
              >
                {
                  row.map((cell, cellIndex) => (
                    <Cell
                      key={cellIndex}
                      locale={locale}
                      data={cell}
                      useStaticTable
                      changingCell={changingCell}
                      getContainer={this.getContainer}
                      catalog={catalog}
                      globalFilter={globalFilter}
                      openFilter={openFilter}
                      deleteCell={deleteCell}
                    />
                  ))
                }
              </tr>
            ))
          }</tbody>
        </table>
      </Scrollbar>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  locale: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticTable);
