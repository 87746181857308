import shortid from 'shortid';
import {fetchResponse} from './helpers/fakeFetch';
import generatedCube from '../../mocks/holdin_x_TV_And.json';
import rows from '../../mocks/new_rows.json';
import columns from '../../mocks/new_columns.json';

const cubesAPI = {
  getMatricesByQueueId (catalog, id, axis) {
    console.log('api.cubes.getMatricesByQueueId(', catalog, id, axis, ')');

    return fetchResponse(200, axis === 'rows' ? rows : columns);
  },

  downloadByUuid (catalog, uuid, axis) {
    console.log('api.cubes.downloadById(', catalog, uuid, axis, ')');

    return fetchResponse(1000, 'test');
  },

  send (catalog, payload) {
    console.log('api.cubes.send(', catalog, payload, ')');

    return fetchResponse(300, {
      id: shortid.generate(),
      ...generatedCube
    });
  },

  getList (catalog, accessType, advertiserId) {
    console.log('api.cubes.getList(', catalog, accessType, advertiserId, ')');

    return fetchResponse(300, []);
  },

  getCubeById (catalog, id) {
    console.log('api.cubes.getCubeById(', catalog, id, ')');

    return fetchResponse(200, generatedCube);
  },

  getTemplateById (catalog, id) {
    console.log('api.cubes.getTemplateById(', catalog, id, ')');

    return fetchResponse(200, rows);
  },

  save (catalog, cubeId, payload) {
    console.log('api.cubes.save(', catalog, cubeId, payload, ')');

    if (cubeId) {
      return fetchResponse(300, {
        id: cubeId,
        catalog: catalog,
        ...payload
      });
    }

    return fetchResponse(300, {
      id: shortid.generate(),
      catalog: catalog,
      ...payload
    });
  },

  checkTitle (catalog, title) {
    console.log('api.cubes.checkTitle(', catalog, title, ')');

    return fetchResponse(300, true);
  },

  rename (catalog, cubeId, title) {
    console.log('api.cubes.rename(', catalog, cubeId, title, ')');

    return fetchResponse(300, {
      id: cubeId,
      catalog,
      title
    });
  },

  copy (catalog, cubeId) {
    console.log('api.cubes.copy(', catalog, cubeId, ')');

    return fetchResponse(300, {
      id: cubeId,
      title: 'Copy',
      createdDate: new Date()
    });
  },

  delete (catalog, cubeId) {
    console.log('api.cubes.delete(', catalog, cubeId, ')');

    return fetchResponse(500, {});
  }
};

export default cubesAPI;
