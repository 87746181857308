import {Component} from 'react';
import PropTypes from 'prop-types';
import store from '../../store';
import locales from '../../locales';

export const localizeMessage = ({id, locale = store.getState().locale.lang}, values = {}, messages) => {
  if (typeof messages === 'undefined') {
    messages = locales[locale];
  }

  if (typeof messages[id] === 'undefined') {
    console.warn(new Error(`Localized string '${id}' not found.`));

    return '';
  }

  let resultMessage = messages[id];

  for (const variable in values) {
    if (Object.prototype.hasOwnProperty.call(values, variable)) {
      const re = new RegExp(`{${variable}}`, 'g');

      resultMessage = resultMessage.replace(re, values[variable]);
    }
  }

  return resultMessage;
};

class LocalizedMessage extends Component {
  static propTypes = {
    children: PropTypes.func,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.string
      )
    ]).isRequired,
    values: PropTypes.object,
    messages: PropTypes.object.isRequired
  };

  static defaultProps = {
    values: {}
  };

  render () {
    const {children, id, values, messages} = this.props;

    if (typeof children === 'function') {
      return children.apply(
        children,
        Array.isArray(id)
          ? id.map(localId => localizeMessage({id: localId}, values, messages))
          : [localizeMessage({id}, values, messages)]
      );
    }

    if (Array.isArray(id)) {
      return id.map(localId => localizeMessage({id: localId}, values, messages)).join(' ');
    }

    return localizeMessage({id}, values, messages);
  }
}

export default LocalizedMessage;
