import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Scrollbar from '../../../../components/Scrollbar';
import Cell from './Cell';
import classes from './Table.module.scss';
import Config from '../../../../config';

class LazyTableBlock extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.array.isRequired,
    direction: PropTypes.oneOf(['x', 'y']).isRequired,
    position: PropTypes.oneOf(['top', 'left', 'center']).isRequired,
    cutRightBorder: PropTypes.bool.isRequired,
    hideHorizontalScrollbar: PropTypes.bool,
    hideVerticalScrollbar: PropTypes.bool,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    disableGrouping: PropTypes.bool,
    onResize: PropTypes.func,
    onScroll: PropTypes.func,
    openFilter: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    sortByCell: PropTypes.func
  };

  static defaultProps = {
    cutRightBorder: false,
    hideHorizontalScrollbar: false,
    hideVerticalScrollbar: false,
    disableGrouping: false,
    getContainer: null,
    onResize: null,
    onScroll: null
  };

  state = {
    cellWidth: this.props.cellWidth,
    cellHeight: this.props.cellHeight,
    containerWidth: 0,
    containerHeight: 0,
    headerSeparatorHeight: 0,
    footerSeparatorHeight: 0
  };

  _scrollbar = null;
  onScrollSubscribedCallback = null;
  topIndex = 0;
  leftIndex = 0;
  bottomIndex = 0;
  rightIndex = 0;

  componentDidMount () {
    this.calculateTable();

    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate (prevProps) {
    this.calculateTable();
    this.calculateStretchWidth();

    if (prevProps.cellWidth !== this.props.cellWidth) {
      this.setState({
        cellWidth: this.props.cellWidth
      });
    }

    if (prevProps.direction !== this.props.direction) {
      this.onResize();
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize);
  }

  setScrollbarRef = ref => {
    this._scrollbar = ref;
  };

  getContainer = () => {
    return this._scrollbar.getContainer();
  };

  onResize = () => {
    if (this._scrollbar) {
      this.calculateIndexes(this._scrollbar._scrollbars.view);
    }
  };

  calculateStretchWidth = () => {
    const {position, stretchWidth} = this.props;
    const {cellWidth} = this.state;

    if (position !== 'top' && position !== 'center') {
      return;
    }

    if (stretchWidth === 0 || stretchWidth === cellWidth) {
      return;
    }

    this.setState({
      cellWidth: stretchWidth
    });
  };

  calculateTable = () => {
    const {data, onResize} = this.props;
    const {containerWidth, containerHeight, cellWidth, cellHeight} = this.state;

    const newContainerSizes = {
      columns: data.length ? data[0].length : 0,
      rows: data.length
    };
    const newContainerWidth = newContainerSizes.columns * cellWidth;
    const newContainerHeight = newContainerSizes.rows * cellHeight;

    if (containerWidth === newContainerWidth && containerHeight === newContainerHeight) {
      return;
    }

    if (typeof onResize === 'function') {
      onResize({
        width: newContainerWidth,
        height: newContainerHeight,
        cells: newContainerSizes
      });
    }

    this.setState({
      containerWidth: newContainerWidth,
      containerHeight: newContainerHeight
    }, () => {
      if (this._scrollbar) {
        this.calculateIndexes(this._scrollbar._scrollbars.view);
      }
    });
  };

  onScroll = (e) => {
    const {onScroll} = this.props;

    const _target = e.target;

    if (onScroll) {
      onScroll({
        top: _target.scrollTop,
        left: _target.scrollLeft
      });
    }

    if (this.onScrollSubscribedCallback) {
      this.onScrollSubscribedCallback(_target);
    }
  };

  onScrollAction = ({left, top}, side) => {
    const {position} = this.props;

    if (side !== position) {
      if (position !== 'left' && side !== 'left') {
        this._scrollbar.scrollLeft(left);
      }
      if (position !== 'top' && side !== 'top') {
        this._scrollbar.scrollTop(top);
      }
    }

    this.calculateIndexes(this._scrollbar._scrollbars.view);
  };

  subscribeOnScroll = (callback = null) => {
    this.onScrollSubscribedCallback = callback;
  };

  calculateIndexes = (_scrollView) => {
    const {cellWidth, cellHeight} = this.state;

    const scrollTop = _scrollView.scrollTop;
    const scrollLeft = _scrollView.scrollLeft;

    this.topIndex = Math.floor(scrollTop / cellHeight);
    this.leftIndex = Math.floor(scrollLeft / cellWidth);
    this.bottomIndex = Math.ceil((scrollTop + _scrollView.offsetHeight) / cellHeight);
    this.rightIndex = Math.ceil((scrollLeft + _scrollView.offsetWidth) / cellWidth);

    this.forceUpdate();
  };

  calculateCellSubTopOffset = () => {
    const {cellHeight} = this.props;

    return (
      this._scrollbar._scrollbars.view.scrollTop % cellHeight
    ) / cellHeight;
  };

  calculateCellSubLeftOffset = () => {
    const {cellWidth} = this.state;

    return (
      this._scrollbar._scrollbars.view.scrollLeft % cellWidth
    ) / cellWidth;
  };

  calculateCellList = () => {
    const {data, disableGrouping} = this.props;

    if (!this.bottomIndex || !this.rightIndex) {
      return [];
    }

    const resultCells = [];
    const existsIndexes = [];

    data.slice(this.topIndex, this.bottomIndex).forEach((row, rowIndex) => {
      row.slice(this.leftIndex, this.rightIndex).forEach((cell, cellIndex) => {
        if (cell.type === 'skip') {
          if (disableGrouping) {
            resultCells.push({
              x: this.leftIndex + cellIndex,
              y: this.topIndex + rowIndex,
              colSpan: 1,
              rowSpan: 1,
              data: cell.place.link
            });

            return;
          }

          const pathString = cell.place.link.path.join('-');

          if (existsIndexes.indexOf(pathString) === -1) {
            existsIndexes.push(pathString);

            if (cell.place.link.direction === 'rows') {
              const currentColumn = data[this.topIndex + rowIndex];
              const nextColumn = currentColumn[cell.place.link.colSpan + this.leftIndex + 1];

              const hasNextColumnEmptyCell = nextColumn && nextColumn.place;
              const leftOffset =
                this.leftIndex +
                (hasNextColumnEmptyCell ? this.calculateCellSubLeftOffset() : 0);

              let x = leftOffset;
              const colSpanWidth = cell.place.link.colSpan - (leftOffset - cell.place.position[0]);

              if (currentColumn.length === leftOffset + colSpanWidth) {
                x = this.leftIndex + this.calculateCellSubLeftOffset();
              }

              resultCells.push({
                x: x,
                y: this.topIndex + rowIndex,
                colSpan: colSpanWidth,
                rowSpan: cell.place.link.rowSpan,
                data: cell.place.link
              });
            } else {
              const placeLinkRowIndex = cell.place.position[0];
              const placeLinkColumnIndex = cell.place.position[1];
              const nextRow = data[this.topIndex + rowIndex + 1];
              const hasNextRowEmptyCell = nextRow && nextRow[this.leftIndex + cellIndex].place;
              let topOffset =
                this.topIndex -
                placeLinkRowIndex +
                (hasNextRowEmptyCell ? this.calculateCellSubTopOffset() : 0);

              if (topOffset < 0) {
                topOffset = 0;
              }

              const currentRow = data[placeLinkRowIndex];
              const nextNotSkipColumn = currentRow[placeLinkColumnIndex + cell.place.link.colSpan];
              const hasNextColumnEmptyCell = !!nextNotSkipColumn;
              const x = this.leftIndex +
                (this.topIndex + rowIndex === placeLinkRowIndex ? this.calculateCellSubLeftOffset() : cellIndex);
              let colSpan = cell.place.link.colSpan;

              if (hasNextColumnEmptyCell) {
                const nextNotSkipCellOffsetLeft = placeLinkColumnIndex + cell.place.link.colSpan;
                colSpan = Math.min(colSpan, nextNotSkipCellOffsetLeft - x);
              }

              resultCells.push({
                x: x,
                y: placeLinkRowIndex + topOffset,
                colSpan: Math.min(colSpan,
                  Config.appOptions.LAZY_TABLE_MAX_LEFT_SIDE_WIDTH / Config.appOptions.LAZY_TABLE_CELL_WIDTH),
                rowSpan: cell.place.link.rowSpan - topOffset,
                data: cell.place.link
              });
            }
          }
        } else {
          if (cell.path) {
            existsIndexes.push(cell.path.join('-'));
          }

          let topOffset = 0;
          let leftOffset = 0;

          if (rowIndex === 0) {
            const nextRow = data[this.topIndex + rowIndex + 1];
            const hasNextRowEmptyCell = nextRow && nextRow[this.leftIndex + cellIndex].place;

            if (hasNextRowEmptyCell) {
              topOffset = this.calculateCellSubTopOffset();
            }
          }

          if (cellIndex === 0) {
            const nextColumn = data[this.topIndex + rowIndex][this.leftIndex + cellIndex + 1];
            const hasNextColumnEmptyCell = nextColumn && nextColumn.place;

            if (hasNextColumnEmptyCell) {
              leftOffset = this.calculateCellSubLeftOffset();
            }
          }

          resultCells.push({
            x: this.leftIndex + cellIndex + leftOffset,
            y: this.topIndex + rowIndex + topOffset,
            colSpan: cell.colSpan - leftOffset,
            rowSpan: cell.rowSpan - topOffset,
            data: cell
          });
        }
      });
    });

    return resultCells;
  };

  render () {
    const {
      locale,
      openFilter,
      deleteCell,
      cutRightBorder,
      hideHorizontalScrollbar,
      hideVerticalScrollbar,
      disableGrouping,
      sortByCell
    } = this.props;
    const {containerWidth, containerHeight, cellWidth, cellHeight} = this.state;

    return (
      <Scrollbar
        ref={this.setScrollbarRef}
        hideHorizontalScrollbar={hideHorizontalScrollbar}
        hideVerticalScrollbar={hideVerticalScrollbar}
        onScroll={this.onScroll}
      >
        <div
          className={classes.LazyTable}
          style={{
            width: `${containerWidth - (cutRightBorder ? 1 : 0)}px`,
            height: `${containerHeight}px`
          }}
        >
          {
            this.calculateCellList().map((cell, index) => (
              <Cell
                key={index}
                locale={locale}
                cell={cell}
                data={cell.data}
                cellWidth={cellWidth}
                cellHeight={cellHeight}
                disableGrouping={disableGrouping}
                subscribeOnScroll={this.subscribeOnScroll}
                getContainer={this.getContainer}
                openFilter={openFilter}
                deleteCell={deleteCell}
                sortByCell={sortByCell}
              />
            ))
          }
        </div>
      </Scrollbar>
    );
  }
}

export default LazyTableBlock;
