import alert from '../helpers/alert';
import API from '../api';
import {localizeMessage} from '../components/LocalizedMessage';
import {LOGOUT} from './auth';

// ------------------------------------
// Constants
// ------------------------------------
export const LOAD_PERSONA_CATALOGS = 'LOAD_PERSONA_CATALOGS';
export const LOAD_PERSONA_CATALOGS_SUCCESS = 'LOAD_PERSONA_CATALOGS_SUCCESS';
export const LOAD_PERSONA_CATALOGS_FAILED = 'LOAD_PERSONA_CATALOGS_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const loadCatalogs = () => async (dispatch, getState) => {
  dispatch({
    type: LOAD_PERSONA_CATALOGS
  });

  try {
    const loadedCatalogs = await API.catalogs.getCatalogs();

    const catalogs = {};

    const cState = getState().persona;

    loadedCatalogs.forEach(catalog => {
      catalogs[catalog.url] = {
        loading: false,
        loaded: false,
        categories: [],
        measures: [],
        savedFormulas: [],
        taLists: {},
        ...cState.catalogs.items[catalog.url],
        ...catalog
      };
    });

    dispatch({
      type: LOAD_PERSONA_CATALOGS_SUCCESS,
      catalogs: catalogs
    });

    return catalogs;
  } catch (error) {
    console.error(error);

    if (!error.withoutAlerts) {
      alert.error(localizeMessage({
        id: 'errors.errorLoadingData'
      }));
    }

    if (!error.withLogout) {
      dispatch({
        type: LOAD_PERSONA_CATALOGS_FAILED,
        error: error
      });
    }

    throw error;
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_PERSONA_CATALOGS]: (state, action) => ({
    ...state,
    catalogs: {
      ...state.catalogs,
      loading: true,
      loaded: false,
      error: null
    }
  }),
  [LOAD_PERSONA_CATALOGS_SUCCESS]: (state, action) => {
    return {
      ...state,
      catalogs: {
        loading: false,
        loaded: true,
        error: null,
        items: action.catalogs
      }
    };
  },
  [LOAD_PERSONA_CATALOGS_FAILED]: (state, action) => ({
    ...state,
    catalogs: {
      loading: false,
      loaded: true,
      error: action.error,
      items: {}
    }
  }),

  [LOGOUT]: () => ({
    catalogs: {
      loading: false,
      loaded: false,
      error: null,
      items: {}
    }
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  catalogs: {
    loading: false,
    loaded: false,
    error: null,
    items: {}
  }
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
