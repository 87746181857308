import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage from '../../../../../../components/LocalizedMessage';
import {getItemLinkByPath} from '../../../../helpers/formula';
import classes from './Buttons.module.scss';

const Button = ({children, hint, isSelected, isDisabled, onClick}) => (
  <li
    className={cx(
      classes.Item,
      {
        [classes.ItemSelected]: isSelected,
        [classes.ItemDisabled]: isDisabled
      }
    )}
    onClick={!isDisabled ? onClick : null}
  >
    {
      !isDisabled && hint ?
        <p className={classes.Hint}>
          {hint}
        </p>
        : null
    }
    {children}
  </li>
);

Button.defaultProps = {
  hint: null,
  isSelected: false,
  isDisabled: false
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  hint: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

class Buttons extends Component {
  static propTypes = {
    formula: PropTypes.object.isRequired,
    selectedItem: PropTypes.shape({
      type: PropTypes.string.isRequired,
      path: PropTypes.array.isRequired
    }),
    selectedList: PropTypes.shape({
      parentPath: PropTypes.arrayOf(
        PropTypes.number.isRequired
      ).isRequired,
      indexes: PropTypes.arrayOf(
        PropTypes.number.isRequired
      ).isRequired
    }),
    isSelecting: PropTypes.bool.isRequired,
    changeConnector: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    groupItems: PropTypes.func.isRequired,
    toggleNot: PropTypes.func.isRequired,
    toggleSelection: PropTypes.func.isRequired,
    changeConditionOperator: PropTypes.func.isRequired
  };

  _container = null;

  getContainer = () => {
    return this._container;
  };

  render () {
    const {
      formula,
      selectedItem,
      selectedList,
      isSelecting,
      changeConnector,
      removeItem,
      groupItems,
      toggleNot,
      toggleSelection,
      changeConditionOperator
    } = this.props;

    const selectedItemType = selectedItem ? selectedItem.type : null;
    const selectedItemData = selectedItem ? getItemLinkByPath(formula, selectedItem.path) : null;
    const selectedItemConnector =
      selectedItemData
        ? selectedItemData.connector
        : null;
    const selectedItemConditionType =
      selectedItemData &&
      selectedItemData.condition &&
      selectedItemData.condition.type
        ? selectedItemData.condition.type
        : null;
    const selectedItemConditionOperator =
      selectedItemConditionType &&
      (
        selectedItemConditionType === 'event' ||
        selectedItemConditionType === 'ta_list' ||
        selectedItemConditionType === 'ta_list_advertisers' ||
        selectedItemConditionType === 'ta_integer'
      ) &&
      selectedItemData.condition.operator
        ? selectedItemData.condition.operator
        : null;

    return (
      <div className={classes.Container}>
        <ul
          ref={ref => {
            this._container = ref;
          }}
          className={classes.List}
        >
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.selectable',
              'persona.table.filter.formulaEditor.buttons.selectable.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isSelected={isSelecting}
                onClick={toggleSelection}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.brackets',
              'persona.table.filter.formulaEditor.buttons.brackets.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  !selectedList ||
                  (!selectedList.parentPath.length && formula.children.length === selectedList.indexes.length)
                }
                onClick={groupItems}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.not',
              'persona.table.filter.formulaEditor.buttons.not.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={selectedItemType !== 'item' && !selectedList}
                onClick={toggleNot}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.and',
              'persona.table.filter.formulaEditor.buttons.and.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={selectedItemType !== 'connector' || selectedItemConnector === 'and'}
                onClick={() => changeConnector('and')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.or',
              'persona.table.filter.formulaEditor.buttons.or.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={selectedItemType !== 'connector' || selectedItemConnector === 'or'}
                onClick={() => changeConnector('or')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.in',
              'persona.table.filter.formulaEditor.buttons.in.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === 'in'
                }
                onClick={() => changeConditionOperator('in')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.between',
              'persona.table.filter.formulaEditor.buttons.between.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  selectedItemConditionType !== 'ta_integer' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === 'between'
                }
                onClick={() => changeConditionOperator('between')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.equal',
              'persona.table.filter.formulaEditor.buttons.equal.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '='
                }
                onClick={() => changeConditionOperator('=')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.notEqual',
              'persona.table.filter.formulaEditor.buttons.notEqual.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '\\='
                }
                onClick={() => changeConditionOperator('\\=')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.lessThan',
              'persona.table.filter.formulaEditor.buttons.lessThan.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  selectedItemConditionType !== 'ta_integer' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '<'
                }
                onClick={() => changeConditionOperator('<')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.lessThanOrEqual',
              'persona.table.filter.formulaEditor.buttons.lessThanOrEqual.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  selectedItemConditionType !== 'ta_integer' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '<='
                }
                onClick={() => changeConditionOperator('<=')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.greaterThanOrEqual',
              'persona.table.filter.formulaEditor.buttons.greaterThanOrEqual.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  selectedItemConditionType !== 'ta_integer' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '>='
                }
                onClick={() => changeConditionOperator('>=')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.greaterThan',
              'persona.table.filter.formulaEditor.buttons.greaterThan.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={
                  selectedItemType !== 'item' ||
                  selectedItemConditionType !== 'ta_integer' ||
                  !selectedItemConditionOperator ||
                  selectedItemConditionOperator === '>'
                }
                onClick={() => changeConditionOperator('>')}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
          <LocalizedMessage
            id={[
              'persona.table.filter.formulaEditor.buttons.delete',
              'persona.table.filter.formulaEditor.buttons.delete.hint'
            ]}
          >
            {(localizedMessage, localizedHint) => (
              <Button
                hint={localizedHint}
                isDisabled={selectedItemType !== 'item'}
                onClick={() => removeItem()}
              >
                {localizedMessage}
              </Button>
            )}
          </LocalizedMessage>
        </ul>
      </div>
    );
  }
}

export default Buttons;
