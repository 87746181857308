import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Checkbox.module.scss';

class Checkbox extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    checkboxClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    className: null,
    checkboxClassName: null,
    labelClassName: null
  };

  onChange = e => {
    const {name, onChange} = this.props;

    const checked = e.target.checked;

    if (typeof onChange === 'function') {
      onChange(name, checked);
    }
  };

  render () {
    const {children, checked, disabled, name, className, checkboxClassName, labelClassName} = this.props;

    return (
      <div
        className={cx(
          classes.Container,
          className,
          {
            [classes.ContainerDisabled]: disabled
          }
        )}
      >
        <input
          type='checkbox'
          name={name}
          id={`checkbox-${name}`}
          className={classes.Checkbox}
          onChange={this.onChange}
          checked={checked}
          disabled={disabled}
        />
        <label
          htmlFor={`checkbox-${name}`}
          className={cx(
            classes.Label,
            labelClassName
          )}
        >
          {children}
          <i className={cx(
            classes.LabelCheckbox,
            'icon-checkbox',
            checkboxClassName
          )} />
          <i className={cx(
            classes.LabelCheckbox,
            classes.LabelCheckboxChecked,
            'icon-checkbox-checked',
            checkboxClassName
          )} />
        </label>
      </div>
    );
  }
}

export default Checkbox;
