import Client from './HTTPClient';

const mIndexAPI = {
  insert (name) {
    return Client.post('/application/mindex/add', [
      name
    ]);
  },

  getHints (pattern, offset, limit) {
    return Client.post('/application/mindex/find', {
      pattern,
      offset,
      limit
    });
  }
};

export default mIndexAPI;
