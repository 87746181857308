import locales from '../locales';

// ------------------------------------
// Constants
// ------------------------------------
export const LOCALE_SELECTED = 'LOCALE_SELECTED';

// ------------------------------------
// Actions
// ------------------------------------
export const selectLocale = (locale = defaultLocale) => {
  localStorage.lang = locale;

  return {
    type: LOCALE_SELECTED,
    locale: locale
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOCALE_SELECTED]: (state, action) => ({
    ...state,
    lang: action.locale,
    messages: locales[action.locale]
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
let defaultLocale = navigator.language === 'ru-RU' ? 'ru' : 'en';

if (
  localStorage.lang &&
  Object.keys(locales).indexOf(localStorage.lang) > -1
) {
  defaultLocale = localStorage.lang;
} else {
  localStorage.lang = defaultLocale;
}

const initialState = {
  lang: defaultLocale,
  messages: locales[defaultLocale]
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
