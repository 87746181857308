import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Scrollbar from '../../../../../components/Scrollbar';
import {Item, ItemWithDND} from './Item';
import {restructureCubeChildren} from '../../../helpers/formula';
import classes from './List.module.scss';

class List extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
      title: PropTypes.oneOfType([
        PropTypes.shape({
          ru: PropTypes.string,
          en: PropTypes.string
        }),
        PropTypes.node
      ]).isRequired,
      type: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
          ]).isRequired,
          title: PropTypes.oneOfType([
            PropTypes.shape({
              ru: PropTypes.string,
              en: PropTypes.string
            }),
            PropTypes.node
          ]).isRequired,
          type: PropTypes.string
        })
      ).isRequired
    }),
    isAdmin: PropTypes.bool.isRequired,
    selectedIds: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    ),
    formulaIsOpen: PropTypes.bool,
    changeFormula: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    downloadMemberList: PropTypes.func.isRequired,
    editSavedFormula: PropTypes.func.isRequired,
    deleteSavedFormula: PropTypes.func.isRequired
  };

  static defaultProps = {
    formulaIsOpen: false
  };

  _scrollbar = null;

  setScrollbarRef = ref => {
    this._scrollbar = ref;
  };

  scrollTop = () => {
    this._scrollbar.scrollTop();
  };

  generateItemData = (item) => {
    const {data} = this.props;

    const resultObject = {
      action: 'add',
      data: {
        uid: shortid.generate(),
        id: item.id,
        title: item.title,
        condition:
          item.type === 'ta' ||
          item.type === 'expression'
            ? {
              type: item.type
            }
            : {
              type: item.contentType || item.type,
              operator: '=',
              values: null
            },
        connector: 'and',
        hasNot: false
      }
    };

    if (
      item.type !== 'ta' &&
      item.type !== 'expression' &&
      item.values
    ) {
      resultObject.data.title = item.title;
      resultObject.data.condition.values = item.values;

      if (item.values.length > 1) {
        resultObject.data.condition.operator = 'in';
      }
    }

    if (item.type === 'ta_integer' && resultObject.data.condition.operator === '=') {
      resultObject.data.condition.values = [{value: 0}];
    }

    if (item.type === 'expression' && item.condition) {
      const formulaChildrenWithUids = restructureCubeChildren(item.condition.children, true);

      if (formulaChildrenWithUids.length === 1) {
        resultObject.data = {
          ...formulaChildrenWithUids[0],
          uid: shortid.generate()
        };
      } else {
        resultObject.data.children = formulaChildrenWithUids;
        delete resultObject.data.id;
        delete resultObject.data.title;
      }
    }

    if (['ta_list', 'ta_list_advertisers'].includes(data.type) && item.type === 'ta') {
      resultObject.data = {
        ...resultObject.data,
        id: data.id,
        title: data.title,
        condition: {
          type: data.type,
          operator: '=',
          values: [{
            label: item.title,
            value: item.id
          }]
        }
      };
    }

    resultObject.data.isSaved = !!item.isSaved;

    return resultObject;
  };

  render () {
    const {
      locale,
      data,
      isAdmin,
      selectedIds,
      formulaIsOpen,
      changeFormula,
      onClick,
      downloadMemberList,
      editSavedFormula,
      deleteSavedFormula
    } = this.props;

    if (!data || !data.list) {
      return null;
    }

    const itemProps = {
      locale,
      selectedIds,
      isAdmin,
      onClick,
      downloadMemberList,
      editSavedFormula,
      deleteSavedFormula
    };

    return (
      <Scrollbar ref={this.setScrollbarRef}>
        <ul className={classes.List}>
          {
            data.list.map(item => (
              formulaIsOpen && (
                item.type === 'ta' ||
                item.type === 'expression' ||
                item.type === 'event' ||
                (
                  ['ta_list', 'ta_list_advertisers'].includes(item.type) &&
                  item.list &&
                  item.list.length
                ) ||
                item.type === 'ta_integer'
              ) ?
                <ItemWithDND
                  {...itemProps}
                  key={`${item.type}_${item.id}`}
                  item={item}
                  itemData={this.generateItemData(item)}
                  formulaIsOpen={formulaIsOpen}
                  options={
                    ['ta_list', 'ta_list_advertisers'].includes(item.type) &&
                    item.list &&
                    item.list.length
                      ? item.list.map(filter => filter.title)
                      : null
                  }
                  changeFormula={changeFormula}
                />
                :
                <Item
                  {...itemProps}
                  key={`${item.type}_${item.id}`}
                  item={item}
                  formulaIsOpen={formulaIsOpen}
                />
            ))
          }
        </ul>
      </Scrollbar>
    );
  }
}

export default List;
