import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './RadioList.module.scss';

class RadioList extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node
        ]).isRequired,
        value: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    defaultValue: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    itemActiveClassName: PropTypes.string,
    radioClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    defaultValue: null,
    className: null,
    itemClassName: null,
    itemActiveClassName: null,
    radioClassName: null,
    labelClassName: null
  };

  componentDidMount () {
    const {defaultValue, onChange} = this.props;

    if (
      defaultValue &&
      defaultValue.length &&
      typeof onChange === 'function'
    ) {
      onChange(defaultValue);
    }
  }

  onChange = e => {
    const {onChange} = this.props;

    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  };

  render () {
    const {
      options,
      name,
      defaultValue,
      className,
      itemClassName,
      itemActiveClassName,
      radioClassName,
      labelClassName
    } = this.props;

    return (
      <ul
        className={cx(
          classes.List,
          className
        )}
      >
        {
          options.map(option => (
            <li
              key={option.value}
              className={cx(
                classes.Item,
                itemClassName,
                {
                  [cx(classes.ItemActive, itemActiveClassName)]: defaultValue === option.value
                }
              )}
            >
              <input
                type='radio'
                name={name}
                id={`radio-${name}-${option.value}`}
                value={option.value}
                defaultChecked={option.value === defaultValue}
                className={cx(
                  classes.Radio,
                  radioClassName
                )}
                onChange={this.onChange}
              />
              <label
                htmlFor={`radio-${name}-${option.value}`}
                className={cx(
                  classes.Label,
                  labelClassName
                )}
              >
                {option.label}
              </label>
            </li>
          ))
        }
      </ul>
    );
  }
}

export default RadioList;
