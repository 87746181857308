import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage from '../../../../../../../components/LocalizedMessage';
import IntegerField from './Fields/IntegerField';
import classes from './Templates.module.scss';

const BETWEEN_MIN_VALUE = 0;
const BETWEEN_MAX_VALUE = 2147483647;

class Between extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.object.isRequired,
    isSelecting: PropTypes.bool.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    values: [
      this.props.data.condition.values[0]
        ? this.props.data.condition.values[0]
        : {value: 0},
      this.props.data.condition.values[1]
        ? this.props.data.condition.values[1]
        : {value: 0}
    ]
  };

  onChange = (fieldIndex, value) => {
    const {onChange} = this.props;
    const {values} = this.state;

    const newValue = value.value;
    const newValues = values.map(value => value.value);

    newValues[fieldIndex] = newValue;

    const resultValues = newValues.map(value => ({value}));

    this.setState({
      values: resultValues
    });

    onChange(resultValues);
  };

  render () {
    const {locale, data, isSelecting, toggleChanging, setFieldRef} = this.props;
    const {values} = this.state;

    return (
      <div className={classes.Container}>
        {data.title[locale]}
        {' '}
        <LocalizedMessage
          id='persona.table.filter.formulaEditor.buttons.between'
        />
        {' '}
        <div className={classes.BetweenFields}>
          <IntegerField
            minValue={BETWEEN_MIN_VALUE}
            maxValue={BETWEEN_MAX_VALUE}
            value={values[0]}
            isSelecting={isSelecting}
            setFieldRef={setFieldRef}
            toggleChanging={toggleChanging}
            onChange={value => this.onChange(0, value)}
          />
          {', '}
          <IntegerField
            autoFocus={false}
            minValue={BETWEEN_MIN_VALUE}
            maxValue={BETWEEN_MAX_VALUE}
            value={values[1]}
            isSelecting={isSelecting}
            toggleChanging={toggleChanging}
            onChange={value => this.onChange(1, value)}
          />
        </div>
      </div>
    );
  }
}

export default Between;
