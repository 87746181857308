import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage from '../../../../../../../components/LocalizedMessage';
import IntegerField from './Fields/IntegerField';
import EventField from './Fields/EventField';
import classes from './Templates.module.scss';

const IN_MIN_VALUE = 0;
const IN_MAX_VALUE = 2147483647;

class In extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    isSelecting: PropTypes.bool.isRequired,
    scrollToField: PropTypes.func.isRequired,
    addOnScrollCallback: PropTypes.func.isRequired,
    removeOnScrollCallback: PropTypes.func.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputWidthChange: PropTypes.func.isRequired,
    loadEventHints: PropTypes.func.isRequired
  };

  state = {
    values:
      this.props.data.condition.values &&
      this.props.data.condition.values.length
        ? this.props.data.condition.values
        : []
  };

  onChange = (values) => {
    const {scrollToField, onChange} = this.props;

    this.setState({
      values
    }, () => {
      scrollToField();
    });

    onChange(values);
  };

  render () {
    const {
      locale,
      data,
      catalog,
      isSelecting,
      addOnScrollCallback,
      removeOnScrollCallback,
      toggleChanging,
      selectItem,
      setFieldRef,
      onInputWidthChange,
      loadEventHints
    } = this.props;
    const {values} = this.state;

    return (
      <div className={classes.Container}>
        {data.title[locale]}
        {' '}
        <LocalizedMessage
          id='persona.table.filter.formulaEditor.buttons.in'
        />
        {' '}
        {
          data.condition.type === 'ta_integer' ?
            <IntegerField
              multi
              minValue={IN_MIN_VALUE}
              maxValue={IN_MAX_VALUE}
              isSelecting={isSelecting}
              toggleChanging={toggleChanging}
              selectItem={selectItem}
              setFieldRef={setFieldRef}
              onChange={this.onChange}
              values={values || []}
            />
            :
            <EventField
              locale={locale}
              multi
              data={data}
              catalog={catalog}
              values={values}
              isSelecting={isSelecting}
              addOnScrollCallback={addOnScrollCallback}
              removeOnScrollCallback={removeOnScrollCallback}
              toggleChanging={toggleChanging}
              setFieldRef={setFieldRef}
              onChange={this.onChange}
              onInputWidthChange={onInputWidthChange}
              loadEventHints={loadEventHints}
            />
        }
      </div>
    );
  }
}

export default In;
