import {getArrayItemByParam} from '../../../helpers/utils';
import {parseTableData} from '../logic/parser';
import locales from '../../../locales';
import Config from '../../../config';
import {getTitle} from '../../../helpers';

const {CUSTOM_MEASURE_HORZ_ID, CUSTOM_MEASURE_VERT_ID, CUSTOM_MEASURE_INDEX_ID} = Config.appOptions;

export const MIN_HORIZONTAL_SIZE = 6; // cells
export const MIN_VERTICAL_SIZE = 12;  // cells

export const addAddingDataButtonsToMatrix = (matrix, type, maxDepth) => {
  matrix.push(
    [
      {
        type: 'add-btn',
        direction: type
      }
    ].concat(maxDepth > 1 ? new Array(maxDepth - 1).fill({type: 'cell'}) : [])
  );
};

export const addAdditionCells = (matrix, type) => {
  const minSize = type === 'rows' ? MIN_HORIZONTAL_SIZE : MIN_VERTICAL_SIZE;

  if (matrix.length >= minSize) {
    return false;
  }

  for (let i = matrix.length; i < minSize - 1; i++) {
    matrix.push([
      {
        type: 'cell',
        direction: type
      }
    ]);
  }
};

export const recursiveRender = (locale, matrix, item, type, path = [], row, depth = 0, statistics, statistic) => {
  const sizeType = type === 'rows' ? 'colSpan' : 'rowSpan';

  // If matrix row is not exist, we create new matrix row
  if (typeof matrix[row] === 'undefined') {
    matrix[row] = [];

    // If depth is greater than 0, we add empty cells with link to cell that uses this place
    if (depth > 0) {
      for (let i = 0; i < depth; i++) {
        const place = matrix[row - 1][i].place || {
          link: matrix[row - 1][i],
          position: [row - 1, i]
        };

        place.link[sizeType] += 1;

        matrix[row].push({
          type: 'skip',
          place
        });
      }
    }
  }

  // Add current cell to matrix
  matrix[row].push({
    id: !item.nodeType ? (item.id || null) : null,
    formula: item.formula
      ? {
        title: getTitle(item, locale),
        mode: item.mode || 'ta',
        data: item.formula
      }
      : null,
    type: item.nodeType === 'MEASURE_VALUE' ? 'statistic' : 'head',
    like: item.like || null,
    nodeType: item.nodeType || null,
    singleLevelValue: item.singleLevelValue || false,
    dimensionId: item.dimensionId || null,
    dimensionTitle: item.dimensionTitle || null,
    direction: type,
    path: path,
    content:
      (getTitle(item, locale) ?? '') +
      (
        item.nodeType === 'MEASURE_VALUE' &&
        item.values &&
        item.values.length
          ? ` (${item.values.join(', ')})`
          : ''
      ),
    colSpan: 1,
    rowSpan: 1
  });

  // Add statistics
  if (statistic && (!item.children || !item.children.length) && item.type !== 'statistic') {
    item.children = statistic.map(stat => {
      const statTitle = getArrayItemByParam(statistics, 'id', stat.id).title +
        (stat.nplus && stat.values.length ? ` (${stat.values.join(', ')})` : '');

      return {
        type: 'statistic',
        title: {
          ru: statTitle,
          en: statTitle
        },
        children: []
      };
    });
  }

  // Add children to matrix
  if (item.children && item.children.length) {
    item.children.forEach((child, index) => {
      const currentPath = path.slice(0);
      currentPath.push(index);

      const nextRow = matrix.length + (!index ? -1 : 0);

      recursiveRender(locale, matrix, child, type, currentPath, nextRow, depth + 1, statistics, statistic);
    });
  }
};

const findFirstSpecialCellIndex = (row, direction) => {
  const sizeType = direction === 'rows' ? 'colSpan' : 'rowSpan';

  for (let i = 0; i < row.length; i++) {
    if (
      row[i].type !== 'skip' &&
      row[i][sizeType] >= 1
    ) {
      return i;
    }
  }

  return 0;
};

const convertMatrixAxis = matrix => (
  matrix[0].map((col, i) => (
    matrix.map(row => row[i])
  ))
);

export const generateMatrix = (locale, data, measures, dataTree, direction = 'rows', statistic = null) => {
  const sizeType = direction === 'rows' ? 'colSpan' : 'rowSpan';
  const revertSizeType = direction === 'rows' ? 'rowSpan' : 'colSpan';

  const matrix = [];

  dataTree.forEach((item, index) => {
    recursiveRender(locale, matrix, item, direction, [index], matrix.length, 0, measures, statistic);
  });

  // Get max depth
  const maxDepth = matrix.reduce((prevDepth, row) => Math.max(prevDepth, row.length), 0);

  if (!data) {
    addAddingDataButtonsToMatrix(matrix, direction, maxDepth);
  }

  // Add colSpan/rowSpan for head cells + add skip cells to empty places
  matrix.forEach((row, rowIndex) => {
    const currentSize = maxDepth - row.length + 1;

    if (currentSize > 1) {
      const firstSpecialCellIndex = findFirstSpecialCellIndex(row, direction);

      row[firstSpecialCellIndex][revertSizeType] = currentSize;

      for (let i = 0; i < currentSize - 1; i++) {
        for (let j = 0; j < row[firstSpecialCellIndex][sizeType]; j++) {
          matrix[j + rowIndex].splice(i + firstSpecialCellIndex + 1, 0, {
            type: 'skip',
            place: {
              link: row[firstSpecialCellIndex],
              position: [rowIndex, firstSpecialCellIndex]
            }
          });
        }
      }
    }
  });

  if (!data) {
    addAdditionCells(matrix, direction);
  }

  // Add empty cells
  for (let i = 0; i < matrix.length; ++i) {
    for (let j = 0; j < matrix[0].length; ++j) {
      if (!matrix[i][j]) {
        matrix[i][j] = {
          type: 'cell',
          colSpan: 1,
          rowSpan: 1
        };
      }
    }
  }

  // Convert vertical matrix to horizontal
  if (direction === 'rows' && matrix.length) {
    return convertMatrixAxis(matrix);
  }

  return matrix;
};

const addCustomMeasureRecursive = (item, customMeasure, universeMeasureIndex) => {
  if (!item || !Array.isArray(item.children) || !item.children.length) {
    return;
  }

  if (item.children[0].nodeType === 'MEASURE_VALUE') {
    item.children.splice(universeMeasureIndex + 1, 0, {
      id: customMeasure.id,
      title: {
        ru: customMeasure.title,
        en: customMeasure.title
      },
      nodeType: 'MEASURE_VALUE',
      affinity: false,
      nplus: false,
      values: []
    });

    return;
  }

  item.children.forEach(cItem => {
    addCustomMeasureRecursive(cItem, customMeasure, universeMeasureIndex);
  });
};

const calculateAllChildren = (tree) => {
  let sum = 0;

  if (tree.length && tree[0].nodeType === 'MEASURE_VALUE') {
    return sum + 1;
  }

  for (let i = 0; i < tree.length; i += 1) {
    sum += tree[i].children && tree[i].children.length ? calculateAllChildren(tree[i].children) : 1;
  }

  return sum;
};

export const findAllRespondentsGroup = (categories) => {
  for (let i = 0; i < categories.length; i += 1) {
    if (
      categories[i].title.ru === locales.ru['persona.table.cells.allRespondents'] ||
      categories[i].title.en === locales.en['persona.table.cells.allRespondents']
    ) {
      return categories[i];
    }

    if (categories[i].list && categories[i].list.length) {
      const foundData = findAllRespondentsGroup(categories[i].list);

      if (foundData) {
        return foundData;
      }
    }
  }

  return null;
};

export const hasSpecificAllRespondents = (item, isBackendFormat = false) => {
  return (
    (
      locales.ru['persona.table.cells.allRespondents'] === (!isBackendFormat ? item.title.ru : item.title) ||
      locales.en['persona.table.cells.allRespondents'] === (!isBackendFormat ? item.title.en : item.titleEnglish)
    ) &&
    (
      !item.children.length ||
      item.children[0].nodeType === 'MEASURE_VALUE'
    )
  );
};

const findAllRespondentStartIndex = (tree) => {
  let index = 0;

  for (let i = 0; i < tree.length; i += 1) {
    if (hasSpecificAllRespondents(tree[i])) {
      return index;
    }

    index += calculateAllChildren(tree[i].children) || 1;
  }

  return index;
};

export const addCustomMeasures = (data) => {
  const filteredStatistics = data.statistic.items.filter(item => (
    ![
      CUSTOM_MEASURE_HORZ_ID,
      CUSTOM_MEASURE_VERT_ID,
      CUSTOM_MEASURE_INDEX_ID
    ].includes(item.id)
  ));

  const universeMeasureIndex = filteredStatistics.findIndex(item => item.title === 'Universe');

  if (universeMeasureIndex < 0) {
    return;
  }

  const horzMeasure = data.statistic.items.find(item => item.id === CUSTOM_MEASURE_HORZ_ID);
  const vertMeasure = data.statistic.items.find(item => item.id === CUSTOM_MEASURE_VERT_ID);
  const indexMeasure = data.statistic.items.find(item => item.id === CUSTOM_MEASURE_INDEX_ID);

  const allRespondentRowStartIndex = findAllRespondentStartIndex(data.verticalDataTree);
  const allRespondentColumnStartIndex = findAllRespondentStartIndex(data.horizontalDataTree);

  if (horzMeasure) {
    addCustomMeasureRecursive(
      {
        children: data.statistic.axis === 'rows' ? data.horizontalDataTree : data.verticalDataTree
      },
      {
        id: CUSTOM_MEASURE_HORZ_ID,
        title: 'Horz %'
      },
      universeMeasureIndex
    );

    if (data.statistic.axis === 'rows') {
      data.matrix.forEach((row, rowIndex) => {
        const allRespondentUniverse =
          row[allRespondentColumnStartIndex * filteredStatistics.length + universeMeasureIndex];

        let columnIndex = row.length - 1;
        while (columnIndex >= 0) {
          if (columnIndex % filteredStatistics.length === universeMeasureIndex) {
            const value = row[columnIndex];
            row.splice(columnIndex + 1, 0, Math.round(value / allRespondentUniverse * 10000) / 100);
          }

          columnIndex -= 1;
        }
      });
    } else {
      let rowIndex = data.matrix.length - 1;
      while (rowIndex >= 0) {
        if (rowIndex % filteredStatistics.length === universeMeasureIndex) {
          const row = data.matrix[rowIndex];
          const allRespondentUniverse = row[allRespondentColumnStartIndex];
          const newRow = [];

          // eslint-disable-next-line no-loop-func
          row.forEach((value, columnIndex) => {
            newRow.push(Math.round(value / allRespondentUniverse * 10000) / 100);
          });

          data.matrix.splice(rowIndex + 1, 0, newRow);
        }

        rowIndex -= 1;
      }
    }
  }

  if (vertMeasure || indexMeasure) {
    addCustomMeasureRecursive(
      {
        children: data.statistic.axis === 'rows' ? data.horizontalDataTree : data.verticalDataTree
      },
      {
        id: CUSTOM_MEASURE_VERT_ID,
        title: 'Vert %'
      },
      universeMeasureIndex + (horzMeasure ? 1 : 0)
    );

    if (data.statistic.axis === 'rows') {
      const allRespondentUniverseRow = data.matrix[allRespondentRowStartIndex];

      if (!allRespondentUniverseRow) {
        return;
      }

      data.matrix.forEach((row, rowIndex) => {
        let columnIndex = row.length - 1;
        while (columnIndex >= 0) {
          if (columnIndex % (filteredStatistics.length + (horzMeasure ? 1 : 0)) === universeMeasureIndex) {
            const allRespondentUniverse = allRespondentUniverseRow[columnIndex];
            const value = row[columnIndex];

            row.splice(
              columnIndex + 1 + (horzMeasure ? 1 : 0),
              0,
              Math.round(value / allRespondentUniverse * 10000) / 100
            );
          }

          columnIndex -= 1;
        }
      });
    } else {
      const allRespondentUniverseRowIndex =
        allRespondentRowStartIndex * (filteredStatistics.length + (horzMeasure ? 1 : 0)) + universeMeasureIndex;
      const allRespondentUniverseRow = data.matrix[allRespondentUniverseRowIndex];

      if (!allRespondentUniverseRow) {
        return;
      }

      let rowIndex = data.matrix.length - 1;
      while (rowIndex >= 0) {
        if (rowIndex % (filteredStatistics.length + (horzMeasure ? 1 : 0)) === universeMeasureIndex) {
          const row = data.matrix[rowIndex];
          const newRow = [];

          // eslint-disable-next-line no-loop-func
          row.forEach((value, columnIndex) => {
            const allRespondentUniverse = allRespondentUniverseRow[columnIndex];

            newRow.push(Math.round(value / allRespondentUniverse * 10000) / 100);
          });

          data.matrix.splice(rowIndex + 1 + (horzMeasure ? 1 : 0), 0, newRow);
        }

        rowIndex -= 1;
      }
    }
  }

  if (indexMeasure) {
    addCustomMeasureRecursive(
      {
        children: data.statistic.axis === 'rows' ? data.horizontalDataTree : data.verticalDataTree
      },
      {
        id: CUSTOM_MEASURE_INDEX_ID,
        title: 'Index'
      },
      universeMeasureIndex + (horzMeasure ? 1 : 0) + 1
    );

    const vertIndex = universeMeasureIndex + (horzMeasure ? 1 : 0) + 1;
    if (data.statistic.axis === 'rows') {
      data.matrix.forEach((row, rowIndex) => {
        const allRespondentsVertValue =
          row[allRespondentColumnStartIndex * (filteredStatistics.length + (horzMeasure ? 1 : 0) + 1) + vertIndex];

        let columnIndex = row.length - 1;
        while (columnIndex >= 0) {
          if (columnIndex % (filteredStatistics.length + 1 + (horzMeasure ? 1 : 0)) === vertIndex) {
            const value = row[columnIndex];
            row.splice(columnIndex + 1, 0, Math.round(value / allRespondentsVertValue * 10000) / 100);
          }

          columnIndex -= 1;
        }
      });
    } else {
      let rowIndex = data.matrix.length - 1;
      while (rowIndex >= 0) {
        if (rowIndex % (filteredStatistics.length + 1 + (horzMeasure ? 1 : 0)) === vertIndex) {
          const row = data.matrix[rowIndex];
          const allRespondentVert = row[allRespondentColumnStartIndex];
          const newRow = [];

          // eslint-disable-next-line no-loop-func
          row.forEach((value, columnIndex) => {
            newRow.push(Math.round(value / allRespondentVert * 10000) / 100);
          });

          data.matrix.splice(rowIndex + 1, 0, newRow);
        }

        rowIndex -= 1;
      }
    }
  }

  if (indexMeasure) {
    filteredStatistics.splice(universeMeasureIndex + 1, 0, indexMeasure);
  }

  if (vertMeasure) {
    filteredStatistics.splice(universeMeasureIndex + 1, 0, vertMeasure);
  }

  if (horzMeasure) {
    filteredStatistics.splice(universeMeasureIndex + 1, 0, horzMeasure);
  }

  data.statistic.items = filteredStatistics;
};

export const getTableData = (data, tableStructure, direction) => {
  if (data) {
    const parsedData = parseTableData(data, direction);

    addCustomMeasures(parsedData);

    return {
      verticalDataTree: parsedData.verticalDataTree,
      horizontalDataTree: parsedData.horizontalDataTree,
      statisticAxis: parsedData.statistic.axis,
      statisticItems: parsedData.statistic.items,
      dataMatrix: parsedData.matrix
    };
  }

  return tableStructure;
};

const sortMatrix = ({matrixVertical, matrixHorizontal, tableMatrix}, axis, sortedBy) => {
  if (!sortedBy) {
    return {
      matrixVertical,
      matrixHorizontal,
      tableMatrix
    };
  }

  let copyMatrix = tableMatrix;
  let copyMatrixHorizontal = matrixHorizontal;

  if (axis === 'columns') {
    copyMatrix = convertMatrixAxis(copyMatrix);
    copyMatrixHorizontal = convertMatrixAxis(copyMatrixHorizontal);
  }

  const sortedData = copyMatrix.map((row, index) => ({
    index,
    content: row[sortedBy.index].content
  })).sort((a, b) => {
    if (typeof a.content !== 'number') {
      return 1;
    }

    if (typeof b.content !== 'number') {
      return -1;
    }

    return sortedBy.direction === 'desc'
      ? a.content - b.content
      : b.content - a.content;
  });

  const indexes = sortedData.map(d => d.index);

  let resultDataMatrix = [];
  const resultMatrixVertical = axis === 'rows' ? [] : matrixVertical;
  let resultMatrixHorizontal = axis === 'columns' ? [] : matrixHorizontal;

  for (let k = 0; k < indexes.length; k++) {
    resultDataMatrix.push(copyMatrix[indexes[k]].slice());

    if (axis === 'rows') {
      resultMatrixVertical.push(matrixVertical[indexes[k]].slice());
    } else {
      resultMatrixHorizontal.push(copyMatrixHorizontal[indexes[k]].slice());
    }
  }

  if (axis === 'columns') {
    resultDataMatrix = convertMatrixAxis(resultDataMatrix);
    resultMatrixHorizontal = convertMatrixAxis(resultMatrixHorizontal);
  }

  return {
    matrixVertical: resultMatrixVertical,
    matrixHorizontal: resultMatrixHorizontal,
    tableMatrix: resultDataMatrix
  };
};

export const generateData = (locale, data, measures, tableStructure, direction, sortedBy = null) => {
  const {
    verticalDataTree,
    horizontalDataTree,
    statisticAxis,
    statisticItems,
    dataMatrix
  } = getTableData(data, tableStructure, direction);

  const verticalStatistic =
    !data &&
    statisticAxis === 'columns' &&
    statisticItems.length
      ? statisticItems
      : null;
  const horizontalStatistic =
    !data &&
    statisticAxis === 'rows' &&
    statisticItems.length
      ? statisticItems
      : null;

  const matrixVertical = generateMatrix(locale, data, measures, verticalDataTree, 'columns', verticalStatistic);
  const matrixHorizontal = generateMatrix(locale, data, measures, horizontalDataTree, 'rows', horizontalStatistic);

  if (sortedBy) {
    (
      direction === 'x'
        ? matrixVertical[sortedBy.index][matrixVertical[sortedBy.index].length - 1]
        : matrixHorizontal[matrixHorizontal.length - 1][sortedBy.index]
    ).sortedDirection = sortedBy.direction;
  }

  const tableMatrix = [];

  for (let i = 0; i < matrixVertical.length; i++) {
    tableMatrix[i] = [];

    for (let j = 0; j < matrixHorizontal[0].length; j++) {
      const content =
        dataMatrix &&
        dataMatrix[i] &&
        typeof dataMatrix[i][j] !== 'undefined' &&
        dataMatrix[i][j] !== null &&
        dataMatrix[i][j].toString().length &&
        ['number', 'string'].includes(typeof dataMatrix[i][j])
          ? parseFloat(dataMatrix[i][j])
          : '';

      tableMatrix[i][j] = {
        type: 'cell',
        content: content,
        colSpan: 1,
        rowSpan: 1
      };
    }
  }

  return sortMatrix({
    matrixVertical,
    matrixHorizontal,
    tableMatrix
  }, statisticAxis, sortedBy);

  // return removeEmptyRowsAndColumns(matrixVertical, matrixHorizontal, tableMatrix);
};

// const removeEmptyRowsAndColumns = (sourceMatrixVertical, sourceMatrixHorizontal, sourceTableMatrix) => {
//   const m2 = sourceMatrixHorizontal[0].length;
//   const n1 = sourceMatrixHorizontal.length;
//   const n2 = sourceMatrixVertical.length;
//
//   /* Flags answers on question - how much non-empty elements found in i-th row or j-th column */
//   const nFlags = new Array(n2).fill(0);
//   const mFlags = new Array(m2).fill(0);
//
//   for (let i = 0; i < n2; ++i) {
//     for (let j = 0; j < m2; ++j) {
//       // XXX checking if content is not NAN => mark column and row as used to not remove
//       // XXX this is hack, better use type-value matching to detect empty cells
//       if (!Number.isNaN(sourceTableMatrix[i][j].content)) {
//         nFlags[i]++;
//         mFlags[j]++;
//       }
//     }
//   }
//
//   const matrixVertical = [];
//   for (let i = 0; i < n2; ++i) {
//     if (nFlags[i]) {
//       matrixVertical.push(sourceMatrixVertical[i]);
//     }
//   }
//
//   const matrixHorizontal = [];
//   for (let i = 0; i < n1; ++i) {
//     const newRow = [];
//
//     for (let j = 0; j < m2; ++j) {
//       if (mFlags[j]) {
//         newRow.push(sourceMatrixHorizontal[i][j]);
//       }
//     }
//
//     matrixHorizontal.push(newRow);
//   }
//
//   // add only non-empty rows
//   const tableMatrix = [];
//   for (let i = 0; i < n2; ++i) {
//     if (nFlags[i] > 0) {
//       const currentRow = [];
//
//       for (let j = 0; j < m2; ++j) {
//         if (mFlags[j] > 0) {
//           currentRow.push(sourceTableMatrix[i][j]);
//         }
//       }
//
//       tableMatrix.push(currentRow);
//     }
//   }
//
//   return {
//     matrixVertical,
//     matrixHorizontal,
//     tableMatrix
//   };
// };

export const generateTable = (locale, data, measures, tableStructure, direction, generatedData = null) => {
  const {
    matrixVertical,
    matrixHorizontal,
    tableMatrix
  } = generatedData || generateData(locale, data, measures, tableStructure, direction);

  const n1 = matrixVertical.length;
  const m1 = matrixVertical[0].length;
  const n2 = matrixHorizontal.length;
  const m2 = matrixHorizontal[0].length;

  const c1 = n1 + n2;
  const c2 = m1 + m2;

  const result = new Array(c1);

  for (let i = 0; i < c1; ++i) {
    result[i] = new Array(c2).fill(null);
  }

  for (let i = 0; i < n1; ++i) {
    for (let j = 0; j < m1; ++j) {
      result[i + n2][j] = matrixVertical[i][j];
    }
  }

  for (let i = 0; i < n2; ++i) {
    for (let j = 0; j < m2; ++j) {
      result[i][j + m1] = matrixHorizontal[i][j];
    }
  }

  for (let i = 0; i < n1; ++i) {
    for (let j = 0; j < m2; ++j) {
      result[i + n2][j + m1] = tableMatrix[i][j] || {type: 'cell'};
    }
  }

  const rows = [];

  for (let i = 0; i < result.length; ++i) {
    const cellsLength = result[i].length;
    const cells = [];

    for (let j = 0; j < cellsLength; ++j) {
      if (j >= 0 && j < m1 && i >= 0 && i < n2) {
        cells[j] =
          j === 0 &&
          i === 0
            ? {
              type: 'filter',
              active: !data,
              colSpan: m1,
              rowSpan: n2
            }
            : {
              type: 'skip',
              place: {
                link: i === 0 ? cells[0] : rows[0][0],
                position: [0, 0]
              }
            };
      } else {
        cells[j] = result[i][j] || null;
      }
    }

    rows[i] = cells;
  }

  return rows;
};

export const validateCube = (cube) => {
  // TODO: add validation
  // try {
  //   parseTableData(cube, 'y');
  // } catch (error) {
  //   console.error(error);
  //
  //   return false;
  // }

  return true;
};
