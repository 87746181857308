import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import Tooltip from '../../../../../components/Tooltip';
import {ContextMenuTrigger} from '../../../../../components/ContextMenu';
import StaticCell from './StaticCell';
import LazyCell from './LazyCell';
import Filter from './Filter';
import AddBtn from './AddBtn';
import {generateFormulaString} from '../../../helpers/formula';
import classes from '../Table.module.scss';

class Cell extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.shape({
      type: PropTypes.string.isRequired,
      path: PropTypes.array,
      direction: PropTypes.string,
      colSpan: PropTypes.number,
      rowSpan: PropTypes.number,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    }),
    cell: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      colSpan: PropTypes.number,
      rowSpan: PropTypes.number
    }),
    cellWidth: PropTypes.number,
    cellHeight: PropTypes.number,
    disableGrouping: PropTypes.bool.isRequired,
    useStaticTable: PropTypes.bool.isRequired,
    globalFilter: PropTypes.object,
    catalog: PropTypes.object,
    subscribeOnScroll: PropTypes.func,
    changingCell: PropTypes.shape({
      direction: PropTypes.string.isRequired,
      path: PropTypes.arrayOf(
        PropTypes.number
      ).isRequired
    }),
    getContainer: PropTypes.func,
    openFilter: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    sortByCell: PropTypes.func
  };

  static defaultProps = {
    useStaticTable: false,
    disableGrouping: false
  };

  getContent = () => {
    const {locale, data, globalFilter, catalog, getContainer, useStaticTable} = this.props;
    const {formula, type, content} = data || {};

    switch (type) {
      case 'filter':
        return (
          <Filter
            globalFilter={globalFilter}
            catalog={catalog}
            useStaticTable={useStaticTable}
          />
        );
      case 'add-btn':
        return (
          <AddBtn />
        );
      default:
        let currentContent = typeof content !== 'undefined' ? content.toString() : '';

        if (
          (
            typeof content === 'undefined' ||
            !content.length
          ) &&
          formula
        ) {
          currentContent = generateFormulaString(locale, formula.data);
        }

        if (useStaticTable) {
          return (
            <Tooltip
              container={getContainer}
              overlay={currentContent}
            >
              <div className={classes.CellContent}>
                {currentContent}
              </div>
            </Tooltip>
          );
        }

        return (
          <div
            className={cx(
              classes.CellContent,
              {
                [classes.CellContentEmpty]: !currentContent.length
              }
            )}
          >
            {currentContent}
          </div>
        );
    }
  };

  getHeadCell = (content, isActive, localizedMessages) => {
    const {data, openFilter, deleteCell} = this.props;
    const {formula, direction, path, colSpan, rowSpan, like} = data || {};

    let rightIndex = null;
    const rightPath = path.slice(0);
    let bottomIndex = null;
    const bottomPath = path.slice(0);

    if (direction === 'rows') {
      rightIndex = rightPath.pop();
    } else {
      bottomIndex = bottomPath.pop();
    }

    const menuItems = [
      {
        label: localizedMessages.addCellToTheRight,
        onClick: () => openFilter(direction, rightPath, rightIndex)
      },
      {
        label: localizedMessages.addCellFromBelow,
        onClick: () => openFilter(direction, bottomPath, bottomIndex)
      },
      {
        label: localizedMessages.editCell,
        onClick: () => openFilter(direction, path.slice(0, -1), path.slice(-1)[0], like || 'cell', formula)
      },
      {
        label: localizedMessages.deleteCell,
        onClick: () => deleteCell(direction, path)
      }
    ];

    return (
      <ContextMenuTrigger
        renderTag='td'
        holdToDisplay={0}
        className={cx(
          classes.Cell,
          classes.CellHead,
          classes.CellPointer,
          {
            [classes.CellActive]: isActive
          }
        )}
        colSpan={colSpan}
        rowSpan={rowSpan}
        items={menuItems}
      >
        {content}
      </ContextMenuTrigger>
    );
  };

  render () {
    const {
      data,
      cell,
      cellWidth,
      cellHeight,
      disableGrouping,
      useStaticTable,
      subscribeOnScroll,
      changingCell,
      getContainer,
      openFilter,
      sortByCell
    } = this.props;
    const {id, formula, type, nodeType} = data || {};

    if (type === 'skip') {
      return null;
    }

    const content = this.getContent();

    if (useStaticTable) {
      if (
        type === 'head' &&
        !nodeType &&
        (
          typeof id === 'number' ||
          formula
        )
      ) {
        const isActive =
          changingCell &&
          changingCell.direction === data.direction &&
          changingCell.path.join('-') === data.path.join('-');

        return (
          <LocalizedMessage
            id={[
              'persona.table.cells.contextMenu.addCellToTheRight',
              'persona.table.cells.contextMenu.addCellFromBelow',
              'persona.table.cells.contextMenu.editCell',
              'persona.table.cells.contextMenu.deleteCell'
            ]}
          >
            {(addCellToTheRight, addCellFromBelow, editCell, deleteCell) => (
              this.getHeadCell(
                content,
                isActive,
                {
                  addCellToTheRight,
                  addCellFromBelow,
                  editCell,
                  deleteCell
                }
              )
            )}
          </LocalizedMessage>
        );
      }

      return (
        <StaticCell
          content={content}
          data={data}
          openFilter={openFilter}
        />
      );
    }

    return (
      <LazyCell
        content={content}
        cell={cell}
        cellWidth={cellWidth}
        cellHeight={cellHeight}
        disableGrouping={disableGrouping}
        subscribeOnScroll={subscribeOnScroll}
        getContainer={getContainer}
        sortByCell={sortByCell}
      />
    );
  }
}

export default Cell;
