import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import LocalizedMessage from '../../../../../components/LocalizedMessage';
import DatePicker from '../../../../../components/DatePicker';
import DropPanel from '../../../../../components/DropPanel';
import Tooltip from '../../../../../components/Tooltip';
import Button from '../../../../../components/Button';
import Checkbox from '../../../../../components/Checkbox';
import classes from '../Content.module.scss';

class DateRange extends Component {
  static propTypes = {
    eventMinDate: moment.isMoment,
    eventMaxDate: moment.isMoment,
    eventBetweenStart: moment.isMoment,
    eventBetweenEnd: moment.isMoment,
    eventAverageDay: moment.isMoment,
    eventAverageDayWithAutoCalculation: PropTypes.bool.isRequired,
    changeFilterDates: PropTypes.func.isRequired
  };

  state = {
    start: this.props.eventBetweenStart,
    end: this.props.eventBetweenEnd,
    average: this.props.eventAverageDay,
    averageAuto: this.props.eventAverageDayWithAutoCalculation
  };

  _dropPanel = null;

  setDropPanelRef = ref => {
    this._dropPanel = ref;
  };

  getEventAverageDay = (isAuto = false) => {
    const {start, end, average, averageAuto} = this.state;

    if (!isAuto && !averageAuto && average) {
      return average;
    }

    return moment(
      (start + end) / 2
    );
  };

  toggleEventAverageDay = () => {
    const {averageAuto} = this.state;

    this.setState({
      averageAuto: !averageAuto,
      average: this.getEventAverageDay(!averageAuto)
    });
  };

  changeFilterDate = (type, date) => {
    const {
      start,
      end,
      average,
      averageAuto
    } = this.state;

    const nextState = {
      [type]: date
    };

    if (type === 'start') {
      if (end.isBefore(date)) {
        nextState.end = date.clone();
      }

      if (
        !averageAuto &&
        average.isBefore(date)
      ) {
        nextState.average = date.clone();
      }
    } else if (type === 'end') {
      if (date.isBefore(start)) {
        nextState.start = date.clone();
      }

      if (
        !averageAuto &&
        date.isBefore(average)
      ) {
        nextState.average = date.clone();
      }
    }

    this.setState(nextState, () => {
      if (averageAuto) {
        this.setState({
          average: this.getEventAverageDay(true)
        });
      }
    });
  };

  onDropPanelToggle = (status) => {
    if (!status) {
      this.setState({
        start: this.props.eventBetweenStart,
        end: this.props.eventBetweenEnd,
        average: this.props.eventAverageDay,
        averageAuto: this.props.eventAverageDayWithAutoCalculation
      });
    }
  };

  save = () => {
    const {start, end, average, averageAuto} = this.state;

    this.props.changeFilterDates({
      eventBetweenStart: start,
      eventBetweenEnd: end,
      eventAverageDay: average,
      eventAverageDayWithAutoCalculation: averageAuto
    }, () => {
      if (this._dropPanel) {
        this._dropPanel.toggle(false);
      }

      this.setState({
        start,
        end,
        average,
        averageAuto
      });
    });
  }

  render () {
    const {
      eventMinDate,
      eventMaxDate,
      eventBetweenStart,
      eventBetweenEnd,
      eventAverageDay
    } = this.props;
    const {
      start,
      end,
      average,
      averageAuto
    } = this.state;

    return (
      <LocalizedMessage
        id='persona.table.headerRow.dates.avg'
      >
        {localizedTitle => (
          <DropPanel
            ref={this.setDropPanelRef}
            title={
              `${eventBetweenStart.format('DD.MM.YYYY')} - ${eventBetweenEnd.format('DD.MM.YYYY')}` +
              ` (${localizedTitle}: ${eventAverageDay.format('DD.MM.YYYY')})`
            }
            className={classes.ContentHeaderDropPanel}
            titleClassName={classes.ContentHeaderDropPanelDateTitle}
            contentClassName={cx(
              classes.ContentHeaderDropPanelWrapper,
              classes.ContentHeaderDropPanelWrapperDateRange
            )}
            onToggle={this.onDropPanelToggle}
          >
            <div className={classes.ContentHeaderDropPanelRow}>
              <p className={cx(
                classes.ContentHeaderDropPanelLabel,
                classes.ContentHeaderDropPanelLabelFixedWidth
              )}>
                <LocalizedMessage
                  id='persona.table.headerRow.dates.startDate'
                />
              </p>
              <div className={classes.ContentHeaderDropPanelValue}>
                <DatePicker
                  selected={start}
                  selectsStart
                  startDate={start}
                  endDate={end}
                  minDate={eventMinDate}
                  maxDate={eventMaxDate}
                  onChange={value => this.changeFilterDate('start', value)}
                />
              </div>
            </div>
            <div className={classes.ContentHeaderDropPanelRow}>
              <p className={cx(
                classes.ContentHeaderDropPanelLabel,
                classes.ContentHeaderDropPanelLabelFixedWidth
              )}>
                <LocalizedMessage
                  id='persona.table.headerRow.dates.endDate'
                />
              </p>
              <div className={classes.ContentHeaderDropPanelValue}>
                <DatePicker
                  selected={end}
                  selectsEnd
                  startDate={start}
                  endDate={end}
                  minDate={eventMinDate}
                  maxDate={eventMaxDate}
                  onChange={value => this.changeFilterDate('end', value)}
                />
              </div>
            </div>
            <div className={classes.ContentHeaderDropPanelRow}>
              <p className={cx(
                classes.ContentHeaderDropPanelLabel,
                classes.ContentHeaderDropPanelLabelFixedWidth
              )}>
                <LocalizedMessage
                  id='persona.table.headerRow.dates.averageDate'
                />
                <Tooltip
                  overlay={
                    <p className={classes.ContentHeaderDropPanelLabelTooltip}>
                      <strong>
                        <LocalizedMessage
                          id='persona.table.headerRow.dates.averageDate'
                        />
                      </strong>
                      <LocalizedMessage
                        id='persona.table.headerRow.dates.averageDateDescription'
                      />
                    </p>
                  }
                >
                  <span
                    className={cx(
                      classes.ContentHeaderDropPanelLabelIcon,
                      'icon-info'
                    )}
                  />
                </Tooltip>
              </p>
              <div className={classes.ContentHeaderDropPanelValue}>
                <DatePicker
                  disabled={averageAuto}
                  selected={average}
                  startDate={start}
                  endDate={end}
                  minDate={moment.max(eventMinDate, start)}
                  maxDate={moment.min(eventMaxDate, end)}
                  onChange={value => this.changeFilterDate('average', value)}
                />
              </div>
            </div>
            <Checkbox
              name='i-want-to-secify-an-average-date'
              className={classes.ContentHeaderDropPanelCheckbox}
              checked={!averageAuto}
              onChange={this.toggleEventAverageDay}
            >
              <LocalizedMessage
                id='persona.table.headerRow.dates.iWantToSpecifyAnAverageDate'
              />
            </Checkbox>
            <div className={classes.ContentHeaderDropPanelButtons}>
              <Button
                size='small'
                onClick={this.save}
              >
                <LocalizedMessage
                  id='base.save'
                />
              </Button>
            </div>
          </DropPanel>
        )}
      </LocalizedMessage>
    );
  }
}

export default DateRange;
