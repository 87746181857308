import {connect} from 'react-redux';
import DatePicker from './DatePicker';

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  locale: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
