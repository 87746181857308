import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import LazyTableBlock from './LazyTableBlock';
import Filter from './Cell/Filter';
import {copyTextToClipboard} from '../../../../helpers/clipboard';
import {generateData} from '../../helpers/table';
import classes from './Table.module.scss';
import Config from '../../../../config';
import alert from '../../../../helpers/alert';
import {localizeMessage} from '../../../../components/LocalizedMessage';

class LazyTable extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    measures: PropTypes.array.isRequired,
    tableStructure: PropTypes.shape({
      verticalDataTree: PropTypes.array,
      horizontalDataTree: PropTypes.array,
      statisticAxis: PropTypes.oneOf(['rows', 'columns']).isRequired,
      statisticItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          affinity: PropTypes.bool.isRequired,
          nplus: PropTypes.bool.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.string
          )
        })
      ).isRequired
    }),
    data: PropTypes.object,
    direction: PropTypes.oneOf(['x', 'y']).isRequired,
    sortedBy: PropTypes.shape({
      direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
      index: PropTypes.number.isRequired
    }),
    catalog: PropTypes.object.isRequired,
    globalFilter: PropTypes.shape({
      ta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      event: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-tv': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-web': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      })
    }).isRequired,
    openFilter: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    sortByCell: PropTypes.func.isRequired,
    setCopyTableFn: PropTypes.func.isRequired
  };

  state = {
    leftSideWidth: 0,
    leftSideHeight: 0,
    leftSideColumnsCount: 0,
    topSideHeight: 0,
    topSideColumnsCount: 0,
    stretchWidth: 0,
    lazyTableMaxLeftSideWidth: Config.appOptions.LAZY_TABLE_MAX_LEFT_SIDE_WIDTH,
    lazyTableCellWidth: Config.appOptions.LAZY_TABLE_CELL_WIDTH,
    lazyTableCellHeight: Config.appOptions.LAZY_TABLE_CELL_HEIGHT
  };

  _lazyTableContent = null;
  _topTable = null;
  _leftTable = null;
  _centerTable = null;
  scrollLeft = 0;
  scrollTop = 0;
  scrollSide = null;
  scrollSideTimer = 0;

  componentDidMount () {
    setTimeout(() => {
      this.recalculateLazyTableCellWidth();
    });

    this.props.setCopyTableFn(this.copyTable);
  }

  componentWillUnmount () {
    this.props.setCopyTableFn(null);
  }

  componentDidUpdate () {
    if (this.props.data) {
      this.recalculateLazyTableCellWidth();
    }
  }

  setLazyTableContentRef = (ref) => {
    this._lazyTableContent = ref;
  };

  setTopTableRef = (ref) => {
    this._topTable = ref;
  };

  setLeftTableRef = (ref) => {
    this._leftTable = ref;
  };

  setCenterTableRef = (ref) => {
    this._centerTable = ref;
  };

  recalculateLazyTableCellWidth = () => {
    const {leftSideColumnsCount, topSideColumnsCount} = this.state;

    if (!leftSideColumnsCount || !topSideColumnsCount) {
      return;
    }

    const {lazyTableCellWidth, lazyTableMaxLeftSideWidth, stretchWidth} = this.state;
    const newCellWidth = Math.round(this._lazyTableContent.offsetWidth / (leftSideColumnsCount + topSideColumnsCount));
    let stretch = 0;

    if (Config.appOptions.LAZY_TABLE_CELL_WIDTH > newCellWidth) {
      const calculateStretch = (this._lazyTableContent.offsetWidth - lazyTableMaxLeftSideWidth) / topSideColumnsCount;
      stretch = Math.max(calculateStretch, Config.appOptions.LAZY_TABLE_CELL_WIDTH);
    }

    if (stretchWidth === 0 || newCellWidth > Config.appOptions.LAZY_TABLE_CELL_WIDTH) {
      if (
        lazyTableCellWidth !== newCellWidth ||
        lazyTableMaxLeftSideWidth !== 1000
      ) {
        this.setState({
          lazyTableCellWidth: newCellWidth,
          lazyTableMaxLeftSideWidth: 1000,
          stretchWidth: stretch
        });
      }
    } else if (
      lazyTableCellWidth !== Config.appOptions.LAZY_TABLE_CELL_WIDTH ||
      lazyTableMaxLeftSideWidth !== Config.appOptions.LAZY_TABLE_MAX_LEFT_SIDE_WIDTH
    ) {
      this.setState({
        lazyTableCellWidth: Config.appOptions.LAZY_TABLE_CELL_WIDTH,
        lazyTableMaxLeftSideWidth: Config.appOptions.LAZY_TABLE_MAX_LEFT_SIDE_WIDTH
      });
    }
  };

  onResize = (sizes, side) => {
    if (side === 'top') {
      this.setState({
        topSideHeight: sizes.height,
        topSideColumnsCount: sizes.cells.columns
      });
    } else {
      this.setState({
        leftSideWidth: sizes.width,
        leftSideHeight: sizes.height,
        leftSideColumnsCount: sizes.cells.columns
      });
    }
  };

  onResizeLeft = (sizes) => {
    this.onResize(sizes, 'left');
  };

  onResizeTop = (sizes) => {
    this.onResize(sizes, 'top');
  };

  onScroll = ({left, top}, side) => {
    if (this.scrollSide && this.scrollSide !== side) {
      return;
    }

    this.scrollSide = side;
    this.restartScrollSideTimer();

    if (side === 'top' || side === 'center') {
      this.scrollLeft = left;
    }
    if (side === 'left' || side === 'center') {
      this.scrollTop = top;
    }

    this._topTable.onScrollAction({left, top}, side);
    this._leftTable.onScrollAction({left, top}, side);
    this._centerTable.onScrollAction({left, top}, side);
  };

  onScrollLeft = (position) => {
    this.onScroll(position, 'left');
  };

  onScrollTop = (position) => {
    this.onScroll(position, 'top');
  };

  onScrollCenter = (position) => {
    this.onScroll(position, 'center');
  };

  restartScrollSideTimer = () => {
    clearTimeout(this.scrollSideTimer);
    this.scrollSideTimer = 0;

    this.scrollSideTimer = setTimeout(() => {
      this.scrollSideTimer = 0;
      this.scrollSide = null;
    }, Config.appOptions.SCROLLING_SIDE_TIME_INTERVAL);
  };

  generateData = () => {
    const {locale, data, measures, tableStructure, direction, sortedBy} = this.props;

    const {
      matrixVertical,
      matrixHorizontal,
      tableMatrix
    } = generateData(locale, data, measures, tableStructure, direction, sortedBy);

    return {
      matrixVertical,
      matrixHorizontal,
      tableMatrix
    };
  };

  copyTable = async () => {
    const {
      matrixVertical,
      matrixHorizontal,
      tableMatrix
    } = this.generateData();

    const resultMatrix = [];

    const verticalSize = (matrixVertical[0] || []).length;

    matrixHorizontal.forEach((row) => {
      const rowData = [];

      for (let k = 0; k < verticalSize; k += 1) {
        rowData.push('');
      }

      for (let l = 0; l < row.length; l += 1) {
        rowData.push(row[l].type === 'skip' ? row[l].place.link.content : row[l].content);
      }

      resultMatrix.push(rowData);
    });

    matrixVertical.forEach((row, index) => {
      const rowData = [];

      for (let k = 0; k < row.length; k += 1) {
        rowData.push(row[k].type === 'skip' ? row[k].place.link.content : row[k].content);
      }

      for (let l = 0; l < tableMatrix[index].length; l += 1) {
        rowData.push(tableMatrix[index][l].content);
      }

      resultMatrix.push(rowData);
    });

    await copyTextToClipboard(
      resultMatrix.map(row => row.join('\t')).join('\n')
    );

    alert.success(localizeMessage({
      id: 'persona.table.alerts.successfulTableCopying'
    }));
  };

  render () {
    const {
      locale,
      catalog,
      direction,
      globalFilter,
      openFilter,
      deleteCell,
      sortedBy,
      sortByCell
    } = this.props;
    const {
      topSideHeight,
      leftSideWidth,
      leftSideHeight,
      lazyTableMaxLeftSideWidth,
      lazyTableCellWidth,
      lazyTableCellHeight,
      stretchWidth
    } = this.state;

    const actualLeftSideWidth = Math.min(leftSideWidth, lazyTableMaxLeftSideWidth);
    const actualTopSideHeight = Math.min(topSideHeight, Config.appOptions.LAZY_TABLE_MAX_TOP_SIDE_HEIGHT);
    const {
      matrixVertical,
      matrixHorizontal,
      tableMatrix
    } = this.generateData();

    return (
      <div
        className={classes.LazyTableContainer}
        style={{
          maxHeight: `${actualTopSideHeight + leftSideHeight}px`
        }}
      >
        <div
          ref={this.setLazyTableContentRef}
          className={classes.LazyTableContent}
        >
          <div
            className={classes.LazyTableBox}
            style={{
              width: `${actualLeftSideWidth}px`,
              height: `${actualTopSideHeight}px`
            }}
          >
            <Filter
              globalFilter={globalFilter}
              catalog={catalog}
              openFilter={openFilter}
              minWidth={actualLeftSideWidth - 1}
            />
          </div>
          <div
            className={cx(
              classes.LazyTableBox,
              classes.LazyTableBoxTop
            )}
            style={{
              left: `${actualLeftSideWidth}px`,
              height: `${actualTopSideHeight}px`
            }}
          >
            <LazyTableBlock
              ref={this.setTopTableRef}
              locale={locale}
              data={matrixHorizontal}
              direction={direction}
              position='top'
              stretchWidth={stretchWidth}
              cutRightBorder
              hideHorizontalScrollbar
              cellWidth={lazyTableCellWidth}
              cellHeight={lazyTableCellHeight}
              disableGrouping={!!(sortedBy && direction === 'x')}
              onResize={this.onResizeTop}
              onScroll={this.onScrollTop}
              openFilter={openFilter}
              deleteCell={deleteCell}
              sortByCell={sortByCell}
            />
          </div>
          <div
            className={cx(
              classes.LazyTableBox,
              classes.LazyTableBoxLeft
            )}
            style={{
              top: `${actualTopSideHeight}px`,
              width: `${actualLeftSideWidth}px`
            }}
          >
            <LazyTableBlock
              ref={this.setLeftTableRef}
              locale={locale}
              data={matrixVertical}
              direction={direction}
              position='left'
              hideVerticalScrollbar
              cellWidth={lazyTableCellWidth}
              cellHeight={lazyTableCellHeight}
              disableGrouping={!!(sortedBy && direction === 'y')}
              onResize={this.onResizeLeft}
              onScroll={this.onScrollLeft}
              openFilter={openFilter}
              deleteCell={deleteCell}
              sortByCell={sortByCell}
            />
          </div>
          <div
            className={cx(
              classes.LazyTableBox,
              classes.LazyTableBoxCenter
            )}
            style={{
              top: `${actualTopSideHeight}px`,
              left: `${actualLeftSideWidth}px`
            }}
          >
            <LazyTableBlock
              ref={this.setCenterTableRef}
              locale={locale}
              data={tableMatrix}
              direction={direction}
              position='center'
              stretchWidth={stretchWidth}
              cutRightBorder
              cellWidth={lazyTableCellWidth}
              cellHeight={lazyTableCellHeight}
              onScroll={this.onScrollCenter}
              openFilter={openFilter}
              deleteCell={deleteCell}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  locale: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(LazyTable);
