import {DropTarget} from 'react-dnd';
import AdditionalItem from './AdditionalItem';

const AdditionalItemWithDropTarget = DropTarget('FORMULA_ITEM', {
  drop ({path, direction}, monitor) {
    if (monitor.didDrop()) {
      return;
    }

    return {
      path,
      direction
    };
  }
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(AdditionalItem);

export default AdditionalItemWithDropTarget;
