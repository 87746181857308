import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage, {localizeMessage} from '../../../../components/LocalizedMessage';
import moment from 'moment';
import alert from '../../../../helpers/alert';
import confirm from '../../../../components/Confirm';
import Button from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import Tabs from '../../../../components/Tabs';
import Switch from '../../../../components/Switch';
import Graph from '../../components/Graph';
import Table from '../../components/Table';
import Loader from '../../../../components/Loader';
import DateRange from './DateRange';
import MeasureSelect from './MeasureSelect';
import {getNPlusValue} from '../../logic/parser';
import {getTitle} from '../../../../helpers';
import classes from './Content.module.scss';

class Content extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    mode: PropTypes.oneOf([
      'view',
      'edit'
    ]),
    data: PropTypes.object,
    title: PropTypes.string,
    catalog: PropTypes.object.isRequired,
    eventBetweenStart: moment.isMoment,
    eventBetweenEnd: moment.isMoment,
    eventAverageDay: moment.isMoment,
    eventAverageDayWithAutoCalculation: PropTypes.bool.isRequired,
    changeFilterDates: PropTypes.func.isRequired,
    tableStructure: PropTypes.shape({
      verticalDataTree: PropTypes.array,
      horizontalDataTree: PropTypes.array,
      statisticAxis: PropTypes.oneOf(['rows', 'columns']).isRequired,
      statisticItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          affinity: PropTypes.bool.isRequired,
          nplus: PropTypes.bool.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.string
          )
        })
      ).isRequired,
      statisticRounding: PropTypes.number.isRequired
    }),
    changingCell: PropTypes.shape({
      direction: PropTypes.string.isRequired,
      path: PropTypes.arrayOf(
        PropTypes.number
      ).isRequired
    }),
    globalFilter: PropTypes.shape({
      ta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      event: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-tv': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      'event-web': PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
      }),
      connector: PropTypes.oneOf(['or', 'and']).isRequired
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    isAuthor: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    renameTemplate: PropTypes.func.isRequired,
    copyTemplate: PropTypes.func.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    updateStatisticAxis: PropTypes.func.isRequired,
    updateStatisticRounding: PropTypes.func.isRequired,
    updateStatisticData: PropTypes.func.isRequired,
    changeGlobalFilterConnector: PropTypes.func.isRequired,
    openFilter: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    calculateCube: PropTypes.func.isRequired,
    saveCube: PropTypes.func.isRequired,
    editTable: PropTypes.func.isRequired,
    exportCube: PropTypes.func.isRequired
  };

  state = {
    viewType: 'table',
    graphType: 'bar',
    graphStatistics: [null, null],
    isGraphGroupingData: true,
    sortedBy: null,
    graphCurrentPos: 0,
    graphViewPos: 20
  };

  eventMinDate =
    this.props.catalog
      ? moment(this.props.catalog.startDate)
      : null;

  eventMaxDate =
    this.props.catalog
      ? moment(this.props.catalog.endDate)
      : null;

  _statisticDropPanel = null;
  _statisticMeasureSelect = null;
  _viewTypeTabs = null;
  _connectorTabs = null;
  _graphStatisticRefs = [null, null];
  copyTable = null;

  UNSAFE_componentWillReceiveProps (newProps) {
    const {viewType} = this.state;

    if (
      viewType === 'graph' &&
      this.props.data &&
      !newProps.data
    ) {
      if (this._viewTypeTabs && typeof this._viewTypeTabs.changeValue === 'function') {
        this._viewTypeTabs.changeValue('table');
      } else {
        this.changeViewType('table');
      }
    }

    if (
      this.props.globalFilter.connector !== newProps.globalFilter.connector &&
      this._connectorTabs &&
      typeof this._connectorTabs.changeValue === 'function'
    ) {
      this._connectorTabs.changeValue(newProps.globalFilter.connector);
    }
  }

  setViewTypeTabsRef = ref => {
    this._viewTypeTabs = ref;
  };

  setStatisticMeasureSelectRef = ref => {
    this._statisticMeasureSelect = ref;
  };

  setConnectorTabsRef = ref => {
    this._connectorTabs = ref;
  };

  setStatisticDropPanelRef = ref => {
    this._statisticDropPanel = ref;
  };

  setGraphStatisticRef = index => ref => {
    this._graphStatisticRefs[index] = ref;
  };

  reset = () => {
    this.setState({
      viewType: 'table',
      graphType: 'bar',
      graphStatistics: [null, null],
      isGraphGroupingData: true,
      sortedBy: null,
      graphCurrentPos: 0,
      graphViewPos: 20
    });

    if (this._viewTypeTabs) {
      this._viewTypeTabs.changeValue('table', false);
    }
  };

  resetAfterAxisChanging = () => {
    this.setState({
      graphStatistics: [null, null],
      isGraphGroupingData: true,
      sortedBy: null,
      graphCurrentPos: 0,
      graphViewPos: 20
    }, () => {
      if (this._graphStatisticRefs[0]) {
        const statisticFullData = this.getStatisticFullData();

        this._graphStatisticRefs[0].setValue(statisticFullData.length ? statisticFullData[0].value : undefined);
      }

      if (this._graphStatisticRefs[1]) {
        this._graphStatisticRefs[1].setValue(undefined);
      }
    });
  };

  getStatisticFullData = () => {
    const {data} = this.props;

    if (!data) {
      return [];
    }

    return data.structure.measures.statistics.map(statistic => ({
      title:
        statistic.title +
        (statistic.n ? ` (${getNPlusValue(statistic)})` : ''),
      value: statistic
    }));
  };

  getStatisticDropdownList = (statisticFullData) => {
    return statisticFullData.map(statistic => ({
      label: statistic.title,
      value: statistic.value
    }));
  };

  changeViewType = type => {
    this.setState({
      viewType: type
    });
  };

  changeGraphType = type => {
    this.setState({
      graphType: type
    });
  };

  changeStatisticDirection = async type => {
    await this.props.updateStatisticAxis(type === 'x' ? 'columns' : 'rows');

    this.resetGraphPos();
    this.sortByCell(null);
  };

  diffMeasure = (measure1, measure2) => {
    return (
      (!measure1 && !measure2) ||
      (
        measure1 && measure2 &&
        measure1.id === measure2.id &&
        measure1.title === measure2.title &&
        measure1.affinity === measure2.affinity &&
        measure1.key === measure2.key
      )
    );
  };

  changeGraphStatistic = (statisticNumber, statisticData) => {
    const {graphStatistics} = this.state;

    this.setState({
      graphStatistics: {
        ...graphStatistics,
        [statisticNumber]: statisticData
      }
    });

    this.resetGraphPos();
  };

  toggleGraphGroupingData = isGraphGroupingData => {
    this.setState({
      isGraphGroupingData
    });

    this.resetGraphPos();
  };

  resetGraphOptions = () => {
    this.setState({
      graphType: 'bar',
      graphStatistics: [null, null],
      isGraphGroupingData: true,
      graphCurrentPos: 0,
      graphViewPos: 20
    });
  };

  resetGraphPos = () => {
    this.setState({
      graphCurrentPos: 0,
      graphViewPos: 20
    });
  };

  onChangeGraphPos = positions => {
    const updatingPositions = {};

    if (typeof positions.currentPos === 'number') {
      updatingPositions.graphCurrentPos = positions.currentPos;
    }

    if (typeof positions.viewPos === 'number') {
      updatingPositions.graphViewPos = positions.viewPos;
    }

    this.setState(updatingPositions);
  };

  sortByCell = index => {
    const {sortedBy} = this.state;

    if (typeof index !== 'number') {
      this.setState({
        sortedBy: null
      });

      return;
    }

    if (!sortedBy || sortedBy.index !== index) {
      this.setState({
        sortedBy: {
          direction: 'asc',
          index
        }
      });

      return;
    }

    if (sortedBy && sortedBy.direction === 'asc') {
      this.setState({
        sortedBy: {
          direction: 'desc',
          index
        }
      });

      return;
    }

    this.setState({
      sortedBy: null
    });
  };

  validateSendingData = (tableStructure) => {
    if (
      !tableStructure.horizontalDataTree.length ||
      !tableStructure.verticalDataTree.length
    ) {
      if (!tableStructure.horizontalDataTree.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyAxis.x'
          })
        );
      }

      if (!tableStructure.verticalDataTree.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyAxis.y'
          })
        );
      }

      return false;
    }

    if (!tableStructure.statisticItems.length) {
      alert.warning(
        localizeMessage({
          id: 'persona.table.alerts.validation.noStatistics'
        })
      );

      setTimeout(() => {
        if (this._statisticDropPanel) {
          this._statisticDropPanel.toggle(true);
        }
      });

      return false;
    }

    return true;
  };

  clearData = () => {
    const {clearData} = this.props;

    confirm(
      localizeMessage({
        id: 'persona.table.confirmations.clearTable'
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(() => {
        clearData();

        if (this._statisticMeasureSelect) {
          this._statisticMeasureSelect.resetAxis();
        }
      });
  };

  createTA = () => {
    const {openFilter} = this.props;

    openFilter('ta');
  };

  calculateCube = () => {
    const {tableStructure, calculateCube} = this.props;

    if (this.validateSendingData(tableStructure)) {
      calculateCube();
    }
  };

  saveCube = () => {
    const {tableStructure, saveCube} = this.props;

    if (this.validateSendingData(tableStructure)) {
      saveCube();
    }
  };

  editTable = () => {
    const {editTable} = this.props;

    confirm(
      localizeMessage({
        id: 'persona.table.confirmations.editTable'
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(() => {
        this.resetGraphOptions();

        editTable();
      });
  };

  setCopyTableFn = (fn) => {
    this.copyTable = fn;

    this.forceUpdate();
  };

  render () {
    const {
      locale,
      catalog,
      eventBetweenStart,
      eventBetweenEnd,
      eventAverageDay,
      eventAverageDayWithAutoCalculation,
      changeFilterDates,
      tableStructure,
      globalFilter,
      loading,
      isAdmin,
      isAuthor,
      isDisabled,
      renameTemplate,
      copyTemplate,
      deleteTemplate,
      updateStatisticAxis,
      updateStatisticData,
      updateStatisticRounding,
      changeGlobalFilterConnector,
      changingCell,
      openFilter,
      deleteCell,
      mode,
      data,
      exportCube
    } = this.props;
    const {
      viewType,
      graphType,
      graphStatistics,
      isGraphGroupingData,
      sortedBy,
      graphCurrentPos,
      graphViewPos
    } = this.state;

    const statisticFullData = this.getStatisticFullData();
    const title = getTitle({title: this.props.title}, locale);

    return (
      <div className={cx(
        classes.Container,
        {
          [classes.ContainerDisabled]: isDisabled
        }
      )}>
        <div className={cx(
          classes.SubContainer,
          'content',
          'content-full-height'
        )}>
          <div className={classes.Header}>
            <div className={classes.HeaderButtons}>
              {
                mode === 'view' ?
                  <Button
                    theme='transparent'
                    onClick={this.editTable}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.editTable'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'view' && this.copyTable ?
                  <Button
                    icon='copy'
                    onClick={this.copyTable}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.copyTable'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'view' ?
                  <Button
                    icon='download'
                    onClick={exportCube}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.exportTable'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'edit' ?
                  <Button
                    theme='transparent'
                    disabled={loading}
                    onClick={this.clearData}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.clear'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'edit' ?
                  <Button
                    disabled={loading}
                    onClick={this.createTA}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.createTA'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'edit' ?
                  <Button
                    disabled={loading}
                    onClick={this.saveCube}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.saveTemplate'
                    />
                  </Button>
                  : null
              }
              {
                mode === 'edit' ?
                  <Button
                    disabled={loading}
                    onClick={this.calculateCube}
                  >
                    <LocalizedMessage
                      id='persona.table.header.buttons.calculate'
                    />
                  </Button>
                  : null
              }
            </div>
            <p className={classes.HeaderCatalog}>
              <LocalizedMessage
                id='persona.table.header.database'
              />
              <span className={classes.HeaderCatalogTitle}>
                {catalog ? catalog.title : '...'}
              </span>
            </p>
            {
              title ?
                <h1 className={classes.HeaderTitle}>
                  <span
                    title={title}
                    className={classes.HeaderTitleText}
                  >
                    {title}
                  </span>
                  {
                    isAuthor || isAdmin ?
                      <LocalizedMessage
                        id='base.rename'
                      >
                        {localizedMessage => (
                          <span
                            title={localizedMessage}
                            className={cx(
                              classes.HeaderTitleBtn,
                              classes.HeaderTitleBtnEdit,
                              'icon-edit'
                            )}
                            onClick={renameTemplate}
                          />
                        )}
                      </LocalizedMessage>
                      : null
                  }
                  <LocalizedMessage
                    id='base.copy'
                  >
                    {localizedMessage => (
                      <span
                        title={localizedMessage}
                        className={cx(
                          classes.HeaderTitleBtn,
                          classes.HeaderTitleBtnCopy,
                          'icon-copy'
                        )}
                        onClick={copyTemplate}
                      />
                    )}
                  </LocalizedMessage>
                  {
                    isAuthor || isAdmin ?
                      <LocalizedMessage
                        id='base.delete'
                      >
                        {localizedMessage => (
                          <span
                            title={localizedMessage}
                            className={cx(
                              classes.HeaderTitleBtn,
                              classes.HeaderTitleBtnDelete,
                              'icon-delete'
                            )}
                            onClick={deleteTemplate}
                          />
                        )}
                      </LocalizedMessage>
                      : null
                  }
                </h1>
                :
                <h1 className={classes.HeaderTitle}>
                  <LocalizedMessage
                    id='persona.table.header.newResearch'
                  />
                </h1>
            }
          </div>
          <div className={classes.Content}>
            <div className={classes.ContentHeader}>
              {
                mode === 'view' ?
                  <div className={cx(
                    classes.ContentHeaderColumn,
                    classes.ContentHeaderColumnRight
                  )}>
                    <LocalizedMessage
                      id={[
                        'persona.table.headerRow.view.table',
                        'persona.table.headerRow.view.graph'
                      ]}
                    >
                      {(localizedFirstMessage, localizedSecondMessage) => (
                        <Tabs
                          ref={this.setViewTypeTabsRef}
                          className={classes.ContentHeaderTabs}
                          options={[
                            {
                              label: localizedFirstMessage,
                              value: 'table'
                            },
                            {
                              label: localizedSecondMessage,
                              value: 'graph',
                              disabled: mode !== 'view'
                            }
                          ]}
                          onChange={this.changeViewType}
                        />
                      )}
                    </LocalizedMessage>
                  </div>
                  : null
              }
              {
                viewType === 'table' ?
                  mode === 'edit' ?
                    <div className={classes.ContentHeaderControls}>
                      <div className={classes.ContentHeaderColumn}>
                        <MeasureSelect
                          ref={this.setStatisticMeasureSelectRef}
                          measures={catalog.measures}
                          tableStructure={tableStructure}
                          updateStatisticAxis={updateStatisticAxis}
                          updateStatisticData={updateStatisticData}
                          updateStatisticRounding={updateStatisticRounding}
                          setStatisticDropPanelRef={this.setStatisticDropPanelRef}
                        />
                      </div>
                      {
                        /*
                      <div className={classes.ContentHeaderColumn}>
                        <p className={classes.ContentHeaderColumnLabel}>
                          Фильтры:
                        </p>
                        <Switch
                          className={classes.ContentHeaderSwitch}
                          onChange={status => console.log(status)}
                        />
                      </div>
                         */
                      }
                      <div className={classes.ContentHeaderColumn}>
                        <DateRange
                          eventMinDate={this.eventMinDate}
                          eventMaxDate={this.eventMaxDate}
                          eventBetweenStart={eventBetweenStart}
                          eventBetweenEnd={eventBetweenEnd}
                          eventAverageDay={eventAverageDay}
                          eventAverageDayWithAutoCalculation={eventAverageDayWithAutoCalculation}
                          changeFilterDates={changeFilterDates}
                        />
                      </div>
                      <div className={classes.ContentHeaderColumn}>
                        <p className={classes.ContentHeaderColumnLabel}>
                          <LocalizedMessage
                            id='persona.table.headerRow.connector'
                          />:
                        </p>
                        <LocalizedMessage
                          id={[
                            'persona.table.headerRow.connector.and',
                            'persona.table.headerRow.connector.or'
                          ]}
                        >
                          {(localizedAndMessage, localizedOrMessage) => (
                            <Tabs
                              ref={this.setConnectorTabsRef}
                              className={classes.ContentHeaderTabs}
                              options={[
                                {
                                  label: localizedAndMessage,
                                  value: 'and'
                                },
                                {
                                  label: localizedOrMessage,
                                  value: 'or'
                                }
                              ]}
                              defaultValue={globalFilter.connector.toLowerCase()}
                              onChange={changeGlobalFilterConnector}
                            />
                          )}
                        </LocalizedMessage>
                      </div>
                    </div>
                    :
                    <div className={classes.ContentHeaderControls}>
                      <div className={classes.ContentHeaderColumn}>
                        <p className={classes.ContentHeaderColumnLabel}>
                          <LocalizedMessage
                            id='persona.table.headerRow.kpis'
                          />:
                        </p>
                        <LocalizedMessage
                          id={[
                            'persona.table.headerRow.kpis.location.rows',
                            'persona.table.headerRow.kpis.location.columns'
                          ]}
                        >
                          {(localizedFirstMessage, localizedSecondMessage) => (
                            <Tabs
                              className={classes.ContentHeaderTabs}
                              options={[
                                {
                                  label: localizedFirstMessage,
                                  value: 'y'
                                },
                                {
                                  label: localizedSecondMessage,
                                  value: 'x'
                                }
                              ]}
                              defaultValue={tableStructure.statisticAxis === 'rows' ? 'y' : 'x'}
                              onChange={this.changeStatisticDirection}
                            />
                          )}
                        </LocalizedMessage>
                      </div>
                      <div className={classes.ContentHeaderColumn}>
                        <p className={classes.ContentHeaderColumnLabel}>
                          <LocalizedMessage
                            id='persona.table.headerRow.dates.avg'
                          >
                            {localizedMessage => (
                              `${eventBetweenStart.format('DD.MM.YYYY')} - ${eventBetweenEnd.format('DD.MM.YYYY')}` +
                              ` (${localizedMessage}: ${eventAverageDay.format('DD.MM.YYYY')})`
                            )}
                          </LocalizedMessage>
                        </p>
                      </div>
                      <div className={classes.ContentHeaderColumn}>
                        <p className={classes.ContentHeaderColumnLabel}>
                          <LocalizedMessage
                            id='persona.table.headerRow.connector'
                          />:
                          {' '}
                          <LocalizedMessage
                            id={`persona.table.headerRow.connector.${globalFilter.connector.toLowerCase()}`}
                          />
                        </p>
                      </div>
                    </div>
                  : null
              }
              {
                viewType === 'graph' ?
                  <div className={classes.ContentHeaderControls}>
                    <div
                      className={cx(
                        classes.ContentHeaderColumn
                      )}
                    >
                      <p className={classes.ContentHeaderColumnLabel}>
                        <LocalizedMessage
                          id='persona.table.graph.header.type'
                        />:
                      </p>
                      <Dropdown
                        className={classes.ContentHeaderDropdown}
                        currentValueClassName={classes.ContentHeaderDropdownValue}
                        options={[
                          {
                            label: 'Columns',
                            value: 'bar'
                          },
                          {
                            label: 'Line',
                            value: 'line'
                          },
                          {
                            label: 'Doughnut',
                            value: 'doughnut'
                          }
                        ]}
                        defaultValue={graphType}
                        onChange={this.changeGraphType}
                      />
                    </div>
                    <div
                      className={cx(
                        classes.ContentHeaderColumn,
                        classes.ContentHeaderColumnGraphControl
                      )}
                    >
                      <p className={classes.ContentHeaderColumnLabel}>
                        <LocalizedMessage
                          id='persona.table.headerRow.kpis'
                        />:
                      </p>
                      <LocalizedMessage
                        id={[
                          'persona.table.headerRow.kpis.location.rows',
                          'persona.table.headerRow.kpis.location.columns'
                        ]}
                      >
                        {(localizedFirstMessage, localizedSecondMessage) => (
                          <Dropdown
                            className={classes.ContentHeaderDropdown}
                            currentValueClassName={classes.ContentHeaderDropdownValue}
                            options={[
                              {
                                label: localizedFirstMessage,
                                value: 'y'
                              },
                              {
                                label: localizedSecondMessage,
                                value: 'x'
                              }
                            ]}
                            defaultValue={tableStructure.statisticAxis === 'rows' ? 'y' : 'x'}
                            onChange={this.changeStatisticDirection}
                          />
                        )}
                      </LocalizedMessage>
                    </div>
                    <div className={classes.ContentHeaderColumn}>
                      <p className={classes.ContentHeaderColumnLabel}>
                        <LocalizedMessage
                          id={
                            'persona.table.graph.header.' +
                            (graphType === 'line' || graphType === 'bar' ? 'graph1' : 'graph')
                          }
                        />:
                      </p>
                      <Dropdown
                        ref={this.setGraphStatisticRef(0)}
                        className={classes.ContentHeaderDropdown}
                        currentValueClassName={classes.ContentHeaderDropdownValue}
                        options={this.getStatisticDropdownList(statisticFullData)}
                        defaultValue={
                          graphStatistics[0] ||
                          (statisticFullData.length ? statisticFullData[0].value : undefined)
                        }
                        optionsAutoWidth
                        diffFn={this.diffMeasure}
                        onChange={value => this.changeGraphStatistic(0, value)}
                      />
                    </div>
                    {
                      graphType === 'line' ||
                      graphType === 'bar' ?
                        <div
                          className={classes.ContentHeaderColumn}
                        >
                          <p className={classes.ContentHeaderColumnLabel}>
                            <LocalizedMessage
                              id='persona.table.graph.header.graph2'
                            />:
                          </p>
                          <Dropdown
                            ref={this.setGraphStatisticRef(1)}
                            className={classes.ContentHeaderDropdown}
                            currentValueClassName={classes.ContentHeaderDropdownValue}
                            options={[
                              {
                                label: <LocalizedMessage
                                  id='persona.table.graph.header.noGraph'
                                />,
                                value: null
                              },
                              ...this.getStatisticDropdownList(statisticFullData)
                            ]}
                            defaultValue={graphStatistics[1]}
                            optionsAutoWidth
                            diffFn={this.diffMeasure}
                            onChange={value => this.changeGraphStatistic(1, value)}
                          />
                        </div>
                        : null
                    }
                    {
                      (
                        graphType === 'line' ||
                        graphType === 'bar'
                      ) &&
                      !graphStatistics[1] ?
                        <div className={classes.ContentHeaderColumn}>
                          <p className={classes.ContentHeaderColumnLabel}>
                            <LocalizedMessage
                              id='persona.table.graph.header.grouping'
                            />:
                          </p>
                          <Switch
                            className={classes.ContentHeaderSwitch}
                            defaultStatus={isGraphGroupingData}
                            onChange={this.toggleGraphGroupingData}
                          />
                        </div>
                        : null
                    }
                  </div>
                  : null
              }
            </div>
            <div className={classes.ContentContainer}>
              <div className={classes.ContentWrapper}>
                {
                  viewType === 'table' ?
                    <Table
                      measures={catalog.measures}
                      tableStructure={tableStructure}
                      mode={mode}
                      data={data}
                      direction={tableStructure.statisticAxis === 'rows' ? 'y' : 'x'}
                      catalog={catalog}
                      sortedBy={sortedBy}
                      changingCell={changingCell}
                      globalFilter={globalFilter}
                      openFilter={openFilter}
                      deleteCell={deleteCell}
                      sortByCell={this.sortByCell}
                      setCopyTableFn={this.setCopyTableFn}
                    />
                    : null
                }
                {
                  viewType === 'graph' && !loading ?
                    <Graph
                      locale={locale}
                      type={graphType}
                      direction={tableStructure.statisticAxis === 'rows' ? 'y' : 'x'}
                      selectedStatisticTypes={[
                        graphStatistics[0] || (statisticFullData.length ? statisticFullData[0].value : null),
                        graphStatistics[1]
                      ]}
                      isGraphGroupingData={isGraphGroupingData}
                      catalog={catalog}
                      data={data}
                      currentPos={graphCurrentPos}
                      viewPos={graphViewPos}
                      onChangePos={this.onChangeGraphPos}
                    />
                    : null
                }
              </div>
            </div>
          </div>
          <Loader
            active={loading}
            withDescription
          />
        </div>
      </div>
    );
  }
}

export default Content;
