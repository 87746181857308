import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage, {localizeMessage} from '../../../../../components/LocalizedMessage';
import Loader from '../../../../../components/Loader';
import Scrollbar from '../../../../../components/Scrollbar';
import Button from '../../../../../components/Button';
import {getArrayItemByParam} from '../../../../../helpers/utils';
import alert from '../../../../../helpers/alert';
import classes from './FolderList.module.scss';

class FolderList extends Component {
  static propTypes = {
    folders: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    value: PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    }),
    addNewFolder: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    showNewFolderForm: false,
    focusedAutocompleteIndex: 0,
    searchString: '',
    newFolderAdding: false
  };

  _hints = {};
  _hintScrollbar = null;
  _newFolderField = null;
  _searchField = null;

  componentDidMount () {
    this.focusSearchField();
  }

  setHintScrollbarRef = ref => {
    this._hintScrollbar = ref;
  };

  setNewFolderFieldRef = ref => {
    this._newFolderField = ref;
  };

  setSearchFieldRef = ref => {
    this._searchField = ref;
  };

  focusSearchField = () => {
    if (this._searchField) {
      this._searchField.focus();
    }
  };

  onSearchFieldKeyDown = e => {
    const {folders, onChange} = this.props;
    const {focusedAutocompleteIndex, showNewFolderForm} = this.state;

    if (showNewFolderForm) {
      return;
    }

    switch (e.keyCode) {
      case 13:            // Enter
        if (focusedAutocompleteIndex === 0) {
          this.openNewFolderForm();
        } else if (folders[focusedAutocompleteIndex - 1]) {
          onChange(folders[focusedAutocompleteIndex - 1]);
        }

        break;
      case 38:            // Arrow Up
        if (focusedAutocompleteIndex > 0) {
          this.focusHint(focusedAutocompleteIndex - 1);
        }

        break;
      case 40:            // Arrow Down
        if (focusedAutocompleteIndex < folders.length) {
          this.focusHint(focusedAutocompleteIndex + 1);
        }

        break;
      default:
        break;
    }
  };

  onNewFolderNameFieldKeyDown = e => {
    switch (e.keyCode) {
      case 27:            // Esc
        e.stopPropagation();

        this.setState({
          showNewFolderForm: false
        });

        this.focusSearchField();

        break;
      default:
        break;
    }
  };

  focusHint = (hintIndex, withScroll = true) => {
    const {searchString} = this.state;

    this.setState({
      focusedAutocompleteIndex: hintIndex
    }, () => {
      if (withScroll) {
        const _focusedHint = this._hints[`${searchString}-${hintIndex}`];
        const hintHeight = _focusedHint.offsetHeight;
        const hintOffsetTop = _focusedHint.offsetTop;

        this._hintScrollbar.scrollTop(hintOffsetTop - hintHeight);
      }
    });
  };

  openNewFolderForm = () => {
    const {showNewFolderForm} = this.state;

    if (showNewFolderForm) {
      return;
    }

    this.setState({
      showNewFolderForm: true,
      focusedAutocompleteIndex: 0
    }, () => {
      setTimeout(() => {
        this.focusNewFolderField();
      });
    });
  };

  focusNewFolderField = () => {
    this._newFolderField.focus();
  };

  addNewFolder = async e => {
    e.preventDefault();
    e.stopPropagation();

    const {folders, addNewFolder} = this.props;
    const {newFolderAdding} = this.state;

    if (newFolderAdding) {
      return;
    }

    const value = this._newFolderField.value.trim();

    if (!value.length) {
      alert.warn(
        localizeMessage({
          id: 'persona.accessRights.checkboxes.all.newFolder.errors.nameIsEmpty'
        })
      );

      return false;
    }

    if (getArrayItemByParam(folders, 'label', value)) {
      alert.warn(
        localizeMessage({
          id: 'persona.accessRights.checkboxes.all.newFolder.errors.nameIsTaken'
        })
      );

      return false;
    }

    this.setState({
      newFolderAdding: true
    });

    try {
      await addNewFolder(value);

      this.setState({
        showNewFolderForm: false,
        searchString: '',
        focusedAutocompleteIndex: 1,
        newFolderAdding: false
      });

      setTimeout(() => {
        this.focusSearchField();
      });
    } catch (error) {
      console.error(error);

      this.setState({
        newFolderAdding: false
      });
    }
  };

  searchFolder = e => {
    const value = e.target.value.trim();

    this.setState({
      searchString: value
    });
  };

  filterFolders = () => {
    const {folders} = this.props;
    const {searchString} = this.state;

    if (!searchString.length) {
      return folders;
    }

    const searchStringLower = searchString.toLowerCase();

    return folders.filter(folder => folder.label.toLowerCase().indexOf(searchStringLower) > -1);
  };

  switchToChanging = () => {
    this.props.onChange(null);
  };

  render () {
    const {value, onChange} = this.props;
    const {showNewFolderForm, searchString, focusedAutocompleteIndex, newFolderAdding} = this.state;

    const filteredFolders = this.filterFolders();

    if (value) {
      return (
        <div className={classes.Container}>
          <div className={classes.Result}>
            <Button
              icon='edit'
              size='small'
              theme='transparent'
              className={classes.ResultChangeBtn}
              onClick={this.switchToChanging}
            />
            <p className={classes.ResultValue}>
              {value.label}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.Container}>
        <LocalizedMessage
          id='persona.accessRights.checkboxes.all.placeholder'
        >
          {localizedPlaceholder => (
            <input
              ref={this.setSearchFieldRef}
              type='text'
              placeholder={localizedPlaceholder}
              value={searchString}
              onKeyDown={this.onSearchFieldKeyDown}
              onChange={this.searchFolder}
              className={classes.SearchField}
            />
          )}
        </LocalizedMessage>
        <div className={classes.ListScrollbarContainer}>
          <Scrollbar
            ref={this.setHintScrollbarRef}
          >
            <ul className={classes.List}>
              <li
                ref={ref => {
                  this._hints[`${searchString}-0`] = ref;
                }}
                className={cx(
                  classes.ListItem,
                  {
                    [classes.ListItemSelected]: focusedAutocompleteIndex === 0
                  }
                )}
                onClick={!showNewFolderForm ? this.openNewFolderForm : null}
              >
                {
                  showNewFolderForm
                    ? <form
                      className={classes.NewFolderForm}
                      onSubmit={this.addNewFolder}
                    >
                      <Loader
                        active={newFolderAdding}
                      />
                      <p className={classes.NewFolderFormLabel}>
                        <LocalizedMessage
                          id='persona.accessRights.checkboxes.all.newFolder'
                        />
                      </p>
                      <div className={classes.NewFolderFormFieldContainer}>
                        <input
                          ref={this.setNewFolderFieldRef}
                          type='text'
                          onKeyDown={this.onNewFolderNameFieldKeyDown}
                          className={classes.NewFolderFormField}
                        />
                      </div>
                      <Button
                        size='small'
                        className={classes.NewFolderFormSubmitBtn}
                        onClick={this.addNewFolder}
                      >
                        <LocalizedMessage
                          id='persona.accessRights.checkboxes.all.newFolder.add'
                        />
                      </Button>
                    </form>
                    : <div className={classes.NewFolderItem}>
                      <Button
                        size='small'
                        theme='transparent'
                        icon='add'
                        className={classes.NewFolderItemBtn}
                      />
                      <LocalizedMessage
                        id='persona.accessRights.checkboxes.all.newFolder'
                      />
                    </div>
                }
              </li>
              {
                filteredFolders.map((folder, index) => (
                  <li
                    key={folder.value}
                    ref={ref => {
                      this._hints[`${searchString}-${index + 1}`] = ref;
                    }}
                    className={cx(
                      classes.ListItem,
                      {
                        [classes.ListItemSelected]: focusedAutocompleteIndex === index + 1
                      }
                    )}
                    onClick={() => onChange(folder)}
                  >
                    {folder.label}
                  </li>
                ))
              }
            </ul>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

export default FolderList;
