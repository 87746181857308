import {DropTarget} from 'react-dnd';
import StartingField from './StartingField';

const StartingFieldWithDropTarget = DropTarget('FORMULA_ITEM', {
  drop (props, monitor) {
    if (monitor.didDrop()) {
      return;
    }

    return {
      path: [0],
      direction: 'left'
    };
  }
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(StartingField);

export default StartingFieldWithDropTarget;
