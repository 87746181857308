import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IntegerField from './Fields/IntegerField';
import classes from './Templates.module.scss';

class LessThan extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.object.isRequired,
    isSelecting: PropTypes.bool.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render () {
    const {locale, data, isSelecting, toggleChanging, setFieldRef, onChange} = this.props;

    const values = data.condition.values;

    return (
      <div className={classes.Container}>
        {data.title[locale]} {'<'}
        <IntegerField
          value={values && values.length ? values[0] : null}
          isSelecting={isSelecting}
          toggleChanging={toggleChanging}
          setFieldRef={setFieldRef}
          onChange={value => onChange([value])}
        />
      </div>
    );
  }
}

export default LessThan;
