import API from '../api';
import store from '../store';
import {logOut} from '../modules/auth';
import {localizeMessage} from '../components/LocalizedMessage';
import confirm from '../components/Confirm';
import alert from '../helpers/alert';

export const serialize = (obj) => {
  const str = [];

  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
};

const checkStatus = async (response, showError) => {
  if (
    response.status < 200 ||
    response.status >= 500
  ) {
    let errorMessage = response.statusText || (response.status >= 500 ? 'Internal server error' : 'Unknown error');
    let jsonResponse = null;

    if (
      response.status >= 500 &&
      response.headers.get('content-type') &&
      response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
    ) {
      jsonResponse = await parseJSON(response);

      if (jsonResponse.message) {
        errorMessage = jsonResponse.message;
      }
    }

    const error = new Error(errorMessage);
    error.response = response || null;

    if (jsonResponse) {
      error.jsonResponse = jsonResponse;
    }

    if (showError) {
      alert.error(errorMessage);
      error.withoutAlerts = true;
    }

    throw error;
  }

  if (response.status >= 400 && response.status < 500) {
    const jsonResponse = await parseJSON(response);

    const isAuthExpired = (response.headers.get('www-authenticate') || '')
      .toLowerCase()
      .includes('token is not active');

    const error = new Error(jsonResponse.message || response.statusText);
    error.response = response || null;
    error.isAuthExpired = isAuthExpired;
    error.jsonResponse = jsonResponse || null;

    throw error;
  }

  if (
    response.status !== 204 &&
    response.headers.get('content-type') &&
    response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
  ) {
    return parseJSON(response);
  } else {
    return response;
  }
};

const parseJSON = response => response.json();

export const fetchResponse = async (url, params = {}, showError = true) => {
  const {headers, body, initController, ...otherParams} = params;

  try {
    const responseData = {
      headers: headers(),
      ...otherParams
    };

    if (body) {
      responseData.body = typeof body === 'function' ? body() : body;
    }

    if (initController && AbortController) {
      const controller = new AbortController();

      responseData.signal = controller.signal;

      initController(controller);
    }

    const response = await fetch(url, responseData);

    return await checkStatus(response, showError);
  } catch (error) {
    return handleErrorRequest(error, () => fetchResponse(url, params, showError), showError);
  }
};

const handleErrorRequest = async (error, retry, showError) => {
  const errorMessage = error.message || 'Request error';
  if (
    error.isAuthExpired ||
    (
      error.jsonResponse &&
      error.jsonResponse.error === 'invalid_token' &&
      [
        'Token has expired',
        'Access token expired'
      ].indexOf(error.jsonResponse.error_description) > -1
    )
  ) {
    try {
      await API.auth.reLogIn();

      return retry();
    } catch (error) {
      if (
        error.jsonResponse &&
        [
          'invalid_grant',
          'invalid_token'
        ].indexOf(error.jsonResponse.error) > -1
      ) {
        if (
          typeof localStorage['access_token'] === 'string' &&
          localStorage['access_token'].length
        ) {
          alert.warn(localizeMessage({
            id: 'errors.expiredAuthorization'
          }));

          error.withLogout = true;

          store.dispatch(logOut());
        }

        error.withoutAlerts = true;
        throw error;
      }
    }
  }

  if (
    error.jsonResponse &&
    error.jsonResponse.error === 'invalid_token' &&
    error.jsonResponse.error_description === 'Token was not recognised'
  ) {
    if (
      typeof localStorage['access_token'] === 'string' &&
      localStorage['access_token'].length
    ) {
      alert.warn(localizeMessage({
        id: 'errors.expiredAuthorization'
      }));

      error.withLogout = true;

      store.dispatch(logOut());
    }

    error.withoutAlerts = true;
    throw error;
  }

  if (
    error.response &&
    error.response.status &&
    error.response.status !== 200 &&
    (
      error.response.status < 400 ||
      error.response.status >= 500
    )
  ) {
    if (!error.jsonResponse) {
      return new Promise((resolve, reject) => {
        confirm(
          localizeMessage({
            id: 'errors.errorLoadingDataWithMessage'
          }, {
            errorMessage
          }),
          {
            confirmBtnText: localizeMessage({
              id: 'base.yes'
            }),
            cancelBtnText: localizeMessage({
              id: 'base.no'
            }),
            cancelCallback: () => {
              reject(error);
            }
          }
        )
          .then(() => {
            resolve(retry());
          });
      });
    } else {
      throw error;
    }
  } else {
    if (showError) {
      alert.error(errorMessage);
      error.withoutAlerts = true;
    }

    throw error;
  }
};
