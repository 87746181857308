import Client from './HTTPClient';

const formulaAPI = {
  async getNumberOfTaStatistics (formula, eventBetweenStart, eventBetweenEnd, eventAverageDay, initController) {
    const response = await Client.post('/expression/get-ta-statistics-sample', {
      taFormula: formula,
      eventBetweenStart: eventBetweenStart.format('YYYY-MM-DD'),
      eventBetweenEnd: eventBetweenEnd.format('YYYY-MM-DD'),
      eventAverageDay: eventAverageDay.format('YYYY-MM-DD')
    }, true, true, false, initController);

    return response.SAMPLE ? parseInt(response.SAMPLE) : 0;
  },

  async insert (catalog, title, formula, accessRights, memberLists) {
    const savedFormula = await Client.post('/expression/create', {
      created: '1990-03-31',
      modified: '1990-03-31',
      settingsOlap: catalog,
      title: title,
      condition: JSON.stringify(formula),
      memberIdLists: Array.from(new Set(memberLists)),
      group: null,
      status: 1,
      ...accessRights
    });

    return {
      id: savedFormula.id,
      title: savedFormula.title,
      formula: JSON.parse(savedFormula.condition)
    };
  },

  async update (catalog, id, title, formula, accessRights, memberLists) {
    const updatedFormula = await Client.put(`/expression/${id}`, {
      created: '1990-03-31',
      modified: '1990-03-31',
      settingsOlap: catalog,
      title: title,
      condition: JSON.stringify(formula),
      memberIdLists: Array.from(new Set(memberLists)),
      group: null,
      status: 1,
      ...accessRights
    });

    return {
      id: updatedFormula.id,
      title: updatedFormula.title,
      formula: JSON.parse(updatedFormula.condition)
    };
  },

  delete (catalog, id) {
    return Client.delete(`/expression/${id}`);
  }
};

export default formulaAPI;
