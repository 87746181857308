import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '../../../../../components/Tooltip';
import classes from '../Table.module.scss';

class LazyCell extends Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    cell: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      colSpan: PropTypes.number,
      rowSpan: PropTypes.number,
      data: PropTypes.shape({
        type: PropTypes.string.isRequired,
        path: PropTypes.array,
        direction: PropTypes.string,
        colSpan: PropTypes.number,
        rowSpan: PropTypes.number,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      })
    }).isRequired,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    disableGrouping: PropTypes.bool.isRequired,
    subscribeOnScroll: PropTypes.func.isRequired,
    getContainer: PropTypes.func.isRequired,
    sortByCell: PropTypes.func
  };

  _tooltip = null;

  setTooltipRef = ref => {
    this._tooltip = ref;
  };

  onTooltipVisibleChange = (status) => {
    const {subscribeOnScroll} = this.props;

    subscribeOnScroll(status ? this.onScroll : null);
  };

  onScroll = () => {
    if (this._tooltip) {
      this._tooltip.onMouseLeave();
    }
  };

  onClick = () => {
    const {cell, sortByCell} = this.props;

    if (cell.data.type === 'statistic') {
      sortByCell(cell.data.direction === 'rows' ? cell.x : cell.y);
    }
  };

  render () {
    const {content, cell, cellWidth, cellHeight, disableGrouping, getContainer} = this.props;

    const isHead = ['head', 'statistic'].indexOf(cell.data.type) > -1;
    const isClicking =
      cell.data.nodeType === 'DIMENSION_VALUE' &&
      !cell.data.singleLevelValue;

    const colSpan = !disableGrouping ? (cell.colSpan || 1) : 1;
    const rowSpan = !disableGrouping ? (cell.rowSpan || 1) : 1;

    return (
      <div
        className={classes.CellContainer}
        style={{
          left: `${Math.round(cell.x * cellWidth)}px`,
          top: `${Math.round(cell.y * cellHeight)}px`,
          width: `${Math.round(Math.abs(colSpan) * cellWidth)}px`,
          height: `${Math.round(rowSpan * cellHeight)}px`
        }}
        onClick={isClicking || cell.data.type === 'statistic' ? this.onClick : null}
      >
        <Tooltip
          ref={this.setTooltipRef}
          onVisibleChange={this.onTooltipVisibleChange}
          container={getContainer}
          overlay={isHead ? cell.data.content : null}
        >
          <div
            className={cx(
              classes.Cell,
              {
                [classes.CellHead]: isHead,
                [classes.CellSort]: cell.data.type === 'statistic',
                [classes.CellSortActive]: cell.data.type === 'statistic' && cell.data.sortedDirection,
                'icon-arrow-drop-down': cell.data.type === 'statistic' && cell.data.sortedDirection !== 'desc'
              }
            )}
          >

            <div
              className={cx(
                classes.CellContentContainer,
                {
                  'icon-arrow-drop-up': cell.data.type === 'statistic' && cell.data.sortedDirection !== 'asc'
                }
              )}
            >
              {content}
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }
}

export default LazyCell;
