import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {capitalizeFirstLetter} from '../../../../../../helpers/string';
import classes from '../FormulaEditor.module.scss';

class AdditionalItem extends Component {
  static propTypes = {
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    isOver: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired
  };

  render () {
    const {connectDropTarget, isOver, direction} = this.props;

    return (
      connectDropTarget(
        <div
          className={cx(
            classes.FormulaAdditionalItem,
            classes[`FormulaAdditionalItem${capitalizeFirstLetter(direction)}`],
            {
              [classes.FormulaAdditionalItemOver]: isOver
            }
          )}
        />
      )
    );
  }
}

export default AdditionalItem;
