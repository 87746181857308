import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Img from 'react-image';
import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';
import Scrollbar from '../../../components/Scrollbar';
import Loader from '../../../components/Loader';
import Tooltip from '../../../components/Tooltip';
import Dropdown from '../../../components/Dropdown';
import {generateDateString} from '../../../helpers/date';
import confirm from '../../../components/Confirm';
import alert from '../../../helpers/alert';
import API from '../../../api';
import classes from './List.module.scss';

class List extends Component {
  static propTypes = {
    loadCatalogs: PropTypes.func.isRequired,
    catalogs: PropTypes.object.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired
  };

  state = {
    filtersLoading: false,
    cubesLoading: false,
    cubes: [],
    advertisers: [],
    selectedCatalog: null,
    selectedAccessType: 'my',
    selectedAdvertiser: null,
    searchValue: ''
  };

  _searchField = null;

  componentDidMount () {
    this.loadData();
  }

  setAsyncState = (newState) => {
    return new Promise(resolve => {
      this.setState(newState, () => {
        resolve();
      });
    });
  };

  setSearchFieldRef = ref => {
    this._searchField = ref;
  };

  loadData = async () => {
    const {catalogs, loadCatalogs} = this.props;
    const {filtersLoading, selectedCatalog} = this.state;

    const cCatalogs = {
      ...catalogs
    };

    if (!catalogs.loaded && !catalogs.loading) {
      cCatalogs.items = await loadCatalogs();
    }

    const catalogsKeys = Object.keys(cCatalogs.items);
    if (catalogsKeys.length && !selectedCatalog) {
      await this.setAsyncState({
        selectedCatalog: catalogsKeys[0]
      });
    }

    if (filtersLoading) {
      return;
    }

    this.setState({
      filtersLoading: true
    });

    await Promise.all([
      this.loadAdvertisers(),
      this.loadCubes()
    ]);

    this.setState({
      filtersLoading: false
    });
  };

  loadAdvertisers = async () => {
    try {
      const loadedAdvertisers = await API.catalogs.getAdvertisers();

      if (loadedAdvertisers.length) {
        const advertisers = loadedAdvertisers.map(advertiser => ({
          value: advertiser.id,
          label: advertiser.name,
          isActive: advertiser.isActive,
          key: advertiser.key
        }));

        this.setState({
          advertisers
        });
      }
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.list.alerts.errorLoadingAdvertisers'
        })
      );
    }
  };

  loadCubes = async () => {
    const {selectedCatalog, selectedAccessType, selectedAdvertiser} = this.state;

    if (
      !selectedCatalog ||
      !selectedAccessType
    ) {
      return;
    }

    this.setState({
      cubesLoading: true
    });

    try {
      const cubes = await API.cubes.getList(selectedCatalog, selectedAccessType, selectedAdvertiser);

      this.setState({
        cubes,
        cubesLoading: false
      });
    } catch (error) {
      console.error(error);

      alert.error(
        localizeMessage({
          id: 'persona.list.alerts.errorLoadingCubes'
        })
      );

      this.setState({
        cubesLoading: false
      });
    }
  };

  selectCatalog = (catalogKey) => {
    this.setState({
      selectedCatalog: catalogKey
    }, () => {
      this.loadCubes();
    });
  };

  selectAccessType = (accessType) => {
    const {advertisers} = this.state;

    this.setState({
      selectedAccessType: accessType,
      selectedAdvertiser: accessType === 'advertiser'
        ? advertisers[0].value
        : null
    }, () => {
      this.loadCubes();
    });
  };

  selectAdvertiser = (advertiserId) => {
    this.setState({
      selectedAdvertiser: advertiserId
    }, () => {
      this.loadCubes();
    });
  };

  copyStudy = (catalog, cube) => {
    const {selectedAccessType} = this.state;

    confirm(
      localizeMessage({
        id: 'persona.confirmations.copyCube'
      }, {
        cubeName: cube.title
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(async () => {
        try {
          const copyingCube = await API.cubes.copy(catalog, cube.id);

          alert.success(
            localizeMessage({
              id: 'persona.alerts.successfulCubeCopying'
            }, {
              cubeName: cube.title
            })
          );

          if (selectedAccessType === 'my') {
            const cubesCopy = this.state.cubes.slice();
            cubesCopy.unshift({
              id: copyingCube.id,
              title: copyingCube.title,
              createdDate: copyingCube.created
            });

            this.setState({
              cubes: cubesCopy
            });
          }
        } catch (error) {
          console.error(error);

          alert.error(
            localizeMessage({
              id: 'persona.alerts.errorCubeCopying'
            }, {
              cubeName: cube.title
            })
          );
        }
      });
  };

  deleteStudy = (catalog, cube) => {
    confirm(
      localizeMessage({
        id: 'persona.confirmations.deleteCube'
      }, {
        cubeName: cube.title
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(async () => {
        try {
          await API.cubes.delete(catalog, cube.id);

          alert.success(
            localizeMessage({
              id: 'persona.alerts.successfulCubeRemoving'
            }, {
              cubeName: cube.title
            })
          );

          this.setState({
            cubes: this.state.cubes.filter(cCube => cCube.id !== cube.id)
          });
        } catch (error) {
          console.error(error);

          alert.error(
            localizeMessage({
              id: 'persona.alerts.errorCubeRemoving'
            }, {
              cubeName: cube.title
            })
          );
        }
      });
  };

  onChangeSearchField = (e) => {
    const searchValue = e.target.value;

    this.setState({
      searchValue
    });
  };

  resetSearchField = () => {
    this.setState({
      searchValue: ''
    }, () => {
      this._searchField.focus();
    });
  };

  getAccessTypes = () => {
    const {isExternalUser} = this.props;
    const {advertisers} = this.state;

    const resultAccessTypes = [
      {
        label: <LocalizedMessage
          id='persona.list.filters.accessTypes.my'
        />,
        value: 'my'
      },
    ];

    if (!isExternalUser) {
      resultAccessTypes.push({
        label: <LocalizedMessage
          id='persona.list.filters.accessTypes.all'
        />,
        value: 'all'
      });
    }

    if (!isExternalUser && advertisers.length) {
      resultAccessTypes.push({
        label: <LocalizedMessage
          id='persona.list.filters.accessTypes.advertiser'
        />,
        value: 'advertiser'
      });
    }

    return resultAccessTypes;
  };

  getCubes = () => {
    const {cubes, searchValue} = this.state;

    const value = searchValue.trim().toLowerCase();

    if (!value.length) {
      return cubes;
    }

    return cubes.filter(cube => cube.title.toLowerCase().indexOf(value) > -1);
  };

  render () {
    const {catalogs, isAdmin} = this.props;
    const {
      advertisers,
      filtersLoading,
      cubesLoading,
      selectedCatalog,
      selectedAccessType,
      selectedAdvertiser,
      searchValue
    } = this.state;

    const catalogsKeys = Object.keys(catalogs.items);

    const cubes = this.getCubes();

    return (
      <div>
        <LocalizedMessage
          id='site.title.list'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <Scrollbar>
          <div className='content'>
            <div className={classes.Container}>
              <div className={classes.Category}>
                <h3 className={classes.CategoryTitle}>
                  <LocalizedMessage
                    id='persona.list.groups.catalogs'
                  />
                </h3>
                <ul className={classes.List}>
                  {
                    catalogsKeys.map(catalogKey => (
                      <Tooltip
                        key={catalogKey}
                        overlay={
                          <p className={classes.StudyTooltip}>
                            <strong>
                              {catalogs.items[catalogKey].title}
                            </strong>
                            {catalogs.items[catalogKey].description}
                          </p>
                        }
                        position='bottom'
                      >
                        <li className={classes.Study}>
                          <Link
                            to={`/cube/${catalogKey}`}
                            className={classes.StudyLink}
                          >
                            <h4
                              title={catalogs.items[catalogKey].title}
                              className={classes.StudyName}
                            >
                              {catalogs.items[catalogKey].title}
                            </h4>
                            <div className={classes.StudyImageContainer}>
                              <Img
                                className={classes.StudyImage}
                                src={`/img/catalogs-logos/${catalogKey}.png`}
                                alt={catalogs.items[catalogKey].title}
                                loader={
                                  <Loader
                                    withText={false}
                                    withBackground={false}
                                  />
                                }
                              />
                            </div>
                            <p className={classes.StudyDate}>
                              {generateDateString(catalogs.items[catalogKey].endDate)}
                            </p>
                          </Link>
                        </li>
                      </Tooltip>
                    ))
                  }
                </ul>
              </div>
              <div className={classes.Category}>
                <h3 className={classes.CategoryTitle}>
                  <LocalizedMessage
                    id='persona.list.groups.savedCubes'
                  />
                </h3>
                <div className={cx(
                  classes.Search,
                  'icon-search',
                  {
                    [classes.SearchNotEmpty]: searchValue
                  }
                )}>
                  <LocalizedMessage
                    id='persona.list.filters.search'
                  >
                    {localizedMessage => (
                      <input
                        ref={this.setSearchFieldRef}
                        type='text'
                        placeholder={localizedMessage}
                        value={searchValue}
                        onChange={this.onChangeSearchField}
                        className={classes.SearchField}
                      />
                    )}
                  </LocalizedMessage>
                  <div
                    className={cx(
                      classes.SearchResetBtn,
                      'icon-close'
                    )}
                    onClick={this.resetSearchField}
                  />
                </div>
                {
                  !filtersLoading ?
                    <div className={classes.Filters}>
                      <div className={classes.FiltersItem}>
                        <span className={classes.FiltersItemLabel}>
                          <LocalizedMessage
                            id='persona.list.filters.catalog'
                          />:
                        </span>
                        <Dropdown
                          autoHeightMax={250}
                          currentValueClassName={classes.DropdownCurrentValue}
                          options={catalogsKeys.map(catalogKey => ({
                            label: catalogs.items[catalogKey].title,
                            value: catalogKey
                          }))}
                          defaultValue={selectedCatalog}
                          onChange={this.selectCatalog}
                        />
                      </div>
                      <div className={classes.FiltersItem}>
                        <span className={classes.FiltersItemLabel}>
                          <LocalizedMessage
                            id='persona.list.filters.accessTypes'
                          />:
                        </span>
                        <Dropdown
                          autoHeightMax={250}
                          currentValueClassName={classes.DropdownCurrentValue}
                          options={this.getAccessTypes()}
                          defaultValue={selectedAccessType}
                          onChange={this.selectAccessType}
                        />
                      </div>
                      {
                        selectedAccessType === 'advertiser' ?
                          <div className={classes.FiltersItem}>
                            <span className={classes.FiltersItemLabel}>
                              <LocalizedMessage
                                id='persona.list.filters.advertiser'
                              />:
                            </span>
                            <Dropdown
                              autoHeightMax={250}
                              currentValueClassName={classes.DropdownCurrentValue}
                              options={advertisers}
                              defaultValue={selectedAdvertiser}
                              onChange={this.selectAdvertiser}
                            />
                          </div>
                          : null
                      }
                    </div>
                    : null
                }
                <ul
                  className={cx(
                    classes.List,
                    classes.ListSavedCubes
                  )}
                >
                  {
                    cubes.map(cube => (
                      <li
                        key={`${selectedCatalog}_${selectedAccessType}_${cube.id}`}
                        className={classes.Study}
                      >
                        <div className={classes.StudyButtons}>
                          {
                            cube.isAuthor || isAdmin ?
                              <LocalizedMessage
                                id='base.delete'
                              >
                                {localizedMessage => (
                                  <div
                                    className={cx(
                                      classes.StudyButtonsItemWrapper,
                                      classes.StudyButtonsItemWrapperDelete
                                    )}
                                    onClick={() => this.deleteStudy(selectedCatalog, cube)}
                                  >
                                    <p
                                      title={localizedMessage}
                                      className={cx(
                                        classes.StudyButtonsItem,
                                        classes.StudyButtonsItemDelete,
                                        'icon-delete'
                                      )}
                                    />
                                  </div>
                                )}
                              </LocalizedMessage>
                              : null
                          }
                          <LocalizedMessage
                            id='base.copy'
                          >
                            {localizedMessage => (
                              <div
                                className={cx(
                                  classes.StudyButtonsItemWrapper,
                                  classes.StudyButtonsItemWrapperCopy
                                )}
                                onClick={() => this.copyStudy(selectedCatalog, cube)}
                              >
                                <p
                                  title={localizedMessage}
                                  className={cx(
                                    classes.StudyButtonsItem,
                                    classes.StudyButtonsItemCopy,
                                    'icon-copy'
                                  )}
                                />
                              </div>
                            )}
                          </LocalizedMessage>
                        </div>
                        <Link
                          to={`/cube/${selectedCatalog}/${cube.id}`}
                          className={classes.StudyLink}
                        >
                          <h4
                            title={cube.title}
                            className={classes.StudyName}
                          >
                            {cube.title}
                          </h4>
                          <div className={classes.StudyImageContainer}>
                            <Img
                              className={classes.StudyImage}
                              src={cube.img || require('./assets/my_reports.png')}
                              loader={
                                <Loader
                                  withText={false}
                                  withBackground={false}
                                />
                              }
                            />
                          </div>
                          {
                            cube.createdDate ?
                              <p className={classes.StudyDate}>
                                {generateDateString(cube.createdDate)}
                              </p>
                              : null
                          }
                        </Link>
                      </li>
                    ))
                  }
                </ul>
                {
                  !cubes.length ?
                    <div className={classes.EmptyStudies}>
                      <LocalizedMessage
                        id='persona.list.emptyStudies'
                      />
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </Scrollbar>
        <Loader
          active={catalogs.loading || filtersLoading || cubesLoading}
        />
      </div>
    );
  }
}

export default List;
