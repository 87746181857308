export const wait = ms => new Promise(resolve => {
  setTimeout(resolve, ms);
});

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const hashCode = (s) => {
  let h = 0;
  let i = 0;
  const l = s.length;

  if (l > 0) {
    while (i < l) {
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
    }
  }

  return h;
};

export const cloneObject = (object, ignoredFields = []) => {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (object === null || typeof object !== 'object') return object;

  // Handle Date
  if (object instanceof Date) {
    copy = new Date();
    copy.setTime(object.getTime());

    return copy;
  }

  // Handle Array
  if (object instanceof Array) {
    copy = [];

    for (let i = 0, len = object.length; i < len; i++) {
      copy[i] = cloneObject(object[i], ignoredFields);
    }

    return copy;
  }

  // Handle Object
  if (object instanceof Object) {
    copy = {};

    for (const attr in object) {
      if (Object.prototype.hasOwnProperty.call(object, attr) && ignoredFields.indexOf(attr) < 0) {
        copy[attr] = cloneObject(object[attr], ignoredFields);
      }
    }

    return copy;
  }

  throw new Error('Unable to copy obj! Its type isn\'t supported.');
};

export const transposeMatrix = a => a[0].map((_, c) => a.map(r => r[c]));

const getSubParamValue = (item, paramName) => {
  const paramNames = paramName.split('.');

  let realItem = item || null;

  paramNames.forEach(paramName => {
    realItem = typeof realItem === 'object' && Object.keys(realItem).length ? realItem[paramName] : null;
  });

  return realItem;
};

export const getArrayItemByParam = (items = [], paramName, value) => {
  for (let i = 0; i < items.length; i++) {
    if (getSubParamValue(items[i], paramName) === value) {
      return items[i];
    }
  }

  return null;
};

export const getArrayItemIndexByParam = (items = [], paramName, value) => {
  for (let i = 0; i < items.length; i++) {
    if (getSubParamValue(items[i], paramName) === value) {
      return i;
    }
  }

  return -1;
};

export const arrayToObject = (sourceArray, keyName) => {
  const resultObject = {};

  for (let i = 0; i < sourceArray.length; i++) {
    resultObject[sourceArray[i][keyName]] = sourceArray[i];
  }

  return resultObject;
};

export const removeArrayItemsByIndexes = (sourceArray, indexes) => (
  sourceArray.filter((item, index) => indexes.indexOf(index) === -1)
);

export const sortNumberArray = (sourceArray) => (
  sourceArray.sort((a, b) => a - b)
);

export const capitalizeFirstLetter = sourceString => (
  sourceString.charAt(0).toUpperCase() + sourceString.slice(1).toLowerCase()
);
