/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React from 'react';

export const UILogo = ({className}) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' x={0} y={0} viewBox='0 0 314.7 78.49'>
    <path fill='#FDC31F' d='M207.91,73.31c2.12,0,3.86-0.47,5.23-1.4c1.37-0.93,2.06-2.19,2.06-3.77v-4.67h-5.73
	c-4.69,0-7.04,1.45-7.04,4.36v1.12c0,1.45,0.49,2.54,1.46,3.27C204.87,72.95,206.21,73.31,207.91,73.31 M221.62,77.74
	c-1.79,0-3.16-0.51-4.11-1.53c-0.96-1.02-1.54-2.32-1.74-3.89h-0.31c-0.62,2.04-1.77,3.57-3.43,4.61c-1.66,1.04-3.68,1.56-6.04,1.56
	c-3.36,0-5.95-0.87-7.76-2.62c-1.81-1.74-2.71-4.09-2.71-7.04c0-3.24,1.17-5.67,3.52-7.29c2.35-1.62,5.78-2.43,10.31-2.43h5.86
	v-2.74c0-1.99-0.54-3.53-1.62-4.61c-1.08-1.08-2.76-1.62-5.05-1.62c-1.91,0-3.47,0.42-4.67,1.25c-1.2,0.83-2.22,1.89-3.05,3.18
	l-4.05-3.68c1.08-1.83,2.6-3.33,4.55-4.52c1.95-1.18,4.51-1.78,7.66-1.78c4.2,0,7.41,0.98,9.66,2.93c2.24,1.95,3.36,4.76,3.36,8.41
	v16.26h3.43v5.55H221.62z M161.74,77.74v-32.4h6.79v5.36h0.31c0.71-1.74,1.78-3.2,3.21-4.36c1.43-1.16,3.4-1.74,5.89-1.74
	c3.32,0,5.91,1.09,7.76,3.27c1.85,2.18,2.77,5.29,2.77,9.31v20.56h-6.79V58.05c0-5.07-2.04-7.6-6.11-7.6
	c-0.87,0-1.73,0.11-2.59,0.34c-0.85,0.23-1.61,0.57-2.27,1.03c-0.66,0.46-1.19,1.04-1.59,1.74c-0.39,0.71-0.59,1.54-0.59,2.49v21.68
	H161.74z M139.31,72.82c2.33,0,4.2-0.72,5.61-2.14c1.41-1.42,2.12-3.56,2.12-6.41v-5.46c0-2.85-0.71-4.98-2.12-6.41
	c-1.41-1.42-3.28-2.14-5.61-2.14s-4.2,0.72-5.61,2.14c-1.41,1.42-2.12,3.56-2.12,6.41v5.46c0,2.85,0.71,4.98,2.12,6.41
	C135.12,72.1,136.99,72.82,139.31,72.82z M139.31,78.49c-2.24,0-4.29-0.39-6.14-1.18c-1.85-0.79-3.42-1.92-4.7-3.4
	c-1.29-1.47-2.28-3.26-2.99-5.36c-0.71-2.1-1.06-4.43-1.06-7.01c0-2.58,0.35-4.91,1.06-7.01c0.71-2.1,1.7-3.88,2.99-5.36
	c1.29-1.47,2.86-2.61,4.7-3.4c1.85-0.79,3.89-1.18,6.14-1.18c2.24,0,4.29,0.39,6.14,1.18c1.85,0.79,3.42,1.92,4.7,3.4
	c1.29,1.47,2.28,3.26,2.99,5.36c0.71,2.1,1.06,4.43,1.06,7.01c0,2.58-0.35,4.91-1.06,7.01c-0.71,2.1-1.7,3.88-2.99,5.36
	c-1.29,1.47-2.86,2.61-4.7,3.4C143.6,78.09,141.56,78.49,139.31,78.49z M106.11,78.49c-3.07,0-5.65-0.54-7.73-1.62
	c-2.08-1.08-3.88-2.58-5.42-4.49l4.36-3.99c1.2,1.45,2.54,2.59,4.02,3.4c1.47,0.81,3.19,1.21,5.14,1.21c1.99,0,3.48-0.38,4.45-1.15
	c0.98-0.77,1.46-1.84,1.46-3.21c0-1.04-0.34-1.92-1.03-2.65c-0.69-0.73-1.88-1.19-3.58-1.4l-2.99-0.37
	c-3.32-0.42-5.93-1.38-7.82-2.9c-1.89-1.52-2.83-3.77-2.83-6.76c0-1.58,0.29-2.98,0.87-4.21c0.58-1.23,1.4-2.26,2.46-3.12
	c1.06-0.85,2.33-1.51,3.8-1.96c1.47-0.46,3.1-0.69,4.89-0.69c2.91,0,5.29,0.46,7.13,1.37c1.85,0.91,3.5,2.18,4.95,3.8l-4.17,3.99
	c-0.83-1-1.92-1.86-3.27-2.59c-1.35-0.73-2.9-1.09-4.64-1.09c-1.87,0-3.25,0.37-4.14,1.12c-0.89,0.75-1.34,1.72-1.34,2.93
	c0,1.25,0.39,2.18,1.18,2.8c0.79,0.62,2.06,1.08,3.8,1.37l2.99,0.37c3.57,0.54,6.18,1.59,7.82,3.15c1.64,1.56,2.46,3.64,2.46,6.26
	c0,1.58-0.3,3-0.9,4.27c-0.6,1.27-1.46,2.36-2.59,3.27c-1.12,0.91-2.47,1.62-4.05,2.12C109.82,78.24,108.06,78.49,106.11,78.49z
	 M71.96,77.74v-32.4h6.79v6.23h0.31c0.46-1.66,1.41-3.12,2.87-4.36s3.47-1.87,6.04-1.87h1.81v6.54H87.1c-2.7,0-4.77,0.44-6.2,1.31
	c-1.43,0.87-2.15,2.16-2.15,3.86v20.69H71.96z M50.03,49.89c-1.16,0-2.23,0.21-3.21,0.62c-0.98,0.42-1.81,1-2.49,1.74
	c-0.69,0.75-1.22,1.64-1.59,2.68c-0.37,1.04-0.56,2.18-0.56,3.43v0.44h15.08v-0.62c0-2.49-0.64-4.5-1.93-6.01
	C54.04,50.65,52.27,49.89,50.03,49.89z M50.03,78.49c-2.33,0-4.4-0.39-6.23-1.18c-1.83-0.79-3.39-1.92-4.67-3.4
	c-1.29-1.47-2.27-3.26-2.96-5.36c-0.69-2.1-1.03-4.43-1.03-7.01c0-2.58,0.34-4.91,1.03-7.01c0.69-2.1,1.67-3.88,2.96-5.36
	c1.29-1.47,2.85-2.61,4.67-3.4c1.83-0.79,3.9-1.18,6.23-1.18c2.37,0,4.45,0.42,6.26,1.25c1.81,0.83,3.31,1.98,4.52,3.46
	c1.2,1.47,2.11,3.19,2.71,5.14c0.6,1.95,0.9,4.05,0.9,6.29v2.55H42.18v1.05c0,2.48,0.74,4.51,2.21,6.1
	c1.47,1.59,3.58,2.39,6.32,2.39c1.99,0,3.68-0.44,5.05-1.3c1.37-0.87,2.53-2.05,3.49-3.53l3.99,3.94c-1.2,2-2.95,3.59-5.23,4.78
	C55.72,77.89,53.06,78.49,50.03,78.49z M7.04,54.19h11.21c1.79,0,3.18-0.47,4.17-1.39c1-0.92,1.5-2.25,1.5-3.98v-2.97
	c0-1.72-0.5-3.05-1.5-3.98c-1-0.92-2.39-1.39-4.17-1.39H7.04V54.19z M0,77.74V34.25h18.69c4.03,0,7.14,1.18,9.35,3.55
	c2.2,2.37,3.3,5.55,3.3,9.53s-1.1,7.16-3.3,9.53c-2.2,2.37-5.32,3.55-9.35,3.55H7.04v17.32H0z'/>
    <path fill='#1A1A18' d='M93.08,4.89h-0.14l-2.81,8.56h5.73L93.08,4.89z M98.39,21.41l-1.75-5.25h-7.26l-1.7,5.25h-3.2L91.11,2h3.95
	l6.62,19.41H98.39z M78.48,6.56h-0.14L76.82,9.7l-4.25,7.76L68.31,9.7l-1.53-3.14h-0.14v14.85h-3V2h3.73l5.2,9.87h0.17L77.93,2h3.56
	v19.41h-3V6.56z M60.22,21.41H45.85V18.6L56.38,4.78H46.4V2h13.51v2.81L49.38,18.63h10.84V21.41z M34.81,21.41v-2.56h2.64V4.56
	h-2.64V2h8.45v2.56h-2.67v14.29h2.67v2.56H34.81z M25.58,10.92c0.8,0,1.42-0.21,1.86-0.62c0.44-0.42,0.67-1.01,0.67-1.79V7.19
	c0-0.78-0.22-1.37-0.67-1.79C27,4.99,26.37,4.78,25.58,4.78h-5v6.14H25.58z M20.57,21.41h-3.14V2h8.37c1.74,0,3.11,0.52,4.11,1.57
	c1,1.05,1.5,2.47,1.5,4.27c0,1.39-0.32,2.54-0.96,3.46c-0.64,0.92-1.58,1.56-2.82,1.93l4.2,8.17h-3.5l-3.89-7.84h-3.86V21.41z
	 M3.14,10.9h5c0.8,0,1.42-0.21,1.86-0.62c0.44-0.41,0.67-1.01,0.67-1.77V7.18c0-0.77-0.22-1.36-0.67-1.77
	C9.56,4.99,8.94,4.78,8.15,4.78h-5V10.9z M0,21.41V2h8.34c1.8,0,3.19,0.53,4.17,1.58c0.98,1.06,1.47,2.47,1.47,4.25
	c0,1.78-0.49,3.2-1.47,4.25c-0.98,1.06-2.37,1.58-4.17,1.58h-5.2v7.73H0z'/>
    <path fillRule='evenodd' clipRule='evenodd' fill='#FDC31F' d='M274.79,5.22c19.21,0,34.79,15.58,34.79,34.79c0,19.21-15.58,34.79-34.79,34.79
	c-19.21,0-34.79-15.58-34.79-34.79C240.01,20.79,255.58,5.22,274.79,5.22 M274.79,2.1c20.94,0,37.91,16.97,37.91,37.91
	c0,20.94-16.97,37.91-37.91,37.91c-20.93,0-37.91-16.97-37.91-37.91C236.89,19.07,253.86,2.1,274.79,2.1z'/>
    <path fillRule='evenodd' clipRule='evenodd' fill='#1A1A18' d='M268.53,30.46c1.97,0,3.56,1.6,3.56,3.57c0,1.97-1.6,3.56-3.56,3.56c-1.97,0-3.57-1.6-3.57-3.56
	C264.96,32.06,266.56,30.46,268.53,30.46 M292.59,24.38c1.97,0,3.56,1.6,3.56,3.56c0,1.97-1.6,3.57-3.56,3.57
	c-1.97,0-3.57-1.6-3.57-3.57C289.03,25.98,290.62,24.38,292.59,24.38z M289.43,55.5c1.97,0,3.57,1.6,3.57,3.57
	c0,1.97-1.6,3.56-3.57,3.56c-1.97,0-3.56-1.6-3.56-3.56C285.87,57.09,287.46,55.5,289.43,55.5z M264.8,53.47
	c1.97,0,3.57,1.6,3.57,3.57c0,1.97-1.6,3.56-3.57,3.56c-1.97,0-3.56-1.6-3.56-3.56C261.24,55.07,262.83,53.47,264.8,53.47z
	 M258.89,18.63c1.97,0,3.56,1.6,3.56,3.57c0,1.97-1.6,3.56-3.56,3.56c-1.97,0-3.57-1.6-3.57-3.56
	C255.32,20.23,256.92,18.63,258.89,18.63z'/>
    <path fill='#FDC31F' d='M257.87,21.43c-0.35-0.44-0.28-1.09,0.16-1.44c0.44-0.35,1.09-0.28,1.44,0.16l9.95,12.46l23.51-5.61
	c0.55-0.14,1.1,0.19,1.24,0.74c0.14,0.55-0.19,1.1-0.74,1.24l-22.62,5.38l19.77,24.77c0.35,0.44,0.28,1.09-0.16,1.44
	c-0.44,0.35-1.09,0.28-1.44-0.16l-19.56-24.5l-4,23.04c-0.09,0.56-0.62,0.93-1.18,0.84c-0.56-0.09-0.93-0.62-0.84-1.18l4.31-24.85
	L257.87,21.43z'/>
  </svg>
);
