import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {SwatchesPicker} from 'react-color';
import {convertHexToRGB} from '../../../../helpers/colours';
import classes from './Graph.module.scss';

class Legends extends Component {
  static propTypes = {
    originalType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.object
    ),
    labels: PropTypes.array.isRequired,
    hasSecondChart: PropTypes.bool.isRequired,
    isGroupingData: PropTypes.bool.isRequired,
    getChart: PropTypes.func.isRequired
  };

  state = {
    disabledLabelIndexes: [],
    datasets: [],
    type: null,
    colorPickerIndex: null,
    originalType: null
  };

  _colorPicker = null;

  componentDidMount () {
    this.readDisabledLabels(this.props);
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.readDisabledLabels(newProps);
  }

  setColorPickerRef = ref => {
    this._colorPicker = ref;
  }

  readDisabledLabels = (props) => {
    const {originalType, type, datasets, getChart} = props;
    const {datasets: stateDatasets, originalType: stateOriginalType} = this.state;
    const {disabledLabelIndexes} = this.state;

    this.setState({
      datasets: datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor:
          originalType === stateOriginalType &&
          stateDatasets[index] &&
          stateDatasets[index].type === dataset.type &&
          stateDatasets[index].label === dataset.label
            ? stateDatasets[index].backgroundColor
            : dataset.backgroundColor,
        borderColor:
          originalType === stateOriginalType &&
          stateDatasets[index] &&
          stateDatasets[index].type === dataset.type &&
          stateDatasets[index].label === dataset.label
            ? stateDatasets[index].borderColor
            : dataset.borderColor
      })),
      originalType
    });

    if (!disabledLabelIndexes.length) {
      return;
    }

    setTimeout(() => {
      const newDisabledLabelIndexes = [];

      const _chart = getChart();

      disabledLabelIndexes.forEach(index => {
        const cMeta = type === 'doughnut'
          ? _chart.getDatasetMeta(0).data[index]
          : _chart.getDatasetMeta(index);

        if (cMeta.hidden) {
          newDisabledLabelIndexes.push(index);
        }
      });

      if (disabledLabelIndexes.length === newDisabledLabelIndexes.length) {
        return;
      }

      this.setState({
        disabledLabelIndexes: newDisabledLabelIndexes
      });
    });
  };

  openColorSelector = (colorPickerIndex) => {
    const {originalType, hasSecondChart} = this.props;

    if (hasSecondChart && originalType === 'bar' && colorPickerIndex === 1) {
      return;
    }

    this.setState({
      colorPickerIndex
    });

    document.addEventListener('mousedown', this.handleClickOutside);
  };

  closeColorSelector = () => {
    this.setState({
      colorPickerIndex: null
    });

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this._colorPicker && !this._colorPicker.contains(event.target)) {
      this.closeColorSelector();
    }
  };

  changeColor = (index) => (color) => {
    const {hasSecondChart, getChart} = this.props;
    const {datasets} = this.state;

    const _chart = getChart();

    if (datasets[index].type === 'doughnut') {
      _chart.data.datasets[0].backgroundColor[index] = color.hex;
      datasets[0].backgroundColor[index] = color.hex;
    } else if (hasSecondChart) {
      if (index === 0) {
        _chart.data.datasets[index].borderColor = color.hex;
        datasets[index].borderColor = color.hex;
      } else {
        _chart.data.datasets[index].backgroundColor = convertHexToRGB(color.hex, 20);
        datasets[index].backgroundColor = convertHexToRGB(color.hex, 20);
      }
    } else {
      _chart.data.datasets[index].backgroundColor = convertHexToRGB(color.hex, 20);
      datasets[index].backgroundColor = convertHexToRGB(color.hex, 20);
      _chart.data.datasets[index].borderColor = color.hex;
      datasets[index].borderColor = color.hex;
    }

    this.closeColorSelector();

    this.setState({
      datasets: datasets
    }, () => {
      _chart.update();
    });
  };

  toggleLegend = (index) => () => {
    const {type, getChart} = this.props;

    const _chart = getChart();

    const meta = type === 'doughnut'
      ? _chart.getDatasetMeta(0).data[index]
      : _chart.getDatasetMeta(index);

    const copyDisabledIndexes = this.state.disabledLabelIndexes.slice();
    const labelIndex = copyDisabledIndexes.indexOf(index);

    if (labelIndex > -1) {
      copyDisabledIndexes.splice(labelIndex, 1);
      meta.hidden = false;
    } else {
      copyDisabledIndexes.push(index);
      meta.hidden = true;
    }

    _chart.update();

    this.setState({
      disabledLabelIndexes: copyDisabledIndexes
    });
  };

  render () {
    const {
      type,
      labels,
      hasSecondChart,
      isGroupingData
    } = this.props;
    const {disabledLabelIndexes, colorPickerIndex, datasets} = this.state;

    if (
      !hasSecondChart &&
      !isGroupingData &&
      type !== 'doughnut'
    ) {
      return null;
    }

    const legends =
      type !== 'doughnut'
        ? datasets.map(dataset => ({
          label: dataset.label,
          style: {
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            borderWidth: dataset.borderWidth
          }
        }))
        : (datasets[0] ? labels : []).map((label, index) => ({
          label: label,
          style: {
            backgroundColor: datasets[0].backgroundColor[index],
            borderColor: datasets[0].borderColor,
            borderWidth: datasets[0].borderWidth
          }
        }));

    return (
      <ul className={classes.ChartLegends}>
        {
          legends.map((legend, index) => (
            <li
              key={index}
              className={cx(
                classes.ChartLegendsItem,
                {
                  [classes.ChartLegendsItemDisabled]: disabledLabelIndexes.indexOf(index) > -1
                }
              )}
            >
              <span
                className={classes.ChartLegendsColorBox}
                style={legend.style}
                onClick={() => this.openColorSelector(index)}
              />
              <span
                className={classes.ChartLegendsText}
                onClick={this.toggleLegend(index)}
              >
                {legend.label}
              </span>
              {
                index === colorPickerIndex ?
                  <div
                    ref={this.setColorPickerRef}
                    className={classes.ChartLegendsColorPicker}
                  >
                    <SwatchesPicker
                      color={type !== 'doughnut' ? legend.style.borderColor : legend.style.backgroundColor}
                      onChange={this.changeColor(index)}
                    />
                  </div>
                  : null
              }
            </li>
          ))
        }
      </ul>
    );
  }
}

export default Legends;
