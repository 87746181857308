import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {confirmable} from 'react-confirm';
import Button from '../Button';
import classes from './Confirm.module.scss';

export class Confirm extends Component {
  static propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    dismiss: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      confirmBtnText: PropTypes.string.isRequired,
      cancelBtnText: PropTypes.string.isRequired,
      cancelCallback: PropTypes.func
    }).isRequired
  };

  static defaultProps = {
    show: true,
    proceed: null,
    cancel: null,
    confirmation: null
  };

  cancel = () => {
    const {dismiss, options} = this.props;

    dismiss();

    if (typeof options.cancelCallback === 'function') {
      options.cancelCallback();
    }
  };

  render () {
    const {show, proceed, confirmation, options} = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <div className={classes.Content}>
          {
            confirmation ?
              <p className={classes.Question}>
                {confirmation}
              </p>
              : null
          }
          <div className={classes.Buttons}>
            <Button
              onClick={proceed}
            >
              {options.confirmBtnText}
            </Button>
            <Button
              theme='transparent'
              onClick={this.cancel}
            >
              {options.cancelBtnText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default confirmable(Confirm);
