import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {capitalizeFirstLetter} from '../../../../../../helpers/string';
import classes from '../FormulaEditor.module.scss';

class Connector extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    selectedItem: PropTypes.shape({
      type: PropTypes.string.isRequired,
      path: PropTypes.array.isRequired
    }),
    path: PropTypes.array.isRequired,
    isSelectedFirst: PropTypes.bool,
    cursorDirection: PropTypes.string,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    switchConnector: PropTypes.func.isRequired
  };

  switchConnector = () => {
    const {path, switchConnector} = this.props;

    switchConnector(path);
  };

  render () {
    const {
      children,
      selectedItem,
      path,
      isSelectedFirst,
      connectDropTarget,
      isOver,
      cursorDirection
    } = this.props;

    return connectDropTarget(
      <div
        className={cx(
          classes.FormulaConnector,
          {
            [classes.FormulaConnectorSelected]: (
              selectedItem &&
              selectedItem.type === 'connector' &&
              selectedItem.path.join('-') === path.join('-')
            ),
            [classes.FormulaConnectorSelectedFirst]: isSelectedFirst,
            [classes[`FormulaConnectorCursor${capitalizeFirstLetter(cursorDirection)}`]]: (
              isOver
            )
          }
        )}
        onMouseDown={this.switchConnector}
      >
        <div className={classes.FormulaConnectorContent}>
          {children}
        </div>
      </div>
    );
  }
}

export default Connector;
