import {cloneObject} from '../../../helpers/utils';

export const fetchResponse = (ms, response, initController) => new Promise((resolve, reject) => {
  const timeout = setTimeout(() => {
    if (response instanceof Error) {
      reject(response);
    } else {
      resolve(cloneObject(response));
    }
  }, ms);

  if (initController) {
    initController({
      reset: () => {
        clearTimeout(timeout);
      }
    });
  }
});
