import React from 'react';
import {NavLink} from 'react-router-dom';
import cx from 'classnames';
import LocalizedMessage from '../../../components/LocalizedMessage';
import classes from '../Header.module.scss';
import {isOnPointsGroup} from '../../../config';

const Menu = () => (
  <ul className={classes.Menu}>
    <li className={classes.MenuItem}>
      <NavLink
        to='/'
        className={classes.MenuLink}
        activeClassName={classes.MenuLinkActive}
      >
        <LocalizedMessage
          id='site.menu.persona'
        />
      </NavLink>
    </li>
    <li className={classes.MenuItem}>
      <a
        href={`https://algorithm.${isOnPointsGroup ? 'pointsgroup' : 'aizek'}.io`}
        className={classes.MenuLink}
      >
        <LocalizedMessage
          id='site.menu.algorithm'
        />
      </a>
    </li>
    <li className={classes.MenuItem}>
      <a
        href={`https://trade.${isOnPointsGroup ? 'pointsgroup' : 'aizek'}.io`}
        className={classes.MenuLink}
      >
        <LocalizedMessage
          id='site.menu.trade'
        />
      </a>
    </li>
    <li className={classes.MenuItem}>
      <a
        href={`https://clientspace.${isOnPointsGroup ? 'pointsgroup' : 'aizek'}.io/`}
        className={classes.MenuLink}
      >
        <LocalizedMessage
          id='site.menu.clientspace'
        />
      </a>
    </li>
    <li
      className={cx(
        classes.MenuItem,
        classes.MenuItemSupport
      )}
    >
      <a
        href='mailto:support@aizek.io?subject=Appeal%20in%20support'
        className={classes.MenuLink}
      >
        <LocalizedMessage
          id='site.menu.support'
        />
      </a>
    </li>
  </ul>
);

export default Menu;
