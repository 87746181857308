import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IntegerField from './Fields/IntegerField';
import EventField from './Fields/EventField';
import classes from './Templates.module.scss';

const EQUAL_INTEGER_MIN_VALUE = 0;
const EQUAL_INTEGER_MAX_VALUE = 2147483647;

class Equal extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    data: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    isSelecting: PropTypes.bool.isRequired,
    addOnScrollCallback: PropTypes.func.isRequired,
    removeOnScrollCallback: PropTypes.func.isRequired,
    toggleChanging: PropTypes.func.isRequired,
    setFieldRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputWidthChange: PropTypes.func.isRequired,
    loadEventHints: PropTypes.func.isRequired
  };

  render () {
    const {
      locale,
      data,
      catalog,
      isSelecting,
      addOnScrollCallback,
      removeOnScrollCallback,
      toggleChanging,
      setFieldRef,
      onChange,
      onInputWidthChange,
      loadEventHints
    } = this.props;

    const values = data.condition.values;

    return (
      <div className={classes.Container}>
        {data.title[locale]} =
        {
          data.condition.type === 'ta_integer'
            ? <IntegerField
              minValue={EQUAL_INTEGER_MIN_VALUE}
              maxValue={EQUAL_INTEGER_MAX_VALUE}
              value={values && values.length ? values[0] : null}
              isSelecting={isSelecting}
              toggleChanging={toggleChanging}
              setFieldRef={setFieldRef}
              onChange={value => onChange([value])}
            />
            : <EventField
              locale={locale}
              data={data}
              catalog={catalog}
              values={values || null}
              isSelecting={isSelecting}
              addOnScrollCallback={addOnScrollCallback}
              removeOnScrollCallback={removeOnScrollCallback}
              toggleChanging={toggleChanging}
              setFieldRef={setFieldRef}
              onChange={onChange}
              onInputWidthChange={onInputWidthChange}
              loadEventHints={loadEventHints}
            />
        }
      </div>
    );
  }
}

export default Equal;
