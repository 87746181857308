import {fetchResponse} from './helpers/fakeFetch';
import hints from '../../mocks/hints.json';
import Config from '../../config';

const eventHintsAPI = {
  find (catalog, id, pattern, offset = 0) {
    console.log('api.eventHints.find(', catalog, id, pattern, offset, ')');

    const patternLowerCase = pattern.toLowerCase();

    let resultHints = hints
      ? hints[id.toString()]
      : [];

    if (pattern.length) {
      resultHints = resultHints.filter(hint => hint.toLowerCase().indexOf(patternLowerCase) > -1);
    }

    return fetchResponse(1200, {
      hints: resultHints.slice(offset, offset + Config.appOptions.eventHintsLoadingItemsLimit),
      finished: resultHints.length <= offset + Config.appOptions.eventHintsLoadingItemsLimit
    });
  }
};

export default eventHintsAPI;
