import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {DragSource} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import LocalizedMessage, {localizeMessage} from '../../../../../components/LocalizedMessage';
import alert from '../../../../../helpers/alert';
import confirm from '../../../../../components/Confirm';
import Button from '../../../../../components/Button';
import Tooltip from '../../../../../components/Tooltip';
import classes from './List.module.scss';

export class Item extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    item: PropTypes.object.isRequired,
    itemData: PropTypes.object,
    selectedIds: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    ),
    isAdmin: PropTypes.bool.isRequired,
    formulaIsOpen: PropTypes.bool.isRequired,
    canDrag: PropTypes.bool,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    changeFormula: PropTypes.func,
    // downloadMemberList: PropTypes.func.isRequired,
    editSavedFormula: PropTypes.func.isRequired,
    deleteSavedFormula: PropTypes.func.isRequired
  };

  static defaultProps = {
    canDrag: false
  };

  state = {
    removing: false
  };

  componentDidMount () {
    const {connectDragPreview} = this.props;

    if (typeof connectDragPreview === 'function') {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  addItem = (e) => {
    e.stopPropagation();

    const {itemData, changeFormula} = this.props;

    setTimeout(() => {
      changeFormula(itemData, {});
    });
  };

  editItem = (e) => {
    e.stopPropagation();

    const {item, editSavedFormula} = this.props;

    editSavedFormula({
      id: item.id,
      title: item.title,
      children: item.condition.children,
      isSaved: item.isSaved
    });
  };

  deleteItem = (e) => {
    e.stopPropagation();

    const {locale, item, deleteSavedFormula} = this.props;

    confirm(
      localizeMessage({
        id: 'persona.table.confirmations.deleteFormula'
      }, {
        formulaName: item.title[locale]
      }),
      {
        confirmBtnText: localizeMessage({
          id: 'base.yes'
        }),
        cancelBtnText: localizeMessage({
          id: 'base.no'
        })
      }
    )
      .then(async () => {
        this.setState({
          removing: true
        });

        try {
          await deleteSavedFormula(item.id);

          alert.success(localizeMessage({
            id: 'persona.table.filter.alerts.successfulFormulaDeleting'
          }, {
            formulaName: item.title[locale]
          }));
        } catch (error) {
          console.error(error);

          alert.error(localizeMessage({
            id: 'persona.table.filter.alerts.errorFormulaWasNotDeleted'
          }, {
            formulaName: item.title[locale]
          }));

          this.setState({
            removing: false
          });
        }
      });
  };

  render () {
    const {
      locale,
      item,
      selectedIds,
      isAdmin,
      formulaIsOpen,
      connectDragSource,
      canDrag,
      onClick
      // downloadMemberList
    } = this.props;
    const {removing} = this.state;

    const canEdit = item.isSaved || (
      item.type === 'expression' &&
      item.condition &&
      (
        isAdmin ||
        item.edit
      )
    );

    // const memberListId =
    //   item.type === 'expression' &&
    //   item.condition ?
    //     (
    //       item.condition.type === 'member_id_list'
    //         ? item.id
    //         : (
    //           (
    //             item.condition.children &&
    //             item.condition.children.length === 1 &&
    //             item.condition.children[0].condition &&
    //             item.condition.children[0].condition.type === 'member_id_list'
    //           )
    //             ? item.condition.children[0].id
    //             : null
    //         )
    //     ) : null;

    const li = (
      <li
        unselectable={!formulaIsOpen ? 'on' : 'off'}
        className={cx(
          classes.Item,
          {
            [classes.ItemSelected]: selectedIds.indexOf(item.id) > -1,
            [classes.ItemCanDrag]: canDrag,
            [classes.ItemSaved]: canEdit
          }
        )}
        onMouseDown={e =>
          (item.list && item.list.length) ||
          !canDrag
            ? onClick(item, e)
            : null
        }
      >
        <div className={classes.Buttons}>
          {
            canEdit ?
              <Button
                size='small'
                icon='delete'
                disabled={removing}
                className={cx(
                  classes.ButtonsItem,
                  classes.ButtonsItemDelete
                )}
                onMouseDown={this.deleteItem}
              />
              : null
          }
          {
            canEdit ?
              <Button
                size='small'
                theme='transparent'
                icon='edit'
                disabled={removing}
                className={cx(
                  classes.ButtonsItem,
                  classes.ButtonsItemEdit
                )}
                onMouseDown={this.editItem}
              />
              : null
          }
          {
            canDrag ?
              <Button
                size='small'
                theme='transparent'
                icon='add'
                className={cx(
                  classes.ButtonsItem,
                  classes.ButtonsItemAdd
                )}
                onMouseDown={this.addItem}
              />
              : null
          }
          {/*
            memberListId ?
              <Button
                size='small'
                theme='transparent'
                disabled={removing}
                className={cx(
                  classes.ButtonsItem,
                  classes.ButtonsItemDelete
                )}
                onMouseDown={() => downloadMemberList(memberListId, item.title[locale)}
              >
                <LocalizedMessage
                  id='persona.table.filter.downloadMemberList'
                />
              </Button>
              : null
          */}
        </div>
        {
          item.values &&
          item.values.length
            ? (
              item.values.length === 1
                ? `${item.title[locale]} = '${item.values[0].label[locale]}'`
                : (
                  <span>
                    {item.title[locale]}
                    {' '}
                    <LocalizedMessage
                      id='persona.table.filter.formulaEditor.buttons.in'
                    />
                    {' '}
                    [{item.values.map(value => `'${value.label[locale]}'`).join(',')}]
                  </span>
                )
            )
            : item.title[locale]
        }
      </li>
    );

    const resultLi =
      item.type === 'expression'
        ? (
          <div>
            <Tooltip
              overlay={
                <p>
                  {`ID: ${item.id}`}
                </p>
              }
            >
              {li}
            </Tooltip>
          </div>
        )
        : li;

    if (typeof connectDragSource === 'function') {
      return connectDragSource(resultLi);
    }

    return resultLi;
  }
}

export const ItemWithDND = DragSource('FORMULA_ITEM', {
  isDragging (props, monitor) {
    return monitor.getItem().id === props.id;
  },

  beginDrag (props) {
    return props.itemData;
  },

  endDrag (props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    props.changeFormula(item, dropResult);
  }
}, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  canDrag: true,
  isDragging: monitor.isDragging()
}))(Item);
