import React, {Component, cloneElement} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Tooltip.module.scss';

const TOOLTIP_HORIZONTAL_OFFSET = 10;

class Tooltip extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    position: PropTypes.oneOf([
      'top',
      'bottom'
    ]),
    overlay: PropTypes.any,
    container: PropTypes.any,
    onVisibleChange: PropTypes.func
  };

  static defaultProps = {
    overlay: null,
    container: null,
    position: 'top'
  };

  state = {
    isVisible: false,
    containerStyle: null,
    arrowStyle: null
  };

  _tooltip = null;

  setTooltipRef = ref => {
    this._tooltip = ref;
  };

  onMouseEnter = (e) => {
    const {onVisibleChange} = this.props;

    const _target = e.currentTarget;

    this.setState({
      isVisible: true,
      containerStyle: null,
      arrowStyle: null
    }, () => {
      this.setState({
        ...this.calculateStyle(_target)
      });
    });

    if (typeof onVisibleChange === 'function') {
      onVisibleChange(true);
    }
  };

  onMouseLeave = () => {
    const {onVisibleChange} = this.props;

    this.setState({
      isVisible: false
    });

    if (typeof onVisibleChange === 'function') {
      onVisibleChange(false);
    }
  };

  getContainer = () => {
    const {container} = this.props;

    if (!container) {
      return document.body;
    }

    if (typeof container === 'function') {
      return container();
    }

    return container;
  };

  calculateStyle = (_target) => {
    const {position} = this.props;

    const _container = this.getContainer();

    const targetRect = _target.getBoundingClientRect();
    const containerRect = _container.getBoundingClientRect();
    const tooltipRect = this._tooltip.getBoundingClientRect();
    const fixedTargetRect = {
      top: targetRect.top,
      bottom: targetRect.bottom,
      left: Math.max(containerRect.left, targetRect.left),
      right: Math.min(containerRect.right, targetRect.right),
      height: targetRect.bottom - targetRect.top
    };
    fixedTargetRect.width = fixedTargetRect.right - fixedTargetRect.left;

    const width = Math.min(tooltipRect.width, document.body.clientWidth - (TOOLTIP_HORIZONTAL_OFFSET * 2));
    const top = position === 'bottom' ? fixedTargetRect.bottom : fixedTargetRect.top;
    let left = fixedTargetRect.left + (fixedTargetRect.width / 2);

    if (left - (width / 2) < 0) {
      left = (width / 2) + TOOLTIP_HORIZONTAL_OFFSET;
    } else if (left + (width / 2) + (TOOLTIP_HORIZONTAL_OFFSET * 2) > document.body.clientWidth) {
      left = document.body.clientWidth - (width / 2) - TOOLTIP_HORIZONTAL_OFFSET;
    }

    return {
      containerStyle: {
        width: `${width}px`,
        top: `${top}px`,
        left: `${left}px`
      },
      arrowStyle: {
        left: fixedTargetRect.left + (fixedTargetRect.width / 2) - (left - (width / 2))
      }
    };
  };

  render () {
    const {children, overlay, position} = this.props;
    const {isVisible, containerStyle, arrowStyle} = this.state;

    if (
      overlay === null ||
      (typeof overlay === 'string' && !overlay.length)
    ) {
      return children;
    }

    return [
      cloneElement(children, {
        key: 'trigger',
        onMouseEnter: (e) => {
          const {onMouseEnter} = children;

          this.onMouseEnter(e);

          if (typeof onMouseEnter === 'function') {
            onMouseEnter(e);
          }
        },
        onMouseLeave: (e) => {
          const {onMouseLeave} = children;

          this.onMouseLeave(e);

          if (typeof onMouseLeave === 'function') {
            onMouseLeave(e);
          }
        }
      }),
      isVisible ?
        createPortal(
          <div
            key='portal'
            className={cx(
              classes.Container,
              {
                [classes.ContainerBottom]: position === 'bottom',
                [classes.ContainerFixed]: !!containerStyle
              }
            )}
            style={containerStyle}
          >
            <div
              ref={this.setTooltipRef}
              className={classes.Content}
            >
              <div
                className={classes.Arrow}
                style={arrowStyle}
              />
              {overlay}
            </div>
          </div>,
          document.body
        )
        : null
    ];
  }
}

export default Tooltip;
