import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Simple extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    setFieldRef: PropTypes.func.isRequired
  };

  render () {
    const {children, setFieldRef} = this.props;

    return (
      <span ref={setFieldRef}>
        {children}
      </span>
    );
  }
}

export default Simple;
