import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage from '../../../../../../components/LocalizedMessage';
import classes from './StartingField.module.scss';

const StartingField = ({isOver, connectDropTarget}) => (
  connectDropTarget(
    <div
      className={cx(
        classes.Container,
        {
          [classes.ContainerOver]: isOver
        }
      )}
    >
      <LocalizedMessage
        id='persona.table.filter.formulaEditor.empty'
      />
    </div>
  )
);

StartingField.propTypes = {
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired
};

export default StartingField;
