import moment from 'moment';

// Example: 20.03.2017
export const generateDateString = (date) => {
  return moment(date).format('DD.MM.YYYY');
};

// Example: 2017-03-20
export const generateReverseDateString = (date, delimiter = '-') => {
  return moment(date).format(`YYYY${delimiter}MM${delimiter}DD`);
};
