import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Dropdown from '../Dropdown';
import locales from '../../locales';
import classes from './LocaleSwitcher.module.scss';

class LocaleSwitcher extends Component {
  static propTypes = {
    className: PropTypes.string,
    locale: PropTypes.string.isRequired,
    selectLocale: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: null
  };

  render () {
    const {className, locale, selectLocale} = this.props;

    return (
      <Dropdown
        className={cx(
          classes.LocaleSwitcher,
          className
        )}
        currentValueClassName={classes.LocaleSwitcherCurrentValue}
        withArrow={false}
        defaultValue={locale}
        options={
          Object.keys(locales).map(localeId => ({
            label: localeId.toUpperCase(),
            value: localeId
          }))
        }
        onChange={selectLocale}
      />
    );
  }
}

export default LocaleSwitcher;
