import Edit from './containers/EditContainer';
import {connect} from 'react-redux';

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  locale: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
