export const generateRandomColor = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);

  return `rgb(${r},${g},${b})`;
};

export const generateRandomColorList = (length) => {
  const colors = [];

  for (let i = 0; i < length; i++) {
    colors.push(generateRandomColor());
  }

  return colors;
};

export const colorPool = [
  '#d25220',
  '#15bc9d',
  '#9c58af',
  '#f1c345',
  '#3699d6',
  '#ecf0f1',
  '#34495c',
  '#e77d39',
  '#11a088',
  '#95a5a5',
  '#e94a43',
  '#2b80b4',
  '#23ae6a',
  '#9045a7',
  '#bec3c7',
  '#2d3e4e',
  '#c13731',
  '#27cc7b',
  '#f39b3a',
  '#7e8c8d'
];

export const generateColorList = (length) => {
  const colors = [];

  for (let i = 0; i < length; i++) {
    colors.push(colorPool[i % colorPool.length]);
  }

  if (colors.length > 1 && colors[0] === colors[colors.length - 1]) {
    colors.pop();
    colors.push(colors[1]);
  }

  return colors;
};

export const convertHexToRGB = (hex, opacity) => {
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity) {
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  } else {
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
};
