import {cloneObject, hashCode} from '../../../helpers/utils';
import {findStatisticItemIndex} from '../helpers/filter';

export const getPathHashCode = (path) => {
  let sum = 0;

  path.forEach(currentPath => {
    sum += hashCode(currentPath);
  });

  return sum;
};

export const getNPlusValue = (statistic) => {
  if (statistic.n) {
    if (statistic.m) {
      if (statistic.n === statistic.m) {
        return statistic.n.toString();
      } else {
        return `${statistic.n}-${statistic.m}`;
      }
    } else {
      return `${statistic.n}+`;
    }
  }
};

export const convertStatistic = (measures) => {
  const statisticItems = [];

  measures.statistics.forEach(statistic => {
    const statisticIndex = statistic.n
      ? findStatisticItemIndex(statisticItems, {
        id: statistic.id,
        title: statistic.title,
        affinity: statistic.affinity,
        nplus: !!statistic.n
      })
      : -1;

    const value = getNPlusValue(statistic);

    if (statisticIndex > -1) {
      statisticItems[statisticIndex].values.push(value);
    } else {
      statisticItems.push({
        id: statistic.id,
        title: statistic.title,
        affinity: statistic.affinity,
        nplus: !!statistic.n,
        values: value ? [value] : []
      });
    }
  });

  return {
    axis: measures.axis,
    items: statisticItems
  };
};

export const parseTableData = (data, direction) => {
  const dataCopy = cloneObject(data);

  if (direction) {
    dataCopy.structure.measures.axis = direction === 'y' ? 'rows' : 'columns';
  }

  return {
    horizontalDataTree: dataCopy.matrix.horizontalAxis,
    verticalDataTree: dataCopy.matrix.verticalAxis,
    matrix: dataCopy.matrix.mainMatrix,
    statistic: convertStatistic(dataCopy.structure.measures)
  };
};
