import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import FileSaver from 'file-saver';
import LocalizedMessage, {localizeMessage} from '../../../../components/LocalizedMessage';
import alert from '../../../../helpers/alert';
import {cloneObject, getArrayItemByParam, getArrayItemIndexByParam, sortNumberArray} from '../../../../helpers/utils';
import {
  getItemLinkByPath,
  validateFormula,
  getMainParentPath,
  prepareFormula,
  restructureCubeChildren
} from '../../helpers/formula';
import Button from '../../../../components/Button';
// import Dropdown from '../../../../components/Dropdown';
import Tabs from '../../../../components/Tabs';
import Mover from './Mover';
import DragLayer from './DragLayer';
import FormulaEditor from './FormulaEditor';
import MemberListModal from './MemberListModal';
import MmiModal from './MmiModal';
import List from './List';
import rangePopup from './RangePopup';
import API from '../../../../api';
import {getTitle} from '../../../../helpers';
import classes from './Filters.module.scss';

const MIN_FILTER_OFFSET_TOP_PERCENT = 10;                   // percent
const MIN_FILTER_OFFSET_BOTTOM_PERCENT = 10;                // percent
const FORMULA_CONTAINER_MIN_HEIGHT = 210;                   // px
const FORMULA_CONTAINER_MAX_HEIGHT = 305;                   // px
const FORMULA_CONTAINER_VERTICAL_OFFSET = 111;              // px
const FORMULA_CONTAINER_STEP = 32;                          // px

class Filters extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['ru', 'en']).isRequired,
    catalog: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    filterContainerPercentHeight: PropTypes.number,
    updateFilterContainerPercentHeight: PropTypes.func.isRequired,
    filterData: PropTypes.shape({
      direction: PropTypes.string.isRequired,
      formula: PropTypes.object,
      like: PropTypes.string
    }).isRequired,
    globalFilter: PropTypes.object.isRequired,
    savedFormulas: PropTypes.array.isRequired,
    filterSelectedItems: PropTypes.shape({
      category: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      group: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      dimensions: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      ),
      targetAudiences: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      )
    }).isRequired,
    eventBetweenStart: moment.isMoment,
    eventBetweenEnd: moment.isMoment,
    eventAverageDay: moment.isMoment,
    getAccessRights: PropTypes.func.isRequired,
    addCell: PropTypes.func.isRequired,
    updateCell: PropTypes.func.isRequired,
    closeFilter: PropTypes.func.isRequired,
    applyGlobalFilter: PropTypes.func.isRequired,
    insertFormula: PropTypes.func.isRequired,
    updateFormula: PropTypes.func.isRequired,
    deleteFormula: PropTypes.func.isRequired,
    loadEventHints: PropTypes.func.isRequired
  };

  defaultContainerPercentHeight = this.props.filterContainerPercentHeight || (100 - MIN_FILTER_OFFSET_TOP_PERCENT);

  state = {
    selectedCategory:
      !(this.props.filterData.formula || this.props.filterData.like === 'cell') &&
      this.props.filterSelectedItems.category
        ? this.props.filterSelectedItems.category
        : (
          this.props.catalog.filters.categories && this.props.catalog.filters.categories.length
            ? this.props.catalog.filters.categories[0].id
            : null
        ),
    selectedGroup:
      !(this.props.filterData.formula || this.props.filterData.like === 'cell') &&
      this.props.filterSelectedItems.group
        ? this.props.filterSelectedItems.group
        : null,
    selectedDimensions:
      !(this.props.filterData.formula || this.props.filterData.like === 'cell') &&
      this.props.filterSelectedItems.dimensions.length
        ? this.props.filterSelectedItems.dimensions
        : [],
    selectedTargetAudiences:
      !(this.props.filterData.formula || this.props.filterData.like === 'cell') &&
      this.props.filterSelectedItems.targetAudiences.length
        ? this.props.filterSelectedItems.targetAudiences
        : [],
    searchString: '',
    containerPercentHeight: this.defaultContainerPercentHeight,
    globalFilter: {
      ta: {
        title: this.props.globalFilter['ta'].title,
        data: this.props.globalFilter['ta'],
        errorPaths: []
      },
      event: this.props.globalFilter['event']
        ? {
          title: this.props.globalFilter['event'].title,
          data: this.props.globalFilter['event'],
          errorPaths: []
        }
        : {
          title: '',
          data: null,
          errorPaths: []
        },
      'event-tv': this.props.globalFilter['event-tv']
        ? {
          title: this.props.globalFilter['event-tv'].title,
          data: this.props.globalFilter['event-tv'],
          errorPaths: []
        }
        : {
          title: '',
          data: null,
          errorPaths: []
        },
      'event-web': this.props.globalFilter['event-web']
        ? {
          title: this.props.globalFilter['event-web'].title,
          data: this.props.globalFilter['event-web'],
          errorPaths: []
        }
        : {
          title: '',
          data: null,
          errorPaths: []
        }
    },
    cellFormula: {
      ta: {
        title:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'ta'
            ? this.props.filterData.formula.title
            : '',
        data:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'ta'
            ? this.props.filterData.formula.data
            : {
              children: []
            },
        errorPaths: []
      },
      'event-tv': {
        title:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'event-tv'
            ? this.props.filterData.formula.title
            : '',
        data:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'event-tv'
            ? this.props.filterData.formula.data
            : {
              children: []
            },
        errorPaths: []
      },
      'event-web': {
        title:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'event-web'
            ? this.props.filterData.formula.title
            : '',
        data:
          this.props.filterData.like === 'formula' &&
          this.props.filterData.formula &&
          this.props.filterData.formula.mode === 'event-web'
            ? this.props.filterData.formula.data
            : {
              children: []
            },
        errorPaths: []
      }
    },
    formulaTaStatisticsNumber: {
      number: 0,
      loading: false,
      loaded: false,
      error: null
    },
    formulaIsOpen: this.props.filterData.like === 'formula',
    cellFormulaMode: this.props.filterData.formula ? this.props.filterData.formula.mode : 'ta',
    globalFilterMode: !this.props.catalog.splitEvents ? 'event' : 'event-tv',
    formulaSelectedItem: null,
    formulaSelectedList: null,
    formulaSaving: false,
    formulaEditing: false,
    editingSavedFormula: null,
    memberListModal: null,
    showMmiModal: false
  };

  pixelsInOnePercent = 0;
  _searchField = null;
  _groupList = null;
  _dimensionList = null;
  _taList = null;
  _container = null;
  _formulaTitle = null;
  _formulaEditor = null;
  _globalFilterModeTabs = null;
  _formulaContainer = null;
  gettingNumberOfTaStatisticsApiController = null;
  gettingNumberOfTaStatisticsApiTimer = 0;

  componentDidMount () {
    const {filterData} = this.props;

    this.onResize();

    if (filterData.direction === 'ta') {
      this.clearSelectedFilterItems();

      this._formulaTitle.focus();
    } else if (filterData.direction === 'base') {
      this.clearSelectedFilterItems();
    }
  }

  componentWillUnmount () {
    const {updateFilterContainerPercentHeight} = this.props;
    const {containerPercentHeight} = this.state;

    updateFilterContainerPercentHeight(containerPercentHeight);
  }

  setSearchFieldRef = ref => {
    this._searchField = ref;
  };

  setGroupListRef = ref => {
    this._groupList = ref;
  };

  setDimensionListRef = ref => {
    this._dimensionList = ref;
  };

  setTaListRef = ref => {
    this._taList = ref;
  };

  setContainerRef = ref => {
    this._container = ref;
  };

  setFormulaTitleRef = ref => {
    this._formulaTitle = ref;
  };

  setFormulaEditorRef = ref => {
    this._formulaEditor = ref;
  };

  setGlobalFilterModeTabsRef = ref => {
    this._globalFilterModeTabs = ref;
  };

  setFormulaContainerRef = ref => {
    this._formulaContainer = ref;
  };

  selectFilterCategory = category => {
    this.setState({
      selectedCategory: category.id,
      selectedGroup: null,
      selectedDimensions: [],
      selectedTargetAudiences: []
    }, () => {
      if (this._groupList && typeof this._groupList.scrollTop === 'function') {
        this._groupList.scrollTop();
      }
    });
  };

  selectFilterGroup = group => {
    this.setState({
      selectedGroup: group.id,
      selectedDimensions: [],
      selectedTargetAudiences: []
    }, () => {
      if (this._dimensionList) {
        this._dimensionList.scrollTop();
      }
    });
  };

  selectFilterDimensions = (e, dimension, fullDimensionList) => {
    const {filterData} = this.props;
    const {formulaIsOpen, selectedDimensions} = this.state;

    if (
      !filterData.formula &&
      !formulaIsOpen &&
      (
        e.metaKey ||
        e.ctrlKey ||
        e.shiftKey
      ) &&
      selectedDimensions.length
    ) {
      e.preventDefault();

      // Ctrl
      if (
        e.metaKey ||
        e.ctrlKey
      ) {
        if (selectedDimensions.indexOf(dimension.id) > -1) {
          this.setState({
            selectedDimensions: selectedDimensions.filter(cDimensionId => dimension.id !== cDimensionId),
            selectedTargetAudiences: []
          }, () => {
            if (this._taList && typeof this._taList.scrollTop === 'function') {
              this._taList.scrollTop();
            }
          });

          return;
        }

        const nextSelectedDimensions = selectedDimensions.slice(0);
        nextSelectedDimensions.push(dimension.id);

        this.setState({
          selectedDimensions: nextSelectedDimensions,
          selectedTargetAudiences: []
        }, () => {
          if (this._taList && typeof this._taList.scrollTop === 'function') {
            this._taList.scrollTop();
          }
        });

        return;
      }

      // Shift
      if (selectedDimensions.length) {
        const firstSelectedDimensionId = selectedDimensions[0];

        if (firstSelectedDimensionId === dimension.id) {
          this.setState({
            selectedDimensions: [dimension.id],
            selectedTargetAudiences: []
          }, () => {
            if (this._taList && typeof this._taList.scrollTop === 'function') {
              this._taList.scrollTop();
            }
          });

          return;
        }

        const firstSelectedDimensionIndex = getArrayItemIndexByParam(fullDimensionList, 'id', firstSelectedDimensionId);
        const currentSelectedDimensionIndex = getArrayItemIndexByParam(fullDimensionList, 'id', dimension.id);
        const startSelectedDimensionIndex = Math.min(currentSelectedDimensionIndex, firstSelectedDimensionIndex);
        const endSelectedDimensionIndex = Math.max(currentSelectedDimensionIndex, firstSelectedDimensionIndex);
        const nextSelectedDimensions =
          fullDimensionList
            .slice(startSelectedDimensionIndex, endSelectedDimensionIndex + 1)
            .map(cDimension => cDimension.id);

        this.setState({
          selectedDimensions: nextSelectedDimensions,
          selectedTargetAudiences: []
        }, () => {
          if (this._taList && typeof this._taList.scrollTop === 'function') {
            this._taList.scrollTop();
          }
        });
      }

      return;
    }

    this.setState({
      selectedDimensions: [dimension.id],
      selectedTargetAudiences: []
    }, () => {
      if (this._taList && typeof this._taList.scrollTop === 'function') {
        this._taList.scrollTop();
      }
    });
  };

  selectFilterTargetAudience = (e, TA, fullTAList) => {
    const {filterData} = this.props;
    const {formulaIsOpen, selectedTargetAudiences} = this.state;

    if (
      !filterData.formula &&
      !formulaIsOpen &&
      (
        e.metaKey ||
        e.ctrlKey ||
        e.shiftKey
      ) &&
      selectedTargetAudiences.length
    ) {
      e.preventDefault();

      // Ctrl
      if (
        e.metaKey ||
        e.ctrlKey
      ) {
        if (selectedTargetAudiences.indexOf(TA.id) > -1) {
          this.setState({
            selectedTargetAudiences: selectedTargetAudiences.filter(cTAId => TA.id !== cTAId)
          });

          return;
        }

        const nextSelectedTargetAudiences = selectedTargetAudiences.slice(0);
        nextSelectedTargetAudiences.push(TA.id);

        this.setState({
          selectedTargetAudiences: nextSelectedTargetAudiences
        });

        return;
      }

      // Shift
      if (selectedTargetAudiences.length) {
        const firstSelectedTAId = selectedTargetAudiences[0];

        if (firstSelectedTAId === TA.id) {
          this.setState({
            selectedTargetAudiences: [TA.id]
          });

          return;
        }

        const firstSelectedTAIndex = getArrayItemIndexByParam(fullTAList, 'id', firstSelectedTAId);
        const currentSelectedTAIndex = getArrayItemIndexByParam(fullTAList, 'id', TA.id);
        const startSelectedTAIndex = Math.min(currentSelectedTAIndex, firstSelectedTAIndex);
        const endSelectedTAIndex = Math.max(currentSelectedTAIndex, firstSelectedTAIndex);
        const nextSelectedTargetAudiences =
          fullTAList
            .slice(startSelectedTAIndex, endSelectedTAIndex + 1)
            .map(cTA => cTA.id);

        this.setState({
          selectedTargetAudiences: nextSelectedTargetAudiences
        });
      }

      return;
    }

    this.setState({
      selectedTargetAudiences: [TA.id]
    });
  };

  onChangeSearchField = e => {
    this.setState({
      searchString: e.target.value
    });
  };

  resetSearchField = () => {
    this.setState({
      searchString: ''
    });

    if (this._searchField) {
      this._searchField.focus();
    }
  };

  getSelectedItems = currentSelectedCategory => {
    const {selectedGroup, selectedDimensions, selectedTargetAudiences} = this.state;

    const currentSelectedGroup =
      currentSelectedCategory && selectedGroup
        ? getArrayItemByParam(currentSelectedCategory.list, 'id', selectedGroup) || null
        : null;
    const currentSelectedDimensions =
      currentSelectedGroup && selectedDimensions.length
        ? selectedDimensions
          .map(dimensionId => (
            getArrayItemByParam(currentSelectedGroup.list, 'id', dimensionId) || null
          ))
          .filter(dimensionId => dimensionId !== null)
        : [];
    const currentSelectedTargetAudiences =
      currentSelectedDimensions.length === 1 && Array.isArray(currentSelectedDimensions[0].list)
        ? selectedTargetAudiences
          .map(cTAId => getArrayItemByParam(currentSelectedDimensions[0].list, 'id', cTAId))
          .filter(cTA => cTA)
        : [];

    return {
      selectedCategory: currentSelectedCategory,
      selectedGroup: currentSelectedGroup,
      selectedDimensions: currentSelectedDimensions,
      selectedTargetAudiences: currentSelectedTargetAudiences
    };
  };

  filterGroupsByMode = (groups) => {
    const {catalog, filterData} = this.props;
    const {formulaIsOpen, globalFilterMode, cellFormulaMode} = this.state;

    let filterGroups = groups.slice();

    if (
      (!formulaIsOpen && filterData.direction !== 'base') ||
      (filterData.direction === 'base' && globalFilterMode === 'ta') ||
      (filterData.direction !== 'base' && formulaIsOpen && cellFormulaMode === 'ta')
    ) {
      filterGroups = filterGroups.filter(group => (
        group.noHide ||
        group.list.length
      ));
    } else {
      filterGroups = filterGroups.filter(group => {
        if (group.noHide) {
          return true;
        }

        group.list = group.list.filter(dimension => (
          (
            dimension.type === 'event' ||
            dimension.isSaved
          ) &&
          (
            (
              filterData.direction === 'base' &&
              (
                !catalog.splitEvents ||
                !dimension.tabType ||
                `event-${dimension.tabType}` === globalFilterMode
              )
            ) ||
            (
              filterData.direction !== 'base' &&
              (
                !dimension.tabType ||
                `event-${dimension.tabType}` === cellFormulaMode
              )
            )
          )
        ));

        return group.list.length;
      });
    }

    return filterGroups;
  };

  filterGroupsBySearchString = (groups) => {
    const {locale} = this.props;
    const {searchString} = this.state;

    if (!searchString.length) {
      return groups;
    }

    const resultFilterGroups = [];

    for (let k = 0; k < groups.length; k++) {
      const currentGroup = groups[k];

      if (!currentGroup.list.length) {
        continue;
      }

      if (currentGroup.title[locale].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
        resultFilterGroups.push(currentGroup);

        continue;
      }

      const resultDimensions = [];

      for (let l = 0; l < currentGroup.list.length; l++) {
        const currentDimension = currentGroup.list[l];

        if (currentDimension.title[locale].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
          resultDimensions.push(currentDimension);

          continue;
        }

        const resultTA = [];

        if (Array.isArray(currentDimension.list)) {
          for (let m = 0; m < currentDimension.list.length; m++) {
            const currentTA = currentDimension.list[m];

            if (currentTA.title[locale].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
              resultTA.push(currentTA);
            }
          }
        }

        if (resultTA.length) {
          resultDimensions.push({
            ...currentDimension,
            list: resultTA
          });
        }
      }

      if (resultDimensions.length) {
        resultFilterGroups.push({
          ...currentGroup,
          list: resultDimensions
        });
      }
    }

    return resultFilterGroups;
  };

  getFilterGroups = () => {
    const {selectedCategory} = this.state;

    const categoryList = this.getCategoryList();

    let currentSelectedCategory = selectedCategory
      ? getArrayItemByParam(categoryList, 'id', selectedCategory) || null
      : null;

    if (currentSelectedCategory.disabled) {
      currentSelectedCategory = getArrayItemByParam(categoryList, 'disabled', false) || null;
    }

    return {
      categoryList,
      currentSelectedCategory
    };
  };

  getCategoryList = () => {
    const {catalog} = this.props;

    return catalog.filters.categories.map((category, index) => {
      const categoryList = cloneObject(category.list);

      const groupList = this.filterGroupsBySearchString(
        this.filterGroupsByMode(
          categoryList
        )
      );

      return {
        ...category,
        title: category.title,
        list: groupList,
        disabled: (
          !groupList.length ||
          !groupList.some(group => (
            group.noHide ||
            (
              group.list.length &&
              group.list.some(dimension => (
                dimension.type ||
                dimension.list.length
              ))
            )
          ))
        )
      };
    });
  };

  validateFormula = (sourceFormulaTitle, sourceFormulaData, ignoreEmptyTitle = false) => {
    const formulaValidationResult = validateFormula(sourceFormulaData);

    const cellTitleIsValid = ignoreEmptyTitle || sourceFormulaTitle.trim().length;
    const cellDataIsValid = formulaValidationResult.status;

    if (cellTitleIsValid && cellDataIsValid) {
      return {
        status: true
      };
    }

    if (!cellTitleIsValid) {
      alert.warning(
        localizeMessage({
          id: 'persona.table.alerts.validation.invalidFormulaTitle'
        })
      );

      this._formulaTitle.focus();
    }

    if (!cellDataIsValid) {
      if (!formulaValidationResult.errorPaths.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyFormulaData'
          })
        );
      } else {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.invalidFormulaData'
          })
        );
      }

      if (cellTitleIsValid && formulaValidationResult.errorPaths.length) {
        this.focusFieldByPath(formulaValidationResult.errorPaths[0]);
      }

      return {
        status: false,
        errorPaths: formulaValidationResult.errorPaths
      };
    }

    return {
      status: false
    };
  };

  removeErrorPath = removingPath => {
    const {filterData} = this.props;
    const {editingSavedFormula, cellFormula, cellFormulaMode} = this.state;

    const removingPathString = removingPath.join('-');

    setTimeout(() => {
      if (editingSavedFormula) {
        this.setState({
          editingSavedFormula: {
            ...editingSavedFormula,
            errorPaths: editingSavedFormula.errorPaths.filter(path => path !== removingPathString)
          }
        });
      } else if (filterData.direction === 'base') {
        const {globalFilter, globalFilterMode} = this.state;

        this.setState({
          globalFilter: {
            ...globalFilter,
            [globalFilterMode]: {
              ...globalFilter[globalFilterMode],
              errorPaths: globalFilter[globalFilterMode].errorPaths.filter(path => path !== removingPathString)
            }
          }
        });
      } else {
        this.setState({
          cellFormula: {
            ...cellFormula,
            [cellFormulaMode]: {
              ...cellFormula[cellFormulaMode],
              errorPaths: cellFormula[cellFormulaMode].errorPaths.filter(path => path !== removingPathString)
            }
          }
        });
      }
    });
  };

  addOrUpdateCell = () => {
    const {filterData, addCell, updateCell} = this.props;
    const {editingSavedFormula, formulaIsOpen, cellFormula, cellFormulaMode} = this.state;

    if (editingSavedFormula) {
      return;
    }

    const addOrUpdateCell =
      !filterData.formula &&
      filterData.like !== 'cell'
        ? addCell
        : updateCell;

    const {currentSelectedCategory} = this.getFilterGroups();
    const {
      selectedCategory,
      selectedGroup,
      selectedDimensions,
      selectedTargetAudiences
    } = this.getSelectedItems(currentSelectedCategory);

    const filterSelectedItems = {
      category: selectedCategory ? selectedCategory.id : null,
      group: selectedGroup ? selectedGroup.id : null,
      dimensions: selectedDimensions.map(dimension => dimension.id),
      targetAudiences: selectedTargetAudiences.map(TA => TA.id)
    };

    if (formulaIsOpen) {
      const formulaValidationResult = this.validateFormula(
        cellFormula[cellFormulaMode].title,
        cellFormula[cellFormulaMode].data,
        true
      );

      if (!formulaValidationResult.status) {
        this.setState({
          cellFormula: {
            ...cellFormula,
            [cellFormulaMode]: {
              ...cellFormula[cellFormulaMode],
              errorPaths: formulaValidationResult.errorPaths || []
            }
          }
        });

        return;
      }

      addOrUpdateCell({
        title: cellFormula[cellFormulaMode].title,
        children: [],
        formula: {
          title: cellFormula[cellFormulaMode].title,
          children: cellFormula[cellFormulaMode].data.children,
          hasNot: cellFormula[cellFormulaMode].data.hasNot
        },
        mode: cellFormulaMode,
        like: 'formula'
      }, filterSelectedItems);

      return;
    }

    if (
      !selectedDimensions.length ||
      (
        selectedDimensions.length === 1 &&
        !selectedDimensions[0].type &&
        !selectedTargetAudiences.length
      )
    ) {
      alert.warning(
        localizeMessage({
          id: 'persona.table.alerts.validation.dimensionIsNotSelected'
        })
      );

      return;
    }

    if (
      selectedDimensions.length === 1 &&
      selectedDimensions[0].type === 'ta_integer'
    ) {
      rangePopup(
        localizeMessage({
          id: 'persona.table.prompt.addTaInteger.title'
        }),
        {
          minValue: 0,
          maxValue: selectedDimensions[0].maxValue || 2147483647,
          defaultValues: [0, selectedDimensions[0].maxValue ? Math.min(selectedDimensions[0].maxValue, 100) : 100],
          measure: selectedDimensions[0].measure,
          validate: (values) => {
            if (values[0] <= values[1]) {
              return true;
            }

            alert.warning(
              localizeMessage({
                id: 'persona.table.prompt.addTaInteger.firstValueMustBeLessThanSecond'
              })
            );

            return false;
          },
          acceptBtnText: localizeMessage({
            id: 'base.add'
          }),
          cancelBtnText: localizeMessage({
            id: 'base.cancel'
          })
        }
      )
        .then(values => {
          addOrUpdateCell({
            title: '',
            children: [],
            formula: {
              title: null,
              children: [{
                id: selectedDimensions[0].id,
                title: selectedDimensions[0].title,
                condition: {
                  type: 'ta_integer',
                  operator: 'between',
                  values: values.map(value => ({value}))
                },
                connector: 'and',
                hasNot: false
              }],
              hasNot: false
            },
            type: selectedDimensions[0].type,
            like: 'cell'
          }, filterSelectedItems);
        });

      return;
    }

    // TA

    if (
      selectedDimensions.length === 1 &&
      selectedTargetAudiences.length
    ) {
      const cellsData = [];

      selectedTargetAudiences.forEach(selectedTargetAudience => {
        if (
          selectedTargetAudience.type === 'event' &&
          selectedTargetAudience.values
        ) {
          cellsData.push({
            title: {
              ru: `[S] ${selectedDimensions[0].title.ru}`,
              en: `[S] ${selectedDimensions[0].title.en}`,
            },
            children: [],
            formula: {
              title: null,
              children: [{
                id: selectedTargetAudience.id,
                title: selectedTargetAudience.title,
                condition: {
                  type: 'event',
                  operator: selectedTargetAudience.values.length === 1 ? '=' : 'in',
                  values: selectedTargetAudience.values
                },
                connector: 'and',
                hasNot: false
              }],
              hasNot: false
            },
            type: selectedDimensions[0].type,
            like: 'cell'
          });
        } else if (selectedTargetAudience.type === 'expression') {
          cellsData.push({
            title: selectedTargetAudience.title,
            children: [],
            expressionId: selectedTargetAudience.id,
            formula: {
              ...selectedTargetAudience.condition,
              title: null,
              hasNot: false
            },
            type: selectedDimensions[0].type,
            like: 'cell'
          });
        } else {
          cellsData.push({
            title: {
              ru: `${selectedDimensions[0].title.ru} - ${selectedTargetAudience.title.ru}`,
              en: `${selectedDimensions[0].title.en} - ${selectedTargetAudience.title.en}`,
            },
            children: [],
            formula: {
              title: null,
              children: [{
                id: selectedTargetAudience.id,
                title: '',
                condition: {
                  type: 'ta'
                },
                connector: 'and',
                hasNot: false
              }],
              hasNot: false
            },
            type: selectedDimensions[0].type,
            like: 'cell'
          });
        }
      });

      if (cellsData.length) {
        addOrUpdateCell(cellsData, filterSelectedItems);
      }

      return;
    }

    // Dimensions

    const cellsData = [];

    selectedDimensions.forEach(selectedDimension => {
      if (
        selectedDimension.type === 'expression' &&
        selectedDimension.condition
      ) {
        cellsData.push({
          title: selectedDimension.title,
          children: [],
          expressionId: selectedDimension.id,
          formula: {
            ...selectedDimension.condition,
            title: null,
            hasNot: false
          },
          type: selectedDimension.type,
          like: 'cell'
        });
      } else if (
        selectedDimension.type === 'ta_integer'
      ) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.fieldCanBeAddedOnlySeparately'
          }, {
            fieldName: selectedDimension.title
          })
        );
      } else if (!selectedDimension.type) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.fieldCantBeAdded'
          }, {
            fieldName: selectedDimension.title
          })
        );
      } else {
        cellsData.push({
          id: selectedDimension.id,
          title: selectedDimension.title,
          type: selectedDimension.type
        });
      }
    });

    if (cellsData.length) {
      addOrUpdateCell(cellsData, filterSelectedItems);
    }
  };

  closeFilter = () => {
    const {closeFilter} = this.props;
    const {selectedCategory, selectedGroup, selectedDimensions, selectedTargetAudiences} = this.state;

    closeFilter({
      category: selectedCategory,
      group: selectedGroup,
      dimensions: selectedDimensions,
      targetAudiences: selectedTargetAudiences
    });
  };

  changeSavedFormulaTitle = event => {
    const {editingSavedFormula} = this.state;

    this.setState({
      editingSavedFormula: {
        ...editingSavedFormula,
        title: event.target.value.trim()
      }
    });
  };

  changeGlobalFilterTitle = (globalFilterMode, event) => {
    const {globalFilter} = this.state;

    this.setState({
      globalFilter: {
        ...globalFilter,
        [globalFilterMode]: {
          ...globalFilter[globalFilterMode],
          title: event.target.value.trim()
        }
      }
    });
  };

  validateGlobalFilterFormula = () => {
    const {catalog} = this.props;
    const {globalFilter} = this.state;

    const taDataIsEmpty = !globalFilter['ta'].data.children.length;
    const eventDataIsEmpty = !globalFilter['event'].data.children.length;
    const eventTvDataIsEmpty = !globalFilter['event-tv'].data.children.length;
    const eventWebDataIsEmpty = !globalFilter['event-web'].data.children.length;

    const formulaTAValidationResult = !taDataIsEmpty
      ? validateFormula(globalFilter['ta'].data)
      : {status: true};
    const formulaEventValidationResult = !eventDataIsEmpty
      ? validateFormula(globalFilter['event'].data)
      : {status: true};
    const formulaEventTvValidationResult = !eventTvDataIsEmpty
      ? validateFormula(globalFilter['event-tv'].data)
      : {status: true};
    const formulaEventWebValidationResult = !eventWebDataIsEmpty
      ? validateFormula(globalFilter['event-web'].data)
      : {status: true};

    const taDataIsValid = formulaTAValidationResult.status;
    const eventDataIsValid = formulaEventValidationResult.status;
    const eventTvDataIsValid = formulaEventTvValidationResult.status;
    const eventWebDataIsValid = formulaEventWebValidationResult.status;

    if (taDataIsValid &&
      (
        !catalog.splitEvents
          ? eventDataIsValid
          : eventTvDataIsValid && eventWebDataIsValid
      )
    ) {
      return true;
    }

    if (!taDataIsValid) {
      if (!formulaTAValidationResult.errorPaths.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.ta'
            })
          })
        );
      } else {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.invalidFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.ta'
            })
          })
        );
      }
    }

    if (!catalog.splitEvents && !eventDataIsValid) {
      if (!formulaEventValidationResult.errorPaths.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event'
            })
          })
        );
      } else {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.invalidFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event'
            })
          })
        );
      }
    }

    if (catalog.splitEvents && !eventTvDataIsValid) {
      if (!formulaEventTvValidationResult.errorPaths.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event.tv'
            })
          })
        );
      } else {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.invalidFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event.tv'
            })
          })
        );
      }
    }

    if (catalog.splitEvents && !eventWebDataIsValid) {
      if (!formulaEventWebValidationResult.errorPaths.length) {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.emptyFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event.web'
            })
          })
        );
      } else {
        alert.warning(
          localizeMessage({
            id: 'persona.table.alerts.validation.invalidFormulaDataWithName'
          }, {
            formulaName: localizeMessage({
              id: 'persona.table.filter.formulaEditor.types.event.web'
            })
          })
        );
      }
    }

    this.setState({
      globalFilter: {
        ta: {
          ...globalFilter['ta'],
          errorPaths: formulaTAValidationResult.errorPaths || []
        },
        event: {
          ...globalFilter['event'],
          errorPaths: formulaEventValidationResult.errorPaths || []
        },
        'event-tv': {
          ...globalFilter['event-tv'],
          errorPaths: formulaEventTvValidationResult.errorPaths || []
        },
        'event-web': {
          ...globalFilter['event-web'],
          errorPaths: formulaEventWebValidationResult.errorPaths || []
        }
      }
    });

    if (!taDataIsValid) {
      this._globalFilterModeTabs.changeValue('ta');
    } else if (!catalog.splitEvents && !eventDataIsValid) {
      this._globalFilterModeTabs.changeValue('event');
    } else if (catalog.splitEvents && !eventTvDataIsValid) {
      this._globalFilterModeTabs.changeValue('event-tv');
    } else if (catalog.splitEvents && !eventWebDataIsValid) {
      this._globalFilterModeTabs.changeValue('event-web');
    }

    if (
      (!taDataIsValid && formulaTAValidationResult.errorPaths.length) ||
      (!catalog.splitEvents && taDataIsValid && formulaEventValidationResult.errorPaths.length) ||
      (
        catalog.splitEvents &&
        (
          (taDataIsValid && formulaEventTvValidationResult.errorPaths.length) ||
          (taDataIsValid && eventTvDataIsValid && formulaEventWebValidationResult.errorPaths.length)
        )
      )
    ) {
      setTimeout(() => {
        const firstPathString = !taDataIsValid
          ? formulaTAValidationResult.errorPaths[0]
          : !catalog.splitEvents
            ? formulaEventValidationResult.errorPaths[0]
            : !eventTvDataIsValid
              ? formulaEventTvValidationResult.errorPaths[0]
              : formulaEventWebValidationResult.errorPaths[0];

        this.focusFieldByPath(firstPathString);
      });
    }

    return false;
  };

  applyGlobalFilter = () => {
    const {catalog, applyGlobalFilter} = this.props;
    const {globalFilter, selectedCategory, selectedGroup, selectedDimensions, selectedTargetAudiences} = this.state;

    if (!this.validateGlobalFilterFormula()) {
      return;
    }

    const savingGlobalFilter = {
      ta: {
        ...globalFilter['ta'].data,
        title: globalFilter['ta'].data.children.length ? globalFilter['ta'].title : ''
      }
    };

    if (!catalog.splitEvents) {
      savingGlobalFilter['event'] = {
        ...globalFilter['event'].data,
        title: globalFilter['event'].data.children.length ? globalFilter['event'].title : ''
      };
    } else {
      savingGlobalFilter['event-tv'] = {
        ...globalFilter['event-tv'].data,
        title: globalFilter['event-tv'].data.children.length ? globalFilter['event-tv'].title : ''
      };
      savingGlobalFilter['event-web'] = {
        ...globalFilter['event-web'].data,
        title: globalFilter['event-web'].data.children.length ? globalFilter['event-web'].title : ''
      };
    }

    applyGlobalFilter(savingGlobalFilter, {
      category: selectedCategory,
      group: selectedGroup,
      dimensions: selectedDimensions,
      targetAudiences: selectedTargetAudiences
    });
  };

  onResize = () => {
    const {containerPercentHeight} = this.state;

    const containerPixelHeight = this._container.offsetHeight;

    this.pixelsInOnePercent = containerPixelHeight / containerPercentHeight;
  };

  handleChangeProportion = offsetY => {
    const offsetPercentHeight = offsetY / this.pixelsInOnePercent;
    let newContainerPercentHeight = Math.round((this.defaultContainerPercentHeight - offsetPercentHeight) * 100) / 100;
    newContainerPercentHeight = Math.max(newContainerPercentHeight, MIN_FILTER_OFFSET_TOP_PERCENT);
    newContainerPercentHeight = Math.min(newContainerPercentHeight, 100 - MIN_FILTER_OFFSET_BOTTOM_PERCENT);

    if (this.defaultContainerPercentHeight === newContainerPercentHeight) {
      return;
    }

    this.setState({
      containerPercentHeight: newContainerPercentHeight
    });
  };

  handleStopProportionChanging = () => {
    const {containerPercentHeight} = this.state;

    this.defaultContainerPercentHeight = containerPercentHeight;
  };

  resetNumberOfTaStatistics = () => {
    this.setState({
      formulaTaStatisticsNumber: {
        number: '?',
        loading: false,
        loaded: true,
        error: null
      }
    });
  };

  updateNumberOfTaStatistics = async () => {
    const {locale, filterData, eventBetweenStart, eventBetweenEnd, eventAverageDay} = this.props;
    const {editingSavedFormula, globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    const currentFormula = editingSavedFormula
      ? editingSavedFormula.data
      : filterData.direction === 'base'
        ? globalFilter[globalFilterMode].data
        : cellFormula[cellFormulaMode].data;

    if (!currentFormula.children.length) {
      return;
    }

    if (!validateFormula(currentFormula).status) {
      return;
    }

    if (
      this.gettingNumberOfTaStatisticsApiController &&
      typeof this.gettingNumberOfTaStatisticsApiController.reset === 'function'
    ) {
      this.gettingNumberOfTaStatisticsApiController.reset();
    }

    this.setState({
      formulaTaStatisticsNumber: {
        number: '?',
        loading: true,
        loaded: false,
        error: null
      }
    });

    try {
      const number = await API.formula.getNumberOfTaStatistics(
        prepareFormula(locale, currentFormula),
        eventBetweenStart,
        eventBetweenEnd,
        eventAverageDay,
        controller => {
          this.gettingNumberOfTaStatisticsApiController = controller;
        }
      );

      this.gettingNumberOfTaStatisticsApiController = null;

      this.setState({
        formulaTaStatisticsNumber: {
          number: number,
          loading: false,
          loaded: true,
          error: null
        }
      });
    } catch (error) {
      console.error(error);

      alert.error(localizeMessage({
        id: 'persona.table.filter.alerts.errorCantGetFormulaStatisticsNumber'
      }));

      this.setState({
        formulaTaStatisticsNumber: {
          number: '?',
          loading: false,
          loaded: true,
          error: error
        }
      });
    }
  };

  updateFormula = (formula, withFocus = false) => {
    const {filterData} = this.props;
    const {editingSavedFormula, globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    clearTimeout(this.gettingNumberOfTaStatisticsApiTimer);

    if (editingSavedFormula) {
      this.setState({
        editingSavedFormula: {
          ...editingSavedFormula,
          data: formula
        }
      }, () => {
        if (withFocus) {
          this.focusSelectedItem();
        }

        this.recalculateFormulaEditorHeight();
      });

      this.resetNumberOfTaStatistics();
    } else if (filterData.direction === 'base') {
      this.setState({
        globalFilter: {
          ...globalFilter,
          [globalFilterMode]: {
            ...globalFilter[globalFilterMode],
            data: formula
          }
        }
      }, () => {
        if (withFocus) {
          this.focusSelectedItem();
        }

        this.recalculateFormulaEditorHeight();
      });

      if (globalFilterMode === 'ta') {
        this.resetNumberOfTaStatistics();
      }
    } else {
      this.setState({
        cellFormula: {
          ...cellFormula,
          [cellFormulaMode]: {
            ...cellFormula[cellFormulaMode],
            data: formula
          }
        }
      }, () => {
        if (withFocus) {
          this.focusSelectedItem();
        }

        this.recalculateFormulaEditorHeight();
      });

      if (cellFormulaMode === 'ta') {
        this.resetNumberOfTaStatistics();
      }
    }
  };

  changeFormula = ({action, data, prevPath = []}, {path, direction, isConnector = false}) => {
    const {filterData} = this.props;
    const {editingSavedFormula, globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    const currentFormula = editingSavedFormula
      ? editingSavedFormula.data
      : filterData.direction === 'base'
        ? globalFilter[globalFilterMode].data
        : cellFormula[cellFormulaMode].data;

    if (!path) {
      path = [currentFormula.children.length];
    }

    if (!direction) {
      direction = currentFormula.length ? 'right' : 'left';
    }

    const copyFormula = cloneObject(currentFormula);

    const itemShift = !isConnector && direction === 'right' ? 1 : 0;
    const newPath = path.slice(0);
    newPath[newPath.length - 1] += itemShift;

    let removingChildrenLink;

    // Remove moving item from old position
    if (action === 'move') {
      const removingItemIndex = prevPath[prevPath.length - 1];
      removingChildrenLink = getItemLinkByPath(copyFormula, prevPath.slice(0, -1)).children;
      removingChildrenLink.splice(removingItemIndex, 1);

      // Move last item in parent of removed item to parent place
      if (removingChildrenLink.length === 1 && prevPath.length >= 2) {
        getItemLinkByPath(copyFormula, prevPath.slice(0, -2))
          .children[prevPath[prevPath.length - 2]] = removingChildrenLink[0];
      }

      // Shift item index after removing
      const mainParent = getMainParentPath(prevPath, newPath);
      if (newPath[mainParent.length] > prevPath[mainParent.length] && mainParent.length === prevPath.length - 1) {
        newPath[mainParent.length] -= 1;
      }
    }

    const addingItemIndex = newPath[newPath.length - 1];
    const addingChildrenLink = getItemLinkByPath(copyFormula, newPath.slice(0, -1)).children;

    // If formula is empty then add all item children to formula
    if (
      action === 'add' &&
      data.children &&
      data.children.length &&
      !currentFormula.children.length
    ) {
      copyFormula.children = data.children;
    } else {
      // Or add item by new path
      addingChildrenLink.splice(
        addingItemIndex,
        0,
        data
      );
    }

    const prevParentLink = getItemLinkByPath(copyFormula, prevPath.slice(0, -1));
    if (
      prevParentLink.children.length === 1 &&
      prevParentLink.children[0].children &&
      prevParentLink.children[0].children.length
    ) {
      prevParentLink.children = prevParentLink.children[0].children;

      if (prevPath.slice(0, -1).join('-') === newPath.slice(0, prevPath.length - 1).join('-')) {
        newPath.splice(prevPath.length - 1, 1);
      }
    }

    this.changeFormulaSelectedItems({
      selectedItem: null,
      selectedList: null
    });

    this.updateFormula(copyFormula);

    if (data.children && data.children.length && !currentFormula.children.length) {
      this.changeFormulaSelectedItems({
        selectedList: {
          parentPath: [],
          indexes: copyFormula.children.map((item, index) => index)
        }
      });
    } else {
      const withoutFocus =
        action === 'move' ||
        (
          action === 'add' &&
          ['ta_list', 'ta_list_advertisers'].includes(data.condition.type) &&
          data.condition.values &&
          data.condition.values.length
        );

      this.changeFormulaSelectedItems({
        selectedItem: {
          type: 'item',
          path: newPath
        }
      }, !withoutFocus);
    }
  };

  changeFormulaItemTitle = (path, title) => {
    const {filterData} = this.props;
    const {editingSavedFormula, globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    const currentFormula = editingSavedFormula
      ? editingSavedFormula.data
      : filterData.direction === 'base'
        ? globalFilter[globalFilterMode].data
        : cellFormula[cellFormulaMode].data;

    const copyFormula = cloneObject(currentFormula);

    const itemLink = getItemLinkByPath(copyFormula, path);

    itemLink.title = title;

    this.updateFormula(copyFormula);
  };

  getFieldByPath = path => {
    const pathString = typeof path === 'string' ? path : path.join('-');

    return this._formulaEditor.fieldRefs[pathString] || null;
  };

  focusFieldByPath = path => {
    const _field = this.getFieldByPath(path);

    if (_field) {
      _field.focus();
    }
  };

  focusSelectedItem = () => {
    const {formulaSelectedItem} = this.state;

    if (formulaSelectedItem) {
      this.focusFieldByPath(formulaSelectedItem.path);
    }
  };

  changeFormulaSelectedItems = ({selectedItem, selectedList}, focusField = true) => {
    if (selectedList) {
      selectedList.indexes = sortNumberArray(selectedList.indexes);
    }

    this.setState({
      formulaSelectedItem: selectedItem,
      formulaSelectedList: selectedList
    }, () => {
      if (selectedItem && focusField) {
        this.focusFieldByPath(selectedItem.path);
      }
    });
  };

  toggleFormulaMode = (mode = 'ta') => {
    const {editingSavedFormula, formulaIsOpen, cellFormulaMode} = this.state;

    if (editingSavedFormula) {
      return;
    }

    const status = !formulaIsOpen || cellFormulaMode !== mode;

    this.setState({
      formulaIsOpen: status,
      cellFormulaMode: mode
    });

    clearTimeout(this.gettingNumberOfTaStatisticsApiTimer);

    if (mode === 'ta') {
      this.resetNumberOfTaStatistics();
    }

    if (status) {
      this.clearSelectedFilterItems();
    }
  };

  clearSelectedFilterItems = () => {
    const {currentSelectedCategory} = this.getFilterGroups();
    const {
      selectedDimensions,
      selectedTargetAudiences
    } = this.getSelectedItems(currentSelectedCategory);

    if (
      selectedDimensions.length > 1 ||
      (
        selectedDimensions.length === 1 &&
        !['ta_list', 'ta_list_advertisers'].includes(selectedDimensions[0].type) &&
        !selectedDimensions[0].list.length
      )
    ) {
      this.setState({
        selectedDimensions: [],
        selectedTargetAudiences: []
      });
    } else if (selectedTargetAudiences.length) {
      this.setState({
        selectedTargetAudiences: []
      });
    }
  };

  changeGlobalFilterMode = (globalFilterMode) => {
    this.setState({
      globalFilterMode
    });

    clearTimeout(this.gettingNumberOfTaStatisticsApiTimer);

    if (globalFilterMode === 'ta') {
      this.resetNumberOfTaStatistics();
    }
  };

  changeCellFormulaTitle = (event) => {
    const {cellFormula, cellFormulaMode} = this.state;

    this.setState({
      cellFormula: {
        ...cellFormula,
        [cellFormulaMode]: {
          ...cellFormula[cellFormulaMode],
          title: event.target.value.trim()
        }
      }
    });
  };

  clearFormula = () => {
    const {filterData} = this.props;
    const {globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    if (filterData.direction === 'base') {
      this.setState({
        globalFilter: {
          ...globalFilter,
          [globalFilterMode]: {
            title: '',
            data: {
              children: []
            },
            errorPaths: []
          }
        }
      }, () => {
        this.recalculateFormulaEditorHeight();
      });
    } else {
      this.setState({
        cellFormula: {
          ...cellFormula,
          [cellFormulaMode]: {
            title: '',
            data: {
              children: []
            },
            errorPaths: []
          }
        }
      }, () => {
        this.recalculateFormulaEditorHeight();
      });
    }

    if (cellFormulaMode === 'ta') {
      this.resetNumberOfTaStatistics();
    }

    if (this._formulaTitle) {
      this._formulaTitle.value = '';
    }
  };

  downloadMemberList = async (id, title) => {
    const {catalog} = this.props;

    const memberListResponse = await API.memberLists.downloadById(catalog, id);

    if (memberListResponse.status === 204) {
      alert.warning(
        localizeMessage({
          id: 'persona.table.filter.alerts.warningMemberIdListNotFound'
        }, {
          memberIdListTitle: title
        })
      );

      return;
    }

    const blob = await memberListResponse.blob();

    FileSaver.saveAs(blob, `members-${id}.txt`);
  };

  openEditFormulaMode = (type, {id, title, children, isSaved = false}) => {
    const {locale} = this.props;
    const {selectedCategory, selectedGroup, selectedDimensions} = this.state;

    const path = [selectedCategory];

    if (type !== 'group' && selectedGroup) {
      path.push(selectedGroup);
    }

    if (type !== 'group' && type !== 'dimension' && selectedDimensions.length === 1) {
      path.push(selectedDimensions[0]);
    }

    this.setState({
      editingSavedFormula: {
        id,
        title: title[locale],
        isSaved,
        path,
        data: {
          title: title[locale],
          children: restructureCubeChildren(children, true)
        },
        errorPaths: []
      }
    }, () => {
      this._formulaTitle.value = title[locale];

      this.recalculateFormulaEditorHeight();
      this.updateNumberOfTaStatistics();
    });
  };

  cancelFormulaEditing = () => {
    const {filterData} = this.props;
    const {cellFormula, cellFormulaMode, formulaIsOpen} = this.state;

    this.setState({
      editingSavedFormula: null
    }, () => {
      if (formulaIsOpen) {
        if (filterData.direction !== 'base') {
          this._formulaTitle.value = cellFormula[cellFormulaMode].title;
        }

        this.recalculateFormulaEditorHeight();
      }
    });
  };

  insertFormula = async () => {
    // const {filterData, insertFormula, savedFormulas, getAccessRights} = this.props;
    const {locale, filterData, insertFormula, getAccessRights} = this.props;
    const {globalFilter, cellFormula, globalFilterMode, cellFormulaMode} = this.state;

    const currentFormula =
      filterData.direction === 'base'
        ? globalFilter[globalFilterMode]
        : cellFormula[cellFormulaMode];

    const formulaValidationResult = this.validateFormula(currentFormula.title, currentFormula.data);

    if (!formulaValidationResult.status) {
      if (filterData.direction !== 'base') {
        this.setState({
          cellFormula: {
            ...cellFormula,
            [cellFormulaMode]: {
              ...cellFormula[cellFormulaMode],
              errorPaths: formulaValidationResult.errorPaths || []
            }
          }
        });
      }

      return;
    }

    // if (savedFormulas.some(formula => formula.title === currentFormula.title)) {
    //   alert.warning(localizeMessage({
    //     id: 'persona.table.filter.alerts.errorFormulaNameIsTaken'
    //   }));
    //
    //   return;
    // }

    const accessRights = await getAccessRights('formula');

    if (!accessRights) {
      return;
    }

    this.setState({
      formulaSaving: true
    });

    const memberLists = [];
    const savingFormula = prepareFormula(locale, currentFormula.data, memberLists);

    try {
      const savedFormula = await insertFormula(
        currentFormula.title,
        {
          ...savingFormula,
          title: currentFormula.title
        },
        accessRights,
        memberLists
      );

      alert.success(localizeMessage({
        id: 'persona.table.filter.alerts.successfulFormulaSaving'
      }, {
        formulaName: savedFormula.title
      }));

      this.setState({
        formulaSaving: false
      });

      if (filterData.direction === 'ta') {
        this.closeFilter();
      }
    } catch (error) {
      console.error(error);

      alert.error(localizeMessage({
        id: 'persona.table.filter.alerts.errorFormulaWasNotSaved'
      }));

      this.setState({
        formulaSaving: false
      });
    }
  };

  saveUpdatingFormula = async () => {
    // const {updateFormula, savedFormulas, getAccessRights} = this.props;
    const {locale, updateFormula, getAccessRights} = this.props;
    const {editingSavedFormula} = this.state;

    if (!editingSavedFormula) {
      return;
    }

    const formulaValidationResult = this.validateFormula(editingSavedFormula.title, editingSavedFormula.data);

    if (!formulaValidationResult.status) {
      return;
    }

    // if (
    //   savedFormulas.some(formula => (
    //     formula.title[locale] === editingSavedFormula.title &&
    //     formula.id !== editingSavedFormula.id
    //   ))
    // ) {
    //   alert.warning(localizeMessage({
    //     id: 'persona.table.filter.alerts.errorFormulaNameIsTaken'
    //   }));
    //
    //   return;
    // }

    const accessRights = await getAccessRights('formula', editingSavedFormula);

    if (!accessRights) {
      return;
    }

    this.setState({
      formulaEditing: true
    });

    const memberLists = [];
    const updatingFormula = prepareFormula(locale, editingSavedFormula.data, memberLists);

    try {
      const savedFormula = await updateFormula(
        editingSavedFormula.id,
        editingSavedFormula.title,
        updatingFormula,
        editingSavedFormula.isSaved,
        editingSavedFormula.path,
        accessRights,
        memberLists
      );

      alert.success(localizeMessage({
        id: 'persona.table.filter.alerts.successfulFormulaEditing'
      }, {
        formulaName: savedFormula.title
      }));

      this.cancelFormulaEditing();
    } catch (error) {
      console.error(error);

      alert.error(localizeMessage({
        id: 'persona.table.filter.alerts.errorFormulaWasNotEdited'
      }));
    }

    this.setState({
      formulaEditing: false
    });
  };

  deleteSavedFormula = (id) => {
    const {deleteFormula} = this.props;
    const {editingSavedFormula} = this.state;

    if (editingSavedFormula && id === editingSavedFormula.id) {
      this.cancelFormulaEditing();
    }

    return deleteFormula(id);
  };

  recalculateFormulaEditorHeight = () => {
    if (!this._formulaContainer || !this._formulaEditor || !this._formulaEditor._wrapper) {
      return;
    }

    const formulaCurrentHeight = this._formulaEditor._wrapper.offsetHeight;

    let currentFormulaContainerHeight =
      (Math.round(formulaCurrentHeight / FORMULA_CONTAINER_STEP) + 1) * FORMULA_CONTAINER_STEP +
      FORMULA_CONTAINER_VERTICAL_OFFSET;
    currentFormulaContainerHeight = Math.max(currentFormulaContainerHeight, FORMULA_CONTAINER_MIN_HEIGHT);
    currentFormulaContainerHeight = Math.min(currentFormulaContainerHeight, FORMULA_CONTAINER_MAX_HEIGHT);

    this._formulaContainer.style.height = `${currentFormulaContainerHeight}px`;
  };

  openMemberListModal = ({id = null, type = null}, location = null) => {
    this.setState({
      memberListModal: {
        id,
        type,
        location
      }
    });
  };

  closeMemberListModal = () => {
    this.setState({
      memberListModal: null
    });
  };

  openMmiModal = () => {
    this.setState({
      showMmiModal: true
    });
  };

  closeMmiModal = () => {
    this.setState({
      showMmiModal: false
    });
  };

  render () {
    const {
      locale,
      catalog,
      filterData,
      isAdmin,
      loadEventHints
    } = this.props;
    const {
      searchString,
      containerPercentHeight,
      globalFilter,
      formulaTaStatisticsNumber,
      cellFormula,
      formulaIsOpen,
      globalFilterMode,
      cellFormulaMode,
      formulaSelectedItem,
      formulaSelectedList,
      formulaSaving,
      formulaEditing,
      editingSavedFormula,
      memberListModal,
      showMmiModal
    } = this.state;

    const {
      categoryList,
      currentSelectedCategory
    } = this.getFilterGroups();

    const {
      selectedCategory,
      selectedGroup,
      selectedDimensions,
      selectedTargetAudiences
    } = this.getSelectedItems(currentSelectedCategory);

    const globalFilterModeTabs = [];

    if (!catalog.splitEvents) {
      globalFilterModeTabs.push({
        label: <LocalizedMessage
          id='persona.table.filter.formulaEditor.types.event'
        />,
        value: 'event'
      });
    } else {
      globalFilterModeTabs.push({
        label: <LocalizedMessage
          id='persona.table.filter.formulaEditor.types.event.tv'
        />,
        value: 'event-tv'
      });
      globalFilterModeTabs.push({
        label: <LocalizedMessage
          id='persona.table.filter.formulaEditor.types.event.web'
        />,
        value: 'event-web'
      });
    }

    globalFilterModeTabs.push({
      label: <LocalizedMessage
        id='persona.table.filter.formulaEditor.types.ta'
      />,
      value: 'ta'
    });

    const currentFormula =
      editingSavedFormula || (
        filterData.direction === 'base'
          ? globalFilter[globalFilterMode]
          : cellFormula[cellFormulaMode]
      );

    const currentFormulaIsClear = !currentFormula.data.children.length;

    const canSaveFormula =
      filterData.direction === 'base'
        ? globalFilterMode === 'ta'
        : cellFormulaMode === 'ta';

    return (
      <div
        ref={this.setContainerRef}
        className={classes.Container}
        style={{
          height: `${containerPercentHeight}%`
        }}
      >
        <Mover
          onMove={this.handleChangeProportion}
          onStop={this.handleStopProportionChanging}
        />
        <div
          className={cx(
            'content',
            'content-full-height'
          )}
        >
          <div className={classes.Content}>
            <div className={classes.Header}>
              <div className={classes.HeaderButtons}>
                <Button
                  theme='transparent'
                  onClick={this.closeFilter}
                >
                  <LocalizedMessage
                    id='persona.table.filter.header.buttons.cancel'
                  />
                </Button>
                {
                  filterData.direction !== 'ta' ?
                    filterData.direction !== 'base' ?
                      (!filterData.formula && filterData.like !== 'cell')
                        ? (
                          <Button
                            disabled={editingSavedFormula}
                            onClick={this.addOrUpdateCell}
                          >
                            <LocalizedMessage
                              id='persona.table.filter.header.buttons.add'
                            />
                          </Button>
                        )
                        : (
                          <Button
                            disabled={editingSavedFormula}
                            onClick={this.addOrUpdateCell}
                          >
                            <LocalizedMessage
                              id='persona.table.filter.header.buttons.update'
                            />
                          </Button>
                        )
                      : (
                        <Button
                          disabled={editingSavedFormula}
                          onClick={this.applyGlobalFilter}
                        >
                          <LocalizedMessage
                            id='persona.table.filter.header.buttons.apply'
                          />
                        </Button>
                      )
                    : null
                }
              </div>
              <ul className={classes.HeaderCategories}>
                {
                  categoryList.map(category => (
                    <li
                      key={category.id}
                      className={cx(
                        classes.HeaderCategoriesItem,
                        {
                          [classes.HeaderCategoriesItemSelected]: (
                            selectedCategory &&
                            selectedCategory.id === category.id
                          ),
                          [classes.HeaderCategoriesItemDisabled]: category.disabled
                        }
                      )}
                      onClick={!category.disabled ? () => this.selectFilterCategory(category) : null}
                    >
                      {category.title[locale]}
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className={classes.Form}>
              <div className={classes.FormHeader}>
                <div className={cx(
                  classes.FormHeaderSearch,
                  'icon-search'
                )}>
                  <LocalizedMessage
                    id='persona.table.filter.findMeasurement'
                  >
                    {localizedMessage => (
                      <input
                        ref={this.setSearchFieldRef}
                        type='text'
                        placeholder={localizedMessage}
                        className={classes.FormHeaderSearchField}
                        value={searchString}
                        onChange={this.onChangeSearchField}
                      />
                    )}
                  </LocalizedMessage>
                  <div
                    className={cx(
                      classes.FormHeaderSearchResetBtn,
                      'icon-close',
                      {
                        [classes.FormHeaderSearchResetBtnVisible]: searchString.length
                      }
                    )}
                    onClick={this.resetSearchField}
                  />
                </div>
                {
                  // TODO: Change the following link:
                  selectedGroup && selectedGroup.title[locale].toLowerCase().indexOf('mindex') > -1 ?
                    <div className={classes.FormHeaderRight}>
                      <Button
                        size='small'
                        icon='add'
                        theme='transparent'
                        onClick={this.openMmiModal}
                      >
                        <LocalizedMessage
                          id='persona.table.filter.formulaEditor.mmi.btn'
                        />
                      </Button>
                    </div>
                    : null
                }
                {
                  filterData.direction !== 'base' &&
                  filterData.direction !== 'ta' ?
                    <div className={classes.FormHeaderFormulaMode}>
                      <p className={classes.FormHeaderFormulaModeLabel}>
                        <LocalizedMessage
                          id='persona.table.filter.formulaEditor.mode'
                        />:
                      </p>
                      <ul className={classes.FormHeaderFormulaModeList}>
                        <li
                          className={cx(
                            classes.FormHeaderFormulaModeItem,
                            {
                              [classes.FormHeaderFormulaModeItemSelected]: (
                                editingSavedFormula ||
                                (
                                  formulaIsOpen &&
                                  cellFormulaMode === 'ta'
                                )
                              ),
                              [classes.FormHeaderFormulaModeItemDisabled]: editingSavedFormula
                            }
                          )}
                          onClick={() => this.toggleFormulaMode('ta')}
                        >
                          <LocalizedMessage
                            id='persona.table.filter.formulaEditor.mode.ta'
                          />
                        </li>
                        {
                          catalog.url !== 'web-index' ?
                            <li
                              className={cx(
                                classes.FormHeaderFormulaModeItem,
                                {
                                  [classes.FormHeaderFormulaModeItemSelected]: (
                                    !editingSavedFormula &&
                                    (
                                      formulaIsOpen &&
                                      cellFormulaMode === 'event-tv'
                                    )
                                  ),
                                  [classes.FormHeaderFormulaModeItemDisabled]: editingSavedFormula
                                }
                              )}
                              onClick={() => this.toggleFormulaMode('event-tv')}
                            >
                              <LocalizedMessage
                                id='persona.table.filter.formulaEditor.mode.eventsTv'
                              />
                            </li>
                            : null
                        }
                        {
                          catalog.url !== 'tv-index' ?
                            <li
                              className={cx(
                                classes.FormHeaderFormulaModeItem,
                                {
                                  [classes.FormHeaderFormulaModeItemSelected]: (
                                    !editingSavedFormula &&
                                    (
                                      formulaIsOpen &&
                                      cellFormulaMode === 'event-web'
                                    )
                                  ),
                                  [classes.FormHeaderFormulaModeItemDisabled]: editingSavedFormula
                                }
                              )}
                              onClick={() => this.toggleFormulaMode('event-web')}
                            >
                              <LocalizedMessage
                                id='persona.table.filter.formulaEditor.mode.eventsWeb'
                              />
                            </li>
                            : null
                        }
                      </ul>
                    </div>
                    : null
                }
                {
                  /*
                <div className={classes.FormHeaderRight}>
                  <p className={classes.FormHeaderRightTitle}>
                    Сортировка:
                  </p>
                  <Dropdown
                    className={classes.FormHeaderRightDropdown}
                    currentValueClassName={classes.FormHeaderRightDropdownCurrentValue}
                    optionClassName={classes.FormHeaderRightDropdownOption}
                    options={[{value: 'date', label: 'по дате создания'}]}
                    onChange={(value) => console.log(value)}
                  />
                </div>
                   */
                }
              </div>
              <div className={classes.FormContent}>
                <div className={classes.FormGroups}>
                  <List
                    ref={this.setGroupListRef}
                    locale={locale}
                    data={currentSelectedCategory}
                    isAdmin={isAdmin}
                    selectedIds={selectedGroup ? [selectedGroup.id] : []}
                    onClick={this.selectFilterGroup}
                    downloadMemberList={this.downloadMemberList}
                    editSavedFormula={data => this.openEditFormulaMode('group', data)}
                    deleteSavedFormula={this.deleteSavedFormula}
                  />
                </div>
                <div className={classes.FormSubContent}>
                  {
                    filterData.direction === 'base' ||
                    filterData.direction === 'ta' ||
                    formulaIsOpen ||
                    !!editingSavedFormula ?
                      <div
                        ref={this.setFormulaContainerRef}
                        className={cx(
                          classes.FormFormula,
                          {
                            [classes.FormFormulaBase]: filterData.direction === 'base'
                          }
                        )}
                      >
                        <div className={classes.FormFormulaButtons}>
                          {
                            editingSavedFormula ?
                              <Button
                                size='small'
                                type='button'
                                icon='bookmark'
                                disabled={formulaEditing}
                                className={classes.FormFormulaSaveBtn}
                                onClick={this.saveUpdatingFormula}
                              >
                                <LocalizedMessage
                                  id={`persona.table.filter.formulaEditor.${formulaEditing ? 'editing' : 'edit'}`}
                                />
                              </Button>
                              : null
                          }
                          {
                            !editingSavedFormula && canSaveFormula ?
                              <Button
                                size='small'
                                type='button'
                                icon='bookmark'
                                disabled={formulaSaving}
                                className={classes.FormFormulaSaveBtn}
                                onClick={this.insertFormula}
                              >
                                <LocalizedMessage
                                  id={`persona.table.filter.formulaEditor.${formulaSaving ? 'saving' : 'save'}`}
                                />
                              </Button>
                              : null
                          }
                          {
                            editingSavedFormula ?
                              <Button
                                size='small'
                                type='reset'
                                theme='transparent'
                                disabled={formulaEditing}
                                className={classes.FormFormulaCancelBtn}
                                onClick={this.cancelFormulaEditing}
                              >
                                <LocalizedMessage
                                  id='persona.table.filter.formulaEditor.cancel'
                                />
                              </Button>
                              :
                              <Button
                                size='small'
                                type='reset'
                                theme='transparent'
                                disabled={currentFormulaIsClear || formulaEditing || formulaSaving}
                                className={classes.FormFormulaClearBtn}
                                onClick={this.clearFormula}
                              >
                                <LocalizedMessage
                                  id='persona.table.filter.formulaEditor.clear'
                                />
                              </Button>
                          }
                        </div>
                        {
                          (
                            filterData.direction !== 'base' ||
                            (filterData.direction === 'base' && globalFilterMode === 'ta')
                          ) ?
                            <div className={classes.FormFormulaHeader}>
                              <div className={classes.FormFormulaTitle}>
                                <label
                                  htmlFor='formula-title'
                                  className={classes.FormFormulaTitleLabel}
                                >
                                  {
                                    filterData.direction === 'ta' ||
                                    (editingSavedFormula && !editingSavedFormula.isSaved)
                                      ? <LocalizedMessage
                                        id='persona.table.filter.formulaEditor.taName'
                                      />
                                      : <LocalizedMessage
                                        id='persona.table.filter.formulaEditor.formulaName'
                                      />
                                  }
                                </label>
                                <input
                                  key={`title-${
                                    editingSavedFormula
                                      ? 'saved-formula'
                                      : (
                                        filterData.direction === 'base' ? globalFilterMode : 'cell-' + cellFormulaMode
                                      )}`}
                                  id='formula-title'
                                  ref={this.setFormulaTitleRef}
                                  type='text'
                                  maxLength={255}
                                  defaultValue={getTitle(currentFormula, locale)}
                                  className={classes.FormFormulaTitleField}
                                  onChange={event => {
                                    if (
                                      editingSavedFormula
                                    ) {
                                      this.changeSavedFormulaTitle(event);
                                    } else if (filterData.direction === 'base') {
                                      this.changeGlobalFilterTitle(globalFilterMode, event);
                                    } else {
                                      this.changeCellFormulaTitle(event);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            : null
                        }
                        <FormulaEditor
                          ref={this.setFormulaEditorRef}
                          locale={locale}
                          catalog={catalog}
                          formula={currentFormula.data}
                          withDateRange={filterData.direction !== 'base' || globalFilterMode === 'ta'}
                          withSupportOfMemberLists={
                            (filterData.direction !== 'base' && cellFormulaMode === 'ta') ||
                            (filterData.direction === 'base' && globalFilterMode === 'ta')
                          }
                          selectedItem={formulaSelectedItem}
                          selectedList={formulaSelectedList}
                          errorPaths={currentFormula.errorPaths}
                          formulaTaStatisticsNumber={
                            editingSavedFormula ||
                            (filterData.direction === 'base' && globalFilterMode === 'ta') ||
                            (filterData.direction !== 'base' && cellFormulaMode === 'ta')
                              ? formulaTaStatisticsNumber
                              : null
                          }
                          memberListModalIsOpen={!!memberListModal}
                          updateNumberOfTaStatistics={this.updateNumberOfTaStatistics}
                          getFieldByPath={this.getFieldByPath}
                          changeFormulaSelectedItems={this.changeFormulaSelectedItems}
                          removeErrorPath={this.removeErrorPath}
                          updateFormula={this.updateFormula}
                          changeFormula={this.changeFormula}
                          openMemberListModal={this.openMemberListModal}
                          loadEventHints={loadEventHints}
                        />
                        {
                          !editingSavedFormula &&
                          filterData.direction === 'base' ?
                            <Tabs
                              ref={this.setGlobalFilterModeTabsRef}
                              className={classes.FormFormulaTabs}
                              optionClassName={classes.FormFormulaTabsItem}
                              optionSelectedClassName={classes.FormFormulaTabsItemSelected}
                              options={globalFilterModeTabs}
                              defaultValue={globalFilterMode}
                              onChange={this.changeGlobalFilterMode}
                            />
                            : null
                        }
                      </div>
                      : null
                  }
                  <div className={classes.FormSubLists}>
                    {
                      selectedGroup &&
                      currentSelectedCategory.list.some(group => group.id === selectedGroup.id) &&
                      selectedGroup.list &&
                      selectedGroup.list.length ?
                        <div
                          className={cx(
                            classes.FormDimensions,
                            {
                              [classes.FormDimensionsHalf]: (
                                (
                                  filterData.direction !== 'base' ||
                                  globalFilterMode === 'ta'
                                ) &&
                                selectedDimensions.length === 1 &&
                                selectedDimensions[0].list &&
                                selectedDimensions[0].list.length
                              )
                            }
                          )}
                        >
                          <List
                            ref={this.setDimensionListRef}
                            locale={locale}
                            data={selectedGroup}
                            isAdmin={isAdmin}
                            selectedIds={selectedDimensions.map(dimension => dimension.id)}
                            formulaIsOpen={
                              !!editingSavedFormula ||
                              formulaIsOpen ||
                              filterData.direction === 'base' ||
                              filterData.direction === 'ta'
                            }
                            changeFormula={this.changeFormula}
                            onClick={
                              (dimension, e) => this.selectFilterDimensions(e, dimension, selectedGroup.list)
                            }
                            downloadMemberList={this.downloadMemberList}
                            editSavedFormula={data => this.openEditFormulaMode('dimension', data)}
                            deleteSavedFormula={this.deleteSavedFormula}
                          />
                        </div>
                        : null
                    }
                    {
                      (
                        (
                          filterData.direction === 'base' &&
                          globalFilterMode === 'ta'
                        ) ||
                        filterData.direction !== 'base'
                      ) &&
                      selectedGroup &&
                      selectedDimensions.length === 1 &&
                      currentSelectedCategory.list.some(group =>
                        group.list.some(dimension =>
                          dimension.id === selectedDimensions[0].id
                        )
                      ) &&
                      selectedDimensions[0].list &&
                      selectedDimensions[0].list.length ?
                        <div className={classes.FormTargetAudience}>
                          <List
                            ref={this.setTaListRef}
                            locale={locale}
                            data={selectedDimensions[0]}
                            isAdmin={isAdmin}
                            selectedIds={selectedTargetAudiences.map(TA => TA.id)}
                            formulaIsOpen={
                              !!editingSavedFormula ||
                              formulaIsOpen ||
                              filterData.direction === 'base' ||
                              filterData.direction === 'ta'
                            }
                            changeFormula={this.changeFormula}
                            onClick={(TA, e) => this.selectFilterTargetAudience(e, TA, selectedDimensions[0].list)}
                            downloadMemberList={this.downloadMemberList}
                            editSavedFormula={data => this.openEditFormulaMode('ta', data)}
                            deleteSavedFormula={this.deleteSavedFormula}
                          />
                        </div>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DragLayer
          locale={locale}
        />
        {
          memberListModal ?
            <MemberListModal
              data={memberListModal}
              catalog={catalog}
              changeFormula={this.changeFormula}
              changeFormulaItemTitle={this.changeFormulaItemTitle}
              close={this.closeMemberListModal}
            />
            : null
        }
        {
          showMmiModal ?
            <MmiModal
              close={this.closeMmiModal}
            />
            : null
        }
      </div>
    );
  }
}

export default Filters;
