import React from 'react';
import {connect} from 'react-redux';
import {selectLocale} from '../../modules/locale';
import LocaleSwitcher from './LocaleSwitcher';

const mapDispatchToProps = {
  selectLocale
};

const mapStateToProps = (state) => ({
  locale: state.locale.lang
});

export default process.env.NODE_ENV === 'test'
  ? props => <LocaleSwitcher {...props} locale='en' selectLocale={jest.fn()} />
  : connect(mapStateToProps, mapDispatchToProps)(LocaleSwitcher);
