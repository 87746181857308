import React from 'react';
import classes from './OneLineLoader.module.scss';

const OneLineLoader = () => (
  <div className={classes.Container}>
    <span
      className={classes.Circle}
    />
    <span
      className={classes.Circle}
    />
    <span
      className={classes.Circle}
    />
  </div>
);

export default OneLineLoader;
