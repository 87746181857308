import {Component} from 'react';
import PropTypes from 'prop-types';

class BeforeUnload extends Component {
  static propTypes = {
    children: PropTypes.any,
    onBeforeUnload: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: null
  };

  componentDidMount () {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = e => {
    const {onBeforeUnload} = this.props;

    const returnValue =
      typeof onBeforeUnload === 'function'
        ? onBeforeUnload(e)
        : null;

    if (typeof returnValue === 'string') {
      e.returnValue = returnValue;

      return returnValue;
    }
  };

  render () {
    return this.props.children;
  }
}

export default BeforeUnload;
